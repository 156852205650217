
<script>

 export default {
   name: 'BottomButtonBar',
 }

</script>

<template>
  <b-alert
    show
    class="is-bottom-button-bar bottom-button-bar-height position-fixed fixed-bottom p-0 my-0 rounded-0"
    variant="light"
  >
    <b-container>
      <b-row class="mt-3 mb-2">
        <slot></slot>
      </b-row>
    </b-container>
  </b-alert>
</template>

<style scoped>

 .bottom-button-bar-height {
   height: 4.2rem;
 }
 .is-bottom-button-bar {
   border-top: solid 1px #EEE;
   z-index: 1000;
 }

</style>
