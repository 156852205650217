
import WithCopyPasteQuestion from '@/components/quizmaster/mixins/WithCopyPasteQuestion';
import MasterQuestion from '@/models/master/question.js';

// Duplicated in back/src/store/pub-quiz.js
const headerColumnsText = `Points (empty for default)	Question	Image URL	Free-text Answer	Multiple Choice Answer	Answer Trivia	Auto-mark (similar/same/never)	Reveal Answer Trivia	Reveal Correct Answer (1/0)`;

export default {

  mixins: [
     WithCopyPasteQuestion,
  ],

  methods: {
    importCopyColumns() {
      this.$copyText(headerColumnsText);
      this.$bvToast.toast(
        "Paste the column headers into your spreadsheet and add rows with your questions.",
        {
          title: "Column headers copied",
          variant: "success",
        },
      );
    },

    importCopySampleQuestions() {
      this.$copyText(`Points (empty for default)\tQuestion\tImage URL\tFree-text Answer\tMultiple Choice Answer\tAnswer Trivia\tAuto-mark (similar/same/never)\tReveal Answer Trivia\tReveal Correct Answer (1/0)\t
\tWhat ball sport was played on the Moon during the Apollo 14 mission?\thttps://www.teslarati.com/wp-content/uploads/2019/07/Apollo-14-Lunar-Module-LM-NASA-pano-1-crop-c.jpg\tGolf\t\tDuring the Apollo 14 mission, Alan Shepard decided to play golf on the Moon, but there was no green.\t\t"![](https://i.pinimg.com/originals/88/54/04/8854044de9fbd289d65f5ef382030cec.jpg)

---
* https://twitter.com/Rainmaker1973/status/1357988857527025664
* https://www.youtube.com/watch?v=t_jYOubJmfM
"\t\t
\t"Someone on twitter asked: 'So what happens if you get your first covid shot, but then a shark bites your arm off just above where the needle went in, like about 47 minutes after you got the shot? Does that dose still count?'. And a scientist chimed in to say:"\thttps://www.deeperblue.com/wp-content/uploads/2020/09/Change.org-Shark-Petition-1068x611.png\t\t"-- The dose counts
The dose doesn't count"\t\t\t"Prof. Akiko Iwasaki (@VirusesImmunity):

> Actually, we did this experiment when I was a graduate student
> \\
> \\
> We found that removal of the muscle bundle after 10 minutes of vaccine inoculation did not affect antibody responses. Mind you, this was in mice using a DNA vaccine, surgery not shark.

https://twitter.com/VirusesImmunity/status/1370400128016773124


https://www.deeperblue.com/wp-content/uploads/2020/09/Change.org-Shark-Petition-1068x611.png"\t\t
`);
      this.$bvToast.toast(
        "Paste the sample questions into your spreadsheet to see examples of how to format things.",
        {
          title: "Sample questions copied",
          variant: "success",
        },
      );
    },

    masterQuestionsFromSpreadsheet(text) {
      const rows = text.split("\n");
      let rowNumber = 0;
      const questions = rows
            .filter(row => row !== headerColumnsText)
            .filter(row => row !== "")
            .map(row => row.split("\t"))
            .filter(values => ! ( values[0] === "Points" && values[1] === "Question" ))
            .map(values => {
              rowNumber++;
              if ( values.length < 9 ) throw `Question ${rowNumber} has ${values.length} columns instead of 9, so not adding any questions. Clipboard: ${values.map(v => `(${v})`).join("|")}`;
              let [
                points,
                question,
                imageUrl,
                correctAnswer,
                correctMultiAnswer,
                answerTrivia,
                autoMarkCorrectStyle,
                revealAnswerTrivia,
                shouldRevealAnswer,
              ] = values.map(value => value ? value.trim() : value);

              points = Number.parseFloat(points);
              if ( Number.isNaN(points) ) points = null;

              correctMultiAnswer = correctMultiAnswer.replace(/\\n/g, "\n");

              const answerEntryType = (correctMultiAnswer !== "") ? "multiChoice" : "freeText";

              if ( ! ["similar", "same", "never"].find(
                item => item === (autoMarkCorrectStyle || ""),
              ) ) {
                autoMarkCorrectStyle = "similar";
              }

              revealAnswerTrivia = revealAnswerTrivia.replace(/\\n/g, "\n");

              shouldRevealAnswer = {
                "0": false,
                "1": true,
              }[shouldRevealAnswer];
              if ( shouldRevealAnswer === undefined ) shouldRevealAnswer = true;

              return new MasterQuestion({
                points,
                question,
                imageUrl,
                correctAnswer,
                correctMultiAnswer,
                answerTrivia,
                autoMarkCorrectStyle,
                revealAnswerTrivia,
                shouldRevealAnswer,
                answerEntryType,
              });
            });

      return questions;
    },

    async masterQuestionsFromClipboard() {
      const text = await this.getClipboard();
      return await this.$store.dispatch('content/fetchQuestionsFromCsv', text);
    },
  },
};
