
import _ from 'lodash';

import ModelBase from '../model-base.js';

import MasterQuizOrderedTeams from './quiz/ordered-teams.js';
import MasterTeamPlacement from './team-placement.js';
import TeamTeamPlacement from '../team/team-placement.js';
import MasterAnswerScoreSheet from '../master/answer-score-sheet.js';
import TeamAnswerSheet from '../team/answer-sheet.js';
import TeamSession from '../team/session.js';
import MasterQuestion from '../master/question.js';
import MasterQuestionsMetadata from '../master/questions-metadata.js';
import MasterQuestionStatusSheet from '../master/question-status-sheet.js';

// Note: because of Vue's reactivity, prototypes don't work at all, so
// the methods are declared on the instance, not on the prototype :/
export default class MasterQuiz extends ModelBase {

  // The Quiz is purely an orchestration object with no owned
  // state. That's all in the session and teamAnswerSheets.

  static createWithTeams(args = {}) {
    const masterAnswerScoreSheets
          =  args.masterAnswerScoreSheets
          || args.teamTeams.map(teamTeam => {
            return MasterQuiz.newMasterAnswerScoreSheetForTeam(
              args.session.id,
              args.masterAnswerSheet,
              teamTeam,
            );
          });
    const teamAnswerSheets
          =  args.teamAnswerSheets
          || args.teamTeams.map(teamTeam => {
            return MasterQuiz.newTeamAnswerSheetForTeam(
              args.session.id,
              args.masterAnswerSheet,
              teamTeam,
            );
          });

    return new MasterQuiz({
      ...args, ///JPL: should be unpacked to prevent random stuff going in here
      masterAnswerScoreSheets,
      teamAnswerSheets,
    });
  }

  ///JPL: should these be factory methods on the MasterAnswerSheet?
  static newMasterAnswerScoreSheetForTeam(sessionId, masterAnswerSheet, teamTeam) {
    return new MasterAnswerScoreSheet({
      sessionId: sessionId,
      teamId: teamTeam.id,
      questionIdToMasterAnswerScore: masterAnswerSheet.getQuestionIdToMasterAnswerScore(
        teamTeam,
      ),
    });
  }

  static newTeamAnswerSheetForTeam(sessionId, masterAnswerSheet, teamTeam) {
    return new TeamAnswerSheet({
      sessionId: sessionId,
      teamId: teamTeam.id,
      questionIdToTeamAnswer: masterAnswerSheet.getQuestionIdToTeamAnswer(
        teamTeam,
      ),
    });
  }

  static newMasterQuestionStatusSheet(sessionId, masterAnswerSheet) {
    return new MasterQuestionStatusSheet({
      sessionId: sessionId,
      questionIdToMasterQuestionStatus: masterAnswerSheet
        .getQuestionIdToMasterQuestionStatus(),
    });
  }

  constructor({
    id,
    masterAnswerSheet,
    teamAnswerSheets,
    masterAnswerScoreSheets,
    masterQuestionStatusSheet,
    session,
    teamTeams,
  }) {
    super();
    masterAnswerScoreSheets = this.ensureArrayObjects(
      masterAnswerScoreSheets,
      MasterAnswerScoreSheet,
    );
    teamAnswerSheets = this.ensureArrayObjects(
      teamAnswerSheets,
      TeamAnswerSheet,
    );
    masterQuestionStatusSheet =
      masterQuestionStatusSheet ||
      MasterQuiz.newMasterQuestionStatusSheet(session.id, masterAnswerSheet);
    masterQuestionStatusSheet = this.ensureObject(
      masterQuestionStatusSheet,
      MasterQuestionStatusSheet,
    );


    return {
      ...this,
      id,
      masterAnswerSheet,
      session,
      teamTeams,

      masterAnswerScoreSheets,
      teamAnswerSheets,
      masterQuestionStatusSheet,


      newMasterQuestionsMetadata() {
        return new MasterQuestionsMetadata({
          sessionId: this.session.id,
          masterAnswerScoreSheets: this.masterAnswerScoreSheets,
          teamAnswerSheets: this.teamAnswerSheets,
        });
      },

      // Return new TeamQuestionsMetadata object based on the
      // current state of the .masterQuestionsMetadata
      get teamQuestionsMetadata() {
        return this.newMasterQuestionsMetadata().asTeamQuestionsMetadata;
      },

      get masterTeamPlacements() {
        const placements = this.teamTeams.map(teamTeam => {
          return {
            teamTeam,
            totalScore: this.teamCurrentTotalScore(teamTeam),
          };
        });

        const orderedPlacements = _.sortBy(
          placements, [
            placement => 0 - placement.totalScore,
            placement => placement.teamTeam.name,
          ],
        );

        let placementOrdinal = 0;
        let previousTotalScore = -1;
        const finalPlacements = orderedPlacements.map(
          placement => {
            if ( previousTotalScore != placement.totalScore) {
              placementOrdinal++;
            }
            previousTotalScore = placement.totalScore;
            return new MasterTeamPlacement({
              ...placement,
              placementOrdinal,
            });
          },
        );

        return finalPlacements;
      },

      get teamTeamPlacements() {
        return this.masterTeamPlacements.map(
          masterTeamPlacement => new TeamTeamPlacement({
            ...masterTeamPlacement,
            teamName: masterTeamPlacement.teamTeam.name,
          }),
        );
      },

      get teamTeamsInPlacementOrder() {
        return this.masterTeamPlacements.map( placement => placement.teamTeam );
      },

      // Or undefined
      teamPlacementForTeamId(teamId) {
        return this.masterTeamPlacements.find(
          masterTeamPlacement => masterTeamPlacement.teamTeam.id === teamId,
        );
      },

      isPlacementOrdinalShared(placementOrdinal) {
        const placementOrdinalCount = this.placementOrdinalCount;
        return (placementOrdinalCount[ placementOrdinal ] || 0) > 1;
      },

      get placementOrdinalCount() {
        return _.countBy(this.masterTeamPlacements, "placementOrdinal");
      },

      teamSession(saneHtmlFromMarkdownCall) {
        const teamNames = this.teamTeams.map( t => t.name );

        const teamQuestions = this.teamQuestions(saneHtmlFromMarkdownCall);

        const sessionStatus = this.session.sessionStatus;

        const teamQuestionsMetadata = sessionStatus.isQuestionMetadataAvailableToTeams ?
              this.teamQuestionsMetadata :
              null;

        const teamPlacements = sessionStatus.isTeamPlacementAvailableToTeams ?
              this.teamTeamPlacements :
              null;

        const teamSession = new TeamSession({
          id: this.session.id,
          quizName: this.masterAnswerSheet.name,
          teamNames,
          totalQuestionCount: this.questionCount,
          sessionStatus,
          teamQuestions,
          teamQuestionsMetadata,
          teamPlacements,
          preloadImageUrl: this.preloadImageUrl,
        });

        return teamSession;
      },

      ///JPL: refactor this way further
      teamQuestions(saneHtmlFromMarkdownCall) {
        if ( this.session.currentQuestionNumber === undefined ) return [];
        const masterQuestionStatusSheet = this.masterQuestionStatusSheet;
        return this.masterAnswerSheet
          .questions.slice(0, this.session.currentQuestionNumber)
          .map((question, index) => {
            ///JPL: extract some of this to MasterQuestion
            question = new MasterQuestion(question);

            const points = this.masterAnswerSheet.actualPoints( question.points );

            let revealCorrectAnswer = null;
            let revealAnswerTriviaHtml = null;
            let shouldRevealAnswer = false;
            let shouldRevealAnswerScore = false;
            const isQuestionRevealed = masterQuestionStatusSheet
                  .isMasterQuestionRevealed(
                    this.masterAnswerSheet,
                    question,
                  );
            const isThisQuestionRevealed =
                  this.session.sessionStatus.hasRevealedAnswers ||
                  isQuestionRevealed;
            if ( isThisQuestionRevealed ) {
              shouldRevealAnswer = question.shouldRevealAnswer;
              shouldRevealAnswerScore = true;
              if ( question.shouldRevealAnswer ) {
                revealCorrectAnswer = question.revealCorrectAnswer;
              }

              ///JPL: maybe only if entered?
              revealAnswerTriviaHtml = saneHtmlFromMarkdownCall(
                question.revealAnswerTrivia,
              );
            }

            const answerOptions = question.teamAnswerOptions(
              isThisQuestionRevealed,
            );

            const canTeamSubmitAnswer = masterQuestionStatusSheet
                  .canTeamSubmitAnswerForQuestion(
                    this.masterAnswerSheet,
                    question,
                  );

            ///JPL: use the masterQuestion.newTeamAnswer here? missing ordinal?
            return {
              questionId: question.id,
              points: points,
              ordinal: index + 1,
              question: question.question,
              answerEntryType: question.answerEntryType,
              answerOptions,
              shouldRevealAnswer,
              shouldRevealAnswerScore,
              canTeamSubmitAnswer,
              revealCorrectAnswer,
              revealAnswerTriviaHtml,
              imageUrl: question.imageUrl,
            };
          });
      },

      // Return the first imageUrl if not started, or any imageUrl of
      // the NEXT question, or null if there isn't any
      get preloadImageUrl() {
        if ( this.session.sessionStatus.isNotStarted ) {
          return this.masterAnswerSheet.questions[0].imageUrl || null;
        }

        const masterQuestion = this.nextQuestion;
        if ( ! masterQuestion ) return null;

        return masterQuestion.imageUrl || null;
      },

      // If a team exists in teamAnswerSheets but not in teamTeams,
      // it's a new team
      didNewTeamArrive() {
       if ( ! this.teamAnswerSheets ) return false;

       for (const teamAnswerSheet of this.teamAnswerSheets) {
         const teamTeam = this.teamTeams.find(
           teamTeam => teamTeam.id === teamAnswerSheet.teamId
         );
         if ( ! teamTeam ) return true;
       }
       return false;
      },

      ///JPL: turn into getters
      currentQuestion() {
        const currentQuestionId = this.session.currentQuestionId;
        if ( ! currentQuestionId ) return null;
        return this.masterAnswerSheet.getQuestionById( currentQuestionId );
      },

      get currentQuestionNumber() {
        return this.session.currentQuestionNumber;
      },

      get nextQuestion() {
        const currentQuestion = this.currentQuestion();
        if ( ! currentQuestion ) return null;
        return this.masterAnswerSheet.getQuestionAfterId(
          currentQuestion.id,
        );
      },

      get questionCount() {
        return this.masterAnswerSheet.questions.length;
      },

      questionNumber(question) {
        const index = this.masterAnswerSheet.getQuestionIndex(question);
        if ( index < 0 ) return 0;
        return index + 1;
      },

      questionPoints(question) {
        const defaultPoints = this.masterAnswerSheet.defaultPoints;

        if ( ! question ) return defaultPoints;

        const points = question.points;
        if ( points !== undefined ) return points;

        return defaultPoints;
      },

      // The questions that have been asked
      get currentQuestions() {
        return this.masterAnswerSheet.getFirstQuestions(
          this.session.currentQuestionNumber,
        );
      },

      get isCurrentQuestionTheLastOne() {
        const currentQuestion = this.currentQuestion();
        if ( ! currentQuestion ) return false;
        return currentQuestion === this.masterAnswerSheet.questions.slice(-1)[0];
      },

      isQuestionAsked(question) {
        return !! this.currentQuestions.find(q => q === question);
      },

      teamTeamForTeamId(teamId) {
        return this.teamTeams.find(teamTeam => teamTeam.id === teamId);
      },

      maybeStart() {
        return this.session.maybeStart( this.masterAnswerSheet );
      },

      get allMasterAnswerScores() {
        return this.teamTeams.map(teamTeam => {
          return this.ensureMasterAnswerScoreSheetForTeam(teamTeam).masterAnswerScores;
        }).flat();
      },

      // Reset any isCorrect for all the questions that aren't
      // confirmed.
      syncMasterAnswerScoreSheets() {
        this.masterAnswerScoreSheets.forEach(masterAnswerScoreSheet => {
          const teamTeam = this.teamTeamForTeamId(masterAnswerScoreSheet.teamId);
          if ( ! teamTeam ) {
            console.warn(`syncMasterAnswerScoreSheets: Missing teamTeam`, masterAnswerScoreSheet.teamId);
            return;
          }

          const teamAnswerSheet = this.ensureTeamAnswerSheetForTeam(teamTeam);

          Object
            .values( masterAnswerScoreSheet.questionIdToMasterAnswerScore )
            .forEach(masterAnswerScore => {
              const questionId = masterAnswerScore.questionId;
              const teamAnswer = teamAnswerSheet.questionIdToTeamAnswer[ questionId ];
              masterAnswerScore.syncWithTeamAnswer(teamAnswer); // Can be undefined
            });
        });
      },

      teamAnswersForMasterQuestion(masterQuestion) {
        return this.teamAnswerSheets.map(teamAnswerSheet => {
          const teamAnswer = teamAnswerSheet.teamAnswerByQuestionId(
            masterQuestion.id,
          );
          if ( teamAnswer ) return [ teamAnswer ];
          return [];
        }).flat();
      },

      unmarkedTeamAnswersForMasterQuestion(teamAnswers, masterQuestion) {
        return teamAnswers.filter(teamAnswer => {
          const masterAnswerScoreSheet = this.ensureMasterAnswerScoreSheetForTeam(
            this.teamTeamForTeamId( teamAnswer.teamId ),
          );
          const masterAnswerScore = masterAnswerScoreSheet.ensureMasterAnswerScore(
            this.answerSheet,
            masterQuestion,
          );
          return ! masterAnswerScore.isMarked;
        });
      },

      questionIdToTeamAnswerScore(teamId) {
        // ///JPL: if available, send all, otherwise send only revealed
        // const teamAnswerScoreSheet = this.teamAnswerScoreSheet(teamId);

        const teamTeam = this.teamTeamForTeamId(teamId);
        if ( ! teamTeam ) return null;
        const masterAnswerScoreSheet = this.ensureMasterAnswerScoreSheetForTeam(
          teamTeam,
        );

        return masterAnswerScoreSheet
          .questionIdToTeamAnswerScoreFromMasterAnswerScoreSheet(
            this.session.sessionStatus.isScoreSheetAvailableToTeams,
            this.masterQuestionStatusSheet,
            this.masterAnswerSheet,
          );
      },

      teamAnswerScoreSheet(teamId) {

        const questionIdToTeamAnswerScore = this.questionIdToTeamAnswerScore(
          teamId,
        );
        if ( ! questionIdToTeamAnswerScore ) return false;


        let placementDetails = { };
        if ( this.session.sessionStatus.isTeamPlacementAvailableToTeams ) {
          const masterTeamPlacement = this.teamPlacementForTeamId(teamId);
          if (masterTeamPlacement) {
            placementDetails.placementOrdinal = masterTeamPlacement.placementOrdinal;
            placementDetails.isPlacementShared = this.isPlacementOrdinalShared(
              masterTeamPlacement.placementOrdinal,
            );
          }
        }

        ///JPL: create object
        const teamAnswerScoreSheet = {
          sessionId: this.session.id,
          teamId,
          questionIdToTeamAnswerScore,
          ...placementDetails,
        };

        return teamAnswerScoreSheet;
      },

      isMasterQuestionRevealed(masterQuestion) {
        return this.masterQuestionStatusSheet.isMasterQuestionRevealed(
          this.masterAnswerSheet,
          masterQuestion,
        );
      },

      // if there are any submitted, non-empty, unmarked answers for
      // this question
      doesMasterQuestionHaveUnmarkedAnswers(masterQuestion) {
        const teamAnswers = this.teamAnswersForMasterQuestion( masterQuestion );
        const markableTeamAnswers = teamAnswers.filter(
          teamAnswer => ! teamAnswer.isEmpty,
        );
        const unmarkedTeamAnswers = this.unmarkedTeamAnswersForMasterQuestion(
          markableTeamAnswers,
          masterQuestion,
        );
        if ( unmarkedTeamAnswers.length ) return true;

        return false;
      },

      canRevealMasterQuestion(masterQuestion) {
        // if already manually revealed, then no
        return ! this.isMasterQuestionRevealed(masterQuestion);
      },

      revealMasterQuestion(masterQuestion) {
        const wasRevealed = this.masterQuestionStatusSheet.revealMasterQuestion(
          this.masterAnswerSheet,
          masterQuestion,
        );
        if ( ! wasRevealed ) return false;
        ///JPL: auto-confirm the guys
        this.markEmptyTeamAnswersIncorrectForQuestion(masterQuestion);

        ///JPL: this.autoMarkTeamAnswers();
        return true;
      },

      confirmAllUnconfirmedTeamAnswers() {
        const masterAnswerSheet = this.masterAnswerSheet;
        this.teamAnswerSheets.forEach(
          teamAnswerSheet => teamAnswerSheet.confirmAllUnconfirmedAnswersOnAnswerSheet(
            masterAnswerSheet,
          ),
        );
      },

      confirmRevealedUnconfirmedTeamAnswers() {
        const confirmedCount = _.sumBy(
          teamAnswerSheets,
          teamAnswerSheet => teamAnswerSheet.confirmUnconfirmedAnswersForQuestionsOnAnswerSheet(
            masterAnswerSheet,
            this.masterQuestionStatusSheet.revealedQuestionIds,
          ),
        );
        return confirmedCount;
      },

      // Ensure there's a teamAnswer in each teamAnswerSheets
      ensureMasterAnswerScores(question) {
        this.masterAnswerScoreSheets.forEach(
          masterAnswerScoreSheet => masterAnswerScoreSheet.ensureMasterAnswerScore(
            this.masterAnswerSheet,
            question,
          ),
        );
      },
      ensureTeamAnswers(question) {
        this.teamAnswerSheets.forEach(
          teamAnswerSheet => teamAnswerSheet.masterEnsureTeamAnswer(
            this.masterAnswerSheet,
            question,
          ),
        );
      },

      masterAnswerScoreSheetForTeam(teamTeam) {
        return this.masterAnswerScoreSheets.find(
          it => it.teamId === teamTeam.id,
        );
      },

      // Ensure, for when a team joins after the quiz is started
      ensureMasterAnswerScoreSheetForTeam(teamTeam) {
        const masterAnswerScoreSheet = this.masterAnswerScoreSheetForTeam(teamTeam);
        if ( masterAnswerScoreSheet ) return masterAnswerScoreSheet;

        const newMasterAnswerScoreSheet = MasterQuiz.newMasterAnswerScoreSheetForTeam(
          this.session.id,
          this.masterAnswerSheet,
          teamTeam,
        );
        this.masterAnswerScoreSheets.push(newMasterAnswerScoreSheet);
        return newMasterAnswerScoreSheet;
      },

      ///JPL: bad name, it doesnt take an id
      teamAnswerSheetForTeamId(teamTeam) {
        return this.teamAnswerSheets.find(
          it => it.teamId === teamTeam.id,
        );
      },

      // Ensure, for when a team joins after the quiz is started
      ensureTeamAnswerSheetForTeam(teamTeam) {
        const teamAnswerSheet = this.teamAnswerSheetForTeamId(teamTeam);
        if ( teamAnswerSheet ) return teamAnswerSheet;

        const newTeamAnswerSheet = MasterQuiz.newTeamAnswerSheetForTeam(
          this.session.id,
          this.masterAnswerSheet,
          teamTeam,
        );

        this.teamAnswerSheets.push(newTeamAnswerSheet);
        return newTeamAnswerSheet;
      },

      masterAnswerScoreFor(teamTeam, question) {
        return this
          .ensureMasterAnswerScoreSheetForTeam(teamTeam)
          .ensureMasterAnswerScore(
            this.masterAnswerSheet,
            question,
        );
      },
      teamAnswerFor(teamTeam, question) {
        const teamAnswerSheet = this.ensureTeamAnswerSheetForTeam(
          teamTeam,
        );
        return teamAnswerSheet.masterEnsureTeamAnswer(
            this.masterAnswerSheet,
            question,
        );
      },

      get allTeamAnswers() {
        return this.teamTeams.map(teamTeam => {
          return this.ensureTeamAnswerSheetForTeam(teamTeam).teamAnswers();
        }).flat();
      },

      masterAnswerScoreForTeamAnswer(teamAnswer) {
        ///JPL: extract accessor methods for these;
        const teamTeam = this.teamTeams.find(teamTeam => teamTeam.id === teamAnswer.teamId);
        const question = this.masterAnswerSheet.getQuestionById(teamAnswer.questionId);
        return this.masterAnswerScoreFor(teamTeam, question);
      },

      ///JPL: rename unmarked? or rename MasterAnswerScore.isUnmarked?
      nextUnscoredTeamAnswer(orderedTeamTeams, question, searchFromTeamAnswer) {
        const masterQuizOrderedTeams = new MasterQuizOrderedTeams({
          masterQuiz: this,
          orderedTeamTeams,
          question,
          searchFromTeamAnswer,
        });
        return masterQuizOrderedTeams.nextUnscoredTeamAnswer();
      },

      previousUnscoredTeamAnswer(orderedTeamTeams, question, searchFromTeamAnswer) {
        const masterQuizOrderedTeams = new MasterQuizOrderedTeams({
          masterQuiz: this,
          orderedTeamTeams,
          question,
          searchFromTeamAnswer,
        });
        return masterQuizOrderedTeams.previousUnscoredTeamAnswer();
      },

      teamCurrentTotalScore(teamTeam) {
        return this.ensureMasterAnswerScoreSheetForTeam(teamTeam)
          .currentTotalScore();
      },

      get unconfirmedAnswerCount() {
        let count = 0;
        this.teamTeams.forEach(teamTeam => {
          const teamAnswerSheet = this.ensureTeamAnswerSheetForTeam(teamTeam);
          this.currentQuestions.forEach(question => {
            const teamAnswer = teamAnswerSheet.masterEnsureTeamAnswer(
              this.masterAnswerSheet,
              question,
            );
            if ( ! teamAnswer.isConfirmed ) count++;
          });
        });

        return count;
      },

      get unmarkedAnswerCount() {
        let count = 0;
        this.teamTeams.forEach(teamTeam => {
          const teamAnswerSheet = this.ensureTeamAnswerSheetForTeam(teamTeam);
          const masterAnswerScoreSheet = this.ensureMasterAnswerScoreSheetForTeam(teamTeam);
          this.currentQuestions.forEach(question => {
            const teamAnswer = teamAnswerSheet.masterEnsureTeamAnswer(
              this.masterAnswerSheet,
              question,
            );
            if ( ! teamAnswer.isConfirmed ) return;

            const masterAnswerScore = masterAnswerScoreSheet.ensureMasterAnswerScore(
              this.masterAnswerSheet,
              question,
            );
            if ( masterAnswerScore.isUnmarked ) count++;
          });
        });

        return count;
      },

      get unrevealedQuestionCount() {
        const revealedCount = this.masterQuestionStatusSheet.revealedMasterQuestionCount;
        const askedCount = this.session.askedQuestionCount;
        return askedCount - revealedCount;
      },

      get areThereUnrevealedQuestions() {
        return this.unrevealedQuestionCount > 0;
      },

      get areThereRevealedQuestions() {
        return this.masterQuestionStatusSheet.revealedMasterQuestionCount > 0;
      },

      markAllQuestionsEmptyTeamAnswersIncorrect() {
        this.masterAnswerScoreSheets.forEach(masterAnswerScoreSheet => {
          const teamAnswerSheet = this.teamAnswerSheetForTeamId(
            { id: masterAnswerScoreSheet.teamId },
          );
          if ( ! teamAnswerSheet ) return;
          masterAnswerScoreSheet.markAllEmtpyTeamAnswersOnAnswerSheetIncorrect(
            this.masterAnswerSheet,
            teamAnswerSheet,
          );
        });
      },

      markRevealedQuestionsEmptyTeamAnswersIncorrect() {
        this.masterQuestionStatusSheet.revealedQuestionIds.forEach(
          questionId => {
            const masterQuestion = this.masterAnswerSheet.getQuestionById(questionId);
            this.markEmptyTeamAnswersIncorrectForQuestion(
              masterQuestion,
            );
          },
        );
      },

      markEmptyTeamAnswersIncorrectForQuestion(masterQuestion) {
        this.masterAnswerScoreSheets.forEach(masterAnswerScoreSheet => {
          const teamAnswerSheet = this.teamAnswerSheetForTeamId(
            { id: masterAnswerScoreSheet.teamId },
          );
          if ( ! teamAnswerSheet ) return;
          masterAnswerScoreSheet.markEmtpyTeamAnswersForQuestionIncorrect(
            this.masterAnswerSheet,
            teamAnswerSheet,
            masterQuestion,
          );
        });
      },

      autoMarkTeamAnswers() {
        this.masterAnswerScoreSheets.forEach(masterAnswerScoreSheet => {
          const teamAnswerSheet = this.teamAnswerSheetForTeamId(
            { id: masterAnswerScoreSheet.teamId },
          );
          if ( ! teamAnswerSheet ) return;
          masterAnswerScoreSheet.autoMarkUnscoredAnswersOnTeamAnswerSheet(
            this.masterAnswerSheet,
            teamAnswerSheet,
          );
        });
      },

      askNextQuestion() {
        const nextQuestion = this.session.askNextQuestion(this.masterAnswerSheet);
        this.ensureTeamAnswers(nextQuestion);
        this.ensureMasterAnswerScores(nextQuestion);
        return nextQuestion;
      },

      askMoreQuestions() {
        return this.session.maybeAskMoreQuestions();
      },

      finishQuestions() {
        return this.session.maybeFinishQuestions();
      },

      markAnswers() {
        if ( ! this.session.maybeMarkAnswers() ) return false;
        return true;
      },

      revealScoring() {
        return this.session.maybeRevealScoring();
      },

      revealLeaderboard() {
        return this.session.maybeRevealLeaderboard();
      },

      finishQuiz() {
        return this.session.maybeFinish();
      },

    };

  }

}
