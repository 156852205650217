<script>

 import QuestionDifficulty from '@/components/elements/QuestionDifficulty.vue';
 import IconHeart from '@/components/quizmaster/run/Icon/Heart.vue';
 import TeamGameAnswerVoteButtons from './TeamGameAnswerVoteButtons.vue';
 import TeamGameAnswerMultiAnswerOption from './TeamGameAnswerMultiAnswerOption.vue';

 export default {
   name: 'TeamGameAnswer',
   components: {
     TeamGameAnswerVoteButtons,
     TeamGameAnswerMultiAnswerOption,
     QuestionDifficulty,
     IconHeart,
   },
   props: [
     'teamQuestion',
     'teamAnswer',
     'teamScore', // Can be null
     'isCurrentQuestion',
     'showTeamAnswerScore',
     'showVoteButtons',
     'showVoteReminder',
     'isTeamQuestionHardest',
     'isTeamQuestionEasiest',
     'isTeamQuestionPopular',
     'disableAllInput',
     'totalQuestionCount',
   ],

   data() {
     return {
       answerValue: null,
     };
   },

   created() {
     // On Android Chrome, @input is triggered just by putting the
     // cursor in the input field and then @blur, even if the value is
     // the same. Maybe something with the keyboard device itself.
     //
     // Keep track of the initial pre-@input value, so we can compare
     // it manually
     this.answerValue = this.teamAnswer.answer;
   },

   computed: {
     canTeamSubmitAnswer() {
       if (this.disableAllInput ) return false;
       return this.teamQuestion.canTeamSubmitAnswer;
     },
     disableConfirmButton() {
       return this.teamAnswer.isConfirmed
           || this.disableAllInput;
     },

     isConfirmed() {
       return !! this.teamAnswer.isConfirmed
     },

     isCorrect() {
       if ( ! this.teamScore ) return false;
       return this.teamScore.isCorrect;
     },

     isAnswerTypeFreeText() {
       return this.teamQuestion.isAnswerTypeFreeText;
     },
     isAnswerTypeMultiChoice() {
       return this.teamQuestion.isAnswerTypeMultiChoice;
     },

     showThisTeamAnswerScore() {
       return this.teamQuestion.shouldRevealAnswerScore || this.showTeamAnswerScore;
     },

     optionCount() {
       return this.teamQuestion.answerOptions.length;
     },
     buttonMdCols() {
       if ( this.optionCount === 3 ) return 4;
       return 6;
     },
     buttonXlCols() {
       if ( this.optionCount % 2 === 0 ) return 6;
       return 4;
     },
   },

   methods: {
     description() {
       return `${this.teamQuestion.points} point${this.maybePlural(this.teamQuestion.points)}`;
     },

     onConfirmMultiChoiceAnswer() {
       this.teamAnswer.confirmAnswer();
       this.$emit('team-answer-sheet-changed');
     },

     confirmAnswer() {
       if ( this.teamAnswer.confirmAnswer() ) {
         this.$emit('team-answer-sheet-changed');
       }
     },
     unConfirmAnswer() {
       if (this.answerValue === this.teamAnswer.answer) {
         return;
       }
       this.answerValue = this.teamAnswer.answer;

       if ( this.teamAnswer.unConfirmAnswer() ) {
         this.$emit('team-answer-sheet-changed');
       }
     },

     onInputFieldChange() {
       this.$emit('team-answer-sheet-changed');
     },
     onQuestionVoteChanged() {
       this.$emit('team-answer-sheet-changed');
     },
     ///JPL: onInputFieldInput, maybe emit on debounce 2s or so?
   },
 };

</script>


<template>
  <b-form class="mt-4">
    <b-row>
      <b-col>
        <h3 class="d-inline-block mr-3" :class="{ 'is-current-question': isCurrentQuestion }">
          Question {{teamQuestion.ordinal}}
          <span v-if="isCurrentQuestion"> out of {{totalQuestionCount}}</span>
        </h3>
        <span class="text-nowrap">{{this.description()}}</span>
      </b-col>
    </b-row>

    <b-row v-if="teamQuestion.imageUrl" class="mt-1 mb-2">
      <b-col class="text-center">
        <div class="image-container">
          <img :src="teamQuestion.imageUrl" class="is-image" />
          <img src="/nope.png" class="is-image-nopes" />
        </div>
      </b-col>
    </b-row>

    <b-row v-if="teamQuestion.question">
      <b-col class="mt-1 d-flex">
        <div>
          <IconHeart
            v-if="isTeamQuestionPopular"
            class="mr-1"
            title="This was a popular question"
          />
          <strong>{{teamQuestion.question}}</strong>
        </div>
      </b-col>
    </b-row>

    <b-row
      v-if="teamQuestion.isAnswerTypeFreeText && showThisTeamAnswerScore && teamQuestion.revealCorrectAnswer"
      class="mt-1 mb-2"
    >
      <b-col>
        <strong>Answer:</strong> {{teamQuestion.revealCorrectAnswer}}
      </b-col>
    </b-row>

    <b-row v-if="showThisTeamAnswerScore && teamQuestion.revealAnswerTriviaHtml" class="mt-1 mb-2">
      <b-col>
        <div class="rendered-md" v-html="teamQuestion.revealAnswerTriviaHtml"></div>
      </b-col>
    </b-row>

    <b-row v-if="showVoteButtons" class="mt-1">
      <b-col>
        <TeamGameAnswerVoteButtons
          :teamAnswer="teamAnswer"
          :showVoteReminder="showVoteReminder"
          @question-vote-changed="onQuestionVoteChanged"
        />
      </b-col>
    </b-row>


    <b-row v-if="isAnswerTypeFreeText" class="mt-3">
      <b-col>
        <b-form-group>
          <b-input-group
            :class="{ 'is-confirmed-field': isConfirmed }"
            class="is-field"
          >
            <b-form-input
              v-model="teamAnswer.answer"
              @change="onInputFieldChange()"
              @input="unConfirmAnswer()"
              :disabled="! canTeamSubmitAnswer"
              ref="answerInputField"
              type="text"
              size="md"
              placeholder="Answer"
              autocomplete="off"
            />

            <b-input-group-append
              v-if="teamScore && showThisTeamAnswerScore"
              is-text
              :class="{
                'correct-answer': teamScore.isCorrect,
                'incorrect-answer': ! teamScore.isCorrect
              }">
              <div>
                {{teamScore.pointsAwarded}}
              </div>
            </b-input-group-append>

            <b-input-group-append v-if="canTeamSubmitAnswer">
              <b-button
                @click.prevent="confirmAnswer()"
                :disabled="disableConfirmButton"
                type="submit"
                size="md"
                variant="secondary">{{ isConfirmed ? 'Submitted' : '  Submit' }}</b-button>
            </b-input-group-append>

          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <div v-if="isAnswerTypeMultiChoice">
      <b-row class="mt-3 justify-content-center">
        <b-col
          v-for="(teamAnswerOption, index) in teamQuestion.answerOptions"
          :key="`${teamAnswerOption.answer}-${index}`"
          offset="0"
          cols="12"
          :md="buttonMdCols"
          :xl="buttonXlCols"
          class="mx-0 mt-2"
        >
          <TeamGameAnswerMultiAnswerOption
            :teamAnswer="teamAnswer"
            :teamQuestion="teamQuestion"
            :optionIndex="index"
            :teamAnswerOption="teamAnswerOption"
            :isDisabled=" ! canTeamSubmitAnswer"
            @confirm-multi-choice-answer="onConfirmMultiChoiceAnswer"
          />
        </b-col>
      </b-row>

      <b-row v-if="teamScore && showThisTeamAnswerScore" class="mt-3">
        <b-col>
          <b-form-group>
            <b-input-group class="is-field">
              <b-form-input
                :disabled="true"
                :value="teamAnswer.multiChoiceAnswer(teamQuestion)"
                ref="answerInputField"
                type="text"
                size="md"
              />

              <b-input-group-append
                is-text
                :class="{
                  'correct-answer': teamScore.isCorrect,
                  'incorrect-answer': ! teamScore.isCorrect
                }">
                <div>
                  {{teamScore.pointsAwarded}}
                </div>
              </b-input-group-append>

            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </div>


    <b-row v-if="showThisTeamAnswerScore">
      <b-col v-if="isTeamQuestionHardest || isTeamQuestionEasiest">
        <div v-if="isTeamQuestionHardest" class="pr-2 text-right" title="Hot! Hot! Hot!">
          <QuestionDifficulty :difficulty="3" />
          <span v-if="isCorrect">
            This was a tough one!
            <strong>But you got it right!</strong>
          </span>
          <span v-else>
            <strong>This was a tough one!</strong>
            Not many people got it right.
          </span>
        </div>
        <div v-else-if="isTeamQuestionEasiest && isCorrect" class="text-right" title="Simples... Is this trivia or trivial?">
          <div class="pr-2">
            <QuestionDifficulty :difficulty="1" />
            This was an easy one!
          </div>
        </div>
      </b-col>
    </b-row>

  </b-form>
</template>


<style>

 .rendered-md {
   padding-left: 2rem;
   padding-right: 2rem;
 }

 .rendered-md img {
   /* don't display inline */
   display: block;
   /* center */
   margin: auto;
   max-width: 70%;
   max-height: 40vh;
   margin-top: 1rem;
   margin-bottom: 0.5rem;
 }

 .rendered-md p:last-of-type {
   margin-bottom: 0;
 }

 .is-image {
   max-width: 90%;
   max-height: 65vh;
 }

 /* When used in EditQuestion / PreviewTeamGameAnswer */
 .preview-question .is-image {
   max-height: 40vh;
 }

 .is-image-nopes {
   position: absolute;
   top: 0px;
   left: 0px;
   width: 100%;
   opacity: 0;
 }

 .preivew-question .rendered-md img {
   max-height: 30vh;
 }

 .rendered-md blockquote {
   background: #f9f9f9;
   border-left: 10px solid #ccc;
   margin: 1.5em 10px;
   padding: 0.5em 10px;
   /* quotes: "\201C"; */
   quotes: "“" "”";
   border-radius: 1.2rem;
 }
 .rendered-md blockquote:before {
   color: #ccc;
   content: open-quote;
   font-size: 4em;
   line-height: 0.1em;
   margin-right: 0.10em;
   vertical-align: -0.4em;
 }
 .rendered-md blockquote p {
   display: inline;
 }

</style>

<style scoped>

 .is-current-question {
   font-weight: bold;
 }

 .is-field {
   border: 1px solid #fff;
   border-radius: 4px;
 }

 .is-confirmed-field {
   border: 1px solid #888;
 }

 .correct-answer .input-group-text {
   background-color: var(--correct-disabled-bg);
 }

 .incorrect-answer .input-group-text {
   background-color: var(--incorrect-disabled-bg);
 }

 .vote-buttons.btn-group-sm button.btn {
   font-size: 70%;
 }


 .is-answer-option {
   padding: 0.2rem;
   padding-left: 1rem;
   padding-right: 1rem;

   margin-top: 0.4rem;
 }

</style>
