
import WithAnalytics from '@/components/mixins/WithAnalytics';
import MasterClipboardQuestion from '@/models/master/clipboard-question.js';

// Note: requires:
// this.logEvent()
export default {
  mixins: [
    WithAnalytics,
  ],

  methods: {

     async readQuestionFromClipboard() {
       const masterClipboardQuestion = MasterClipboardQuestion.newFromString(
         await this.getClipboard(),
       );
       if ( ! masterClipboardQuestion ) {
         return this.reportError('Clipboard does not contain a Quiz Question');
       }
       return masterClipboardQuestion;
     },

     async getClipboard() {
       try {
         const text = await navigator.clipboard.readText();
         return text;
       } catch (err) {
         console.error('Failed to read clipboard contents: ', err);
         return null;
       }
     },

     setClipboard(masterClipboardQuestion, clipboardContainerEl = undefined) {
       const clipboardQuestion = JSON.stringify(masterClipboardQuestion);
       return this
         .$copyText(clipboardQuestion, clipboardContainerEl)
         .then(
           function () {
             //
           },
           function (e) {
             console.log(`Could not copy `, clipboardQuestion, e);
         });
     },

     copyQuestion(question, clipboardContainerEl = undefined) {
       this.setClipboard( question.asClipboardMasterQuestion, clipboardContainerEl );
       this.logCopyQuestion();
     },
     logCopyQuestion() {
       this.logEvent('copy_question', { event_category: 'setup', event_value: this.questionCount });
     },
  }
};
