///JPL: or rename all "master" to "qm"

import _ from 'lodash';

import ModelBase from '../model-base.js';
import MasterQuestion from './question.js';
import MasterSession from './session.js';

// Note: because of Vue's reactivity, prototypes don't work at all, so
// the methods are declared on the instance, not on the prototype :/
export default class AnswerSheet extends ModelBase {

  static createDefault() {
     var questions = Array(1).fill().map(() => {
       return new MasterQuestion({});
     });

    return new AnswerSheet({
      name: "The Quiz without a name",
      questions,
    });
  }

  static createEmpty() {
    return new AnswerSheet({});
  }

  constructor({ id, name, defaultPoints, questions, sessions, createdTime, updatedTime }) {
    super();
    ///JPL: note: returns epoch ms, not a Date obect. is this correct here?
    const now = Date.now();
    return {
      ...this,
      id, // Populated on backend
      name,
      defaultPoints: this.ensureNumeric(defaultPoints) || 1,
      questions: this.ensureArrayObjects(questions, MasterQuestion),
      sessions: this.ensureArrayObjects(sessions, MasterSession),

      createdTime: this.ensureDate(createdTime || now),
      updatedTime: this.ensureDate(updatedTime || now),


      // Return clone of the answer sheet with the same config, and
      // copies of the questions
      clone() {
        const answerSheet = new AnswerSheet({
          name: `Copy of ${this.name}`,
          defaultPoints: this.defaultPoints,
          questions: this.questions.map(question => question.clone()),
        });
        answerSheet.addSession();
        return answerSheet;
      },

      prepareToSave() {
       if ( this.sessions.length == 0 ) {
         this.addSession();
       }
      },

      get unfinishedSessions() {
        return this.sessions.filter(session => ! session.sessionStatus.isFinished);
      },

      get allSessions() {
       return _.sortBy(
         this.sessions,
         session => session.updatedTime
       ).reverse();
      },

      addSession() {
        const session = new MasterSession({ answerSheetId: this.id });
        this.sessions.push( session );
        return session;
      },

      deleteSession(session) {
        const index = this.sessions.findIndex(s => s === session);
        if (index >= 0) {
          this.sessions.splice(index, 1);
        }
        else {
          console.error(`MasterSession (${session.id}) not found`);
        }
      },

      sessionById(id) {
        return this.sessions.find((s) => s.id === id);
      },
      getNewestSession() {
        return this.sessions[ this.sessions.length - 1 ];
      },

      addQuestion(args = {}) {
        const question = new MasterQuestion(args);
        this.questions.push( question );
        return question;
      },

      newQuestion() {
        return new MasterQuestion({});
      },

      addQuestionAbove(question, newQuestion) {
        const index = this.getQuestionIndex(question);
        if ( index < 0 ) return null;

        newQuestion = newQuestion || this.newQuestion();
        this.questions.splice(index, 0, newQuestion);
        return index;
      },

      deleteQuestion(question) {
        const index = this.getQuestionIndex(question);
        if ( index === -1 ) return null;
        this.questions.splice(index, 1);
        return index;
      },

      removeEmptyQuestions() {
        const emptyQuestions = this.questions.filter(
          question => question.isEmpty,
        );
        emptyQuestions.forEach(question => this.deleteQuestion(question));
      },

      undoDeleteQuestion(index, question) {
        this.questions.splice(index, 0, question);
        return question;
      },

      moveQuestionUp(question) {
        const index = this.getQuestionIndex(question);
        if ( index === -1 ) return null;

        if ( index <= 0 ) return null;
        const newIndex = index - 1;

        this.questions.splice(index, 1);
        this.questions.splice(newIndex, 0, question);
        return newIndex;
      },

      moveQuestionDown(question) {
        const index = this.getQuestionIndex(question);
        if ( index === -1 ) return null;

        if ( index >= this.questions.length ) return null;
        const newIndex = index + 1;

        this.questions.splice(index, 1);
        this.questions.splice(newIndex, 0, question);
        return newIndex;
      },

      getQuestionIndex(question) {
        return this.questions.findIndex(item => item === question);
      },

      getFirstQuestion() {
        if ( ! this.questionCount ) {
          this.addQuestion();
        }
        return this.questions[0];
      },

      getQuestionById(id) {
        return this.questions.find(q => q.id === id);
      },

      getQuestionByIndex(index) {
        return this.questions[ index ];
      },

      getQuestionBeforeId(id) {
        const index = this.questions.findIndex(q => q.id === id);
        if ( index <  0 ) return null; // Not found
        if ( index <= 0 ) return null; // Already at first question
        return this.questions[ index - 1 ];
      },

      getQuestionAfterId(id) {
        const index = this.questions.findIndex(q => q.id === id);
        if ( index < 0 )                             return null;
        if ( index + 1 > this.questions.length - 1 ) return null;
        return this.questions[ index + 1 ];
      },

      ensureGetQuestionAfterId(id) {
        const index = this.questions.findIndex(q => q.id === id);
        if ( index < 0 ) {
          return this.addQuestion();
        }

        if ( index + 1 > this.questions.length - 1 ) {
          return this.addQuestion();
        }

        return this.questions[ index + 1 ];
      },

      getFirstQuestions(count) {
        return this.questions.slice(0, count);
      },

      get questionCount() {
        return this.questions.length;
      },

      actualPoints(points) {
        const isEmpty = (
          points === undefined || points === null || points === ""
        );
        if ( isEmpty ) return this.defaultPoints;
        return points;
      },

      get totalPointsPossible() {
        return this
          .questions
          .map(question => this.actualPoints( question.points ))
          .reduce((total, points) => total += points, 0);
      },

      getQuestionIdToTeamAnswer(teamTeam) {
        return Object.fromEntries(
          this.questions.map(question => [
            question.id,
            question.newTeamAnswer(this, teamTeam.id),
          ]),
        );
      },

      getQuestionIdToMasterAnswerScore(teamTeam) {
        return Object.fromEntries(
          this.questions.map(question => [
            question.id,
            question.newMasterAnswerScore(this, teamTeam.id),
          ]),
        );
      },

      getQuestionIdToMasterQuestionStatus() {
        return Object.fromEntries(
          this.questions.map(question => [
            question.id,
            question.newMasterQuestionStatus(),
          ]),
        );
      },

      get questionsTitleString() {
        return this.questions
          .map(question => question.question || "")
          .map((str, index) => {
            return `${index + 1}. ${ str.substr(0, 40) }`;
          })
          .join(`\n`);
      },

    };
  }

}
