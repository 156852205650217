
<script>

 import ModalConfirm from '@/components/share/Modal/Confirm';

 export default {
   name: 'InviteTeamListItem',
   components: { ModalConfirm },
   props: [
     'teamTeam',
   ],

   computed: {
     modalId() {
       return `bv-modal-delete-team-${this.teamTeam.id}`;
     },
   },

   methods: {
     deleteTeam() {
       this.$bvModal.show(this.modalId);
     },
     deleteTeamOk() {
       this.$emit("delete-team");
     },

     copyTeamPageUrl() {
       this.$copyText(this.teamTeam.teamUrl);
       this.$bvToast.toast(
         `Copied secret Team URL to the clipboard. Send this link ONLY to the '${this.teamTeam.name}' team!`,
         {
           title: "Copy Team URL",
           variant: "success",
         }
       );
     },
   },
 }
</script>

<template>
  <div>
    <b-row class="is-team-item">
      <b-col cols="10">
        <ul><li>{{teamTeam.name}}</li></ul>
      </b-col>
      <b-col cols="2">
        <b-dropdown
          :dropright="this.$root.isMobile"
          variant="outline-secondary"
          size="sm"
        >
          <template v-slot:button-content>
            <font-awesome-icon :icon="['fa', 'cog']" />
          </template>

          <b-dropdown-item @click.prevent="copyTeamPageUrl()" href="#">
            <font-awesome-icon :icon="['far', 'copy']" class="mr-1" />
            Copy secret Team URL
          </b-dropdown-item>

          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item :href="this.teamTeam.teamPath" target="_blank" variant="danger">
            <font-awesome-icon :icon="['fa', 'external-link-alt']" class="mr-1" />
            Sneak a peak at the team's own page
          </b-dropdown-item>

          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click.prevent="deleteTeam()" href="#" variant="danger">
            <font-awesome-icon :icon="['far', 'trash-alt']" class="mr-1" />
            Delete Team
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>

    <ModalConfirm
      :elementId="modalId"
      @click-ok="deleteTeamOk"
    >
      <template #title>
        Delete Team?
      </template>
      <template #message>
        <p>
          Do you want to delete the team
          <strong>{{teamTeam.name}}</strong>?
        </p>
      </template>
    </ModalConfirm>

  </div>

</template>


<style scoped>

</style>
