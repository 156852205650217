
// Note: because of Vue's reactivity, prototypes don't work at all, so
// the methods are declared on the instance, not on the prototype :/
export default class ModelBase {

  constructor() {
    return {
      ensureArrayObjects(array, className) {
        return [ ...(array || []) ]
          .map(o => o instanceof className ? o : new className(o));
      },

      ensureValuesObjects(object, className) {
        object = object || {};
        return Object.fromEntries(
          Object
            .entries(object)
            .map(
              ([ key, o ]) => {
                if ( ! (o instanceof className) ) {
                  o = new className(o);
                }
                return [ key, o ];
              },
            ),
        );
      },

      ensureObject(object, className) {
        if ( ! object ) return null;
        return object instanceof className ? object : new className(object);
      },

      ensureNumeric(value) {
        if ( value === undefined || value === null ) return value;
        return parseFloat(value);
      },

      ensureDate(value) {
        if ( value === undefined || value === null ) return value;
        // JS dipshittery: https://stackoverflow.com/questions/643782/how-to-check-whether-an-object-is-a-date
        if ( Object.prototype.toString.call(value) === '[object Date]' ) return value;
        return new Date(value);
      },
    };
  }

}
