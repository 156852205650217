
import SocketIO from "socket.io-client";

export default {
  created() {
    this.socketIo = SocketIO("/");
  },
  beforeDestroy() {
    this.socketIo.close();
    this.socketIo = undefined;
  },
};
