<script>

 import SessionStatusBadge from '@/components/elements/SessionStatusBadge.vue';

 export default {
   name: 'AdminAnswerSheetSessions',
   components: {
     SessionStatusBadge,
   },
   props: [
     'masterAnswerSheet',
   ],

   methods: {
     teamTeamNames(session) {
       return session.maybeTeamTeams.map(team => team.name).join("\n");
     },
   },
 }

</script>

<template>
  <div>
    <details>
      <summary>
        <div class="d-flex ml-4" style="margin-top: -1.5rem">
          <div class="flex-grow-1">
            {{masterAnswerSheet.name}}
          </div>
          <div>
            {{masterAnswerSheet.questionCount}}
          </div>
        </div>
      </summary>
      <small>
        <ol>
          <li
            v-for="question in masterAnswerSheet.questions"
            :key="question.id"
            :title="question.shortRevealCorrectAnswer"
          >
            {{ question.question }}
          </li>
        </ol>
      </small>
    </details>
    <div class="ml-4">
      <span
        v-for="session in masterAnswerSheet.sessions"
        :key="session.id"
        class="pr-1"
      >
        <span
          v-if="session.maybeTeamTeams.length > 0"
          :title="teamTeamNames(session)"
        >
          <SessionStatusBadge
            :sessionStatus="session.sessionStatus"
            :teamCount="session.maybeTeamTeams.length"
          />
          ({{session.maybeTeamTeams.length}})
        </span>
      </span>
    </div>
  </div>
</template>

<style scoped>
</style>
