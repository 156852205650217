
import axios from '@/store/axios';
import MasterAnswerScoreSheet from '../../../models/master/answer-score-sheet.js';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {

    // Post session to the API.
    async saveObjects({ dispatch }, { sessionId, masterAnswerScoreSheets }) {
      const userSession = await dispatch('auth/ensureSession', {}, { root: true });
      return await axios.post(
        `/api/user-session/${userSession.id}/session/${sessionId}/answer-score-sheet`,
        masterAnswerScoreSheets,
      ).then(res => {
        const data = res.data;
        return data.masterAnswerScoreSheets.map(
          masterAnswerScoreSheet => new MasterAnswerScoreSheet(masterAnswerScoreSheet),
        );
      });
      ///JPL: catch
    },

    async loadObjects({ dispatch }, { sessionId }) {
      const userSession = await dispatch('auth/ensureSession', {}, { root: true });
      return await axios.get(`/api/user-session/${userSession.id}/session/${sessionId}/answer-score-sheet`)
        .then(res => {
          const data = res.data;
          return data.masterAnswerScoreSheets.map(
            masterAnswerScoreSheet => new MasterAnswerScoreSheet(masterAnswerScoreSheet),
          );
        });
      ///JPL: catch
    },

  },

};
