
export default {
  computed: {
    userSession() { return this.$store.state.auth.userSession },
  },

  methods: {
    hasPermissionRole(roleName) {
      if ( ! this.userSession ) return false;
      return this.userSession.hasPermissionRole(roleName);
    },
  }
};
