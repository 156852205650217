
<script>

 import EditQuestionSummaryForTheTeams from './EditQuestionSummaryForTheTeams.vue';
 import EditQuestionSummaryForYou from './EditQuestionSummaryForYou.vue';

 export default {
   name: 'EditQuestion',
   components: {
     EditQuestionSummaryForTheTeams,
     EditQuestionSummaryForYou,
   },
   props: {
     'index': {},
     'maxIndex': {},
     'question': {},
     'placeholderPoints': {},
     'hasOrdering': { default: true },
     'hasSaveButton': { default: false },
   },

   data() {
     return {
       showExpanded: false,
       placeholders: [
         {
           question: "e.g. Yuri Gagarin was the first man in space, but who was the first woman?",
           correctAnswer: "e.g. Valentina Tereshkova",
         },
         {
           question: "e.g. What does the I in the IPA style of beer stand for?",
           correctAnswer: "e.g. India",
         },
       ],
     }
   },

   created() {
     let initialShowExpanded = true;
     if ( this.question.question ) initialShowExpanded = false;
     if ( this.question.correctAnswer ) initialShowExpanded = false;
     this.showExpanded = initialShowExpanded;
   },

   computed: {
     questionNumber() { return this.index + 1 },
     placeholder() {
       if (this.$root.isMobile) {
         return {
           correctAnswer: "Correct Answer",
           question: "Question",
         };
       }
       return this.placeholders[ this.index ] || {};
     },
     isFirstQuestion() { return this.index === 0 },
     isLastQuestion() { return this.index >= this.maxIndex - 1 },
     placeholderPointsString() {
       const points = this.question.points;
       if ( points ) return points + "";
       return this.placeholderPoints + "";
     },

     isMoveUpDisabled() { return this.isFirstQuestion },
     isMoveDownDisabled() { return this.isLastQuestion },


     clipboardIconTitle() {
       return this.hasClipboardSupport()
            ? "Clipboard (works across different Quizzes!)"
            : "This browser doesn't support copy+paste of entire Questions :(  Try e.g. Chrome!";
     },

     contractedTitle() {
       return `Q (${this.placeholderPointsString} pt${this.maybePlural(this.placeholderPointsString)}): ${this.question.question || ''}

A: ${this.question.correctAnswer || ''}

Answer Trivia: ${this.question.answerTrivia || ''}

Reveal Answer Trivia: ${this.question.revealAnswerTrivia || ''}`;
     },
   },

   methods: {
     focusQuestionInputField() {
       const el = this.$refs.questionInputField;
       if ( ! el ) return false;

       this.$refs.questionInputField.focus();
       return true;
     },

     toggleExpanded() {
       this.showExpanded = ! this.showExpanded;
       if ( this.showExpanded ) {
         this.ensureQuestionCardIsVisible();
       }
     },

     ensureQuestionCardIsVisible() {
       this.$nextTick( () => {
         this.$refs.bottomOfCard.scrollIntoView({
           behaviour: 'smooth', block: 'nearest',
         });
       });
     },

     ensureBottomOfCardIsVisible() {
       setTimeout( () => {
         const el = this.$refs.bottomOfCard;
         el.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
       }, 50);
     },

     addMarkdownLinkExample() {
       this.insertTextAtCursor(
         this.question, 'revealAnswerTrivia',
         this.$refs.revealAnswerTrivia,
         "[Ants](https://en.wikipedia.org/wiki/Ant)",
       )
     },

     addMarkdownImageExample() {
       this.insertTextAtCursor(
         this.question, 'revealAnswerTrivia',
         this.$refs.revealAnswerTrivia,
         "![](https://i.imgur.com/HggYvB7.jpg)",
       )
     },

     addMarkdownImage() {
       this.insertTextAtCursor(
         this.question, 'revealAnswerTrivia',
         this.$refs.revealAnswerTrivia,
         "![]()",
         1,
       )
     },

     insertTextAtCursor: function(reactiveVariable, reactivePropertyName, control, insertText, backspaceCount = 0) {
       if ( ! control ) return;

       const startPos = control.selectionStart;
       const endPos = control.selectionEnd;

       const controlValue = control.value;
       const newValue = controlValue.substring(
         0, startPos,
       ) + insertText + controlValue.substring(
         endPos, controlValue.length,
       );

       this.$set(reactiveVariable, reactivePropertyName, newValue);

       const newCursorPos = startPos + insertText.length - backspaceCount;
       this.$nextTick(() => {
         control.selectionStart = control.selectionEnd = newCursorPos;
         control.focus();
       }, 10);
     },

     saveQuestion() {
       this.$emit('save-question');
       this.toggleExpanded();
     },

     deleteQuestion() {
       this.$emit('delete-question');
     },
     addQuestionAbove() {
       this.$emit('add-question-above');
     },
     moveQuestionUp() {
       this.$emit('move-question-up');
     },
     moveQuestionDown() {
       this.$emit('move-question-down');
     },

     cutQuestion() {
       this.$emit('cut-question');
     },
     copyQuestion() {
       this.$emit('copy-question');
     },
     pasteQuestionAbove() {
       this.$emit('paste-question-above');
     },

   },
 }
</script>

<template>
  <div>
    <b-card ref="questionCard" class="mb-3 question-card">
      <template v-slot:header>
        <!-- Base: normal order, then d-md-flex and reverse order -->
        <div class="d-block d-md-flex">
          <div class="order-2 text-right text-nowrap">

            <b-button
              @click.prevent="$emit('preview-question')"
              title="Preview how this question is presented to the teams"
              variant="outline-secondary"
              size="sm"
            >
              <small>Preview</small>
              <font-awesome-icon :icon="['fas', 'film']" class="ml-1" />
            </b-button>

            <b-button-group
              v-if="hasOrdering"
              :key="`moveUpDown-${index}`"
              class="ml-3"
            >
              <b-button
                @click.prevent="moveQuestionUp"
                :disabled="isMoveUpDisabled"
                title="Move question up"
                variant="outline-secondary"
                size="sm"
              ><small>Move</small> ⇡</b-button>
              <b-button
                @click.prevent="moveQuestionDown"
                :disabled="isMoveDownDisabled"
                title="Move question down"
                variant="outline-secondary"
                size="sm"
              ><small>Move</small> ⇣</b-button>
            </b-button-group>

            <b-button
              v-if="hasOrdering"
              :key="`insertAbove-${index}`"
              @click.prevent="addQuestionAbove"
              title="Insert new Question above this one"
              variant="outline-secondary"
              size="sm"
              class="ml-2"
            ><span class="nowrap"><small>Insert above</small> ⇡</span></b-button>

            <b-dropdown
              right
              :disabled="isClipboardDisabled()"
              :title="clipboardIconTitle"
              class="ml-2"
              size="sm"
              variant="outline-secondary"
            >
              <template v-slot:button-content>
                <font-awesome-icon :icon="['far', 'clipboard']" />
              </template>

              <b-dropdown-item-button
                v-if="hasOrdering"
                @click.prevent="cutQuestion"
                title="Cut Question to clipboard"
                variant="outline-secondary"
                size="sm"
              ><span class="nowrap">
                <font-awesome-icon :icon="['fas', 'cut']" /> Cut Question
              </span></b-dropdown-item-button>


              <b-dropdown-item-button
                @click.prevent="copyQuestion"
                title="Copy Question to clipboard"
                variant="outline-secondary"
                size="sm"
              ><span class="nowrap">
                <font-awesome-icon :icon="['far', 'copy']" /> Copy Question
              </span></b-dropdown-item-button>


              <b-dropdown-item-button
                v-if="hasOrdering"
                :key="`pasteAbove-${index}`"
                @click.prevent="pasteQuestionAbove"
                title="Paste Question from clipboard, insert it above this question"
                variant="outline-secondary"
                size="sm"
              ><span class="nowrap">
                <font-awesome-icon :icon="['fas', 'paste']" /> Paste Question above ⇡
              </span></b-dropdown-item-button>

            </b-dropdown>

            <b-button-close
              @click.prevent="deleteQuestion"
              class="ml-3"
              title="Delete Question"
            />

          </div>
          <div class="order-1 mt-1 mt-md-0 flex-grow-1 text-nowrap">
            <span class="align-middle">
              <span class="is-collapse-expand-button mr-1">
                <b-button
                  v-if="showExpanded" key="showExpandedButton"
                  @click.prevent="toggleExpanded"
                  size="sm"
                  variant="outline-info"
                  class="mr-2"
                  title="Hide question details"
                >
                  <span class="mx-1">
                    <font-awesome-icon :icon="['fas', 'angle-double-up']" />
                  </span>
                  <small class="mr-1">
                    Edit
                  </small>
                </b-button>
                <b-button
                  v-else key="showContractedButton"
                  @click.prevent="toggleExpanded"
                  size="sm"
                  variant="outline-info"
                  class="mr-2"
                  title="Show question details"
                >
                  <span class="mx-1">
                    <font-awesome-icon :icon="['fas', 'angle-double-down']" />
                  </span>
                  <small class="mr-1">
                    Edit
                  </small>
                </b-button>
              </span>
              <strong>Question {{questionNumber}}</strong>
            </span>
          </div>
        </div>
      </template>


      <div v-if="showExpanded" key="showExpanded">
        <b-row>
          <b-col>
            <b-form-group
              description="Visible to the teams, one question at a time"
            >
              <template #label>
                <div class="d-flex">
                  <div class="flex-grow-0">Question</div>
                  <div class="ml-4">
                    <b-form inline>
                      <b-form-input
                        v-model="question.points"
                        :placeholder="placeholderPointsString"
                        class="is-points-input-field"
                        size="sm"
                      />
                      <label class="ml-2"><small>Points</small></label>
                    </b-form>
                  </div>
                </div>
              </template>
              <b-form-input
                size="sm"
                v-model="question.question"
                :title="question.question"
                :placeholder="placeholder.question"
                ref="questionInputField"
              />
            </b-form-group>
          </b-col>

          <b-col cols="2" v-if="question.imageUrl" class="text-right align-self-center">
            <a :href="question.imageUrl" target="_blank"><img :src="question.imageUrl" class="is-medium-image" /></a>
          </b-col>
        </b-row>



        <details>
          <summary
            @click="ensureBottomOfCardIsVisible"
            :title="`Image URL: ${question.imageUrl || ''}`"
          >
            <small>
              Question details:
              <span>
                <span v-if="question.imageUrl" key="withImageUrl">
                  <strong>
                    <font-awesome-icon :icon="['far', 'image']" />
                    Image URL
                  </strong>
                </span>
                <span v-else key="withoutImageUrl">
                  <font-awesome-icon :icon="['far', 'image']" />
                  Image URL
                </span>
              </span>
            </small>
          </summary>

          <b-row>
            <b-col class="mt-2">
              <div class="expanded-detail">
                <b-form-group>
                  <template v-slot:description>
                    Link to an image, visible to the teams.
                    <strong>The teams might see the actual URL, so make sure it doesn't reveal the answer!</strong>
                  </template>
                  <template v-slot:label>
                    <font-awesome-icon :icon="['far', 'image']" />
                    Image URL
                  </template>
                  <b-form-input
                    size="sm"
                    v-model="question.imageUrl"
                  />
                </b-form-group>
              </div>
            </b-col>
          </b-row>

        </details>

        <hr />

        <b-card no-body>
          <b-tabs v-model="question.answerEntryTypeIndex" card>
            <b-tab title="Free text">
              <b-form-group label="Correct Answer">
                <template v-slot:description>
                  <div>
                    Visible to you, to help scoring.
                    <span v-if="question.shouldRevealAnswer" key="trueShouldRevealAnswer">
                      Visible to the teams after <strong>Reveal Scoring</strong> unless
                      unchecked below.
                    </span>
                    <span v-else key="falseShouldRevealAnswer">
                      Not visible to the teams unless checked below.
                    </span>
                  </div>
                  <div>
                    Separate correct answer variations with a <code>;</code>
                    e.g. <code>Grizzly bear; Bear; Grizzly; California Grizzly</code>.
                  </div>
                </template>
                <b-form-input
                  size="sm"
                  v-model="question.correctAnswer"
                  :title="question.correctAnswer"
                  :placeholder="placeholder.correctAnswer"
                />
              </b-form-group>
            </b-tab>

            <b-tab title="Multiple choice">
              <b-form-group label="Answer options">
                <template v-slot:description>
                  <div>
                    Write one answer option per line.
                    Prefix correct options with <code>--</code> (two dashes),
                    e.g. <code>-- California Grizzly</code>
                    or <code>-- -273 Celsius</code>
                  </div>
                  <div>
                    <span v-if="question.shouldRevealAnswer" key="trueShouldRevealAnswer">
                      The correct option(s) are indicated to the teams
                      after <strong>Reveal Scoring</strong> unless
                      unchecked below.
                    </span>
                    <span v-else key="falseShouldRevealAnswer">
                      The correct option(s) are not indicated to the
                      teams unless checked below.
                    </span>
                  </div>
                </template>
                <b-form-textarea
                  v-model="question.correctMultiAnswer"
                  :spellcheck="false"
                  rows="2"
                  max-rows="8"
                  size="sm"
                ></b-form-textarea>


              </b-form-group>
            </b-tab>

          </b-tabs>
        </b-card>

        <details class="mt-2">
          <summary
            @click="ensureBottomOfCardIsVisible"
            :title="`Answer Trivia: ${question.answerTrivia || ''}`"
          >
            <small>
              For you: <EditQuestionSummaryForYou :question="question" class="ml-1" />
            </small>
          </summary>

          <div class="expanded-detail pt-2">

            <b-form-group>
              <template v-slot:description>
                Interesting related trivia, visible only to you.
                Read this out when going through the correct answers during <strong>Reveal Scoring</strong>.
                <!-- Make it your QI moment! -->
              </template>
              <template v-slot:label>
                <font-awesome-icon :icon="['far', 'lightbulb']" />
                Answer Trivia
              </template>
              <b-form-input
                size="sm"
                v-model="question.answerTrivia"
              />
            </b-form-group>

            <b-form-group
              v-if="question.canAutoMarkCorrect"
              key="auto-mark-free-text-answer-as-correct"
            >
              <template v-slot:label>
                <span class="is-magic-wand-icon">
                  <font-awesome-icon :icon="['fas', 'magic']" />
                </span>
                Auto-mark free-text answer as "correct"
              </template>
              <template v-slot:description>
                Submitted answers that match one of the <i>Correct
                Answer variations</i> are auto-marked as "correct".
                <br />
                Other answers are left for you to mark (they're not
                marked "incorrect").
                <br />
                You can always manually fix any mistaken auto-marked answer.
              </template>

              <b-form-group>
                <b-form-radio v-model="question.autoMarkCorrectStyle" value="similar">
                  if it's <strong>similar</strong> to one of the correct answers.
                  <small>For <strong>names, things, phrases, long/difficult words</strong>.</small>
                </b-form-radio>
                <b-form-radio v-model="question.autoMarkCorrectStyle" value="same">
                  if it's <strong>the same</strong> as one of the correct answers.
                  <small>For <strong>specific words, numbers</strong>.</small>
                </b-form-radio>
                <b-form-radio v-model="question.autoMarkCorrectStyle" value="never">
                  <strong>never</strong>
                </b-form-radio>
              </b-form-group>
            </b-form-group>
          </div>

        </details>


        <details>
          <summary
            @click="ensureBottomOfCardIsVisible"
            :title="`Reveal Answer Trivia: ${question.revealAnswerTrivia || ''}`"
          >
            <small>
              For the teams:
              <EditQuestionSummaryForTheTeams :question="question" class="ml-1" />
            </small>
          </summary>

          <div class="expanded-detail pt-2">

            <small>
              <p>
                These may be revealed to the teams at <strong>Reveal Scoring</strong>,
                when you go through the correct answers.
              </p>
            </small>
            <b-form-group>
              <template v-slot:description>
                Interesting related trivia or maybe a picture,
                revealed to teams at <strong>Reveal Scoring</strong>.
                <br/>
                This is Markdown which supports e.g. links and image links.
                Huh, what is <i>Markdown</i>
                <b-button
                  v-b-toggle.sidebar-markdown-instructions
                  pill
                  variant="info"
                  class="is-tiny-button ml-1"
                  title="Markdown instructions"
                >
                  <font-awesome-icon :icon="[ 'far', 'question-circle' ]" />
                </b-button>

              </template>
              <template v-slot:label>
                <font-awesome-icon :icon="['far', 'lightbulb']" />
                Reveal Answer Trivia
                <small class="ml-1">
                  Add markdown:
                  <span class="ml-1">
                    <a href="#" @click.prevent="addMarkdownLinkExample">Link example</a>
                    |
                    <a href="#" @click.prevent="addMarkdownImageExample">Image example</a>
                    |
                    <a href="#" @click.prevent="addMarkdownImage">Image URL</a>
                  </span>
                </small>
              </template>
              <b-form-textarea
                ref="revealAnswerTrivia"
                v-model="question.revealAnswerTrivia"
                :spellcheck="false"
                rows="2"
                max-rows="7"
                size="sm"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group>
              <template v-slot:description>
                Reveal the Correct Answer at <strong>Reveal Scoring</strong>.
                This is the first answer variation if they are separated by a
                <code>;</code> e.g. <code>Grizzly bear</code>
              </template>

              <b-form-checkbox v-model="question.shouldRevealAnswer">
                Reveal Correct Answer (i.e. <i>{{question.shortRevealCorrectAnswer}}</i>)
              </b-form-checkbox>
            </b-form-group>
          </div>

        </details>

        <div v-if="hasSaveButton" class="text-right">
          <b-button
            @click.prevent="saveQuestion()"
            variant="primary" size="sm"
            class="mt-2 px-4"
          >Save</b-button>
        </div>

        <div ref="bottomOfCard" class="is-bottom-of-card">B &nbsp;</div>
      </div>

      <div v-else key="showContracted">
        <b-row>
          <b-col :title="contractedTitle">
            <div class="d-flex">
              <div class="flex-grow-1 mr-2 text-nowrap overflow-hidden">
                <div class="d-flex">
                  <div>
                    <strong>Q:</strong>
                  </div>
                  <div class="flex-grow-1">
                    <div class="ml-1">{{question.question}} -
                      <strong>A:</strong>
                      {{question.effectiveCorrectAnswer}}
                    </div>
                    <div class="is-contracted-summary">
                      <small>
                        <EditQuestionSummaryForYou :question="question" />,
                        <EditQuestionSummaryForTheTeams :question="question" />
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="question.imageUrl">
                <div class="is-small-image-cell text-center">
                  <a :href="question.imageUrl" target="_blank"><img :src="question.imageUrl" class="is-small-image ml-2" /></a>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

    </b-card>
  </div>
</template>

<style>

 .question-card .card-body {
   padding-bottom: 0.6rem;
 }

 /* JPL: remove */
 .points-field fieldset.form-group {
   display: inline;
   margin-bottom: 0;
 }

 .is-medium-image {
   max-height: 6rem;
   max-width: 100%;
 }

</style>

<style scoped>

 button.is-tiny-button {
   font-size: 80%;
   line-height: 1rem;

   padding-left: 0.2rem;
   padding-right: 0.2rem;
   padding-top: 0;
   padding-bottom: 0;
 }

 .is-collapse-expand-button {
   margin-left: -0.3rem;
 }

 input.is-points-input-field.form-control.form-control-sm {
   font-size: 60%;
   width: 2rem;
 }

 .is-small-image-cell {
   width: 4.5rem;
 }

 .is-small-image {
   max-width: 4.5rem;
   max-height: 3rem;
 }

 .expanded-detail {
   border-left: 1px solid #DDD;
   padding-left: 1rem;
   margin-left: 0.3rem;
 }

 .is-magic-wand-icon {
   padding-left: 0.3rem;
   padding-right: 0.3rem;
   padding-top: 0.1rem;
   padding-bottom: 0.0rem;
   border-radius: 0.3rem;

   background-color: var(--success);
   color: var(--white);
 }

 .is-contracted-summary {
   margin-left: 0.25rem;
   margin-top: -0.1rem;
 }

 /* Offset this below the card, to leave space for the .bottom-button-bar-height */
 .is-bottom-of-card {
   position: relative;
   /* Slightly more than the .bottom-button-bar-height in the parent component */
   top: 5.2rem;
   height: 1px;
   opacity: 0;
 }

</style>
