
<script>

 export default {
   name: "StatusActionsConfirmAnswers",

   props: [
     'quiz',
   ],

   methods: {
     clickMarkAnswers() {
       this.$emit('mark-answers');
     },
     clickAskMoreQuestions() {
       this.$emit('ask-more-questions');
     },
   },

 };

</script>


<template>
  <b-row>
    <b-col>
      <b-card>
        <b-row>
          <b-col>
            <div class="float-right text-right">
              <div class="actions-buttons">
                <div>
                  <b-button @click.prevent="clickAskMoreQuestions()" variant="secondary">
                    <small><font-awesome-icon :icon="[ 'fas', 'undo-alt' ]" /></small> Ask More Questions
                  </b-button>
                </div>
                <div>
                  <b-button @click.prevent="clickMarkAnswers()" variant="primary" class="mt-1">
                    ➞ Mark Answers
                  </b-button>
                </div>
              </div>
            </div>

            <b-card-text>
              <big class="mr-2"><font-awesome-icon :icon="['fas', 'bullhorn']" /></big>
              <strong>Tell the teams:</strong>
              <blockquote class="blockquote">
                "Please <b>answer</b> and <b>submit</b> all questions , or click the <strong>Submit Remaining</strong> button!"
              </blockquote>
              <big class="mr-2"><font-awesome-icon :icon="['far', 'clock']" /></big>
              Wait 10-20s, then continue to
              <b-badge size="sm" variant="primary">Mark Answers</b-badge>,
              where they can no longer change their answers.
            </b-card-text>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<style scoped>

</style>
