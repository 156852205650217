
import axios from '@/store/axios';
import Session from '../../../models/master/session.js';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {

    // Post session to the API.
    async saveObject({ dispatch }, session ) {
      const userSession = await dispatch('auth/ensureSession', {}, { root: true });
      return await axios.post(`/api/user-session/${userSession.id}/session/${session.id}`, session)
        .then((res) => {
          const data = res.data;
          return new Session(data.session);
        });
      ///JPL: catch
    },

  },

};
