
<script>

 export default {
   name: "StatusActionsRevealLeaderboard",

   props: [
     'quiz',
   ],

   methods: {
     clickFinishQuiz() {
       this.$emit('finish-quiz');
     },
   },

 };

</script>


<template>
  <b-row>
    <b-col>
      <b-card>
        <b-row>
          <b-col cols="9">
            <div>
              <div>
                <b-card-text>
                  <big class="mr-2"><font-awesome-icon :icon="['far', 'eye']" /></big>
                  Teams can now see the leaderboard with <strong>each others total scores and ranking</strong>.

                  <br />

                  <big class="mr-2"><font-awesome-icon :icon="['far', 'eye']" /></big>
                  Teams can now see the list of <strong>popular questions</strong>.
                  They can <i>not</i> see any downvotes.
                </b-card-text>

                <b-card-text>
                  <strong>This is it, you may now finish the quiz!</strong>
                </b-card-text>
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="actions-buttons text-right">
              <div>
                <div>
                  <b-button @click.prevent="clickFinishQuiz()" variant="primary" class="mt-1">
                    ➞ Finish Quiz
                  </b-button>
                </div>
              </div>
            </div>

          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<style scoped>

</style>
