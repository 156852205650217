
<script>

 import ModalConfirm from '@/components/share/Modal/Confirm.vue';

 export default {
   name: "StatusActionsRevealScoring",
   components: {
     ModalConfirm,
   },
   props: [
     'quiz',
   ],

   methods: {
     clickRevealLeaderboard() {
       this.$bvModal.show('bv-modal-confirm-reveal-leaderboard');
     },
     clickRevealLeaderboardOk() {
       this.$emit('reveal-leaderboard');
     },
   },

 };

</script>


<template>
  <b-row>
    <b-col>
      <b-card>
        <b-row>
          <b-col cols="9">
            <div>
              <div>
                <b-card-text>
                  <big class="mr-2"><font-awesome-icon :icon="['far', 'eye']" /></big>
                  Teams can now see <strong>their own scores</strong> and
                  <strong>the correct answers</strong>.

                  <br />

                  <big class="mr-2"><font-awesome-icon :icon="['fas', 'heart']" /></big>
                  Teams can now <strong>vote</strong> for questions they like/dislike.
                </b-card-text>

                <b-card-text>
                  <big class="mr-2"><font-awesome-icon :icon="['fas', 'bullhorn']" /></big>
                  You should
                  <strong>go through remaining correct answers and any extra trivia.</strong>
                  Pro-tip: use the
                  <b-badge size="sm" variant="primary">
                    View
                    <font-awesome-icon :icon="['fas', 'film']" class="ml-1" />
                  </b-badge>
                  button

                  <br/>

                  <big class="mr-2"><font-awesome-icon :icon="['fas', 'bullhorn']" /></big>
                  You should
                  <strong>read out the winners now</strong>,
                  before revealing the complete
                  <b-badge size="sm" variant="primary">Leaderboard</b-badge>
                  .
                </b-card-text>
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="actions-buttons text-right">
              <div>
                <div>
                  <b-button @click.prevent="clickRevealLeaderboard()" variant="primary" class="mt-1">
                    ➞ Leaderboard
                  </b-button>
                </div>
              </div>
            </div>

          </b-col>
        </b-row>
      </b-card>


      <ModalConfirm
        elementId="bv-modal-confirm-reveal-leaderboard"
        @click-ok="clickRevealLeaderboardOk"
      >
        <template #title>
          Display Leaderboard to the Teams?
        </template>
        <template #message>
          <p>
            The teams will now see the full leaderboard with<br />
            everyone's place and score.
          </p>
          <p>
            You may want to reveal the winners yourself first.
          </p>
          <p>Continue?</p>
        </template>
      </ModalConfirm>
    </b-col>
  </b-row>
</template>

<style scoped>

</style>
