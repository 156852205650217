
export default class UserPermissionRole {

  constructor({ id, name }) {
    return {
      id,
      name,
    };
  }

}
