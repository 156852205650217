
import axios from '@/store/axios';
import Team from '../../../models/master/team.js';

export default {
  namespaced: true,
  state: {
    teams: [],
  },
  mutations: {
    // When saved on the edit page, add here if no id, or replace if
    // id
    setObject(state, team) {
      const existingIndex = state.teams.findIndex((q) => q.id === team.id);
      if (existingIndex >= 0) {
        state.teams.splice(existingIndex, 1, team);
      }
      else {
        state.teams.push(team);
      }
    },

  },
  getters: {
  },
  actions: {

    // Get list of teams with from the API. Update or add any missing
    // to Store.
    async fetchList({ commit, dispatch }, { sessionId }) {
      const userSession = await dispatch('auth/ensureSession', {}, { root: true });
      if ( ! this.$app.$root.callApi ) return [];

      return await axios.get(`/api/user-session/${userSession.id}/session/${sessionId}/teams`)
        .then(res => {
          const teams = res.data.teams;

          const teamObjects = teams.map(teamData => {
            const newTeam = new Team(teamData);
            commit('setObject', newTeam);
            return newTeam;
          });

          return teamObjects;
        });
      ///JPL: catch
    },

    async deleteObject({ dispatch }, { sessionId, id }) {
      const userSession = await dispatch('auth/ensureSession', {}, { root: true });
      if ( ! this.$app.$root.callApi ) return;
      return await axios.delete(`/api/user-session/${userSession.id}/session/${sessionId}/team/${id}`);
    },
  },

};
