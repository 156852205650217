
export default class MasterTeamPlacement {
  constructor({ teamTeam, totalScore, placementOrdinal } = {}) {
    return {
      teamTeam,
      totalScore,
      placementOrdinal,
    };
  }
}
