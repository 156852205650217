<script>

 import TopNavBar from '@/components/common/TopNavBar.vue';
 import BottomFooter from '@/components/common/BottomFooter.vue';

 export default {
   name: 'AboutPage',
   components: {
     TopNavBar,
     BottomFooter,
   },
 }

</script>

<template>
  <div class="is-footer-flex-container">
    <TopNavBar />

    <b-container class="is-footer-flex-content">

      <h2>Acknowledgments</h2>

      <p>
        Thank you very much to the following people for contributing
        photos and other media:
      </p>


      <h3>The Noun Project</h3>

      <ul>
        <li>
          Logo icon created by <a href="https://thenounproject.com/catalarem/" target="_blank">Rémy Médard</a>, the Noun Project
        </li>
      </ul>

      <h3>Unsplash</h3>

      <b-row>
        <b-col>
          <ul>
            <li>Afif Kusuma</li>
            <li>Alexander Jawfox</li>
            <li>Brooke Cagle</li>
            <li>Bruno Emmanuelle</li>
            <li>Chris Montgomery</li>
            <li>Disruptivo</li>
            <li>Eliott Reyna</li>
            <li>Glen Carrie</li>
            <li>Jonas Leupe</li>
            <li>Louis Hansel</li>
          </ul>
        </b-col>
        <b-col>
          <ul>
            <li>Pejmon Hodaee</li>
            <li>Priscilla Du Preez</li>
            <li>Quyet Nguyen</li>
            <li>Steven Erixon</li>
            <li>Thiago Garcia</li>
            <li>Thought Catalog</li>
            <li>Wesley Tingey</li>
            <li>William Moreland</li>
            <li>You X Ventures</li>
            <li>Heber Galindo</li>
            <li>Fallon Michael</li>
            <li>Jason Yoder</li>
          </ul>
        </b-col>
      </b-row>

      <p>
        and other beautiful photographs at <a href="https://unsplash.com" target="_blank">Unsplash</a>.
      </p>

    </b-container>

    <BottomFooter />
  </div>
</template>

<style scoped>

</style>
