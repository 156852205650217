
import WebCookie from '../web/cookie.js';

import ModelBase from '../model-base.js';
import UserAccountExtract from './account-extract.js';



const sessionWebCookie = new WebCookie({
  name: 'PQ_SESSION',
  options: { expires: 1 },
});

const rememberUsernameWebCookie = new WebCookie({
  name: 'PQ_REMEMBER_USERNAME',
  options: { expires: 60 },
});



export default class UserSession extends ModelBase {


  static newFromCookie() {
    const cookieValue = sessionWebCookie.get();
    return new UserSession({ cookieValue: cookieValue });
  }

  constructor({ id, cookieValue, userAccountId, userAccountExtract }) {
    super();
    return {
      ...this,
      id,
      cookieValue,

      // Populated after login
      userAccountId, ///JPL: try to remove this, use the userAccountExtract.id
      userAccountExtract: this.ensureObject(userAccountExtract, UserAccountExtract),


      get hasCookie() {
        return !! this.cookieValue;
      },

      get hasSession() {
        return !! (this.cookieValue && this.id);
      },

      get hasUserAccount() {
        return !! (this.hasSession && this.userAccountId);
      },

      // Effective id, either userAccount or session account
      get accountId() {
        return this.userAccountId || this.id;
      },

      ///JPL: needed? if it's an async fn, this happens automatically
      get asPromise() {
        return new Promise(resolve => resolve(this));
      },

      hasPermissionRole(roleName) {
        if ( ! this.userAccountExtract ) return false;
        return this.userAccountExtract.hasPermissionRole(roleName);
      },
      get hasAnyAdminPermissionRole() {
        if ( ! this.userAccountExtract ) return false;
        return this.userAccountExtract.hasAnyAdminPermissionRole;
      },

      maybeSetUserAccountExtract(userAccountExtract) {
        if ( ! userAccountExtract ) return null;
        this.userAccountExtract = this.ensureObject(userAccountExtract, UserAccountExtract);
        this.userAccountId = this.userAccountExtract.id;
        return this.userAccountExtract;
      },

      setUserSessionId(id) {
        this.id = id;
        this.setCookie();
        return id;
      },

      setCookie() {
        this.cookieValue = sessionWebCookie.set( this.id );
      },

      logIn(userAccountExtract) {
        this.maybeSetUserAccountExtract(userAccountExtract);
        this.setCookie();
      },

      get sessionCookieOptions() {
        return { expires: 1 };
      },

      logOut() {
        this.userAccountExtract = null;
        this.userAccountId = null;
        this.id = null;
        sessionWebCookie.remove();
      },

      setRememberUsernameCookie(username, shouldRememberUsername) {
        if ( shouldRememberUsername ) {
          rememberUsernameWebCookie.set(username);
        }
        else {
          rememberUsernameWebCookie.remove();
        }
      },

      get rememberedUsername() {
        return rememberUsernameWebCookie.get(); // or undefined
      },

    };
  }

}
