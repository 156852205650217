
<script>

 import CollapsibleCard from '@/components/share/CollapsibleCard';

 export default {
   name: 'EditAnswerSheetInstructions',
   components: {
     CollapsibleCard,
   },
 }
</script>

<template>
  <b-card >
    <div role="tablist">
      <CollapsibleCard accordion="edit-answer-sheet" title="How does this work?" :visible="true">
        <b-card-text>
          This is where you design the Answer Sheet for your Quiz.
        </b-card-text>
        <b-card-text>
          See instructions in the boxes below.
        </b-card-text>
      </CollapsibleCard>

      <CollapsibleCard accordion="edit-answer-sheet" title="Creating a quiz">
        <b-card-text>
          You can create your quiz from scratch right here on
          this page.
        </b-card-text>

        <b-card-text>
          <b>Points:</b>
          Number of points for the correct answer. But later
          when running the quiz, you can award any number
          of points (half-points, bonus points, whatever suits
          you).
        </b-card-text>

        <b-card-text>
          <b>Question:</b>
          The question you'll read out to the teams during the
          quiz. <i>The teams can see asked questions</i>, so
          please ensure they make sense on their own.
        </b-card-text>

        <b-card-text>
          <b>Correct Answer:</b>
          You can see the correct answer when running the
          quiz. That will make the questions easier to mark.
        </b-card-text>
        <b-card-text>
          You can enter <i>variations</i> of the correct
          answer, separated by a semicolon (<code>;</code>),
          e.g.<br />
          <code>Rubber boots; wellies; galoshes</code>
        </b-card-text>
        <b-card-text>
          The teams can see the first correct answer
          variation (in this case <code>Rubber boots</code>)
          during <strong>Reveal Scoring</strong> (you can
          turn this off per question).
        </b-card-text>
        <b-card-text>
          You'll probably want to read the correct answers
          out at the end, along with any related trivia.
        </b-card-text>

        <b-card-text>
          <b>Auto-marking:</b>
          Submitted free-text answers can be automatically marked.
        </b-card-text>
        <b-card-text>
          Since this is quite fuzzy, a submitted answer can
          be auto-marked "correct" either:
          <ul>
            <li>if it is <i>similar</i> to a correct answer (default)</li>
            <li>if it is the <i>same</i> as a correct answer</li>
            <li>not at all, it is left for you to mark manually.</li>
          </ul>

          If you have a two-part answer, you probably don't
          want to auto-mark it. Or you could provide two
          correct variants, e.g.<br />
          <code>cows, pigs; pigs, cows</code>.
        </b-card-text>
        <b-card-text>
          A submitted free-text answer is never auto-marked
          "incorrect", since it could easily be correct even
          if it doesn't happen to match any of your correct
          answer variations. Instead the answer is left
          alone for you to mark manually.
        </b-card-text>

      </CollapsibleCard>

      <CollapsibleCard class="mt-2" accordion="edit-answer-sheet" title="Running the quiz">
        <b-card-text>
          During the quiz you run through the questions by
          clicking
          <b-badge size="sm" variant="primary">Ask Next Question</b-badge>.
        </b-card-text>
        <b-card-text>
          Each <b>question</b> becomes visible <i>to the teams</i> but you would normally still read them out.
        </b-card-text>
        <b-card-text>
          While the teams are busy answering questions you can
          start marking their submitted answers, or you can
          wait for a while until there are a bunch of answers
          to mark.
        </b-card-text>
        <b-card-text>
          At the end, you will have time to mark all remaining
          answers.
        </b-card-text>
        <b-card-text>
          Each <b>answer</b> becomes visible to the teams when
          you reveal it. You can reveal the score and the
          correct answer either all at once, or each question
          individually.
        </b-card-text>
      </CollapsibleCard>

      <CollapsibleCard class="mt-2" accordion="edit-answer-sheet" title="If you have already created a quiz">
        <b-card-text>
          You can import questions from a spreadsheet like MS Excel /
          Office 365, Google Sheets.
        </b-card-text>
        <b-card-text>
          Look below for the tab <strong>Import Questions</strong>.
        </b-card-text>

        <b-card-text>
          If you've already created a Quiz here, you may want to reuse it.
          <ul>
            <li>Run the same quiz again with other people, using the same questions. You can invite teams from the home page.</li>
            <li>Copy-paste questions from another Quiz into this answer sheet.</li>
          </ul>
        </b-card-text>
        <b-card-text>
        </b-card-text>
      </CollapsibleCard>
    </div>
  </b-card>
</template>

<style scoped>

</style>
