import ModelBase from '../model-base.js';

export default class TeamAnswerScore extends ModelBase {
  constructor({ teamId, questionId, isCorrect, pointsAwarded }) {
    super();
    return {
      ...this,
      teamId,
      questionId,
      isCorrect,
      pointsAwarded: this.ensureNumeric(pointsAwarded),
    };
  }
}

