
<script>

 import ShareTwitterButton from '@/components/share/Share/TwitterButton.vue';

 export default {
   name: 'ShareTweetButton',
   components: { ShareTwitterButton },
   props: {
     tweetText: {},
     eventLabel: {},
   },

   computed: {
     tweetUrl() {
       const escapedText = encodeURIComponent(this.tweetText);
       return `https://twitter.com/intent/tweet?text=${escapedText}`;
     },
   },
 }
</script>

<template>
  <ShareTwitterButton :url="tweetUrl" :eventLabel="eventLabel">
    <slot>Tweet</slot>
  </ShareTwitterButton>
</template>
