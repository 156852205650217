'use strict';

import _ from 'lodash';
import doubleMetaphone from 'double-metaphone';
import extractNumbers from 'extract-numbers';

import ModelBase from '../model-base.js';

import shortid from 'shortid';

import MasterClipboardQuestion from '../master/clipboard-question.js';
import MasterAnswerScore from '../master/answer-score.js';
import MasterQuestionStatus from '../master/question-status.js';
import TeamAnswer from '../team/answer.js';
import TeamAnswerOption from '../team/answer-option.js';

export default class Question extends ModelBase {
  constructor({ id, points, question, answerEntryType = "freeText", correctAnswer, correctMultiAnswer, shouldRevealAnswer = true, autoMarkCorrectStyle = "similar", answerTrivia, revealAnswerTrivia, imageUrl }) {
    super();
    id = id || shortid.generate();
    return {
      ...this,
      id,
      points: this.ensureNumeric(points),
      question,
      answerEntryType,
      correctAnswer,
      correctMultiAnswer,
      shouldRevealAnswer,
      autoMarkCorrectStyle,
      answerTrivia,
      revealAnswerTrivia,
      imageUrl,
      // When changing the properties here, remember to update
      // master/clipboard-question.js !!


      // Return a clone of this question
      clone() {
        return new Question({
          ...this,
          id: shortid.generate(),
        });
      },

      get isEmpty() {
        if ( this.question )           return false;
        if ( this.correctAnswer )      return false;
        if ( this.correctMultiAnswer ) return false;
        if ( this.answerTrivia )       return false;
        if ( this.revealAnswerTrivia ) return false;
        if ( this.imageUrl )           return false;
        return true;
      },

      get asClipboardMasterQuestion() {
        return new MasterClipboardQuestion(this);
      },

      newTeamAnswer(answerSheet, teamId) {
        return new TeamAnswer({
          teamId: teamId,
          questionId: this.id,
          pointsAwarded: answerSheet.actualPoints( this.points ),
          imageUrl: this.imageUrl,
        });
      },

      newMasterAnswerScore(answerSheet, teamId) {
        return new MasterAnswerScore({
          teamId: teamId,
          questionId: this.id,
          pointsAwarded: answerSheet.actualPoints( this.points ),
          isCorrect: null,
        });
      },

      newMasterQuestionStatus() {
        return new MasterQuestionStatus({
          questionId: this.id,
        });
      },

      get isAnswerEntryTypeFreeText() {
        return this.answerEntryType === "freeText";
      },
      get isAnswerEntryTypeMultiChoice() {
        return this.answerEntryType === "multiChoice";
      },

      get effectiveCorrectAnswer() {
        if ( this.isAnswerEntryTypeFreeText ) {
          return this.correctAnswer;
        }
        else if ( this.isAnswerEntryTypeMultiChoice ) {
          return this.correctAnswerOptionsString;
        }
        else {
          throw Error(`Internal error: answerEntryType ${this.answerEntryType}`);
        }
      },

      get effectivePrimaryCorrectAnswer() {
        if ( this.isAnswerEntryTypeFreeText ) {
          return this.primaryCorrectAnswer;
        }
        else if ( this.isAnswerEntryTypeMultiChoice ) {
          return this.correctAnswerOptionsString;
        }
        else {
          throw Error(`Internal error: answerEntryType ${this.answerEntryType}`);
        }
      },

      ///JPL: maybe rename these to freeText
      ///JPL: or maybe abstract away the different answerEntrytype
      get correctAnswerVariations() {
        const answer = this.correctAnswer || "";
        const answers = answer
              .split(/\s*;\s*/)
              .map(answer => answer.trim())
              .filter(answer => answer !== "");
        return answers;
      },

      get saneCorrectAnswerVariations() {
        return this.correctAnswerVariations.map(answer => this.sanitizeAnswer(answer));
      },

      get normalizedCorrectAnswerVariations() {
        return _.flatMap(
          this.correctAnswerVariations,
          answer => this.normalizeAnswer(answer),
        );
      },

      get primaryCorrectAnswer() {
        const answers = this.correctAnswerVariations;
        return answers[0] || null;
      },

      get revealCorrectAnswer() {
        return this.primaryCorrectAnswer;
      },

      get shortRevealCorrectAnswer() {
        const answer = this.effectivePrimaryCorrectAnswer;
        if ( ! answer ) return "";
        const shortAnswer = answer.substr(0, 50);
        if ( shortAnswer === answer ) return answer;
        return `${shortAnswer}...`;
      },

      isMultiChoiceAnswerCorrect(teamAnswer) {
        const correctIndexToTrue = this.correctAnswerOptionIndexToTrue;
        let foundCorrect = false;
        for (let selectedIndex of teamAnswer.multiChoiceSelectedOptionIndexes) {
          if ( correctIndexToTrue[selectedIndex] ) {
            foundCorrect = true;
          }
          else {
            return false;
          }
        }
        return foundCorrect;
      },

      get canAutoMarkCorrect() {
        return this.isAnswerEntryTypeFreeText;
      },

      get shouldAutoMarkCorrect() {
        if ( this.autoMarkCorrectStyle === "similar") return true;
        if ( this.autoMarkCorrectStyle === "same") return true;
        // "never"
        return false;
      },

      isAutoMarkCorrect(teamAnswer) {
        const autoMarkCorrectStyle = this.autoMarkCorrectStyle;
        if ( autoMarkCorrectStyle === "never" ) {
          return false;
        }
        else if ( autoMarkCorrectStyle === "same" ) {
          return this.isAutoMarkCorrectSame(teamAnswer);
        }
        else if ( autoMarkCorrectStyle === "similar" ) {
          return this.isAutoMarkCorrectSimilar(teamAnswer);
        }

        console.error("Invalid autoMarkCorrectStyle value: ", autoMarkCorrectStyle);
        return false;
      },

      // Same: whitespace, case insensitive
      isAutoMarkCorrectSame(teamAnswer) {
        const saneTeamAnswer = this.sanitizeAnswer(teamAnswer.answer);
        const isSameFound = this.saneCorrectAnswerVariations.find(
          variation => variation === saneTeamAnswer,
        );
        if ( isSameFound ) {
          return true;
        }

        return false;
      },

      isSameAnswerVariationsCorrect(saneCorrectAnswerVariations, saneTeamAnswer) {
        return !! saneCorrectAnswerVariations.find(
          variation => variation === saneTeamAnswer,
        );
      },

      isAutoMarkCorrectSimilar(teamAnswer) {
        const [ shortCorrectAnswerVariations, longCorrectAnswerVariations ] =
              _.partition(
                this.saneCorrectAnswerVariations,
                answer => answer.length <= 2
              );

        // Check short correct answer variations with "same"
        const saneTeamAnswer = this.sanitizeAnswer(teamAnswer.answer);
        const isShortCorrect = this.isSameAnswerVariationsCorrect(
          shortCorrectAnswerVariations,
          saneTeamAnswer,
        );
        if (isShortCorrect) return true;


        // Check long correct answer variations with "similar"
        const normalizedCorrectAnswerExists = _.keyBy(
          longCorrectAnswerVariations.flatMap(
            variation => this.normalizeAnswer(variation),
          ),
          answer => answer,
        );
        const normalizedTeamAnswers = this.normalizeAnswer(saneTeamAnswer);
        const isSimilarFound = normalizedTeamAnswers.find(
          teamAnswer => !! normalizedCorrectAnswerExists[teamAnswer]
        );
        if ( isSimilarFound ) return true;


        // Could be a number, check all with "same"
        if ( this.isAutoMarkCorrectSame(teamAnswer)) return true;

        return false;
      },

      // Sanitize whitespace
      sanitizeAnswer(answerString) {
        answerString = answerString || "";
        return answerString.replace(/\s+/g, " ").trim().toUpperCase();
      },

      // Sanitize and normalize by upcasing, and converting to
      // metaphone + the same without any stopwords like"a", "an", "the"
      normalizeAnswer(answerString) {
        return _
          .chain([
            this.sanitizeAnswer(answerString),
            this.withoutStopWords( this.sanitizeAnswer(answerString) ),
          ])
          .flatMap(string => this.normalizeString(string))
          .uniq()
          .value();
      },

      normalizeString(string) {
        const numbers = extractNumbers(string) || [];
        const numbersString = numbers.length ? numbers.join(" ") + " " : "";
        return doubleMetaphone(string).map(s => `${numbersString}${s}`);
      },

      withoutStopWords(string) {
        string = string.replace(/\s+\bAND\b\s+/g, ", ");
        string = string.replace(/\s*\bTHE\b\s+/g, "");
        string = string.replace(/\s*\bAN\b\s+/g, "");
        string = string.replace(/\s*\bA\b\s+/g, "");
        return string;
      },


      // Read and modified by the EditQuestion <b-tab> component
      get answerEntryTypeIndex() {
        return this.answerEntryType === "freeText" ? 0 : 1;
      },
      set answerEntryTypeIndex(value) {
        this.answerEntryType = value === 0 ? "freeText" : "multiChoice";
      },


      get answerOptions() {
        const correctMultiAnswer = this.correctMultiAnswer || "";
        return correctMultiAnswer
          .split(/\n/)
          .map(s => s.trim())
          .map(s => {
            const match = s.match(/^(--)?\s*(.+)$/);
            if ( ! match ) return null;

            let answer = match[2];
            let isCorrect = !! match[1];

            return new TeamAnswerOption({
              answer,
              isCorrect,
            });
          })
          .filter(teamAnswerOption => !! teamAnswerOption);
      },

      get answerOptionStrings() {
        return this.answerOptions.map(answerOption => answerOption.answer);
      },

      get correctAnswerOptions() {
        return this.answerOptions.filter(answerOption => answerOption.isCorrect);
      },

      get correctAnswerOptionIndexToTrue() {
        let correctIndexes = {};
        this.answerOptions.forEach((answerOption, index) => {
          if (answerOption.isCorrect) {
            correctIndexes[index] = true;
          }
        });
        return correctIndexes;
      },

      get correctAnswerOptionsString() {
        return this.correctAnswerOptions.map(option => option.answer).join("; ");
      },

      teamAnswerOptions(isThisQuestionRevealed) {
        return this.answerOptions.map(
          answerOption => answerOption.newMaybeRevealed(isThisQuestionRevealed)
        );
      },
    };

  }
}
