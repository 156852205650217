<script>
 export default {
   name: 'TeamGameAnswerVoteButtons',
   props: [
     'teamAnswer',
     'showVoteReminder',
   ],

   data() {
     return {
     };
   },

   computed: {
     voteUpClass() {
       return this.teamAnswer.questionVote === true ? "is-up-voted" : "is-unclicked";
     },
     voteDownClass() {
       return this.teamAnswer.questionVote === false ? "is-down-voted" : "is-unclicked";
     },

     voteUpVariant() {
       return this.teamAnswer.questionVote === true ? "outline-danger" : "outline-primary";
     },
     voteDownVariant() {
       return this.teamAnswer.questionVote === false ? "secondary" : "outline-secondary";
     },
   },

   methods: {
     clickUpvote() {
       this.teamAnswer.questionVote = this.teamAnswer.questionVote === true ? null : true;
       this.$emit('question-vote-changed');
     },
     clickDownvote() {
       this.teamAnswer.questionVote = this.teamAnswer.questionVote === false ? null : false;
       this.$emit('question-vote-changed');
     },
   },
 };

</script>


<template>
  <div>
    <div v-if="showVoteReminder">
      <b-button-group size="sm" class="vote-buttons">
        <b-button
          @click.prevent="clickUpvote"
          :variant="voteUpVariant"
          title="Great question!"
          class="text-nowrap"
        >
          <font-awesome-icon :icon="['fas', 'heart']" />
          Like
        </b-button>
        <b-button
          @click.prevent="clickDownvote"
          :variant="voteDownVariant"
          title="This question can be improved"
        >
          <font-awesome-icon :icon="['fas', 'poo']" />
        </b-button>
      </b-button-group>
    </div>
    <div v-else class="text-nowrap">
      <span
        @click.prevent="clickUpvote"
        class="is-upvote is-vote-button"
        title="Great question!"
      >
        <span :class="voteUpClass"><font-awesome-icon :icon="['fas', 'heart']" /></span>
      </span>

      <span
        @click.prevent="clickDownvote"
        class="is-downvote is-vote-button ml-1"
        title="This question can be improved"
      >
        <span :class="voteDownClass"><font-awesome-icon :icon="['fas', 'poo']" /></span>
      </span>
    </div>
  </div>

</template>


<style scoped>

 .vote-buttons.btn-group-sm button.btn {
   font-size: 70%;
 }

 .is-vote-button {
   cursor: pointer;
 }

 .is-vote-button:hover {
   color: var(--primary)
 }

 .is-upvote .is-up-voted {
   color: var(--danger);
 }

 .is-downvote .is-down-voted {
   color: #935116;
 }

</style>
