
import ModelBase from '../model-base.js';
import SessionStatus from '../session-status.js';
import TeamTeam from '../team/team.js';

// Note: because of Vue's reactivity, prototypes don't work at all, so
// the methods are declared on the instance, not on the prototype :/
export default class Session extends ModelBase {

  static createDefault({ answerSheetId }) {
    return new Session({
      answerSheetId,
    });
  }

  constructor({ id, answerSheetId, sessionStatus, currentQuestionId, currentQuestionNumber, maybeTeamTeams = [], createdTime, updatedTime  }) {
    super();
    sessionStatus = sessionStatus || new SessionStatus;
    ///JPL: note: returns epoch ms, not a Date obect. is this correct here?
    const now = Date.now();
    return {
      ...this,
      id,
      answerSheetId,

      // Preparing
      sessionStatus: this.ensureObject(sessionStatus, SessionStatus),

      // Running, asking questions
      currentQuestionId,
      currentQuestionNumber, // 1 .. n

      // Only populated when loading list of answer sheets and sessions
      maybeTeamTeams: this.ensureArrayObjects(maybeTeamTeams, TeamTeam),

      createdTime: this.ensureDate(createdTime || now),
      updatedTime: this.ensureDate(updatedTime || now),


      get invitePath() {
        return `/team/invitation/${this.id}`;
      },
      get inviteUrl() {
        return `${process.env.VUE_APP_BASE_URL}${this.invitePath}`;
      },

      askFirstQuestion(answerSheet) {
        const firstQuestion = answerSheet.getFirstQuestion();
        this.currentQuestionNumber = 1;
        this.currentQuestionId = firstQuestion.id;
        return firstQuestion;
      },

      askNextQuestion(answerSheet) {
        const nextQuestion = answerSheet.ensureGetQuestionAfterId(
          this.currentQuestionId,
        );
        this.currentQuestionNumber++;
        this.currentQuestionId = nextQuestion.id;
        return nextQuestion;
      },

      isQuestionCurrent(question) {
        return question.id === this.currentQuestionId;
      },

      isQuestionNumberAsked(questionNumber) {
        return questionNumber <= this.currentQuestionNumber;
      },

      get askedQuestionCount() {
        return this.currentQuestionNumber;
      },


      // Status changes
      maybeStart(masterAnswerSheet) {
        if ( this.sessionStatus.maybeStart() ) {
          this.askFirstQuestion( masterAnswerSheet );
          return true;
        }
        return false;
      },

      maybeFinishQuestions() {
        return this.sessionStatus.maybeFinishQuestions();
      },

      maybeAskMoreQuestions() {
        return this.sessionStatus.maybeAskMoreQuestions();
      },

      maybeMarkAnswers() {
        return this.sessionStatus.maybeMarkAnswers();
      },

      maybeRevealScoring() {
        return this.sessionStatus.maybeRevealScoring();
      },

      maybeRevealLeaderboard() {
        return this.sessionStatus.maybeRevealLeaderboard();
      },

      maybeFinish() {
        return this.sessionStatus.maybeFinish();
      },

    };
  }
}
