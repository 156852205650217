<script>

 import MasterAnswerSheet from '@/models/master/answer-sheet.js';
 import LibraryQuestion from '@/models/library/question.js';
 import AdminLibraryQuestions from '@/models/admin/library/questions.js';

 import WithCopyPasteQuestion from '@/components/quizmaster/mixins/WithCopyPasteQuestion';

 import TopNavBar from '@/components/common/TopNavBar.vue';
 import BottomFooter from '@/components/common/BottomFooter.vue';

 import AdminLibraryQuestionItem from '@/components/admin/library/QuestionItem.vue';
 import PreviewEditModalQuestion from '@/components/quizmaster/Preview/EditModalQuestion.vue';

 export default {
   name: 'AdminLibraryQuestionsPage',
   mixins: [
     WithCopyPasteQuestion,
   ],
   components: {
     TopNavBar,
     BottomFooter,
     AdminLibraryQuestionItem,
     PreviewEditModalQuestion,
   },

   data() {
     return {
       adminLibraryQuestions: new AdminLibraryQuestions(),
       previewAnswerSheet: null,

       filterRegexString: "",
       pasteQuestionSelectedContributorId: "even-remotely",

       previewSelectedQuestion: null,
       previewSessionStatus: "revealScoring",
     };
   },

   async created() {
     const {
       libraryQuestions,
       contributors,
       libraryCategories,
       libraryDifficulties,
       libraryTags,
     } = await this.$store.dispatch('adminLibraryQuestion/fetchList');
     this.adminLibraryQuestions = new AdminLibraryQuestions({
       libraryQuestions,
       contributors,
       libraryCategories,
       libraryDifficulties,
       libraryTags,
     });
   },

   watch: {
     matchingLibraryQuestions: function() {
       // `previewAnswerSheet` needs to be data, not a computed
       // property, so that there's a data structure which is
       // reactive. That happens when it's set.
       this.previewAnswerSheet = new MasterAnswerSheet({
         defaultPoints: 1,
         questions: this.matchingMasterQuestions,
       });
     },
   },

   computed: {
     matchingLibraryQuestions() {
       if ( this.filterRegexString === "" ) return this.adminLibraryQuestions.libraryQuestions;
       return this.adminLibraryQuestions.libraryQuestions.filter(
         libraryQuestion => libraryQuestion.matchesRegex(this.filterRegexString)
       );
     },

     matchingMasterQuestions() {
       return this.matchingLibraryQuestions.map(
         libraryQuestion => libraryQuestion.asMasterQuestion,
       );
     },

     questionCount() {
       return this.matchingLibraryQuestions.length;
     },

     selectedContributorOptions() {
       return this.adminLibraryQuestions.orderedContributors;
     },

     allVueInputTags() {
       return [
         ...this.adminLibraryQuestions.libraryCategories.map(
           libraryCategory => libraryCategory.getVueTagsInputCategory(),
         ),
         ...this.adminLibraryQuestions.libraryTags.map(
           libraryTag => libraryTag.getVueTagsInputTag(),
         ),
         ...this.adminLibraryQuestions.libraryDifficulties.map(
           libraryDifficulty => libraryDifficulty.getVueTagsInputDifficulty(),
         ),
       ];
     },

   },

   methods: {

     async saveQuestion(masterQuestion) {
       const updatedLibraryQuestion = LibraryQuestion.newFromMasterQuestion(
         masterQuestion,
       );

       const savedLibraryQuestion = await this.$store.dispatch(
         'adminLibraryQuestion/saveObject',
         updatedLibraryQuestion,
       );

       const index = this.adminLibraryQuestions.getLibraryQuestionIndexById(
         masterQuestion.id,
       );
       this.adminLibraryQuestions.replaceLibraryQuestionByIndex(
         index,
         savedLibraryQuestion,
       );

       this.$bvToast.toast(
         `Question ${index + 1} was saved`,
         {
           title: "Question Saved",
           variant: "success",
         },
       );
     },

     async saveLibraryQuestion(libraryQuestion) {
       ///JPL: really need to merge this with the master question
       ///contents here...

       const savedLibraryQuestion = await this.$store.dispatch(
         'adminLibraryQuestion/saveObject',
         libraryQuestion,
       );

       const index = this.adminLibraryQuestions.getLibraryQuestionIndexById(
         libraryQuestion.id,
       );
       this.adminLibraryQuestions.replaceLibraryQuestionByIndex(
         index,
         savedLibraryQuestion,
       );

       this.$bvToast.toast(
         `Question ${index + 1} was saved`,
         {
           title: "Question Saved",
           variant: "success",
         },
       );
     },


     async pasteQuestion() {
       const masterQuestion = await this.readQuestionFromClipboard();
       if ( ! masterQuestion ) return null;

       ///JPL: keep track of original question
       const libraryQuestion = LibraryQuestion.newFromMasterQuestion(
         masterQuestion,
         {
           contributorId: this.pasteQuestionSelectedContributorId,
         },
       );

       const createdLibraryQuestion = await this.$store.dispatch(
         'adminLibraryQuestion/createObject', libraryQuestion,
       );

       this.adminLibraryQuestions.addLibraryQuestion(createdLibraryQuestion);
     },

     async deleteQuestion(libraryQuestion) {
       const index = this.adminLibraryQuestions.getLibraryQuestionIndexById(
         libraryQuestion.id,
       );

       if ( ! window.confirm(`Delete question ${index + 1} and all its related metadata?`) ) {
         return;
       }

       await this.$store.dispatch(
         'adminLibraryQuestion/deleteObject',
         libraryQuestion.id, // same as libraryQuestion
       );

       this.adminLibraryQuestions.deleteLibraryQuestionByIndex(index);

       this.$bvToast.toast(
         `Question ${index + 1} was permanently deleted along with all its related metadata`,
         {
           title: "Question Deleted",
           variant: "danger",
         },
       );

       ///JPL: this.$nextTick(() => this.focusQuestionIndex(index));
     },



     previewQuestion(masterQuestion) {
       this.previewSelectedQuestion = masterQuestion;
     },

     focusQuestionIndex(index, scrollBlock = "center") {
       if ( index === null || index === undefined ) return;

       // Can't just index into the $refs array, since the
       // animation/transition stuff jumbles the order, so instead
       // find the component for the question itself
       const masterQuestion = this.previewAnswerSheet.questions[index];
       if ( ! masterQuestion ) return;

       const editQuestionComponent = this.$refs.editQuestion.find(
         component => {
           return component.libraryQuestion.id === masterQuestion.id;
         }
       );
       if ( ! editQuestionComponent ) {
         return;
       }

       editQuestionComponent.$el.scrollIntoView({
         behaviour: 'smooth',
         block: scrollBlock,
       });

       editQuestionComponent.makeEditable();
     },

     onFocusQuestionIndex(index, scrollBlock = "center") {
       this.focusQuestionIndex(index, scrollBlock);
     },

     copyPreviewQuestion(clipboardContainerEl) {
       const question = this.previewSelectedQuestion;
       if ( ! question ) return;
       this.copyQuestion(question, clipboardContainerEl);
     },

   },

 }

</script>

<template>
  <div class="is-footer-flex-container">
    <TopNavBar />

    <b-container class="is-footer-flex-content">

      <h2>Quiz Question Library</h2>

      <b-row class="mt-4">
        <b-col cols="12" lg="7">
          <b-form-group
            label="Regex filter"
            description="Only show entries where anything matches the filter regex"
            label-cols="2"
            content-cols="10"
          >
            <b-form-input size="sm" v-model="filterRegexString" />
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="5" class="mt-1 mb-2 mt-lg-0 mb-lg-0 text-right">
          <b-button
            @click.prevent="pasteQuestion"
            size="sm"
            variant="outline-secondary"
          >
            <font-awesome-icon :icon="['fas', 'paste']" />
            Paste Question
          </b-button>

          <b-form-select
            size="sm"
            v-model="pasteQuestionSelectedContributorId"
            :options="selectedContributorOptions"
            value-field="id" text-field="moniker"
            class="w-50 ml-2"
          />

        </b-col>
      </b-row>



      <b-row class="mb-1">
        <b-col cols="10">
          <strong class="ml-3">
            {{adminLibraryQuestions.libraryQuestions.length}} Questions
            <span v-if="filterRegexString">
              ({{matchingLibraryQuestions.length}} matching)
            </span>
          </strong>
        </b-col>
        <b-col cols="2"></b-col>
      </b-row>
      <div
        v-for="(libraryQuestion, index) in matchingLibraryQuestions"
        :key="libraryQuestion.id"
      >
        <AdminLibraryQuestionItem
          :adminLibraryQuestions="adminLibraryQuestions"
          :libraryQuestion="libraryQuestion"
          :masterQuestion="previewAnswerSheet.getQuestionByIndex(index)"
          :index="index"
          :maxIndex="questionCount"
          :allVueInputTags="allVueInputTags"
          @preview-question="previewQuestion($event)"
          @copy-question="copyQuestion(libraryQuestion)"
          @save-question="saveQuestion($event)"
          @delete-question="deleteQuestion(libraryQuestion)"
          @save-library-question="saveLibraryQuestion($event)"
          ref="editQuestion"
        />
      </div>


    </b-container>


    <PreviewEditModalQuestion
      v-if="previewSelectedQuestion"
      :answerSheet="previewAnswerSheet"
      :previewSelectedQuestion.sync="previewSelectedQuestion"
      :previewSessionStatus.sync="previewSessionStatus"
      :questionNumberMax="questionCount"
      @focus-question-index="onFocusQuestionIndex($event)"
      @copy-preview-question="copyPreviewQuestion($event)"
    >
      <template #modal-title>
        Preview
        <font-awesome-icon :icon="['fas', 'film']" class="ml-1" />
        <small class="ml-3">Question as seen by the teams</small>
      </template>
    </PreviewEditModalQuestion>


    <BottomFooter />
  </div>
</template>

<style scoped>

</style>
