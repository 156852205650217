
<script>

 import QuestionsMetadataCardQuestion from '@/components/quizmaster/run/QuestionsMetadataCardQuestion.vue';

 export default {
   name: 'QuestionsMetadataCard',
   components: {
     QuestionsMetadataCardQuestion,
   },
   props: [
     'quiz',
     'masterQuestionsMetadata',
   ],

   computed: {
     popularCount() {
       return this.masterQuestionsMetadata.popularQuestionsMetadata.length;
     },
     unpopularCount() {
       return this.masterQuestionsMetadata.unpopularQuestionsMetadata.length;
     },
   }
 }
</script>

<template>
  <b-card>
    <details key="popular-questions">
      <summary>
        <strong>
          <font-awesome-icon :icon="['fas', 'heart']" />
          {{popularCount}} Most Popular Question{{maybePlural(popularCount)}}
        </strong> <small>(visible to the teams)</small>
      </summary>

      <div v-if="popularCount" class="mt-2 px-3">

        <b-alert show variant="success">
          <div>
            These would be good questions to reuse in a quiz with a different group of people
            <font-awesome-icon :icon="['far', 'grin-alt']" />
          </div>
          <div>
            For your next quiz, consider what made these questions popular!
          </div>
        </b-alert>

        <QuestionsMetadataCardQuestion
          v-for="masterQuestionMetadata in masterQuestionsMetadata.popularQuestionsMetadata"
          :key="masterQuestionMetadata.questionId"
          :quiz="quiz"
          :masterQuestionMetadata="masterQuestionMetadata"
          :masterQuestionsMetadata="masterQuestionsMetadata"
        />
      </div>
      <div v-else class="text-center mt-3 mb-3">
        No votes in yet...
      </div>
    </details>

    <details key="unpopular-questions">
      <summary>
        <strong>
          <font-awesome-icon :icon="['fas', 'poo']" />
          {{unpopularCount}} Most Downvoted Question{{maybePlural(unpopularCount)}}
        </strong> <small>(visible <strong>only to you</strong>)</small>
      </summary>

      <div v-if="unpopularCount" class="mt-2 px-3">

        <b-card-text>
          <b-alert show variant="info">
            <div>
              Is there a way this kind of question can be avoided for your next quiz? Are they too niche? Ambiguous? Controversial?
            </div>
            <div>
              Remember: being popular is only one thing that makes a good quiz question!
              <font-awesome-icon :icon="['far', 'grin-alt']" />
            </div>
          </b-alert>
        </b-card-text>

        <QuestionsMetadataCardQuestion
          v-for="masterQuestionMetadata in masterQuestionsMetadata.unpopularQuestionsMetadata"
          :key="masterQuestionMetadata.questionId"
          :quiz="quiz"
          :masterQuestionMetadata="masterQuestionMetadata"
          :masterQuestionsMetadata="masterQuestionsMetadata"
        />
      </div>
      <div v-else class="text-center mt-3 mb-3">
        No votes in yet...
      </div>
    </details>
  </b-card>
</template>

<style scoped>

</style>
