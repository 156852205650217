
<script>

 import ShareTweetButton from '@/components/share/Share/TweetButton.vue';

 export default {
   name: "StatusActionsFinished",
   components: { ShareTweetButton },

   props: [
     'quiz',
   ],

   computed: {
     winningTeamPlacement() {
       return this.quiz.masterTeamPlacements[0];
     },
     winningTeamName() {
       return this.winningTeamPlacement.teamTeam.name;
     },
     winningTeamTotalPointsAwarded() {
       return this.winningTeamPlacement.totalScore;
     },

     totalPointsPossible() {
       return this.quiz.masterAnswerSheet.totalPointsPossible;
     },

     tweetText() {
       return `I just ran the Even Remotely Pub Quiz
"${this.quiz.masterAnswerSheet.name}".

The team "${this.winningTeamName}" won with a total score of ${this.winningTeamTotalPointsAwarded} point${this.maybePlural(this.winningTeamTotalPointsAwarded)} out of ${this.totalPointsPossible}!

#quiz #pubquiz via @RemotelyPubQuiz

https://even-remotely.com/ Pub Quiz`;
     },
   },

   methods: {
     clickDone() {
       this.$emit('done');
     },
   },

 };

</script>


<template>
  <b-row>
    <b-col>
      <b-card>
        <b-row>
          <b-col cols="9">
            <div>
              <b-card-text>
                <big><font-awesome-icon :icon="['fa', 'star']" /></big>
                The quiz is finished. <strong>Thank you for playing!</strong>
              </b-card-text>
              <b-card-text>
                <big class="mr-1"><font-awesome-icon :icon="['fas', 'share-alt']" /></big>
                Please share your quiz on social media:

                <ShareTweetButton :tweetText="tweetText" eventLabel="tweetQm" class="ml-1">
                  Tweet about you running this quiz!
                </ShareTweetButton>
              </b-card-text>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="actions-buttons text-right">
              <div>
                <div>
                  <b-button @click.prevent="clickDone()" variant="primary" class="mt-1">
                    Done
                  </b-button>
                </div>
              </div>
            </div>

          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<style scoped>

</style>
