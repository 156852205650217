

export default class MasterQuizOrderedTeams {

  constructor({ masterQuiz, orderedTeamTeams, question, searchFromTeamAnswer }) {
    return {
      masterQuiz,
      orderedTeamTeams,
      question,
      searchFromTeamAnswer,


      nextUnscoredTeamAnswer() {
        let searchFromTeamAnswer = this.searchFromTeamAnswer;

        // If we're starting with a teamAnswer, don't look for it
        const removeSearchFromTeamAnswer = !! searchFromTeamAnswer;
        if ( ! searchFromTeamAnswer ) {
          // First, check if the first one in the column is unscored,
          const initialTeamTeam = this.orderedTeamTeams[0];
          const initialTeamAnswer = this.masterQuiz.teamAnswerFor(initialTeamTeam, this.question);
          if (this.isTeamAnswerUnscored(initialTeamAnswer)) return initialTeamAnswer;
          // The first in the column isn't unscored, so we can start
          // the normal search from there
          searchFromTeamAnswer = initialTeamAnswer;
        }
        const orderedTeamAnswers = this.orderedTeamAnswers;
        const allTeamAnswers = this.allWrappedTeamAnswers(
          orderedTeamAnswers,
          searchFromTeamAnswer,
          removeSearchFromTeamAnswer,
        );

        return allTeamAnswers.find(
          teamAnswer => this.isTeamAnswerUnscored(teamAnswer)
        ) || null;
      },

      previousUnscoredTeamAnswer() {
        let searchFromTeamAnswer = this.searchFromTeamAnswer;

        // If we're starting with a teamAnswer, don't look for it
        const removeSearchFromTeamAnswer = !! searchFromTeamAnswer;
        if ( ! searchFromTeamAnswer ) {
          // First, check if the last one in the column is unscored,
          const initialTeamTeam = this.orderedTeamTeams.slice(-1)[0];
          const initialTeamAnswer = this.masterQuiz.teamAnswerFor(initialTeamTeam, this.question);
          if (this.isTeamAnswerUnscored(initialTeamAnswer)) return initialTeamAnswer;
          // The first in the column isn't unscored, so we can start
          // the normal search from there
          searchFromTeamAnswer = initialTeamAnswer;
        }
        const orderedTeamAnswers = this.orderedTeamAnswers.reverse();
        const allTeamAnswers = this.allWrappedTeamAnswers(
          orderedTeamAnswers,
          searchFromTeamAnswer,
          removeSearchFromTeamAnswer,
        );

        return allTeamAnswers.find(
          teamAnswer => this.isTeamAnswerUnscored(teamAnswer)
        ) || null;
      },

      allWrappedTeamAnswers(orderedTeamAnswers, searchFromTeamAnswer, removeSearchFromTeamAnswer) {
        const searchFromIndex = orderedTeamAnswers.findIndex(
          teamAnswer => teamAnswer === searchFromTeamAnswer,
        );
        const splitIndex
              = searchFromIndex <= orderedTeamAnswers.length - 1
              ? searchFromIndex + 1
              : 0;
        const beforeteamAnswers = orderedTeamAnswers.slice(0, splitIndex);
        const afterteamAnswers = orderedTeamAnswers.slice(splitIndex);

        let allTeamAnswers = [ ...afterteamAnswers, ...beforeteamAnswers ];
        if ( removeSearchFromTeamAnswer) {
          allTeamAnswers = allTeamAnswers.filter(
            teamAnswer => teamAnswer !== searchFromTeamAnswer,
          );
        }

        return allTeamAnswers;
      },

      isTeamAnswerUnscored(teamAnswer) {
        if ( ! teamAnswer.isConfirmed ) return false;
        const masterAnswerScore = this.masterQuiz.masterAnswerScoreForTeamAnswer(teamAnswer);
        return masterAnswerScore.isUnmarked;
      },

      // team answer in order of questions, then this.orderedTeamTeams
      get orderedTeamAnswers() {
        return this.masterQuiz.masterAnswerSheet.questions.map(question => {
          return this.orderedTeamTeams.map(teamTeam => {
            const teamAnswerSheet = this.masterQuiz.ensureTeamAnswerSheetForTeam(teamTeam);
            const teamAnswer = teamAnswerSheet.masterEnsureTeamAnswer(
              this.masterQuiz.masterAnswerSheet,
              question,
            );
            return teamAnswer;
          });
        }).flat(Infinity);
      },


    };
  }
}

