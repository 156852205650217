
<script>

 import WithAnalytics from '@/components/mixins/WithAnalytics';

 export default {
   name: 'ShareTwitterButton',
   mixins: [ WithAnalytics ],
   props: {
     url: {},
     eventLabel: { default: "twitter" },
   },
   data() {
     return {
       tweetUrl: "",
     };
   },

   methods: {
     // Delay setting the <a href=""> until click-time, to avoid
     // uBlock Origin hiding it. It's not loading any scripts from
     // Twitter before clicking, so...
     fixTweetUrl() {
       this.logEvent('share', { event_label: this.eventLabel });
       this.tweetUrl = this.url;
     },
   },
 }
</script>

<template>
  <a @click="fixTweetUrl" :href="tweetUrl" target="_blank" rel="nofollow">
    <div class="zzz-birdie-button d-inline-block zzzjpl">
      <font-awesome-icon :icon="['fab', 'twitter']" />
      <span class="zzz-birdie-button-text ml-2">
        <slot></slot>
      </span>
    </div>
  </a>
</template>

<style scoped>

 .zzz-birdie-button {
   display: inline-block;
   padding-left: 0.7rem;
   padding-right: 0.7rem;

   background: #1DA1F2;
   border: none;
   border-radius: 0.4rem;
   color: white;
 }

 .zzz-birdie-button-text {
   font-size: 0.8rem;
   font-family: Arial, Helvetica, sans-serif;
   font-weight: 600;
   line-height: 1.6rem;
 }

 .zzzjpl {
   display: inline-block;
   padding-left: 0.7rem;
   padding-right: 0.7rem;

   background: #1DA1F2;
   border: none;
   border-radius: 0.4rem;
   color: white;
 }

</style>
