<script>
 export default {
   name: 'TeamAnswerCorrectnessIcon',

   props: [
     'teamAnswer',
     'masterAnswerScore',
     'isQuestionAsked',
   ],

   computed: {
     correctAnswerClass() {
       return this.masterAnswerScore.isTeamAnswerCorrect(this.teamAnswer);
     },
     incorrectAnswerClass() {
       return this.masterAnswerScore.isTeamAnswerIncorrect(this.teamAnswer);
     },
     unscoredAnswerClass() {
       return this.masterAnswerScore.isTeamAnswerUnscored(this.teamAnswer);
     },

     answerIcon() {
       if ( this.unscoredAnswerClass ) return {
         fa: [ "fas", "highlighter" ],
         title: "Use the ✓ and ✕ buttons to mark the answer!"
       };
       if ( this.incorrectAnswerClass ) return {
         fa: [ "far", "times-circle" ],
         title: "The answer is incorrect",
       };
       if ( this.correctAnswerClass ) {
         if ( this.masterAnswerScore.isAutoMarkedCorrect ) {
           return {
             fa: [ "fas", "magic" ],
             title: "The answer is auto-marked correct (you can change the marking)",
           };
         }
         else {
           return {
             fa: [ "far", "check-circle" ],
             title: "The answer is correct",
           };
         }
       }

       // Either waiting to ask the question, or for the team to confirm answer
       if ( this.isQuestionAsked ) {
         return {
           fa: [ "fas", "hourglass-half" ],
           title: "Waiting for the team to 'Submit' this answer",
         };
       }
       else {
         return {
           fa: [ "fas", "ellipsis-h" ],
           title: "Question hasn't been asked yet",
         };
       }
     },
   },
 }
</script>

<template>
  <b-input-group-append
    is-text
    :class="{
      'correct-answer': correctAnswerClass,
      'incorrect-answer': incorrectAnswerClass,
      'unscored-answer': unscoredAnswerClass,
    }"
  >
    <div class="answer-status-icon" :title="this.answerIcon.title">
      <font-awesome-icon :icon="this.answerIcon.fa" />
    </div>
  </b-input-group-append>
</template>

<style scoped>

 .answer-status-icon {
   width: 0.9em;
 }

 .correct-answer,
 .correct-answer:focus {
   background-color: var(--correct-disabled-bg);
 }
 .incorrect-answer,
 .incorrect-answer:focus {
   background-color: var(--incorrect-disabled-bg);
 }
 .unscored-answer,
 .unscored-answer:focus {
   background-color: var(--unscored-bg);
 }

 .correct-answer .input-group-text,
 .correct-answer:focus .input-group-text {
   background-color: var(--success);
   color: var(--white);
 }
 .incorrect-answer .input-group-text,
 .incorrect-answer:focus .input-group-text {
   background-color: var(--danger);
   color: var(--white);
 }
 .unscored-answer .input-group-text,
 .unscored-answer:focus .input-group-text {
   background-color: var(--unscored-bg) !important;
 }

</style>
