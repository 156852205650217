export default {
   methods: {
     // https://matteo-gabriele.gitbook.io/vue-gtag/methods/events
     // https://developers.google.com/analytics/devguides/collection/gtagjs/events
     logEvent(action, properties = {}) {
       // event_category
       // event_label
       // event_value (int)
       // console.log(`logEvent`, action, properties);
       const gtag = this.$gtag;
       if ( ! gtag ) return false;

       gtag.event(action, properties);
       return true;
     },
   },
};
