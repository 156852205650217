
///JPL: rename TeamQuiz
<script>
 export default {
   name: "TeamGame",
   props: [ 'teamSession' ],
 }
</script>

<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Place</th>
        <th>Team</th>
        <th>Points</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(teamPlacement, index) in teamSession.teamPlacements" :key="teamPlacement.teamName + index">
        <td class="text-nowrap">
          {{teamPlacement.placementOrdinal}}
          <span v-if="teamPlacement.placementOrdinal === 1" key="gold" class="ml-2">
            <div class="is-medal-circle is-gold text-center">
              <font-awesome-icon :icon="['fa', 'medal']" />
            </div>
          </span>
        </td>
        <td>{{teamPlacement.teamName}}</td>
        <td>{{teamPlacement.totalScore}}</td>
      </tr>
    </tbody>
  </table>
</template>

<style scoped>

 .is-medal-circle {
   display: inline-block;

   padding-top: 0.15rem;
   padding-left: 0.015rem;
   height: 1.7rem;
   width: 1.7rem;
   border-radius: 100%;
 }

 .is-gold {
   background: #2980b9;
   color: #f1c40f;
 }

</style>
