'use strict';

import _ from 'lodash';

import ModelBase from '../../model-base.js';
import LibraryQuestion from '../../library/question.js';
import Contributor from '../../contributor.js';
import LibraryCategory from '../../library/category.js';
import LibraryDifficulty from '../../library/difficulty.js';
import LibraryTag from '../../library/tag.js';

export default class AdminLibraryQuestions extends ModelBase {
  constructor({ libraryQuestions = [], contributors = [], libraryCategories = [], libraryDifficulties = [], libraryTags = [] } = {}) {
    super();
    return {
      ...this,

      libraryQuestions: this.ensureArrayObjects(
        libraryQuestions,
        LibraryQuestion,
      ),
      contributors: this.ensureArrayObjects(
        contributors,
        Contributor,
      ),
      libraryCategories: this.ensureArrayObjects(
        libraryCategories,
        LibraryCategory,
      ),
      libraryDifficulties: this.ensureArrayObjects(
        libraryDifficulties,
        LibraryDifficulty,
      ),
      libraryTags: this.ensureArrayObjects(
        libraryTags,
        LibraryTag,
      ),

      get orderedContributors() {
        return _.orderBy(this.contributors, ['moniker']);
      },

      addLibraryQuestion(libraryQuestion) {
        return this.libraryQuestions.splice( 0, 0, libraryQuestion );
      },
      getLibraryQuestionIndexById(libraryQuestionId) {
        return this.libraryQuestions.findIndex(
          libraryQuestion => libraryQuestion.id === libraryQuestionId
        );
      },
      replaceLibraryQuestionByIndex(index, libraryQuestion) {
        return this.libraryQuestions.splice( index, 1, libraryQuestion );
      },
      deleteLibraryQuestionByIndex(index) {
        return this.libraryQuestions.splice( index, 1 );
      },

      getContributorById(contributorId) {
        return this.contributors.find(contributor => contributor.id === contributorId);
      },

      getLibraryCategoryById(libraryCategoryId) {
        return this.libraryCategories.find(libraryCategory => libraryCategory.id === libraryCategoryId);
      },
      getLibraryDifficultyById(libraryDifficultyId) {
        return this.libraryDifficulties.find(libraryDifficulty => libraryDifficulty.id === libraryDifficultyId);
      },
    };
  }
}
