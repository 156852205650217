
<script>

 export default {
   name: "QuestionDifficulty",
   props: [
     // 1: easy: 3: hard
     'difficulty',
   ],
 }

</script>

<template>
  <span>
    <span v-if="difficulty === 1">
      <span class="is-pepper mr-1 text-nowrap">
        <font-awesome-icon :icon="['fas', 'pepper-hot']" class="is-mild-pepper" />
        <font-awesome-icon :icon="['fas', 'pepper-hot']" class="is-disabled-pepper" />
        <font-awesome-icon :icon="['fas', 'pepper-hot']" class="is-disabled-pepper" />
      </span>
    </span>
    <span v-else-if="difficulty === 3">
      <span class="is-pepper mr-1 text-nowrap">
        <font-awesome-icon :icon="['fas', 'pepper-hot']" class="is-spicy-pepper" />
        <font-awesome-icon :icon="['fas', 'pepper-hot']" class="is-spicy-pepper" />
        <font-awesome-icon :icon="['fas', 'pepper-hot']" class="is-spicy-pepper" />
      </span>
    </span>
  </span>
</template>

<style scoped>

 .is-pepper {
   border: none;
   border-radius: 1rem;
   padding: 0.3rem;
 }

 .is-spicy-pepper {
   color: #FC523C;
 }

 .is-mild-pepper {
   color: #28b463;
 }

 .is-disabled-pepper {
   color:  #aaa;
 }


</style>
