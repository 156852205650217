
import _ from 'lodash';

import Vue from 'vue';
import App from '@/App.vue';

import router from '@/router';

Vue.config.productionTip = false;

import store from '@/store';


// Bootstrap custom + core styles
import './assets/styles/custom.scss';

/// Boostrap components, not the entire 1.3Mb shebang
import {
  LayoutPlugin,
  CardPlugin,
  NavPlugin,
  NavbarPlugin,
  TabsPlugin,
  TablePlugin,
  BadgePlugin,
  ButtonPlugin,
  ButtonGroupPlugin,
  FormPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormTextareaPlugin,
  FormCheckboxPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  InputGroupPlugin,
  ToastPlugin,
  AlertPlugin,
  ModalPlugin,
  SidebarPlugin,
  PopoverPlugin,
  VBTooltipPlugin
} from 'bootstrap-vue';
Vue.use(LayoutPlugin);
Vue.use(CardPlugin);
Vue.use(NavPlugin);
Vue.use(NavbarPlugin);
Vue.use(TabsPlugin);
Vue.use(TablePlugin);
Vue.use(BadgePlugin);
Vue.use(ButtonPlugin);
Vue.use(ButtonGroupPlugin);
Vue.use(FormPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormSelectPlugin);
Vue.use(InputGroupPlugin);
Vue.use(ToastPlugin);
Vue.use(AlertPlugin);
Vue.use(ModalPlugin);
Vue.use(SidebarPlugin);
Vue.use(PopoverPlugin);
Vue.use(VBTooltipPlugin);



import { library } from '@fortawesome/fontawesome-svg-core';

///JPL: clean up import list, remove unused icons
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faHourglass } from '@fortawesome/free-solid-svg-icons/faHourglass';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons/faHourglassHalf';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faUserClock } from '@fortawesome/free-solid-svg-icons/faUserClock';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons/faStopwatch';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { faUndoAlt } from '@fortawesome/free-solid-svg-icons/faUndoAlt';
import { faCut } from '@fortawesome/free-solid-svg-icons/faCut';
import { faPaste } from '@fortawesome/free-solid-svg-icons/faPaste';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons/faBullhorn';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faHighlighter } from '@fortawesome/free-solid-svg-icons/faHighlighter';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faMedal } from '@fortawesome/free-solid-svg-icons/faMedal';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons/faShareAlt';
import { faListOl } from '@fortawesome/free-solid-svg-icons/faListOl';
import { faPepperHot } from '@fortawesome/free-solid-svg-icons/faPepperHot';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import { faPoo } from '@fortawesome/free-solid-svg-icons/faPoo';
import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons/faAngleDoubleUp';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons/faAngleDoubleDown';
import { faMagic } from '@fortawesome/free-solid-svg-icons/faMagic';
import { faFilm } from '@fortawesome/free-solid-svg-icons/faFilm';
library.add(
  faCheckCircle,
  faCheck,
  faTimes,
  faTimesCircle,
  faQuestionCircle,
  faHourglass,
  faHourglassHalf,
  faClock,
  faUserClock,
  faStopwatch,
  faEllipsisH,
  faSpinner,
  faUserCircle,
  faUndoAlt,
  faCut,
  faPaste,
  faCog,
  faExternalLinkAlt,
  faBullhorn,
  faEdit,
  faHighlighter,
  faStar,
  faMedal,
  faUserFriends,
  faShareAlt,
  faListOl,
  faPepperHot,
  faHeart,
  faPoo,
  faAngleDoubleUp,
  faAngleDoubleDown,
  faMagic,
  faFilm,
);

import { faCheckCircle as farCheckCircle } from '@fortawesome/free-regular-svg-icons/faCheckCircle';
import { faQuestionCircle as farQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';
import { faTimesCircle as farTimesCircle } from '@fortawesome/free-regular-svg-icons/faTimesCircle';
import { faLightbulb as farLightbulb } from '@fortawesome/free-regular-svg-icons/faLightbulb';
import { faCopy as farCopy } from '@fortawesome/free-regular-svg-icons/faCopy';
import { faClipboard as farClipboard } from '@fortawesome/free-regular-svg-icons/faClipboard';
import { faTrashAlt as farTrashAlt } from '@fortawesome/free-regular-svg-icons/faTrashAlt';
import { faClock as farClock } from '@fortawesome/free-regular-svg-icons/faClock';
import { faEye as farEye } from '@fortawesome/free-regular-svg-icons/faEye';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons/faStar';
import { faPlayCircle as farPlayCircle } from '@fortawesome/free-regular-svg-icons/faPlayCircle';
import { faThumbsUp as farThumbsUp } from '@fortawesome/free-regular-svg-icons/faThumbsUp';
import { faThumbsDown as farThumbsDown } from '@fortawesome/free-regular-svg-icons/faThumbsDown';
import { faMeh as farMeh } from '@fortawesome/free-regular-svg-icons/faMeh';
import { faGrinAlt as farGrinAlt } from '@fortawesome/free-regular-svg-icons/faGrinAlt';
import { faImage as farImage } from '@fortawesome/free-regular-svg-icons/faImage';
import { faClone as farClone } from '@fortawesome/free-regular-svg-icons/faClone';
library.add(
  farQuestionCircle,
  farTimesCircle,
  farCheckCircle,
  farLightbulb,
  farCopy,
  farClipboard,
  farTrashAlt,
  farClock,
  farEye,
  farStar,
  farThumbsUp,
  farThumbsDown,
  farPlayCircle,
  farMeh,
  farGrinAlt,
  farImage,
  farClone,
);

import { faTwitter as fabTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
library.add(
  fabTwitter,
);



import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
Vue.component('font-awesome-icon', FontAwesomeIcon);

import VueTagsInput from '@johmun/vue-tags-input';
Vue.component('vue-tags-input', VueTagsInput);

import VueWindowSize from 'vue-window-size';
Vue.use(VueWindowSize);

import VueDetails from 'vue-details';
Vue.component('v-details', VueDetails);

//JPL: used?
import VuePlainFoldable from '@/components/vue-plain-foldable.vue';
import 'vue-foldable/dist/vue-foldable.css';
Vue.component('plain-foldable', VuePlainFoldable);

import KeyPress from 'vue-keypress';
Vue.component('KeyPress', KeyPress);

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);


// Application level shared components
import NoteBadge from '@/components/share/NoteBadge.vue';
Vue.component('NoteBadge', NoteBadge);



import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
extend(
  'required',
  {
    ...required,
    message: '{_field_} is required'
  },
);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);


import scrollIntoView from 'scroll-into-view-if-needed';


import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);



Vue.mixin({
  methods: {
     maybePlural(value) {
       return value == 1 ? "" : "s";
     },
    ordinalWithSuffix(value) {
      if ( isNaN(value) ) return "no";
      let ten = value % 10;
      let hundred = value % 100;
      if (ten == 1 && hundred != 11) {
        return value + "st";
      }
      if (ten == 2 && hundred != 12) {
        return value + "nd";
      }
      if (ten == 3 && hundred != 13) {
        return value + "rd";
      }
      return value + "th";
    },
    scrollEntirelyIntoView(element, args = {}) {
      scrollIntoView(element, {  scrollMode: 'if-needed', ...args });
    },

    reportError(message) {
      this.$bvToast.toast(
        message, {
          variant: 'danger',
          title: 'Error',
          solid: true,
        },
      );
      return false;
    },

    hasClipboardSupport() {
      if ( ! navigator ) return false;
      if ( ! navigator.clipboard ) return false;
      return !! navigator.clipboard.readText;
    },
    isClipboardDisabled() {
      return ! this.hasClipboardSupport();
    },

    htmlBr(text) {
      return text.split(/\n/)
        .map(l => _.escape(l))
        .join('<br>');
    },
    htmlUl(text) {
      return "<ul>" +
        text.split(/\n/).map(l => `<li>${_.escape(l)}</li>`).join("\n") +
        "</ul>";
    },
    htmlOl(text) {
      return "<ol>" +
        text.split(/\n/).map(l => `<li>${_.escape(l)}</li>`).join("\n") +
        "</ol>";
    },
  },
});

import WithRouter from '@/components/mixins/WithRouter';
Vue.mixin(WithRouter);


router.$store = store;



import VueGtag from "vue-gtag";
const gtagId = process.env.VUE_APP_GTAG_ID;
if ( gtagId ) {
  Vue.use(VueGtag, { config: { id: gtagId } }, router);
}


import { primaryInput } from 'detect-it';



const app = new Vue({
  render: h => h(App),
  router,
  store,
  computed: {
    isMobile() {
      return this.windowWidth <= 768;
    },
    isTouchDevice() {
      return primaryInput === 'touch';
    },
    callApi() {
      return process.env.VUE_APP_CALL_API === "true";
    },
  },
});
store.$app = app;

store.dispatch('auth/maybeLoadSession');

app.$mount('#app');

