
import Cookie from 'js-cookie';

export default class WebCookie {
  constructor({ name, options = {}}) {
    return {
      name,
      options,


      set(value, options = {}) {
        Cookie.set( this.name, value, this.getCookieOptions(options) );
        return value;
      },

      get() {
        return Cookie.get(this.name); // or undefined
      },

      remove() {
        Cookie.remove(this.name, this.options);
      },

      get shouldSetSecureCookie() {
        return process.env.VUE_APP_SET_SECURE_COOKIE === "true";
      },

      getCookieOptions(options = {}) {
        const secureOption = this.shouldSetSecureCookie ? { Secure: true } : {};
        const effectiveOptions = {
          ...this.options,
          ...options,
          ...secureOption,
          SameSite:"Strict",
        };
        return effectiveOptions;
      },

    };
  }

}
