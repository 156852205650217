
<script>

 export default {
   name: 'App',
 }
</script>

<template>
  <div id="app" style="height: 100%">
    <router-view />
  </div>
</template>

<style>

 /* Remove chrome outline of the clickable summary element */
 details summary:focus {
   outline: 1px solid #eee;
 }

 .text-larger {
   font-size: 120%;
 }

 .text-much-larger {
   font-size: 140%;
 }

 .text-smaller {
   font-size: 80%;
 }

 .text-much-smaller {
   font-size: 60%;
 }

 .validation-error {
   margin-top: 0.2em;
   text-align: right;
   font-size: 0.8em;
   color: var(--danger);
 }

 :root {
   --correct-disabled-bg: #9ed3aa;
   --incorrect-disabled-bg: #e87c86;

   --correct-bg: #3cb521;

   --unscored-bg: #dce589;
   --unconfirmed-bg: #e9ecef;

   --highlight-bg: palegoldenrod;

   /* JPL: move to grid css */
   --selected-question-bg: #eeeeee;

   --dark-bg: #55423D;
   --light-bg: #FFF3EC;
 }


 /* The height: 100% for all containing elements and these will push */
 /* the footer to the bottom of a non-filled page */
 .is-footer-flex-container {
   display: flex;
   flex-direction: column;
   height: 100%;
 }
 .is-footer-flex-content {
   flex: 1 0 auto;
 }


 .b-popover div.popover-body xsmall ul {
   padding-left: 1.5rem;
 }

</style>
