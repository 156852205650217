
<script>

 import TransitionFade from '@/components/transition/TransitionFade';

 export default {
   name: "StatusActionsAskQuestions",
   components: { TransitionFade },

   props: [
     'quiz',
     'isCurrentQuestionTheLastOne',
   ],

   computed: {
     isFirstQuestion() {
       return this.quiz.currentQuestionNumber == 1;
     },

     askFinishQuestionsButtonVariant() {
       return this.isCurrentQuestionTheLastOne ? "primary" : "secondary";
     },

     currentQuestion() {
       return this.quiz.currentQuestion();
     },
   },

   methods: {
     clickFinishQuestions() {
       this.$emit('finish-questions');
     },
   },

 };

</script>


<template>
  <b-row v-if="currentQuestion">
    <b-col>
      <b-card>
        <b-row>
          <b-col>
            <div class="float-right text-right">
              <div>
                <b-button
                  @click.prevent="clickFinishQuestions()"
                  :variant="askFinishQuestionsButtonVariant"
                >➞ Submit all Answers</b-button>
              </div>
            </div>
            <TransitionFade>
              <div v-if="! isCurrentQuestionTheLastOne" key="ask-questions">
                <TransitionFade>
                  <div v-if="isFirstQuestion" key="ask-first-question">
                    <b-card-text>
                      <big class="mr-2"><font-awesome-icon :icon="['fas', 'bullhorn']" /></big>
                      <strong>Ask the teams the first question</strong> below.
                      Read out the question, wait a bit, read it again.
                    </b-card-text>
                    <b-card-text>
                      <big class="mr-2"><font-awesome-icon :icon="['far', 'clock']" /></big>
                      After giving the the teams some time to think, click
                      <b-badge size="sm" variant="primary">Ask Next Question</b-badge>
                      to advance to question 2.
                    </b-card-text>
                  </div>
                  <div v-else key="ask-remaining-questions">
                    <b-card-text>
                      <big class="mr-2"><font-awesome-icon :icon="['fas', 'bullhorn']" /></big>
                      <strong>Ask the teams each question</strong>, one at a time. Press
                      <b-badge size="sm" variant="primary">Ask Next Question</b-badge>
                      to advance.
                    </b-card-text>
                    <b-card-text>
                      <big class="mr-2"><font-awesome-icon :icon="['fas', 'highlighter']" /></big>
                      <strong><i>Good idea:</i> mark the answers</strong> correct/incorrect as
                      they are submitted.
                    </b-card-text>
                  </div>
                </TransitionFade>
              </div>
              <div v-else key="continue-to-submit-answers">
                <b-card-text>
                  <strong>This is the last question.</strong>
                </b-card-text>
                <b-card-text>
                  <big class="mr-2"><font-awesome-icon :icon="['fas', 'bullhorn']" /></big>
                  <strong>Ask the last question</strong>, then proceed to
                  <b-badge size="sm" variant="primary">Submit All Answers</b-badge>
                  where the teams are prompted to wrap up their
                  un-answered questions.
                </b-card-text>
              </div>
            </TransitionFade>
          </b-col>
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>

<style scoped>

</style>
