<script>

 export default {
   name: 'LibraryQuestionCard',
   props: [
     'selectableLibraryQuestion',
     'isBorrowSelectAllowanceReached',
     'libraryClassification',
   ],

   computed: {
     libraryQuestion() {
       return this.selectableLibraryQuestion.item;
     },

     libraryCategory() {
       return this.libraryClassification.getLibraryCategoryById(
         this.libraryQuestion.libraryCategoryId,
       );
     },

     isSelected: {
       get: function() {
         return this.selectableLibraryQuestion.isSelected;
       },
       set: function(value) {
         return this.selectableLibraryQuestion.isSelected = value;
       },
     },

     isBorrowed() {
       return this.selectableLibraryQuestion.isConfirmed;
     },
     isSelectable() {
       if ( this.isBorrowSelectAllowanceReached ) return false;
       return ! this.isBorrowed;
     },
     isDeselectable() {
       if ( this.isBorrowSelectAllowanceReached ) {
         if ( ! this.isSelected ) return false;
       }
       return ! this.isBorrowed;
     },
     showCheckbox() {
       return this.isSelectable || this.isDeselectable;
     },
   },

   methods: {

     toggleSelected() {
       if ( this.isBorrowed ) return;

       // If we've selected to the borrow limit, only enable de-selects
       if ( this.isBorrowSelectAllowanceReached ) {
         if ( ! this.isSelected ) return;
       }

       this.selectableLibraryQuestion.toggleSelected();
     },
   },
 }

</script>

<template>
  <b-card
    @click.prevent="toggleSelected"
    bg-variant="default"
    class="is-library-question-card mt-2 shadow"
    :class="{ 'is-selectable': isSelectable, 'is-deselectable': isDeselectable, 'is-selected': isSelected, 'is-borrowed': isBorrowed }"
  >
    <template #header>
      <div class="d-flex w-100">
        <div class="flex-grow-1">
          <small>
            <strong v-if="libraryCategory">
              {{libraryCategory.name}}
            </strong>
            <span v-else>
              <strong
                v-if="libraryQuestion.isAnswerEntryTypeFreeText"
              >Free-text</strong>
              <strong
                v-if="libraryQuestion.isAnswerEntryTypeMultiChoice"
              >Multiple-choice</strong>
            </span>

            <small v-if="libraryQuestion.libraryTags.length" class="ml-1">
              <span
                 v-for="(libraryTag, index) in libraryQuestion.libraryTags"
                 :key="libraryTag.id"
               ><span v-if="index != 0">, </span>
                {{libraryTag.name}}</span>
            </small>
          </small>
        </div>

        <span
          v-if="! isBorrowed"
        >
          <b-checkbox
            v-model="isSelected"
            :disabled="! showCheckbox"
          />
        </span>
        <span
          v-else>
          <small><strong>Borrowed!</strong></small>
        </span>
      </div>
    </template>
    <div
      v-if="libraryQuestion.imageUrl"
      class="text-center mb-1"
    >
      <img :src="libraryQuestion.imageUrl" class="is-card-library-image" />
    </div>
    <small>
      <b-card-text>
        {{libraryQuestion.question}}
      </b-card-text>
      <b-card-text
        v-if="libraryQuestion.isAnswerEntryTypeMultiChoice"
        class="text-center"
      >
        <b-badge
          v-for="answerOption in libraryQuestion.answerOptionStrings"
          :key="answerOption"
          variant="light"
          class="ml-1"
        ><span class="px-1">{{answerOption}}</span></b-badge>
      </b-card-text>
    </small>
  </b-card>
</template>

<style scoped>

 .is-card-library-image {
   max-width: 100%;
   max-height: 25vh;
 }

 .is-select-checkbox {
   margin-right: -0.7rem;
 }

</style>

<style>

 div.is-library-question-card.card.is-selectable:hover,
 div.is-library-question-card.card.is-deselectable:hover
 {
   opacity: 0.8;
   border: solid 1px #AAA;
   cursor: pointer;
 }

 div.is-library-question-card.card.is-selectable.is-selected,
 div.is-library-question-card.card.is-deselectable.is-selected
 {
   border: solid 1px #000;
 }

 div.is-library-question-card.card.is-borrowed {
   border: solid 1px #000;
   border-radius: 8px;
 }

</style>
