<script>

 import _ from 'lodash';

 import LibraryAllowances from '@/models/library/allowances.js';
 import LibraryClassification from '@/models/library/classification.js';

 import UiSelectable from '@/ui/selectable.js';
 import LibraryQuestionCard from '@/components/library/QuestionCard.vue';
 import BottomButtonBar from '@/components/elements/BottomButtonBar.vue';

 export default {
   name: 'LibraryQuestionList',
   components: {
     BottomButtonBar,
     LibraryQuestionCard,
   },

   data() {
     return {
       selectableLibraryQuestions: [],
       libraryAllowances: new LibraryAllowances(),
       libraryClassification: new LibraryClassification(),
     };
   },

   created() {
     this.loadNewRandomQuestions();
   },

   computed: {
     viewAllowanceRemainingCount() {
       return this.libraryAllowances.view.remainingCount;
     },
     borrowAllowanceRemainingCount() {
       return this.libraryAllowances.borrow.remainingCount;
     },

     borrowAllowanceTotalCount() {
       return this.libraryAllowances.borrow.totalCount;
     },

     isViewAllowanceReached() {
       return this.viewAllowanceRemainingCount <= 0;
     },

     isBorrowSelectAllowanceReached() {
       const remainingCount = this.borrowAllowanceRemainingCount - this.selectedLibraryQuestionCount;
       return remainingCount <= 0;
     },
     selectedLibraryQuestions() {
       return this.selectableLibraryQuestions.filter(question => question.isSelected);
     },
     selectedLibraryQuestionCount() {
       return this.selectedLibraryQuestions.length;
     },
   },

   methods: {

     async loadNewRandomQuestions() {
       return this
         .$store.dispatch('libraryQuestion/fetchRandomList')
         .then(({
           libraryAllowances,
           libraryQuestions,
           libraryCategories,
           libraryTags,
         }) => {
           this.addLibraryQuestions(libraryQuestions);
           this.libraryAllowances = libraryAllowances;

           this.libraryClassification = new LibraryClassification({
             libraryCategories,
             libraryTags,
           });
         });
     },

     async addRandomQuestions() {
       await this.loadNewRandomQuestions();
     },

     addLibraryQuestions(libraryQuestions) {
       this.selectableLibraryQuestions = _.uniq(
         [
           ...this.selectableLibraryQuestions,
           ...libraryQuestions.map(
             libraryQuestion => new UiSelectable({ item: libraryQuestion })
           ),
         ],
         selectableLibraryQuestion => selectableLibraryQuestion.item.id);
     },

     async borrowSelectedQuestions() {
       const selectableLibraryQuestions = this
         .selectableLibraryQuestions
         .filter(selectableQuestion => selectableQuestion.isSelected);
       selectableLibraryQuestions.forEach(selectableLibraryQuestion => {
         selectableLibraryQuestion.isSelected = false;
         selectableLibraryQuestion.isConfirmed = true;
       });

       const libraryQuestionIds = selectableLibraryQuestions.map(
         selectableQuestion => selectableQuestion.item.id,
       );

       // Fetch questions from server
       const { libraryQuestions, libraryAllowances } = await this.$store.dispatch(
         "libraryQuestion/borrowQuestionIds", { libraryQuestionIds },
       );

       this.libraryAllowances = libraryAllowances;

       this.$emit('borrow-library-questions', libraryQuestions);
     },
   }

 }

</script>

<template>
  <div>

    <b-row>
      <b-col
        v-for="selectableLibraryQuestion in selectableLibraryQuestions"
        :key="selectableLibraryQuestion.id"
        cols="12"
        md="6"
        lg="4"
      >
        <LibraryQuestionCard
          ref="libraryQuestionCard"
          :selectableLibraryQuestion="selectableLibraryQuestion"
          :isBorrowSelectAllowanceReached="isBorrowSelectAllowanceReached"
          :libraryClassification="libraryClassification"
        />
      </b-col>
    </b-row>

    <b-row v-if="isViewAllowanceReached">
      <b-col class="text-center mt-5 mb-3">
        <strong>There are no more questions left to view today</strong>
      </b-col>
    </b-row>

    <div class="text-right mt-4">
      <b-button
        @click.prevent="addRandomQuestions()"
        :disabled="isViewAllowanceReached"
        variant="primary"
      >More random questions</b-button>
    </div>

    <div class="text-right mt-2">
      <p>
        <small>You can view {{viewAllowanceRemainingCount}} more questions today</small>
      </p>
    </div>

    <BottomButtonBar>
      <b-col cols="4">
        <span v-if="isBorrowSelectAllowanceReached">
          <span v-if="selectedLibraryQuestionCount == 0">
            <strong>
              You have borrowed all of today's {{borrowAllowanceTotalCount}} questions
            </strong>
          </span>
          <span v-else>
            <strong>
              {{selectedLibraryQuestionCount}}
              out of today's {{borrowAllowanceRemainingCount}}
              question{{maybePlural(selectedLibraryQuestionCount)}} selected
            </strong>
          </span>
        </span>
        <span v-else>
          {{selectedLibraryQuestionCount}} selected
          question{{maybePlural(selectedLibraryQuestionCount)}}
          (max {{borrowAllowanceRemainingCount}} today)
        </span>
      </b-col>
      <b-col cols="8" class="text-right mr-0">
        <b-button
          @click.prevent="borrowSelectedQuestions"
          :disabled="! selectedLibraryQuestionCount"
          variant="primary"
          title=""
        >Borrow
          <span v-if="selectedLibraryQuestionCount">{{selectedLibraryQuestionCount}}</span>
          question{{maybePlural(selectedLibraryQuestionCount)}}</b-button>
        <b-button
          @click.prevent="$emit('activate-tab-my-questions')"
          variant="light"
          title=""
          class="ml-3"
        >Back to My Questions</b-button>
      </b-col>
    </BottomButtonBar>

  </div>
</template>

<style scoped>

</style>
