<script>

 import _ from 'lodash';
 import WithUserSession from '@/components/mixins/WithUserSession';
 import WithAnalytics from '@/components/mixins/WithAnalytics';
 import SignupCard from '@/components/common/SignupCard.vue';
 import TopNavBar from '@/components/common/TopNavBar.vue';
 import BottomFooter from '@/components/common/BottomFooter.vue';
 import AnswerSheetListItem from '@/components/quizmaster/AnswerSheetListItem';

 export default {
   name: 'AnswerSheetList',
   mixins: [
     WithUserSession,
     WithAnalytics,
   ],
   components: {
     TopNavBar,
     BottomFooter,
     SignupCard,
     AnswerSheetListItem,
   },

   created() {
     // The parent component HomePage has already called
     // masterAnswerSheet/maybeFetchList, which has loaded them into
     // the store, so the getter below will have data
   },

   computed: {
     answerSheets() {
       return _.sortBy(
         this.$store.getters['masterAnswerSheet/getList'],
         answerSheet => answerSheet.updatedTime
       ).reverse();
     },

     answerSheetCount() {
       return this.answerSheets.length;
     },

     sessions() {
       return this.answerSheets.map(answerSheet => answerSheet.sessions).flat();
     },

     activeSessions() {
       return this.sessions.filter(session => ! session.sessionStatus.hasFinished);
     },

     hasActiveQuizSessions() {
       return !! this.activeSessions.length;
     },

     hasUserAccount() {
       return this.userSession.hasUserAccount;
     },
   },

   methods: {
     logCreateQuiz() {
       this.logEvent('create_quiz', { event_category: 'setup', event_label: 'answer_sheet_list' });
     },
   }
 }
</script>

<template>
  <div class="is-footer-flex-container">
    <TopNavBar />

    <b-container class="is-footer-flex-content">

      <b-row>
        <b-col cols="12" :lg="hasUserAccount ? 12 : 8">

          <div class="d-flex justify-content-between">
            <div><h1>Your Quizzes</h1></div>

            <div v-if="answerSheetCount >= 3">
              <router-link to="/quizmaster/answer-sheet/new">
                <b-button
                  @click="logCreateQuiz"
                  :variant="hasActiveQuizSessions ? 'outline-secondary' : 'primary'"
                  class="">Set up another Quiz</b-button>
              </router-link>
            </div>
          </div>

          <div v-if="answerSheets.length === 0" class="no-answer-sheets text-center pt-5">
            <router-link to="/quizmaster/answer-sheet/new">
              <b-button variant="primary">Set up new Quiz</b-button>
            </router-link>
          </div>

          <div v-else>
            <ul class="mt-3">
              <li class="li-answer-sheet" v-for="(answerSheet, index) in answerSheets" :key="index">
                <AnswerSheetListItem
                  :answerSheet="answerSheet"
                />
              </li>
            </ul>

            <router-link to="/quizmaster/answer-sheet/new">
              <b-button
                @click="logCreateQuiz"
                :variant="hasActiveQuizSessions ? 'outline-secondary' : 'primary'"
                class="mt-5 mb-4">Set up another Quiz</b-button>
            </router-link>
          </div><!-- answerSheets.length !== 0 -->

        </b-col>


        <b-col
          v-if="! hasUserAccount"
          cols="12" lg="4"
          class="mt-3 mt-lg-0"
        >
          <SignupCard>

            <template v-slot:header>
              <b-card-text>
                <strong>Create an account to avoid losing your quiz questions.</strong>
              </b-card-text>
            </template>

            <template v-slot:footer>
              <div class="small mt-3">
                (we'll add this quiz to your account)
              </div>
            </template>

          </SignupCard>
        </b-col>
      </b-row>


    </b-container>

    <BottomFooter />
  </div>
</template>

<style scoped>

 li.li-answer-sheet:not(:first-child)  {
   border-top: solid 1px var(--secondary);
   padding-top: 1em;
   margin-top: 1.3em;
 }

</style>
