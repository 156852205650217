
<script>

 import QuestionDifficulty from '@/components/elements/QuestionDifficulty.vue';
 import IconHeart from '@/components/quizmaster/run/Icon/Heart.vue';

 export default {
   name: 'QuestionsMetadataCardQuestion',
   components: {
     QuestionDifficulty,
     IconHeart,
   },
   props: [
     'quiz',
     'masterQuestionMetadata',
     'masterQuestionsMetadata',
   ],

   computed: {
     masterQuestion() {
       return this.quiz.masterAnswerSheet.getQuestionById(
         this.masterQuestionMetadata.questionId,
       );
     },
     isMasterQuestionHardest() {
       return this.masterQuestionsMetadata.isQuestionHardest(this.masterQuestion);
     },
     isMasterQuestionEasiest() {
       return this.masterQuestionsMetadata.isQuestionEasiest(this.masterQuestion);
     },
     questionNumber() {
       return this.quiz.questionNumber(this.masterQuestion);
     },


     clipboardIconTitle() {
       return this.hasClipboardSupport()
            ? "Copy question to clipboard (so you can paste it into another quiz)"
            : "This browser doesn't support copy+paste of entire Questions :(  Try e.g. Chrome!";
     },

   },

   methods: {
     copyQuestion() {
       this.setClipboard( this.masterQuestion.asClipboardMasterQuestion );
     },
     setClipboard(masterClipboardQuestion) {
       const clipboardQuestion = JSON.stringify(masterClipboardQuestion);
       return this
         .$copyText(clipboardQuestion)
         .then(
           () => {
             this.$bvToast.toast(
               `You can now paste the question into your own quiz.`, {
                 title: 'Question copied',
                 variant: 'info',
               },
             );
           },
           () => {
             this.reportError(`Question could not be copied`);
           },
         );
     },
   },
 }
</script>

<template>
  <div class="d-flex mb-4">

    <div class="flex-grow-1">

      <div class="pr-2">
        <strong>
          Q{{questionNumber}}:
        </strong>

        <span :title="masterQuestion.correctAnswer">
          {{masterQuestion.question}}
        </span>

        <span>
          <span
            v-if="isMasterQuestionHardest"
          >
            <QuestionDifficulty :difficulty="3" title="This was a hard one!" />
          </span>
          <span v-else-if="isMasterQuestionEasiest">
            <QuestionDifficulty :difficulty="1" title="Easy question" />
          </span>
        </span>
      </div>

      <div class="d-flex">
        <div class="text-nowrap flex-grow-1">
          <span class="pr-2">
            <small class="mr-1">{{masterQuestionMetadata.upvoteCount}} x</small>
            <font-awesome-icon :icon="['fas', 'heart']" />
          </span>
          <span class="pr-2">
            <small class="mr-1">{{masterQuestionMetadata.downvoteCount}} x</small>
            <font-awesome-icon :icon="['fas', 'poo']" />
          </span>
          =
          <span class="pr-2">
            <small class="mr-1">{{masterQuestionMetadata.voteCount}} x</small>
            <IconHeart class="mr-1" />
          </span>
        </div>
        <div class="mr-3">
          <b-button
            :disabled="isClipboardDisabled()"
            @click.prevent="copyQuestion"
            :title="clipboardIconTitle"
            variant="outline-secondary"
            size="sm"
          ><span class="nowrap">
            <font-awesome-icon :icon="['far', 'copy']" /> Copy
          </span></b-button>
        </div>
      </div>
    </div>

    <div v-if="masterQuestion.imageUrl">
      <div class="is-image-cell text-center">
        <img :src="masterQuestion.imageUrl" class="is-image ml-2" />
      </div>
    </div>

  </div>
</template>

<style scoped>

 .is-image-cell {
   width: 5rem;
 }

 .is-image {
   max-width: 5rem;
   max-height: 4rem;
 }

</style>
