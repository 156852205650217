<script>

 import TopNavBar from '@/components/common/TopNavBar.vue';
 import BottomFooter from '@/components/common/BottomFooter.vue';
 import LoadingSpinner from '@/components/share/LoadingSpinner';

 export default {
   name: 'PrivacyPolicyPage',
   components: {
     TopNavBar,
     BottomFooter,
     LoadingSpinner,
   },
   mounted() {
     this.$store.dispatch('content/fetchPrivacyPolicyHtml');
   },

   computed: {
     privacyPolicyHtml() {
       return this.$store.state.content.privacyPolicyHtml;
     },
   },
 }

</script>

<template>
  <div class="is-footer-flex-container">
    <TopNavBar />

    <b-container class="is-footer-flex-content">

      <div id="page-content" class="page-content">
        <h2>Privacy Policy</h2>

        <div v-if="privacyPolicyHtml" v-html=privacyPolicyHtml></div>
        <LoadingSpinner v-else /><!-- if session // Loading screen -->
      </div>

    </b-container>

    <BottomFooter />
  </div>
</template>

<style scoped>

</style>
