
<script>
 import PreviewTeamGameAnswer from '@/components/quizmaster/Preview/TeamGameAnswer.vue';
 import QuestionRevealButton from '@/components/quizmaster/run/QuestionRevealButton.vue';

 export default {
   name: 'PreviewModalQuestion',
   components: {
     PreviewTeamGameAnswer,
     QuestionRevealButton,
   },
   props: [
     'answerSheet',
     'maybeQuiz',
     'previewSelectedQuestion',
     'questionNumberMax',
     'previewShowTeamAnswerScore',
   ],

   mounted() {
     this.$bvModal.show("preview-question-modal");
   },

   computed: {

     previewSelectedQuestionIndex() {
       const masterQuestion = this.previewSelectedQuestion;
       if ( ! masterQuestion ) return 0;
       return this.answerSheet.getQuestionIndex(masterQuestion);
     },
     isPreviewFirstQuestionDisabled() {
       return this.isPreviewPreviousQuestionDisabled;
     },
     isPreviewPreviousQuestionDisabled() {
       const masterQuestion = this.previewSelectedQuestion;
       if ( ! masterQuestion ) return true;
       return this.answerSheet.getQuestionIndex(masterQuestion) === 0;
     },
     isPreviewNextQuestionDisabled() {
       const masterQuestion = this.previewSelectedQuestion;
       if ( ! masterQuestion ) return true;
       return this.answerSheet.getQuestionIndex(masterQuestion) === this.questionNumberMax - 1;
     },
   },

   methods: {

     previewQuestion(question) {
       this.$emit('update:previewSelectedQuestion', question)
       this.$bvModal.show("preview-question-modal");
     },
     previewQuestionEnd() {
       this.$bvModal.hide('preview-question-modal');

     },
     previewQuestionEndHidden() {
       setTimeout(() => {
         const masterQuestion = this.previewSelectedQuestion;
         const index = this.answerSheet.getQuestionIndex(masterQuestion);
         this.$emit('focus-question-index', index);
         this.$emit('update:previewSelectedQuestion', null)
       }, 100);
     },
     previewFirstQuestion() {
       if ( this.isPreviewFirstQuestionDisabled ) return;
       const newIndex = 0;
       const newQuestion = this.answerSheet.getQuestionByIndex(newIndex);
       this.$emit('update:previewSelectedQuestion', newQuestion)
     },
     previewPreviousQuestion() {
       if ( this.isPreviewPreviousQuestionDisabled ) return;
       const masterQuestion = this.previewSelectedQuestion;
       const newIndex = this.answerSheet.getQuestionIndex(masterQuestion) - 1;
       const newQuestion = this.answerSheet.getQuestionByIndex(newIndex);
       this.$emit('update:previewSelectedQuestion', newQuestion)
     },
     previewNextQuestion() {
       if ( this.isPreviewNextQuestionDisabled ) return;
       const masterQuestion = this.previewSelectedQuestion;
       const newIndex = this.answerSheet.getQuestionIndex(masterQuestion) + 1;
       const newQuestion = this.answerSheet.getQuestionByIndex(newIndex);
       this.$emit('update:previewSelectedQuestion', newQuestion)
     },

     async copyPreviewQuestion() {
       const clipboardContainerEl = this.$refs.clipboardContainer;
       this.$emit('copy-preview-question', clipboardContainerEl);
       this.$bvToast.toast(
         'Question copied to clipboard. You can paste it into the answer sheet of this or another Quiz.',
         {
           title: "Copied",
           variant: "success",
           toaster: "b-toaster-top-center",
         },
       );
     },

     onQuestionStatusSheetChanged() {
       this.$emit('question-status-sheet-changed');
     }
   },
 }
</script>

<template>
  <div>
    <b-modal
      id="preview-question-modal"
      size="xl" centered
      scrollable
      @hidden="previewQuestionEndHidden"
    >
      <div v-if="previewSelectedQuestion" class="px-0 px-lg-5">
        <PreviewTeamGameAnswer
          :masterQuestion="previewSelectedQuestion"
          :masterAnswerSheet="answerSheet"
          :questionIndex="previewSelectedQuestionIndex"
          :showTeamAnswerScore="previewShowTeamAnswerScore"
        />
      </div>
      <template #modal-header>
        <h5 class="d-flex flex-fill w-100 mb-0">
          <div><slot name="modal-title"></slot></div>
          <div class="flex-fill flex-grow-1 text-right">
            <!-- Since this is in a Bootstrap Modal, the container el of the
                 click event needs to be provided to the copyText call -->
            <span ref="clipboardContainer">
              <b-button
                @click.prevent="copyPreviewQuestion"
                title="Copy Question to clipboard"
                variant="outline-secondary"
                size="sm"
                class="is-preview-question-modal-copy-button mr-1"
              >
                <font-awesome-icon :icon="['far', 'copy']" />
              </b-button>
            </span>
            <button
              @click.prevent="previewQuestionEnd()"
              type="button"
              class="close"
              aria-label="Close"
            >
              <strong>×</strong>
            </button>
          </div>
        </h5>
      </template>
      <template #modal-footer>
        <div class="d-flex w-100">

          <slot name="left-footer"></slot>

          <div>
            <QuestionRevealButton
              v-if="maybeQuiz"
              :quiz="maybeQuiz"
              :selectedQuestion="previewSelectedQuestion"
              :buttonSize="'md'"
              @question-status-sheet-changed="onQuestionStatusSheetChanged"
              class="mr-3"
            />

            <b-button-group class="ml-3 mt-2 mt-lg-0">
              <b-button
                :disabled="isPreviewFirstQuestionDisabled"
                @click.prevent="previewFirstQuestion()"
                title="Select the first question"
                size="sm"
                variant="outline-primary"
              >⇤ First</b-button>
              <b-button
                :disabled="isPreviewPreviousQuestionDisabled"
                @click.prevent="previewPreviousQuestion()"
                title="Select the previous question"
                variant="outline-primary"
              >← Prev</b-button>
              <b-button
                :disabled="isPreviewNextQuestionDisabled"
                @click.prevent="previewNextQuestion()"
                title="Select the next question"
                variant="outline-primary"
              >Next →</b-button>
            </b-button-group>

            <b-button
              variant="primary"
              @click.prevent="previewQuestionEnd()"
              class="px-3 ml-4 mt-2 mt-lg-0"
            >Close</b-button>
          </div>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<style>

 #preview-question-modal .modal-content {
   height: 100%;
 }

 .is-preview-question-modal-copy-button {
   margin-top: -0.5rem;
   margin-bottom: -0.4rem;
 }

</style>
