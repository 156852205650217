
import ModelBase from '../model-base.js';
import TeamAnswer from './answer.js';

export default class TeamAnswerSheet extends ModelBase {

  constructor({ id, sessionId, teamId, questionIdToTeamAnswer, writerBrowserId }) {
    super();
    return {
      ...this,
      id, ///JPL: used? no, stored by teamId, which is globally unique
      sessionId,
      teamId,
      questionIdToTeamAnswer: this.ensureValuesObjects(questionIdToTeamAnswer, TeamAnswer),

      // The primary client browser that has the right to write
      // changes to this data
      writerBrowserId,


      ///JPL: turn into getter, so it's the same as for the
      ///MasterAnswerScore, this discrepancy is confusing
      teamAnswers() {
        return Object.values(this.questionIdToTeamAnswer);
      },

      get emptyTeamAnswers() {
        return this.teamAnswers().filter(teamAnswer => teamAnswer.isEmpty);
      },

      get confirmedTeamAnswers() {
        return this.teamAnswers().filter(teamAnswer => teamAnswer.isConfirmed);
      },

      get unconfirmedAnswers() {
        return this.teamAnswers().filter(teamAnswer => ! teamAnswer.isConfirmed);
      },

      addTeamAnswer(questionId, newTeamAnswer) {
        // Use Object.assign to preserve Vue reactivity
        this.questionIdToTeamAnswer = Object.assign(
          { [ questionId ]: newTeamAnswer },
          this.questionIdToTeamAnswer,
        );

        return newTeamAnswer;
      },

      teamAnswerByQuestionId(questionId) {
        return this.questionIdToTeamAnswer[ questionId ];
      },

      teamEnsureTeamAnswer(teamQuestion) {
        const teamAnswer = this.questionIdToTeamAnswer[ teamQuestion.questionId ];
        if ( teamAnswer ) return teamAnswer;

        return this.addTeamAnswer(
          teamQuestion.questionId,
          teamQuestion.newTeamAnswer(this.teamId),
        );
      },

      masterEnsureTeamAnswer(masterAnswerSheet, masterQuestion) {
        const teamAnswer = this.questionIdToTeamAnswer[ masterQuestion.id ];
        if ( teamAnswer ) return teamAnswer;

        return this.addTeamAnswer(
          masterQuestion.id,
          masterQuestion.newTeamAnswer(masterAnswerSheet, this.teamId),
        );
      },

      masterEnsureTeamAnswers(masterAnswerSheet) {
        masterAnswerSheet.questions.forEach(masterQuestion => {
          this.masterEnsureTeamAnswer(masterAnswerSheet, masterQuestion);
        });
      },

      confirmAllUnconfirmedAnswers() {
        const unconfirmedAnswers = this.unconfirmedAnswers;
        unconfirmedAnswers.forEach(teamAnswer => teamAnswer.confirmAnswer());
        return unconfirmedAnswers.length;
      },

      confirmUnconfirmedAnswersForQuestions(questionIds) {
        const unconfirmedAnswers = this.unconfirmedAnswers.filter(
          teamAnswer => questionIds.find(
            questionId => questionId === teamAnswer.questionId,
          )
        );

        unconfirmedAnswers.forEach(teamAnswer => teamAnswer.confirmAnswer());

        return unconfirmedAnswers.length;
      },

      confirmAllUnconfirmedAnswersOnAnswerSheet(masterAnswerSheet) {
        this.masterEnsureTeamAnswers(masterAnswerSheet);
        this.confirmAllUnconfirmedAnswers();
      },

      confirmUnconfirmedAnswersForQuestionsOnAnswerSheet(masterAnswerSheet, questionIds) {
        this.masterEnsureTeamAnswers(masterAnswerSheet);
        return this.confirmUnconfirmedAnswersForQuestions(questionIds);
      },

    };
  }
}
