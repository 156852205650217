
import axios from '@/store/axios';
import MasterQuestionStatusSheet from '../../../models/master/question-status-sheet.js';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {

    // Post session to the API.
    async saveObject({ dispatch }, { sessionId, masterQuestionStatusSheet }) {
      const userSession = await dispatch('auth/ensureSession', {}, { root: true });
      return await axios.post(
        `/api/user-session/${userSession.id}/session/${sessionId}/question-status-sheet`,
        masterQuestionStatusSheet,
      ).then(res => {
        const masterQuestionStatusSheet = res.data || {};
        return new MasterQuestionStatusSheet(masterQuestionStatusSheet);
      });
      ///JPL: catch
    },

    async loadObject({ dispatch }, { sessionId }) {
      const userSession = await dispatch('auth/ensureSession', {}, { root: true });
      return await axios.get(`/api/user-session/${userSession.id}/session/${sessionId}/question-status-sheet`)
        .then(res => {
          const masterQuestionStatusSheet = res.data.masterQuestionStatusSheet || {};
          return new MasterQuestionStatusSheet(masterQuestionStatusSheet);
        });
      ///JPL: catch
    },

  },

};
