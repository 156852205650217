
import shortid from 'shortid';
import axios from '@/store/axios';
import TeamAnswerSheet from '../../../models/team/answer-sheet.js';

// Team private data
export default {
  namespaced: true,
  state: {
    browserId: null,
    isBrowserWriter: true,
  },
  mutations: {
    ensureBrowserId(state) {
      if ( state.browserId ) return;
      state.browserId = shortid.generate();
    },
    setIsBrowserWriter(state, value) {
      state.isBrowserWriter = !! value;
    },
  },
  getters: {
  },
  actions: {

    // Return promise of teamAnswerSheet with teamId, after having
    // fetched it from the API. Don't commit it to the Store.
    ///JPL: unify naming to "load" or "fetch"
    async fetchObject(context, { sessionId, teamId }) {
      return await axios.get(`/api/team/session/${sessionId}/team/${teamId}/answer-sheet`)
        .then((res) => {
          const teamAnswerSheet = res.data.teamAnswerSheet
                || {
                  sessionId,
                  teamId,
                };
          return new TeamAnswerSheet( teamAnswerSheet );
        });
      ///JPL: catch
    },

    // Post team answerSheet to the API.
    async saveObject({ commit, state }, teamAnswerSheet) {

      if ( ! state.isBrowserWriter ) {
        return;
      }

      commit('ensureBrowserId');
      const browserId = state.browserId;

      ///JPL: make request with auth
      ///JPL: debounce this 0.5s or so?
      return await axios.post(
        `/api/team/session/${teamAnswerSheet.sessionId}/team/${teamAnswerSheet.teamId}/answer-sheet`,
        {
          ...teamAnswerSheet,
          browserId,
        },
      )
        .then(
          res => {
            const data = res.data;
            ///JPL: possibly set isBrowserWriter: true here
            return new TeamAnswerSheet(data.teamAnswerSheet);
          },
          error => {
            const message = error.response.data.error;
            if ( message === "browserId is for a reader browser" ) {
              console.log("This is not a writer browser");
              commit('setIsBrowserWriter', false);
            }
          },
        )
        .catch(error => console.error(`Save answer sheet failed`, error));
      ///JPL: catch
    },

    // Post request to become browserWriter to the API.
    async becomeWriter({ commit, state }, [ sessionId, teamId ]) {
      commit('ensureBrowserId');
      const browserId = state.browserId;

      return await axios.post(
        `/api/team/session/${sessionId}/team/${teamId}/answer-sheet/writer`,
        { browserId },
      )
        .then(() => {})
        .catch(error => console.error(`Become writer failed`, error));
    },

  },

};
