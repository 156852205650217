
import axios from 'axios';

const auth = process.env.VUE_APP_HTTP_AUTH_USERNAME ? {
  username: process.env.VUE_APP_HTTP_AUTH_USERNAME,
  password: process.env.VUE_APP_HTTP_AUTH_PASSWORD,
} : {};

var axiosInstance = axios.create({
  auth,
});

export default axiosInstance;
