
import Vue from 'vue';
import Router from 'vue-router';

import HomePage from '../components/home/HomePage.vue';
import AboutPage from '../components/home/AboutPage.vue';
import ContactPage from '../components/home/ContactPage.vue';
import TermsAndConditionsPage from '../components/home/TermsAndConditionsPage.vue';
import PrivacyPolicyPage from '../components/home/PrivacyPolicyPage.vue';
import LoginPage from '../components/user/LoginPage.vue';
import SignupPage from '../components/user/SignupPage.vue';

import EditAnswerSheet from '../components/quizmaster/EditAnswerSheet.vue';
import EditSession from '../components/quizmaster/InviteTeam.vue'; ///JPL: fix name
import MasterRunQuiz from '../components/quizmaster/MasterRunQuiz.vue';

import TeamInvitation from '../components/team/TeamInvitation.vue';
import TeamGame from '../components/team/TeamGame.vue';

import LibraryPage from '../components/library/LibraryPage.vue';
import LibraryQuestionsPage from '../components/library/LibraryQuestionsPage.vue';

import AdminPage from '../components/admin/AdminPage.vue';
import AdminLibraryQuestionsPage from '../components/admin/AdminLibraryQuestionsPage.vue';
import AdminUserAccountsPage from '../components/admin/AdminUserAccountsPage.vue';
import AdminAnswerSheetsPage from '../components/admin/AdminAnswerSheetsPage.vue';



Vue.use( Router );


const router = new Router({
  // https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
  mode: 'history',

  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: HomePage,
    },
    {
      path: '/log-in',
      name: 'LoginPage',
      component: LoginPage,
      meta: { title: "Pub Quiz: Log In" },
    },
    {
      path: '/sign-up',
      name: 'SignupPage',
      component: SignupPage,
      meta: { title: "Pub Quiz: Sign Up" },
    },

    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
      meta: { title: "Pub Quiz: About" },
    },
    {
      path: '/terms-and-conditions',
      name: 'TermsAndConditionsPage',
      component: TermsAndConditionsPage,
      meta: { title: "Pub Quiz: Terms & Conditions" },
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
      meta: { title: "Pub Quiz: Privacy Policy" },
    },
    {
      path: '/contact',
      name: 'ContactPage',
      component: ContactPage,
      meta: { title: "Pub Quiz: Contact" },
    },

    {
      path: '/quizmaster/answer-sheet/:loadAnswerSheetId/invite-team/new',
      name: 'NewSession',
      component: EditSession,
      props: true,
      meta: { title: "Pub Quiz: Invite Teams" },
    },
    {
      path: '/quizmaster/answer-sheet/:loadAnswerSheetId/invite-team/:loadSessionId',
      name: 'EditSession',
      component: EditSession,
      props: true,
      meta: { title: "Pub Quiz: Invite Teams" },
    },

    {
      path: '/quizmaster/answer-sheet/:loadAnswerSheetId/run-quiz/:loadSessionId',
      name: 'MasterRunQuiz',
      component: MasterRunQuiz,
      props: true,
      meta: { title: "Pub Quiz: Run Quiz" },
    },

    {
      path: '/quizmaster/answer-sheet/new',
      name: 'NewAnswerSheet',
      component: EditAnswerSheet,
      meta: { title: "Pub Quiz: Create Answer Sheet" },
    },
    {
      path: '/quizmaster/answer-sheet/:loadAnswerSheetId',
      name: 'EditAnswerSheet',
      component: EditAnswerSheet,
      props: true,
      meta: { title: "Pub Quiz: Create Answer Sheet" },
    },

    {
      path: '/team/invitation/:loadSessionId',
      name: 'TeamInvitation',
      component: TeamInvitation,
      props: true,
      meta: { title: "Pub Quiz: Sign Up Your Team" },
    },

    {
      path: '/team/game/:loadTeamSessionId/team/:loadTeamSessionTeamId',
      name: 'TeamGame',
      component: TeamGame,
      props: true,
      meta: { title: "Pub Quiz" },
    },


    {
      path: '/library',
      name: 'LibraryPage',
      component: LibraryPage,
      props: true,
      meta: { title: "Pub Quiz: Library" },
    },
    {
      path: '/library/questions',
      name: 'LibraryQuestionsPage',
      component: LibraryQuestionsPage,
      props: true,
      meta: { title: "Pub Quiz: Question Library" },
    },

    {
      path: '/admin',
      name: 'AdminPage',
      component: AdminPage,
      props: true,
      meta: { title: "Pub Quiz: Admin" },
    },
    {
      path: '/admin/user-accounts',
      name: 'AdminUserAccountsPage',
      component: AdminUserAccountsPage,
      props: true,
      meta: { title: "Pub Quiz: User Accounts" },
    },
    {
      path: '/admin/answer-sheets',
      name: 'AdminAnswerSheetsPage',
      component: AdminAnswerSheetsPage,
      props: true,
      meta: { title: "Pub Quiz: Answer Sheets" },
    },
    {
      path: '/admin/library/questions',
      name: 'AdminLibraryQuestionsPage',
      component: AdminLibraryQuestionsPage,
      props: true,
      meta: { title: "Pub Quiz: Library Question Admin" },
    },


  ],

  scrollBehavior () {
    return { x: 0, y: 0 };
  },

});

router.afterEach(to =>  {
  const title = to.meta && to.meta.title
        ? to.meta.title
        : 'Pub Quiz Scoring';
  document.title = `${title} | Even Remotely`;
});

router.afterEach(function afterEachNewRoute() {
  router.$store.dispatch("router/newRoute");
});


export default router;
