
<script>
 export default {
   name: "TeamGameInstructions",
   props: [
     'header',
     'isBrowserWriter',
   ],

   computed: {
     isBrowserReader() {
       return ! this.isBrowserWriter;
     },
   },

   methods: {
     becomeSecretary() {
       this.$emit('become-secretary');
     },
   }
 }
</script>

<template>
  <div>
    <b-card
      v-if="isBrowserWriter"
      :header="header"
    >
      <b-card-text>
        <strong>
          Please
          <b-badge size="sm" variant="secondary">Submit</b-badge>
          each answer when you think you've got it right.
        </strong>
      </b-card-text>
      <b-card-text>
        <strong>Unless the quiz host tells you otherwise</strong>,
        you can change your mind and
        <b-badge size="sm" variant="secondary">Submit</b-badge>
        an answer again until the end of the quiz
      </b-card-text>
      <details>
        <summary>Are there several people in your team?</summary>
        <b-card-text class="mt-1">
          <strong>
            If the people in your team are sitting in front of
            different screens:
          </strong>

          <ul>
            <li>
              You probably want to confer within the team over chat or
              video conference
            </li>
            <li>
              Share the URL of this page amongst your team members
            </li>
            <li>
              You need to agree on a "secretary" to submit the answers
              (currently you)
            </li>
          </ul>
        </b-card-text>
      </details>
    </b-card>

    <b-card
      v-if="isBrowserReader"
      header="Note: This page is for viewing only"
      header-bg-variant="info"
      header-text-variant="white"
    >
      <b-card-text>
        <strong>
          Another device is the "secretary" of your team and can
          submit answers.
        </strong>
      </b-card-text>
      <ul>
        <li>
          If there are many people in your team (in front of different
          screens), agree amongst yourself on a "secretary" who
          submits the answers.
      </li>
      <li>
        If you're the only one in your team, click "Become Secretary"
        to use this device.
      </li>
      </ul>
      <b-card-text class="text-right">
        <b-button @click.prevent="becomeSecretary" size="sm" variant="primary">
          Become Secretary
        </b-button>
      </b-card-text>
    </b-card>

  </div>

</template>
