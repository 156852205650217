
export default {
  namespaced: true,
  state: {
    displayAccountMenu: true,
  },
  mutations: {
    setDisplayAccountMenu(state, isShown) {
      return state.displayAccountMenu = isShown;
    },
  },
  actions: {
    // Called by the router afterEach navigation
    async newRoute({ commit }) {
      return await commit('setDisplayAccountMenu', true);
    },
  },
};
