
<script>
 export default {
   name: 'CollapsibleCard',
   props: {
     title: {},
     visible: { default: false },
     accordion: { default: "accordion" },
   },
   data() {
     return {
       isVisible: this.visible,
     };
   },
 }
</script>

<template>
  <b-card no-body class="mb-1">
    <b-card-header class="p-1">
      <span class="ml-1">
        <span v-if="isVisible">▾</span>
        <span v-else>▸</span>
      </span>
      <a
        href=""
        class="ml-2"
        @click.prevent="isVisible = !isVisible"
      >{{title}}</a>
    </b-card-header>
    <b-collapse v-model="isVisible" :accordion="accordion" role="tabpanel">
      <b-card-body>
        <slot></slot>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>
