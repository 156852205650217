<script>

 export default {
   name: 'ScoringGridProgress',
   props: [
     'quiz',
     'showCurrentQuestion',
     'selectedQuestion',
   ],

   methods: {
     isQuestionCurrent(question) {
       if ( ! this.showCurrentQuestion ) return false;
       return this.quiz.currentQuestion() === question;
     },
     isQuestionSelected(question) {
       return this.selectedQuestion === question;
     },
     isQuestionAsked(question) {
       return this.quiz.isQuestionAsked(question);
     },
     isQuestionRevealed(question) {
       return this.quiz.isMasterQuestionRevealed(question);
     },

     questionTitle(questionIndex, question) {
       return `${questionIndex + 1}: ${question.question}`;
     },

     selectQuestion(question) {
       this.$emit('select-question', question);
     },
   }
 }

</script>

<template>
  <div class="is-question-progress d-flex ">
    <div
      class="is-progress-question flex-grow-1"
      v-for="(question, questionIndex) in quiz.masterAnswerSheet.questions"
      :key="question.id"
      @click="selectQuestion(question)"
      :class="{
        'is-progress-selected-question': isQuestionSelected(question),
        'is-progress-current-question': isQuestionCurrent(question),
        'is-progress-asked-question': isQuestionAsked(question),
        'is-progress-revealed-question': isQuestionRevealed(question),
      }"
      :title="questionTitle(questionIndex, question)"
    >
    </div>
  </div>
</template>

<style scoped>

 .is-progress-question {
   margin-left: 0.1rem;

   height: 0.5rem;

   border-top: 1px solid rgba(0, 0, 0, 0.125);
   border-left: 1px solid rgba(0, 0, 0, 0.125);
   border-right: 1px solid rgba(0, 0, 0, 0.125);
   border-radius: 0.5rem 0.5rem 0 0;
 }
 .is-progress-question:first-of-type {
   margin-left: 0;
 }

 .is-progress-question.is-progress-asked-question {
   cursor: pointer;
 }

 .is-progress-question.is-progress-selected-question {
   background: #eee;
 }

 .is-progress-question.is-progress-current-question {
   background: #3399f3;
 }

 .is-progress-revealed-question {
   border-top: 1px solid rgba(0, 0, 0, 0.4);
   border-left: 1px solid rgba(0, 0, 0, 0.4);
   border-right: 1px solid rgba(0, 0, 0, 0.4);
 }

</style>
