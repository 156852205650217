<script>

 import TopNavBar from '@/components/common/TopNavBar.vue';
 import BottomFooter from '@/components/common/BottomFooter.vue';

 export default {
   name: 'ContactPage',
   components: {
     TopNavBar,
     BottomFooter,
   },
 }

</script>

<template>
  <div class="is-footer-flex-container">
    <TopNavBar />

    <b-container class="is-footer-flex-content">

      <h2>Contact</h2>

      <p>
        If you have any questions or comments, we'd love to hear them!
      </p>
      <p>
        Please send us an email at
        <a href="mailto:evenremotelypubquiz@yahoo.com">evenremotelypubquiz@yahoo.com</a>
      </p>




    </b-container>

    <BottomFooter />
  </div>
</template>

<style scoped>

</style>
