<template>
  <div class="vue-foldable">
    <div
      class="vue-foldable-container"
      :style="{ maxHeight: currentMaxHeight + 'px' }"
      ref="container"
    >
      <slot/>
    </div>

    <div
      :class="{ 'collapsed': collapsed }"
      class="vue-foldable-mask"
      v-if="!noMask"
    ></div>

    <slot
      name="view-more"
      :toggle="toggle"
      :collapsed="collapsed"
    >
      <div
        class="vue-foldable-view-more"
        :class="{ 'collapsed': collapsed }"
        @click="toggle"
        v-if="reachThreshold"
      >
        <span
          style="display: inline-block"
          class="vue-foldable-icon"
          :class="{ 'collapsed': collapsed }"
        >{{ downArrowText }}</span>

        <span class="vue-foldable-text">
          {{ collapsed ? viewMoreText : collapseText }}
        </span>
      </div>
    </slot>
  </div>
</template>

<script>

 import VueFoldable from 'vue-foldable';

 export default {
   name: 'vue-plain-foldable',
   extends: VueFoldable,

   props: {
     downArrowText: { default: '▼' },
     viewMoreText: { default: 'View more' },
     collapseText: { default: 'Collapse' },
   },
 }

</script>

<style>
 .vue-foldable-icon, .vue-foldable-text {
   color: #000;
 }
</style>
