
<script>

 export default {
   name: 'EditQuestionSummaryForYou',
   props: [ "question" ],
 }

</script>

<template>
  <span>
    <span>
      <span v-if="question.answerTrivia" key="withAnswerTrivia"><strong><font-awesome-icon :icon="['far', 'lightbulb']" /> Answer Trivia</strong></span>
      <span v-else key="withoutAnswerTrivia"><font-awesome-icon :icon="['far', 'lightbulb']" /> Answer Trivia</span>
    </span>

    <span v-if="question.canAutoMarkCorrect">
      <span class="mr-1">, </span>
      <span v-if="question.shouldAutoMarkCorrect" key="withShouldRevealAnswer">
        <strong>
          <font-awesome-icon :icon="['fas', 'magic']" />
          Auto-mark correct answers ({{question.autoMarkCorrectStyle}})
        </strong>
      </span>
      <span v-else key="withoutShouldRevealAnswer">
        <font-awesome-icon :icon="['fas', 'magic']" />
        Don't auto-mark correct answers
      </span>
    </span>
  </span>
</template>
