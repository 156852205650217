
<script>

 import CollapsibleCard from '@/components/share/CollapsibleCard';

 export default {
   name: 'EditQuestionMarkdownInstructions',
   components: {
     CollapsibleCard,
   },
 };

</script>

<template>
  <b-card >
    <div role="tablist">
      <CollapsibleCard accordion="instructions" title="Markdown" :visible="true">
        <b-card-text>
          <strong>Markdown</strong> is a way to add formatting elements to plain text.
          For example, you can add headings, links and images.
        </b-card-text>

        <b-card-text>
          For your quiz, you probably need these the most:

          <ol>
            <li>
              <i>Normal text</i> - for the trivia details
            </li>
            <li>
              <i>Links</i> - to link to interesting or explanatory web pages
            </li>
            <li>
              <i>Image URLs</i> - to show or illustrate the correct answer
            </li>
          </ol>


          External links:

          <ol>
            <li><a href="https://guides.github.com/features/mastering-markdown/#syntax" target="_blank" nofollow>Documentation</a></li>
            <li><a href="https://guides.github.com/features/mastering-markdown/#examples" target="_blank" nofollow>Example</a></li>
            <li><a href="https://www.markdownguide.org/cheat-sheet/" target="_blank" nofollow>Cheat-sheet</a></li>
          </ol>

        </b-card-text>
      </CollapsibleCard>

      <CollapsibleCard class="mt-2" accordion="instructions" title="Example: Normal text">
        <b-card-text>
          <code>
Just type normal text in paragraphs.
            <br />
            <br />

Separate paragraphs with a blank line.
          </code>
        </b-card-text>
        <b-card-text>
          <b-alert show variant="secondary">
            <p>
              Just type normal text in paragraphs.
            </p>

            <p>
              Separate paragraphs with a blank line.
            </p>
          </b-alert>
        </b-card-text>
      </CollapsibleCard>

      <CollapsibleCard class="mt-2" accordion="instructions" title="Example: Links">
        <b-card-text>
          URLs in the text are automatically turned into links.
        </b-card-text>
        <b-card-text>

          <p>
            You can also provide a link text:
          </p>

          <small>
            <code>
[Ants](https://en.wikipedia.org/wiki/Ant)
            </code>
          </small>
        </b-card-text>
        <b-card-text>
          <small>
            <b-alert show variant="secondary">
              <a href="https://en.wikipedia.org/wiki/Ant" target="_blank" nofollow>Ants</a>
            </b-alert>
          </small>
        </b-card-text>
      </CollapsibleCard>

      <CollapsibleCard class="mt-2" accordion="instructions" title="Example: Images">
        <b-card-text>
          The format for image URLs is a bit fiddly (note the first
          exclamation point). The square brackets
          <code>[]</code> is for the image description, which you can
          simply leave empty.
        </b-card-text>
        <b-card-text>
          <small>
            <code>
![](https://i.imgur.com/HggYvB7.jpg)
            </code>
          </small>
        </b-card-text>
        <b-card-text>
          <small>
            <b-alert show variant="secondary text-center">
              <img
                class="is-example-image"
                src="https://i.imgur.com/HggYvB7.jpg"
              />
            </b-alert>
          </small>
        </b-card-text>

        <b-card-text>
          Images are placed on ther own line, sized to fit the page
          (a bit smaller than the question Image URL).
        </b-card-text>

      </CollapsibleCard>


      <div class="pt-5 pb-5"></div>

      <slot></slot>

    </div>
  </b-card>
</template>

<style scoped>

 h5 {
   color: black;
 }

 .is-example-image {
   width: 8rem;
 }

</style>
