import { render, staticRenderFns } from "./TeamGame.vue?vue&type=template&id=72c34eb4&scoped=true&"
import script from "./TeamGame.vue?vue&type=script&lang=js&"
export * from "./TeamGame.vue?vue&type=script&lang=js&"
import style0 from "./TeamGame.vue?vue&type=style&index=0&id=72c34eb4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72c34eb4",
  null
  
)

export default component.exports