
<script>

 import ScoringGridProgress from '@/components/quizmaster/run/ScoringGridProgress.vue';
 import TeamAnswerCell from '@/components/quizmaster/run/TeamAnswerCell.vue';
 import QuestionHeadingCell from '@/components/quizmaster/run/QuestionHeadingCell.vue';
 import ScoringBar from '@/components/quizmaster/run/ScoringBar.vue';
 import QuestionBar from '@/components/quizmaster/run/QuestionBar.vue';

 export default {
   name: "ScoringGrid",
   components: {
     ScoringGridProgress,
     QuestionHeadingCell,
     TeamAnswerCell,
     ScoringBar,
     QuestionBar,
   },

   props: [
     'quiz',
     'masterQuestionsMetadata',
     'showTeamPlacements',
     'selectedQuestion',
     'selectedTeamAnswer',
     'showUnscoredNavButtons',
     'showFirstQuestionButton',
     'showCurrentQuestion',
     'isCurrentQuestionTheLastOne',
     'showQuestionDifficulty',
   ],

   data() {
     return {
     }
   },

   created() {
     this.selectedTeamTeam = this.quiz.teamTeams[0]; ///JPL: used?
     this.selectQuestion( this.quiz.currentQuestion() );
   },

   watch: {
     'quiz.session.currentQuestionNumber': function watchCurrentQuestionNumber() {
       this.selectCurrentQuestion();
     },
   },

   computed: {
     unmarkedAnswerCount() {
       return this.quiz.unmarkedAnswerCount;
     },
     unconfirmedAnswerCount() {
       return this.quiz.unconfirmedAnswerCount;
     },

     orderedTeamTeams() {
       return this.showTeamPlacements
            ? this.quiz.teamTeamsInPlacementOrder
            : this.quiz.teamTeams;
     },
     orderedTeamTeamPlacements() {
       return this.showTeamPlacements
            ? this.quiz.masterTeamPlacements
            : this.quiz.teamTeams.map(teamTeam => { return { teamTeam } });
     },

     totalPointsPossible() {
       return this.quiz.masterAnswerSheet.totalPointsPossible;
     }
   },

   methods: {
     teamCurrentTotalScore(teamTeam) {
       return this.quiz.teamCurrentTotalScore(teamTeam);
     },

     currentQuestionCellRef(question) {
       return this.quiz.session.isQuestionCurrent(question) ? "currentQuestionCell" : null;
     },

     masterAnswerScoreFor(teamTeam, question) {
       if ( ! teamTeam || ! question) return null;
       return this.quiz.masterAnswerScoreFor(teamTeam, question);
     },

     masterAnswerScoreForQuestionAndTeamAnswer(question, teamAnswer) {
       if ( (! teamAnswer)|| (! question) ) return null;
       const teamTeam = this.quiz.teamTeamForTeamId(teamAnswer.teamId);
       return this.masterAnswerScoreFor(teamTeam, question)
     },

     teamAnswerFor(teamTeam, question) {
       if ( ! teamTeam || ! question) return null;
       return this.quiz.teamAnswerFor(teamTeam, question);
     },

     scrollSelectedQuestionIntoView() {
       this.$nextTick(() => {
         const columns = this.$refs.selectedQuestionColumn;
         if ( ! columns ) return;
         const columnEl = columns[0];
         if ( ! columnEl ) return;
         this.scrollEntirelyIntoView(
           columnEl,
           {
             behavior: 'smooth',
             inline: 'nearest',
             block: 'start',
           },
         );
       });
     },

     isQuestionCurrent(question) {
       if ( ! this.showCurrentQuestion ) return false;
       return this.quiz.currentQuestion() === question;
     },

     isQuestionSelected(question) {
       return this.selectedQuestion === question;
     },

     selectQuestion(question) {
       // Don't select questions not displayed in the grid
       if ( ! this.quiz.isQuestionAsked(question ) ) return;

       if ( question !== this.selectedQuestion) {
         this.$emit("update:selectedTeamAnswer", null);
         this.$emit("update:selectedQuestion", question);
       }
       this.scrollSelectedQuestionIntoView();
     },

     onSelectQuestion(question) {
       this.selectedAnswer = null;
       this.selectQuestion(question);
     },

     ///JPL: these should probably be in the parent component RunQuiz,
     ///and events should be emitted upwards to them
     askNextQuestion() {
       this.$emit('ask-next-question');
     },
     finishQuestions() {
       this.$emit('finish-questions');
     },

     selectCurrentQuestion() {
       this.selectQuestion( this.quiz.currentQuestion() );
     },
     selectFirstQuestion() {
       this.selectQuestion( this.quiz.masterAnswerSheet.questions[0] );
     },

     selectPreviousQuestion() {
       const currentQuestion = this.selectedQuestion;
       const newQuestion = this.quiz.masterAnswerSheet.getQuestionBeforeId( currentQuestion.id );
       if ( ! newQuestion ) return null;
       this.selectQuestion(newQuestion);
       return newQuestion;
     },
     selectNextQuestion() {
       const currentQuestion = this.selectedQuestion;
       const newQuestion = this.quiz.masterAnswerSheet.getQuestionAfterId( currentQuestion.id );
       if ( ! newQuestion ) return null;
       if ( ! this.quiz.isQuestionAsked(newQuestion) ) return null;
       this.selectQuestion(newQuestion);
       return newQuestion;
     },
     selectQuestionIndex(index) {
       const newQuestion = this.quiz.masterAnswerSheet.getQuestionByIndex( index );
       if ( ! newQuestion ) return null;
       if ( ! this.quiz.isQuestionAsked(newQuestion) ) return null;
       this.selectQuestion(newQuestion);
       return newQuestion;
     },

     selectAnswerAbove() {
       console.log(`Above`);
     },
     selectAnswerBelow() {
       console.log(`Below`);
     },

     selectPreviousUnscoredAnswer() {
       const question = this.selectedQuestion;
       if ( ! question ) return null;

       const newTeamAnswer = this.quiz.previousUnscoredTeamAnswer(
         this.orderedTeamTeams,
         question,
         this.selectedTeamAnswer, // or null
       );
       if ( ! newTeamAnswer ) {
         this.selectQuestion(this.selectedQuestion);
         return null;
       }

       const newQuestion = this.quiz.masterAnswerSheet.getQuestionById(
         newTeamAnswer.questionId,
       );
       this.onSelectQuestionAndTeamAnswer(newQuestion, newTeamAnswer);

       return newTeamAnswer;
     },

     selectNextUnscoredAnswer() {
       const question = this.selectedQuestion;
       if ( ! question ) return null;

       const newTeamAnswer = this.quiz.nextUnscoredTeamAnswer(
         this.orderedTeamTeams,
         question,
         this.selectedTeamAnswer, // or null
       );
       if ( ! newTeamAnswer ) {
         this.selectQuestion(this.selectedQuestion);
         return null;
       }

       const newQuestion = this.quiz.masterAnswerSheet.getQuestionById(
         newTeamAnswer.questionId,
       );
       this.onSelectQuestionAndTeamAnswer(newQuestion, newTeamAnswer);

       return newTeamAnswer;
     },


     ///JPL: extract, maybe all of the "selected question and answer" state and behavious
     isAnswerSelected(question, teamTeam) {
       if ( ! this.selectedTeamAnswer ) return false;
       if ( ! this.selectedQuestion ) return false;
       if ( this.selectedQuestion !== question ) return false;

       const teamAnswer = this.selectedTeamAnswer;
       return teamAnswer.questionId === question.id
           && teamAnswer.teamId === teamTeam.id;
     },

     isTeamTeamSelected(teamTeam) {
       if ( ! this.selectedTeamAnswer ) return false;
       const teamAnswer = this.selectedTeamAnswer;
       return teamAnswer.teamId === teamTeam.id;
     },

     onSelectQuestionAndTeamAnswer(question, teamAnswer) {
       this.selectQuestion(question);
       this.$emit("update:selectedTeamAnswer", teamAnswer);
     },

     onQuestionStatusSheetChanged() {
       this.$emit('question-status-sheet-changed');
     },
   },
 };

</script>

<template>
  <div>
    <ScoringGridProgress
      :quiz="quiz"
      :showCurrentQuestion="showCurrentQuestion"
      :selectedQuestion="selectedQuestion"
      @select-question="onSelectQuestion($event)"
      class="mt-2"
    />

    <b-card class="team-answers">

      <QuestionBar
        :quiz="quiz"
        :masterQuestionsMetadata="masterQuestionsMetadata"
        :selectedQuestion="selectedQuestion"
        :showFirstQuestionButton="showFirstQuestionButton"
        :showCurrentQuestion="showCurrentQuestion"
        :isCurrentQuestionTheLastOne="isCurrentQuestionTheLastOne"
        :showQuestionDifficulty="showQuestionDifficulty"
        @ask-next-question="askNextQuestion"
        @select-current-question="selectCurrentQuestion"
        @select-first-question="selectFirstQuestion"
        @select-previous-question="selectPreviousQuestion"
        @select-next-question="selectNextQuestion"
        @select-question-index="selectQuestionIndex"
        @finish-questions="finishQuestions"
        @question-status-sheet-changed="onQuestionStatusSheetChanged"
      />

      <!-- Header -->
      <b-row class="mt-3">

        <b-col
          :cols="showTeamPlacements ? 3 : 2"
          class="teams-list"
          :class="{ 'teams-list-with-placements': showTeamPlacements }"
        >
          <div class="is-empty-team-cell team-cell-width heading-cell-height">
            <div v-if="showTeamPlacements">
              <b-row>
                <b-col><strong><u>Place</u></strong></b-col>
                <b-col class="text-right"><strong><u>Score (out of {{totalPointsPossible}})</u></strong></b-col>
              </b-row>
            </div>
            <div v-else></div>
          </div>
          <div
            v-for="(teamTeamPlacement, index) in orderedTeamTeamPlacements"
            :key="index"
            class="is-team-cell team-cell-width grid-cell-height d-flex"
            :class="{ 'is-selected-team': isTeamTeamSelected(teamTeamPlacement.teamTeam) }"
          >
            <div v-if="showTeamPlacements" class="team-placement-ordinal">
              <strong>{{teamTeamPlacement.placementOrdinal}}</strong>
            </div>
            <div
              class="flex-grow-1 overflow-hidden"
              :title="teamTeamPlacement.teamTeam.name"
            >{{teamTeamPlacement.teamTeam.name}}</div>
            <div class="team-current-total-score pl-2">
              <div v-if="showTeamPlacements">
                <strong>{{teamCurrentTotalScore(teamTeamPlacement.teamTeam)}}</strong>
              </div>
              <div v-else>
                {{teamCurrentTotalScore(teamTeamPlacement.teamTeam)}}
              </div>
            </div>
          </div>
        </b-col>

        <b-col :cols="showTeamPlacements ? 9 : 10">
          <div class="grid-container">
            <div
              class="question-answers-column"
              v-for="(question, questionIndex) in quiz.currentQuestions"
              :key="question.id"
              @click="selectQuestion(question)"
              :class="{
                'is-selected-question-column': isQuestionSelected(question),
                'is-current-question-column': isQuestionCurrent(question),
              }"
              :ref="isQuestionSelected(question) ? 'selectedQuestionColumn' : ''"
            >
              <div class="is-question-column heading-cell-height">
                <QuestionHeadingCell
                  :quiz="quiz"
                  :masterQuestionsMetadata="masterQuestionsMetadata"
                  :questionNumber="questionIndex + 1"
                  :question="question"
                  :isCurrentQuestion="quiz.session.isQuestionCurrent(question)"
                  :showCurrentQuestion="showCurrentQuestion"
                  :showQuestionDifficulty="showQuestionDifficulty"
                  :ref="currentQuestionCellRef(question)"
                />
              </div>
              <div
                class="answer-column grid-cell-width"
              >
                <div class="answer-column grid-cell-width">
                  <TeamAnswerCell
                    v-for="(teamTeam) in orderedTeamTeams"
                    :key="teamTeam.id"
                    :masterQuestion="question"
                    :masterAnswerScore="masterAnswerScoreFor(teamTeam, question)"
                    :teamAnswer="teamAnswerFor(teamTeam, question)"
                    :isCurrentQuestion="quiz.session.isQuestionCurrent(question)"
                    :isSelectedQuestion="isQuestionSelected(question)"
                    :isSelectedAnswer="isAnswerSelected(question, teamTeam)"
                    :isQuestionAsked="quiz.session.isQuestionNumberAsked(questionIndex + 1)"
                    @select-question="onSelectQuestion(question)"
                    @select-team-answer="onSelectQuestionAndTeamAnswer(question, $event)"
                    v-on="$listeners"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <ScoringBar
            :quiz="quiz"
            :masterAnswerScore="masterAnswerScoreForQuestionAndTeamAnswer(selectedQuestion, selectedTeamAnswer)"
            :selectedQuestion="selectedQuestion"
            :selectedTeamAnswer="selectedTeamAnswer"
            :unmarkedAnswerCount="unmarkedAnswerCount"
            :showUnscoredNavButtons="showUnscoredNavButtons"
            @select-previous-unscored-answer="selectPreviousUnscoredAnswer"
            @select-next-unscored-answer="selectNextUnscoredAnswer"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<style>

 div.card.team-answers {
   border-top: none;
   border-top-left-radius: 0;
   border-top-right-radius: 0;
 }

 div.teams-list {
   margin-right: -0.5rem;
   padding-right: 0.7rem;
   border-radius: 0.4rem;
 }

 div.teams-list-with-placements {
   background: var(--highlight-bg);
 }

 .grid-container {
   overflow-x: auto;
   white-space: nowrap;
 }

 .grid-cell-width {
   display: inline-block;
   width: 20rem;
   margin-right: 1rem;
   white-space: nowrap;
 }

 .grid-cell-height {
   height: 2.0rem;
   margin-bottom: 0.5rem;
 }

 .is-team-cell.grid-cell-height {
   height: 2.0rem;
   padding-top: 0.25rem;
   padding-bottom: 0.25rem;
 }

 .team-cell-width {
   white-space: nowrap;
   overflow-x: hidden;
   padding-left: 0.3rem;
   padding-right: 0.3rem;
   border-radius: 0.3rem;
 }

 .heading-cell-height {
   height: 2.0rem;
   margin-top: 0.5rem;
   margin-bottom: 0.5rem;
 }

 .scoring-bar-cell-height {
   height: 2.0rem;
   margin-top: 0.5rem;
   margin-bottom: 0.5rem;
 }


 .question-answers-column {
   display: inline-block;
 }

 .answer-column {
 }


 /* JPL: split out in scoped and non scoped style elements */

 .unscored-icon {
   background-color: var(--unscored-bg) !important;
 }

 .unconfirmed-icon {
   background-color: var(--unconfirmed-bg) !important;
 }


 .team-placement-ordinal {
   display: inline-block;
   width: 1.2rem;
 }


 .is-selected-question-column {
   background: var(--selected-question-bg);
   padding-left: 0.7rem;
   padding-right: 0.0rem;
   margin-right: 0.7rem;
   border-radius: 0.4rem;
 }

 .is-current-question-column {
   background: #3399f3;
   color: #fff;
   padding-left: 0.7rem;
   padding-right: 0.0rem;
   margin-right: 0.7rem;
   border-radius: 0.4rem;
 }

 .is-selected-team {
   background: var(--selected-question-bg);
 }



</style>
