<script>

 import WithUserSession from '@/components/mixins/WithUserSession';
 import LoginCard from '@/components/common/LoginCard.vue';
 import TopNavBar from '@/components/common/TopNavBar.vue';
 import BottomFooter from '@/components/common/BottomFooter.vue';


 export default {
   name: "LoginPage",
   mixins: [ WithUserSession ],
   components: {
     TopNavBar,
     BottomFooter,
     LoginCard,
   },

   async created() {
     // Wait until the session is loaded before checking the login status
     await this.$store.dispatch('auth/maybeLoadSession');
     if ( this.userSession.hasUserAccount ) {
       this.$router.push("/");
     }
   },
 }

</script>
<template>
  <div class="is-footer-flex-container">
    <TopNavBar />
    <b-container class="is-footer-flex-content">
      <b-row>
        <b-col cols="12" offset="0" md="8" offset-md="2" class="mt-5">
          <LoginCard :redirect="true" />
        </b-col>
      </b-row>
    </b-container>
    <BottomFooter />
  </div>
</template>

<style scoped>
</style>

