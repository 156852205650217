<script>

 import WithUserSession from '@/components/mixins/WithUserSession';
 import HomePageLanding from '@/components/home/HomePageLanding.vue';
 import AnswerSheetList from '@/components/quizmaster/AnswerSheetList.vue';

 export default {
   name: 'HomePage',
   mixins: [ WithUserSession ],
   components: {
     HomePageLanding,
     AnswerSheetList,
   },

   created() {
     this.$store.dispatch('masterAnswerSheet/maybeFetchList')
   },

   computed: {
     answerSheets() {
       return this.$store.getters['masterAnswerSheet/getList'];
     },
     hasNoAnswerSheets() {
       return ! this.answerSheets.length;
     },
   },
 }

</script>

<template>
  <HomePageLanding
    v-if="hasNoAnswerSheets"
  />
  <AnswerSheetList v-else />
</template>

<style scoped>

</style>
