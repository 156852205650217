
import MasterAnswerSheet from '../../../models/master/answer-sheet.js';

export default {
  props: [ 'loadAnswerSheetId', 'loadSessionId' ],

  data() {
    return {
      answerSheet: null,
      session: null,
      ///JPL: bad name, these are really master/team.js objects
      teamTeams: [],
    };
  },

  methods: {

    loadAnswerSheetSessionsTeamsByPropsIds() {
      return this
        .loadAnswerSheetById(this.loadAnswerSheetId)
        .then(() => {
          if ( this.loadSessionId ) {
            return this.loadSessionById( this.loadSessionId );
          }

          this.answerSheet.addSession();
          return this.saveAnswerSheet().then(() => this.loadNewestSession());
        })
        .then(() => {
          if ( this.loadSessionId ) {
            return this.loadTeamsBySessionId( this.loadSessionId );
          }
        });
    },

     initDefaultAnswerSheet() {
       const answerSheet = MasterAnswerSheet.createDefault();
       this.$set( this, 'answerSheet', answerSheet );
     },

    loadAnswerSheetById(id) {
      ///JPL: show any error
      return this
        .$store
        .dispatch('masterAnswerSheet/fetchObject', { id })
        .then((answerSheet) => {
          ///JPL: if error, display 404, go back to main page
          this.$set( this, 'answerSheet', answerSheet || MasterAnswerSheet.createDefault() );
        })
        .catch((e) => {
          console.error(`API error: ${e})`);
          ///JPL: if error, display 404, go back to main page
          ///JPL: needed? We've got a default...
          this.initDefaultAnswerSheet();
        });
    },

    saveAnswerSheet() {
      return this.$store
        .dispatch('masterAnswerSheet/saveObject', this.answerSheet)
        .then(answerSheet => this.$set(this, 'answerSheet', answerSheet));
    },
    loadSessionById(id) {
      this.$set( this, 'session', this.answerSheet.sessionById(id));
    },
    loadNewestSession() {
      this.$set( this, 'session', this.answerSheet.getNewestSession());
    },

    loadTeamsBySessionId(sessionId) {
      return this.$store
        .dispatch('masterTeam/fetchList', { sessionId })
        .then(teamTeams => this.$set(this, 'teamTeams', teamTeams));
    },


  }
};
