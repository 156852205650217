
import ModelBase from '../model-base.js';
import MasterAnswerScore from './answer-score.js';

export default class MasterAnswerScoreSheet extends ModelBase {

  constructor({ sessionId, teamId, questionIdToMasterAnswerScore }) {
    super();
    return {
      ...this,
      sessionId,
      teamId,
      questionIdToMasterAnswerScore: this.ensureValuesObjects(
        questionIdToMasterAnswerScore,
        MasterAnswerScore,
      ),

      get masterAnswerScores() {
        return Object.values(this.questionIdToMasterAnswerScore);
      },

      addMasterAnswerScore(questionId, newMasterAnswerScore) {
        // Use Object.assign to preserve Vue reactivity
        this.questionIdToMasterAnswerScore = Object.assign(
          { [ questionId ]: newMasterAnswerScore },
          this.questionIdToMasterAnswerScore,
        );

        return newMasterAnswerScore;
      },

      ensureMasterAnswerScore(answerSheet, question) {
        const masterAnswerScore = this.questionIdToMasterAnswerScore[ question.id ];
        if ( masterAnswerScore ) return masterAnswerScore;

        return this.addMasterAnswerScore(
          question.id,
          question.newMasterAnswerScore(answerSheet, this.teamTeam),
        );
      },

      ensureMasterAnswerScores(masterAnswerSheet) {
        masterAnswerSheet.questions.forEach(masterQuestion => {
          this.ensureMasterAnswerScore(masterAnswerSheet, masterQuestion);
        });
      },

      markAllEmptyTeamAnswersIncorrect(teamAnswerSheet, maybeMasterQuestion) {
        const emptyTeamAnswers = teamAnswerSheet.emptyTeamAnswers;
        emptyTeamAnswers.forEach(teamAnswer => {
          const questionId = teamAnswer.questionId;
          if ( (!maybeMasterQuestion) || (maybeMasterQuestion.id === questionId) ) {

            const masterAnswerScore = this.questionIdToMasterAnswerScore[
              questionId
            ];
            if (masterAnswerScore.isUnmarked) {
              masterAnswerScore.markIncorrect();
            }
          }
        });

        return emptyTeamAnswers.length;
      },

      markAllEmtpyTeamAnswersOnAnswerSheetIncorrect(masterAnswerSheet, teamAnswerSheet) {
        this.ensureMasterAnswerScores(masterAnswerSheet);
        this.markAllEmptyTeamAnswersIncorrect(teamAnswerSheet, null);
      },

      markEmtpyTeamAnswersForQuestionIncorrect(masterAnswerSheet, teamAnswerSheet, masterQuestion) {
        this.ensureMasterAnswerScores(masterAnswerSheet);
        this.markAllEmptyTeamAnswersIncorrect(teamAnswerSheet, masterQuestion);
      },

      autoMarkTeamAnswerSheet(masterAnswerSheet, teamAnswerSheet) {
        const confirmedTeamAnswers = teamAnswerSheet.confirmedTeamAnswers;
        confirmedTeamAnswers.forEach(teamAnswer => {
          const questionId = teamAnswer.questionId;
          const masterAnswerScore =
            this.questionIdToMasterAnswerScore[questionId];
          const masterQuestion = masterAnswerSheet.getQuestionById(questionId);

          if ( masterQuestion.isAnswerEntryTypeMultiChoice ) {
            masterAnswerScore.autoDetermineIsCorrect(masterQuestion, teamAnswer);
            return;
          }

          if ( masterAnswerScore.isMarked ) return;
          masterAnswerScore.autoMark(masterQuestion, teamAnswer);
        });

        return confirmedTeamAnswers.length;
      },

      autoMarkUnscoredAnswersOnTeamAnswerSheet(masterAnswerSheet, teamAnswerSheet) {
        this.ensureMasterAnswerScores(masterAnswerSheet);
        this.autoMarkTeamAnswerSheet(masterAnswerSheet, teamAnswerSheet);
      },

      currentTotalScore() {
        return this.masterAnswerScores.reduce(
          (total, masterAnswerScore) => total + masterAnswerScore.actualPointsAwarded,
          0,
        );
      },

      // Either questions individually revealed to teams, or if the
      // entire score sheet is available to the teams
      questionIdToTeamAnswerScoreFromMasterAnswerScoreSheet(
        isScoreSheetAvailableToTeams,
        masterQuestionStatusSheet,
        masterAnswerSheet,
      ) {
        const teamId = this.teamId;
        const questionIdToTeamAnswerScore = Object.fromEntries(
          Object
            .entries( this.questionIdToMasterAnswerScore )
            .filter(([ questionId ]) => {
              if ( isScoreSheetAvailableToTeams ) return true;

              const masterQuestion = masterAnswerSheet.getQuestionById(
                questionId,
              );
              if ( ! masterQuestion ) return false;
              return masterQuestionStatusSheet.isMasterQuestionRevealed(
                masterAnswerSheet,
                masterQuestion,
              );
            })
            .map(( [questionId, masterAnswerScore] ) => {
              return [
                questionId,
                ///JPL: new TeamAnswerScore, or rather asTeamAnswerScore
                {
                  teamId,
                  questionId,
                  isCorrect: masterAnswerScore.isCorrect,
                  pointsAwarded: masterAnswerScore.actualPointsAwarded,
                }
              ];
            }),
        );
        return questionIdToTeamAnswerScore;
      },
    };
  }
}
