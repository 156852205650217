'use strict';

import _ from 'lodash';

import ModelBase from '../model-base.js';
import Contributor from '../contributor.js';
import LibraryCategory from '../library/category.js';
import LibraryDifficulty from '../library/difficulty.js';
import LibraryTag from '../library/tag.js';

export default class LibraryClassification extends ModelBase {
  constructor({ contributors = [], libraryCategories = [], libraryDifficulties = [], libraryTags = [] } = {}) {
    super();
    return {
      ...this,

      ///JPL: unused atm, use this class in AdminLibraryQuestions?
      contributors: this.ensureArrayObjects(
        contributors,
        Contributor,
      ),
      libraryCategories: this.ensureArrayObjects(
        libraryCategories,
        LibraryCategory,
      ),
      libraryDifficulties: this.ensureArrayObjects(
        libraryDifficulties,
        LibraryDifficulty,
      ),
      libraryTags: this.ensureArrayObjects(
        libraryTags,
        LibraryTag,
      ),

      get orderedContributors() {
        return _.orderBy(this.contributors, ['moniker']);
      },

      getContributorById(contributorId) {
        return this.contributors.find(contributor => contributor.id === contributorId);
      },

      getLibraryCategoryById(libraryCategoryId) {
        return this.libraryCategories.find(libraryCategory => libraryCategory.id === libraryCategoryId);
      },
      getLibraryDifficultyById(libraryDifficultyId) {
        return this.libraryDifficulties.find(libraryDifficulty => libraryDifficulty.id === libraryDifficultyId);
      },
    };
  }
}
