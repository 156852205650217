
<script>

 import WithAnalytics from '@/components/mixins/WithAnalytics';
 import WithUserSession from '@/components/mixins/WithUserSession';
 import TopNavBar from '@/components/common/TopNavBar.vue';
 import BottomFooter from '@/components/common/BottomFooter.vue';

 import MasterAnswerSheet from '../../models/master/answer-sheet.js';
 import EditAnswerSheetQuestionList from '@/components/quizmaster/EditAnswerSheetQuestionList.vue';
 import EditAnswerSheetInstructions from '@/components/quizmaster/EditAnswerSheetInstructions.vue';
 import EditAnswerSheetTabQuizLibrary from '@/components/quizmaster/EditAnswerSheetTabQuizLibrary.vue';
 import EditQuestionMarkdownInstructions from '@/components/quizmaster/EditQuestionMarkdownInstructions.vue';
 import EditAnswerSheetImportQuestions from '@/components/quizmaster/EditAnswerSheetImportQuestions.vue';

 export default {
   name: 'EditAnswerSheet',
   mixins: [
     WithUserSession,
     WithAnalytics,
   ],
   components: {
     TopNavBar,
     BottomFooter,
     EditAnswerSheetQuestionList,
     EditAnswerSheetInstructions,
     EditAnswerSheetTabQuizLibrary,
     EditQuestionMarkdownInstructions,
     EditAnswerSheetImportQuestions,
   },
   props: [ 'loadAnswerSheetId' ],

   data() {
     return {
       answerSheet: MasterAnswerSheet.createEmpty(),

       quizLibraryTabActivated: false,
     }
   },

   async created() {
     await this.$store.dispatch('auth/ensureSession');

     if ( this.loadAnswerSheetId ) {
       this
         .loadAnswerSheetById(this.loadAnswerSheetId);
     }
     else {
       this.initDefaultAnswerSheet();
     }
   },

   computed: {

     hasUserAccount() {
       return this.userSession.hasUserAccount;
     },

     isNewAnswerSheet() {
       return ! this.loadAnswerSheetId;
     },

     questionCount() { return this.answerSheet.questions.length },

     showTipReturnKey() {
       if ( this.$root.isMobile ) return false; // No <Return> key on a phone

       // Show <Ctrl-Return> tip after a few questions, but not too long
       const count = this.answerSheet.questions.length;
       if ( count >= 3 && count <= 5) return true;

       return false;
     },
   },

   methods: {

     initDefaultAnswerSheet() {
       this.$set( this, 'answerSheet', MasterAnswerSheet.createDefault() );
     },

     loadAnswerSheetById(id) {
       ///JPL: show any error
       return this
         .$store
         .dispatch('masterAnswerSheet/fetchObject', { id })
         .then((answerSheet) => {
           ///JPL: if error, display 404, go back to main page
           this.$set( this, 'answerSheet', answerSheet || MasterAnswerSheet.createDefault() );
         })
         .catch((e) => {
           console.error(`API error: ${e})`);
           ///JPL: if error, display 404, go back to main page
           ///JPL: needed? We've got a default...
           this.initDefaultAnswerSheet();
         });
     },

     onFocusQuestionIndex(index, scrollBlock = "center") {
       const questionListComponent = this.$refs.EditAnswerSheetQuestionList;
       if ( ! questionListComponent ) return;
       questionListComponent.focusQuestionIndex(index, scrollBlock);
     },

     activateMyQuestionsTab() {
       this.$scrollTo( this.$refs.questionTabs.$el, 1200, { offset: -65 } );
       this.$refs.myQuestionsTab.activate();
     },

     ///JPL: rename to activate?
     browseQuizLibrary() {
       this.$scrollTo( this.$refs.questionTabs.$el, 1200, { offset: -20 } );

       // Wait a little but to avoid too jarring move
       // Consider doing a slow scroll down to here!
       setTimeout(
         () => this.$refs.libraryTab.activate(),
         1400,
       );
     },

     onQuestionActivateTab(newIndex) {
       if (newIndex === 1) {
         this.quizLibraryTabActivated = true;
       }
     },

     async borrowLibraryQuestions(libraryQuestions) {
       if ( libraryQuestions.length === 0 ) return;

       ///JPL: remove any empty question at end
       this.answerSheet.removeEmptyQuestions();


       ///JPL: filter out questions already in the answer sheet

       ///JPL: origin id or whatever
       libraryQuestions.forEach(
         libraryQuestion => this.answerSheet.addQuestion(libraryQuestion)
       );

       this.activateMyQuestionsTab();

       ///JPL: put added question in focus


       // Save the answer sheet, to avoid the QM losing the borrowed
       // questions
       const savedAnswerSheet = await this.$store.dispatch(
         'masterAnswerSheet/saveObject',
         this.answerSheet,
       );
       // If it was just created, now contains the new id
       this.$set(this, 'answerSheet', savedAnswerSheet);

       this.logSaveQuiz();

       const questionCount = libraryQuestions.length;
       this.$bvToast.toast(
         `You borrowed ${questionCount} question${this.maybePlural(questionCount)}`,
         {
           title: "Quiz Saved",
           variant: "success",
           toaster: "b-toaster-top-right",
         },
       );

     },

     logSaveQuiz() {
       this.logEvent('save_quiz', { event_category: 'setup', event_value: this.questionCount });
     },
   },
 }
</script>

<template>
  <div>
    <div
      class="pb-4"
      :class="{'is-friendly-bg': isNewAnswerSheet }"
    >
      <TopNavBar />
    </div>


    <div
      v-if="isNewAnswerSheet"
      class="is-friendly-bg w-100 pt-1 pb-4 mb-5 shadow-sm mt-0"
    >
      <b-container>

        <b-row class="mt-0">
          <b-col cols="12" lg="8">
            <h3 class="">Let's get started with your Quiz!</h3>

            <b-row class="mt-3">
              <b-col>
                <h5>Write your own questions</h5>
                <p>
                  You can enter questions of your own, or questions you've
                  researched on the Internet.
                </p>
              </b-col>

              <b-col>
                <h5>Borrow questions</h5>
                <p>
                  <span v-if="hasUserAccount">
                    You
                  </span>
                  <span v-else>
                    With an account you
                  </span>
                  can also borrow free questions from the
                  <strong>free Quiz Question Library</strong>.
                </p>

                <div class="text-center mt-5">
                  <b-button
                    @click.prevent="browseQuizLibrary"
                    size="md"
                    variant="primary"
                  >Browse Quiz Library</b-button>

                  <p class="mt-3">
                    <small>or enter your own questions below</small>
                  </p>
                </div>
              </b-col>
            </b-row>

          </b-col>

          <b-col cols="0" lg="4" class="d-none d-lg-block text-center pl-4">
            <img
              src="/unsplash/top/quyet-nguyen-xLcuNGBsY5k-unsplash-h28.jpg"
              alt="Researching a quiz question"
              class="is-vertical-z-image"
            />
          </b-col>
        </b-row>
      </b-container>
    </div>


    <b-container class="edit-answer-sheet">

      <b-row>
        <b-col cols="12" md="6">
          <h2>Quiz Details</h2>

          <b-form-group
            label="Quiz Name:"
            description='E.g. "Heroes and villains theme" or "Family quiz IV". Visible to the teams'
          >
            <b-form-input size="sm" autocomplete="off" v-model="answerSheet.name" type="text" />
          </b-form-group>

          <b-form-group
            label="Points for correct answer (default):"
            description="E.g. 1, 2, 0.5 . Can be set per question below (and later while marking the answers during the quiz)"
          >
            <b-form-input size="sm" v-model="answerSheet.defaultPoints" type="text" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <EditAnswerSheetInstructions />
        </b-col>

      </b-row>



      <b-tabs
        @activate-tab="onQuestionActivateTab($event)"
        ref="questionTabs"
        justified class="mt-4"
      >
        <b-tab
          ref="myQuestionsTab"
        >
          <template #title>
            <strong>My Questions</strong>
          </template>

          <EditAnswerSheetQuestionList
            ref="EditAnswerSheetQuestionList"
            :answerSheet="answerSheet"
            class="mt-3"
          />
        </b-tab>

        <b-tab
          ref="libraryTab"
        >
          <template #title>
            <strong>Quiz Question Library</strong>
          </template>

          <EditAnswerSheetTabQuizLibrary
            :quizLibraryTabActivated="quizLibraryTabActivated"
            @activate-tab-my-questions="activateMyQuestionsTab"
            @borrow-library-questions="borrowLibraryQuestions($event)"
          />

        </b-tab>

        <b-tab
          ref="importTab"
        >
          <template #title>
            <strong>Import Questions</strong>
          </template>

          <EditAnswerSheetImportQuestions
            :answerSheet="answerSheet"
            @activate-tab-my-questions="activateMyQuestionsTab"
            @focus-question-index="onFocusQuestionIndex(...$event)"
            @import-library-questions="importLibraryQuestions($event)"
          />

        </b-tab>
      </b-tabs>


      <b-sidebar
        id="sidebar-markdown-instructions"
        title="Markdown Help"
        shadow
        right
        width="35rem"
        backdrop
        backdrop-variant="dark"
      >
        <EditQuestionMarkdownInstructions />
      </b-sidebar>

      <!-- some whitespce at the bottom  -->
      <b-row class="pt-5"><b-col> </b-col></b-row>

    </b-container>

    <BottomFooter />

    <!-- Spacer to keep the bottom of the page off the Save alert -->
    <div class="bottom-button-bar-height"></div>
  </div>
</template>

<style scoped>

 h3 {
   font-size: 1.5em;
 }


 .is-friendly-bg {
   background: #FFF3EC;
 }
 .is-friendly-panel {
   border-bottom-left-radius: 0.7rem;
   border-bottom-right-radius: 0.7rem;
 }

 .is-vertical-z-image {
   border: 1px solid #555;
   border-radius: 5px;
   height: 20rem;
 }

 .is-vertical-library-image {
   border: 1px solid #555;
   border-radius: 5px;
   height: 16rem;
 }
 .is-horizontal-library-image {
   border: 1px solid #555;
   border-radius: 5px;
   width: 18rem;
 }

</style>
