<script>

 import SessionStatusBadge from '@/components/elements/SessionStatusBadge.vue';

 export default {
   name: 'AnswerSheetListItemSessionItem',
   components: {
     SessionStatusBadge,
   },
   props: [
     'answerSheet',
     'session',
   ],

   methods: {
     sessionTeamTeamsJoined() {
       const teams = this.session.maybeTeamTeams || [];
       return teams.map(t => t.name).join(", ");
     },

     sessionTeamCount() {
       const teams = this.session.maybeTeamTeams || [];
       return teams.length;
     },

     isSessionNotStartedWithoutTeams() {
       if ( ! this.session.sessionStatus.isNotStarted ) return false;
       return ! this.sessionTeamCount();
     },

     isSessionNotStartedWithTeams() {
       if ( ! this.session.sessionStatus.isNotStarted ) return false;
       return !! this.sessionTeamCount();
     },
   }
 }
</script>

<template>
  <li class="mt-2">
    <SessionStatusBadge :sessionStatus="session.sessionStatus" :teamCount="sessionTeamCount()" class="mr-2" />

    <router-link
      :to="{ path: `/quizmaster/answer-sheet/${answerSheet.id}/run-quiz/${session.id}` }"
      class="ml-2"
    >
      <b-button
        v-if="session.sessionStatus.isInProgress"
        size="sm"
        variant="primary"
      >
        <font-awesome-icon :icon="['far', 'play-circle']" />
        Continue Quiz
      </b-button>
    </router-link>

    <router-link
      class="ml-1"
      :to="{ path: `/quizmaster/answer-sheet/${answerSheet.id}/invite-team/${session.id}` }"
    >
      <b-button
        v-if="isSessionNotStartedWithoutTeams()"
        size="sm"
        variant="primary"
        class="mt-2 mt-sm-0"
      >
        <font-awesome-icon :icon="['fas', 'user-friends']" class="mr-1" />
        Invite teams &amp;
        <font-awesome-icon :icon="['far', 'play-circle']" class="ml-1" />
        Start Quiz
      </b-button>
      <b-button
        v-else-if="isSessionNotStartedWithTeams()"
        size="sm"
        variant="primary"
        class="mt-2 mt-sm-0"
      >
        <font-awesome-icon :icon="['fas', 'user-friends']" class="mr-1" />
        Invite more teams &amp;
        <font-awesome-icon :icon="['far', 'play-circle']" class="ml-1" />
        Start Quiz
      </b-button>
      <b-button
        v-else-if="session.sessionStatus.isInProgress"
        size="sm"
        variant="outline-secondary"
        class="mt-2 mt-sm-0"
      >
        <font-awesome-icon :icon="['fas', 'user-friends']" class="mr-1" />
        Invite more teams
      </b-button>
      <b-button
        v-else
        size="sm"
        variant="outline-secondary"
        class="mt-2 mt-sm-0"
      >
        <font-awesome-icon :icon="['fas', 'list-ol']" class="mr-1" />
        View Result
      </b-button>
    </router-link>

    <p
      v-if="sessionTeamTeamsJoined()"
      class="mt-2"
    >
      Teams:
      <span class="teams">{{ sessionTeamTeamsJoined() }}</span>
    </p>
  </li>
</template>

<style scoped>

</style>
