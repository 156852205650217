
import axios from '@/store/axios';
import LibraryQuestion from '../../../models/library/question.js';
import LibraryAllowances from '../../../models/library/allowances.js';
import LibraryCategory from '../../../models/library/category.js';
import LibraryDifficulty from '../../../models/library/difficulty.js';
import LibraryTag from '../../../models/library/tag.js';

function newLibraryQuestions(libraryQuestions) {
  if ( ! libraryQuestions ) return [];

  const libraryQuestionObjects = libraryQuestions.map(libraryQuestionData => {
    const newLibraryQuestion = new LibraryQuestion(libraryQuestionData);
    return newLibraryQuestion;
  });

  return libraryQuestionObjects;
}

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {

    async fetchRandomList({ dispatch }) {
      const userSession = await dispatch('auth/ensureSession', {}, { root: true });

      return await axios.get(`/api/user-session/${userSession.id}/library/question/random`)
        .then(res => {
          return {
            libraryQuestions: newLibraryQuestions(res.data.libraryQuestions),
            libraryAllowances: new LibraryAllowances(res.data.libraryAllowances),
            libraryCategories: res.data.libraryCategories.map(
              libraryCategory => new LibraryCategory(libraryCategory),
            ),
            libraryDifficulties: res.data.libraryDifficulties.map(
              libraryDifficulty => new LibraryDifficulty(libraryDifficulty),
            ),
            libraryTags: res.data.libraryTags.map(
              libraryTag => new LibraryTag(libraryTag),
            ),
          };
        });
      ///JPL: catch
      ///JPL: error if not an account
    },

    async borrowQuestionIds({ dispatch }, { libraryQuestionIds }) {
      const userSession = await dispatch('auth/ensureSession', {}, { root: true });

      return await axios.post(
        `/api/user-session/${userSession.id}/library/question/borrow`,
        { libraryQuestionIds }
      )
        .then(res => {
          return {
            libraryQuestions: newLibraryQuestions(res.data.libraryQuestions),
            libraryAllowances: new LibraryAllowances(res.data.libraryAllowances),
          };
        });
      ///JPL: catch
      ///JPL: error if not an account
    },
  },

};
