
<script>
 export default {
   name: 'BottomFooter',
 };
</script>

<template>
  <div class="is-bottom-links w-100 mt-4">
    <b-container fluid class="pt-3 pb-4">
      <b-row class="pt-2">
        <b-col cols="8" offset="2" class="text-center">
          <small>
            <router-link to="/contact">Contact</router-link>
            |
            <router-link to="/about">About</router-link>
            |
            <router-link to="/privacy-policy">Privacy Policy</router-link>
            |
            <router-link to="/terms-and-conditions">Terms &amp; Conditions</router-link>
          </small>
        </b-col>
        <b-col cols="2" class="text-right">
          <span class="mr-2">
            <a
              href="https://twitter.com/RemotelyPubQuiz"
              target="_blank"
              rel="nofollow"
              title="Twitter @RemotelyPubQuiz"
            >
              <font-awesome-icon :icon="['fab', 'twitter']" />
            </a>
          </span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>

 .is-bottom-links {
   background: #55423D;
   color: #fff;
 }

 .is-bottom-links a {
   color: #fff;
 }

</style>
