
<script>

 import CopyableUrl from '@/components/share/CopyableUrl';
 import TeamGameInstructions from '@/components/team/TeamGameInstructions';

 export default {
   name: "TeamGameWaiting",
   components: {
     CopyableUrl,
     TeamGameInstructions,
   },

   props: [
     'teamTeam',
     'teamSession',
     'isBrowserWriter',
   ],

   computed: {
     teamUrl() {
       return this.teamTeam.teamUrl;
     },
     teamUrlLabel() {
       return `'${this.teamTeam.name}' team URL:`;
     },

     teamNames() {
       return this.teamSession.teamNames;
     },
   },

   methods: {
   },

 }
</script>

<template>
  <b-container>
    <div v-if="teamSession">
      <b-row class="mt-5">

        <b-col cols="12" md="5">
          <h3>Teams</h3>
          <transition-group tag="ul" class="mb-4" name="team-transition">
            <li v-for="(teamName, index) in teamNames" :key="teamName + index">
              {{teamName}}
            </li>
          </transition-group>

          <div v-if="teamNames.length <= 1">
            <p class="mt-3">Waiting for other teams to join...</p>
          </div>

        </b-col>

        <b-col cols="12" md="7">
          <TeamGameInstructions
            header="When the Quiz starts"
            :isBrowserWriter="isBrowserWriter"
          />
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col cols="12" lg="8" offset-lg="2">
          <p>
            You can <i>bookmark this page</i>, or <i>remember</i> the secret URL to come back here:
            <CopyableUrl class="mt-1" :url="teamUrl">
              Copy team URL!
            </CopyableUrl>
          </p>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<style scoped>

 .team-transition-enter-active {
   transition: opacity 3s ease;
 }

 .team-transition-enter {
   opacity: 0
 }

 .team-transition-move {
   transition: transform 1s ease;
 }

</style>
