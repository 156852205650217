
<script>

 import WithAnalytics from '@/components/mixins/WithAnalytics';
 import TopNavBar from '@/components/common/TopNavBar.vue';
 import BottomFooter from '@/components/common/BottomFooter.vue';
 import Quiz from '../../models/master/quiz';
 import MasterQuestionsMetadata from '../../models/master/questions-metadata';
 import TeamAnswerSheet from '../../models/team/answer-sheet';

 import LoadingSpinner from '@/components/share/LoadingSpinner';
 import StatusActionsAskQuestions from '@/components/quizmaster/run/StatusActionsAskQuestions.vue';
 import StatusActionsConfirmAnswers from '@/components/quizmaster/run/StatusActionsConfirmAnswers.vue';
 import StatusActionsMarkAnswers from '@/components/quizmaster/run/StatusActionsMarkAnswers.vue';
 import StatusActionsRevealScoring from '@/components/quizmaster/run/StatusActionsRevealScoring.vue';
 import StatusActionsRevealLeaderboard from '@/components/quizmaster/run/StatusActionsRevealLeaderboard.vue';
 import StatusActionsFinished from '@/components/quizmaster/run/StatusActionsFinished.vue';
 import ScoringGrid from '@/components/quizmaster/run/ScoringGrid.vue';
 import QuestionsMetadataCard from '@/components/quizmaster/run/QuestionsMetadataCard.vue';
 import StatusProgress from '@/components/quizmaster/run/StatusProgress.vue';
 import WithSessionTeams from '@/components/quizmaster/mixins/WithSessionTeams';
 import WithSocketIo from '@/components/mixins/WithSocketIo';

 export default {
   name: 'MasterRunQuiz',
   mixins: [
     WithAnalytics,
     WithSessionTeams,
     WithSocketIo,
   ],
   components: {
     TopNavBar,
     BottomFooter,
     LoadingSpinner,
     StatusActionsAskQuestions,
     StatusActionsConfirmAnswers,
     StatusActionsMarkAnswers,
     StatusActionsRevealScoring,
     StatusActionsRevealLeaderboard,
     StatusActionsFinished,
     StatusProgress,
     ScoringGrid,
     QuestionsMetadataCard,
   },

   props: [
     // WithSessionTeams: loadAnswerSheetId, loadSessionId
   ],
   data() {
     const emptyMasterQuestionsMetadata = new MasterQuestionsMetadata({
       masterQuestionStatusSheet: null,
       masterAnswerScoreSheets: [],
       teamAnswerSheets: [],
     });
     return {
       // WithSessionTeams: answerSheet, session, teamTeams

       quiz: null,
       selectedQuestion: null,   // typically not null
       selectedTeamAnswer: null, // can be null
       // properly populated when quiz is populated
       masterQuestionsMetadata: emptyMasterQuestionsMetadata,
     };
   },

   created() {
     this.loadEveryting();
   },

   mounted() {

     const io = this.socketIo;

     io.on("teamAnswerSheetsChanged", (message) =>  {
       console.log('on teamAnswerSheetsChanged', message);
       const teamAnswerSheets = message.teamAnswerSheets;
       const newSessionStatus = message.newSessionStatus; // or null
       if (! teamAnswerSheets) {
         return;
       }

       if ( ! this.quiz ) {
         // If we don't have a quiz yet this happens on page load,
         // this is for when we reconnect and get sent the first catchup
         // message
         return;
       }

       this.setTeamAnswerSheets(
         teamAnswerSheets.map(teamAnswerSheet => new TeamAnswerSheet(teamAnswerSheet)),
       );

       if ( newSessionStatus === "markAnswers" ) {
         this.quiz.markAllQuestionsEmptyTeamAnswersIncorrect();
       }
       else {
         this.quiz.markRevealedQuestionsEmptyTeamAnswersIncorrect();
       }

       this.quiz.autoMarkTeamAnswers();

       // If team unconfirmed answer, the
       // masterAnswerScoreSheet.answerScore.isCorrect was reset to
       // null
       this.saveMasterAnswerScoreSheets();

       if ( this.quiz.didNewTeamArrive() ) {
         this.reloadEveryting();
       }
     });

     io.on('connect', () => {
       io.emit(
         'masterSubscribeToSessionAnswerSheets',
         {
           sessionId: this.loadSessionId,
           ///JPL: auth token
         },
       );

       // In case of reconnect, the QM may have done edits. This
       // sends the current state to the server
       this.saveAllState();
     });
   },

   watch: {
     // Watch this and update the aggregated metadata about questions
     // (difficulty, popularity)
     'quiz.masterAnswerScoreSheets': {
       handler: function() {
         this.updateMasterQuestionsMetadataFromQuiz();
       },
       deep: true,
     },
     'quiz.teamAnswerSheets': {
       handler: function() {
         this.updateMasterQuestionsMetadataFromQuiz();
       },
       deep: true,
     },
   },

   computed: {
     hasUnmarkedAnswers() {
       const masterQuiz = this.quiz;
       if ( ! masterQuiz ) return false;
       return !! this.quiz.unmarkedAnswerCount;
     },
     isCurrentQuestionTheLastOne() {
       return this.quiz.isCurrentQuestionTheLastOne;
     },
     showContinueMarkingTip() {
       if ( this.session.sessionStatus.isRevealingScoring ) return true;
       if ( this.session.sessionStatus.isRevealingLeaderboard ) return true;
       return false;
     },
   },

   methods: {

     reloadEveryting() {
       return this.loadEveryting();
     },

     loadEveryting() {
       return this
         .loadAnswerSheetSessionsTeamsByPropsIds()
         .then(() => {
           if ( this.session.sessionStatus.isNotStarted ) {
             return;
           }

           ///JPL: also load the teamAnswerSheet
           Promise.all([
             this.$store.dispatch(
               'masterQuestionStatusSheet/loadObject',
               { sessionId: this.session.id },
             ),
             this.$store.dispatch(
               'masterAnswerScoreSheet/loadObjects',
               { sessionId: this.session.id },
             ),
             this.$store.dispatch(
               'masterTeamAnswerSheet/loadObjects',
               { sessionId: this.session.id },
             ),
           ]).then(([ masterQuestionStatusSheet, masterAnswerScoreSheets, teamAnswerSheets ]) => {
             this.$set(
               this, 'quiz', Quiz.createWithTeams({
                 teamTeams: this.teamTeams,
                 masterAnswerSheet: this.answerSheet,
                 session: this.session,
                 masterQuestionStatusSheet,
                 masterAnswerScoreSheets,
                 teamAnswerSheets,
               }),
             );
             this.updateMasterQuestionsMetadataFromQuiz();

             // In case of re-entering this page when the quiz is in progress
             this.quiz.autoMarkTeamAnswers();
           });

           ///JPL: then focus on current question

         });
     },

     async startSession() {
       if ( ! this.quiz ) {
         this.$set(
           this, 'quiz', Quiz.createWithTeams({
             teamTeams: this.teamTeams,
             masterAnswerSheet: this.answerSheet,
             session: this.session,
           }),
         );
         this.updateMasterQuestionsMetadataFromQuiz();

         this.logStartSession();
       }


       if ( this.quiz.maybeStart() ) {
         await this.saveAllState();
       }
     },

     async saveAllState() {
       if ( ! this.session ) return;
       await this.saveSession();

       if ( ! this.quiz ) return;
       await this.saveQuizAnswerSheet(); // Don't use the response
       await this.saveMasterQuestionStatusSheet();
       await this.saveMasterAnswerScoreSheets();
       await this.saveTeamAnswerSheets();
     },

     saveQuizAnswerSheet() {
       ///JPL: only do this if the answerSheet isDirty, i.e. if there are
       // new questions added (or maybe if the question/points have
       // changed)
       return this
         .$store
         .dispatch('masterAnswerSheet/saveObject', this.quiz.masterAnswerSheet);
     },

     saveSession() {
       return this.$store.dispatch('masterSession/saveObject', this.session);
     },

     saveMasterQuestionStatusSheet() {
       return this.$store.dispatch(
         'masterQuestionStatusSheet/saveObject',
         {
           sessionId: this.session.id,
           masterQuestionStatusSheet: this.quiz.masterQuestionStatusSheet,
         }
       );
     },

     saveMasterAnswerScoreSheets() {
       return this.$store.dispatch(
         'masterAnswerScoreSheet/saveObjects',
         {
           sessionId: this.session.id,
           masterAnswerScoreSheets: this.quiz.masterAnswerScoreSheets,
         }
       );
     },

     setTeamAnswerSheets(teamAnswerSheets) {
       this.$set(this.quiz, 'teamAnswerSheets', teamAnswerSheets);
       this.quiz.syncMasterAnswerScoreSheets();
     },

     saveTeamAnswerSheets() {
       return this.$store.dispatch(
         'masterTeamAnswerSheet/saveObjects',
         {
           sessionId: this.session.id,
           teamAnswerSheets: this.quiz.teamAnswerSheets,
         }
       );
     },

     ///JPL: remove (if used, replace with the websocket event)
     async reloadTeamAnswerSheets() {
       const teamAnswerSheets = await this.$store.dispatch(
         'masterTeamAnswerSheet/loadObjects',
         { sessionId: this.session.id },
       );

       this.setTeamAnswerSheets(teamAnswerSheets);
     },

     updateMasterQuestionsMetadataFromQuiz() {
       if ( ! this.quiz ) return;
       this.$set(
         this, 'masterQuestionsMetadata',
         this.quiz.newMasterQuestionsMetadata(),
       );
     },



     // // Status Panel buttons

     // Ask Questions
     clickAskNextQuestion() {
       this.quiz.askNextQuestion();
       this.saveQuizAnswerSheet(); // Don't use the response
       ///JPL: needed? this.saveMasterQuestionStatusSheet();
       this.saveMasterAnswerScoreSheets();
       this.saveSession();
       this.$nextTick(() => {
         const refs = this.$refs.scoringGrid.$refs.currentQuestionCell;
         const currentQuestionCell = Array.isArray(refs) ? refs[0] : refs;
         if (currentQuestionCell) {
           this.scrollEntirelyIntoView(
             currentQuestionCell.$el,
             {
               behavior: 'smooth',
               inline: 'end',
               block: 'start',
             }
           );
         }
       });
       this.logAskNextQuestion();
     },

     clickFinishQuestions() {
       this.quiz.finishQuestions();
       this.saveSession();
       this.logSubmitAnswers();
     },

     clickAskMoreQuestions() {
       this.quiz.askMoreQuestions();
       this.saveSession();
       this.logAskMoreQuestions();
     },

     clickMarkAnswers() {
       this.quiz.markAnswers();
       this.saveSession();
       this.saveMasterAnswerScoreSheets();
       this.logMarkAnswers();
     },

     clickRevealScoring() {
       // Select first Question, so it can be read back to the teams
       this.selectFirstQuestion();
       this.quiz.revealScoring();
       this.saveSession();
       this.logRevealScoring();
     },

     clickRevealLeaderboard() {
       this.quiz.revealLeaderboard();
       this.saveSession();
       this.logRevealLeaderboard();
     },

     clickFinishQuiz() {
       this.quiz.finishQuiz();
       this.saveSession();
       this.logFinishQuiz();
     },

     clickDone() {
       this.$router.push({ name: "HomePage" });
     },

     withScoringGridComponent(fn) {
       const scoringGridComponent = this.$refs.scoringGrid;
       if ( ! scoringGridComponent ) return;
       return fn.call(this, scoringGridComponent);
     },

     selectFirstQuestion() {
       this.withScoringGridComponent(
         scoringGridComponent => scoringGridComponent.selectFirstQuestion(),
       );
     },



     // Events
     onMasterAnswerScoreChanged() {
       this.saveMasterAnswerScoreSheets();
     },

     onSelectPreviousUnscoredAnswer() {
       this.withScoringGridComponent(
         scoringGridComponent => scoringGridComponent.selectPreviousUnscoredAnswer(),
       );
     },
     onSelectNextUnscoredAnswer() {
       this.withScoringGridComponent(
         scoringGridComponent => scoringGridComponent.selectNextUnscoredAnswer(),
       );
     },

     onSelecCurrentQuestion() {
       this.withScoringGridComponent(
         scoringGridComponent => scoringGridComponent.selectCurrentQuestion(),
       );
     },

     onSelecPreviousQuestion() {
       this.withScoringGridComponent(
         scoringGridComponent => scoringGridComponent.selectPreviousQuestion(),
       );
     },

     onSelectNextQuestion() {
       this.withScoringGridComponent(
         scoringGridComponent => scoringGridComponent.selectNextQuestion(),
       );
     },

     onKeyLeft() {
       //TODO: skip moving if focus is in a text field
       this.onSelecPreviousQuestion();
     },

     onKeyRight() {
       //TODO: skip moving if focus is in a text field
       this.onSelectNextQuestion();
     },

     onKeyUp() {
       this.withScoringGridComponent(
         scoringGridComponent => scoringGridComponent.selectAnswerAbove(),
       );
     },
     onKeyDown() {
       this.withScoringGridComponent(
         scoringGridComponent => scoringGridComponent.selectAnswerBelow(),
       );
     },

     onMarkCorrect() {
       this.scoreSelectedMasterAnswerScore(
         function (masterAnswerScore) { masterAnswerScore.markCorrect() },
       );
       this.onSelectNextUnscoredAnswer();
     },

     onMarkIncorrect() {
       this.scoreSelectedMasterAnswerScore(
         function (masterAnswerScore) { masterAnswerScore.markIncorrect() },
       );
       this.onSelectNextUnscoredAnswer();
     },

     async onQuestionStatusSheetChanged() {
       return await this.saveMasterQuestionStatusSheet();
     },
     scoreSelectedMasterAnswerScore(markMasterAnswerScoreFunction) {
       const teamAnswer = this.selectedTeamAnswer;
       if ( ! teamAnswer ) return;
       const masterAnswerScore = this.quiz.masterAnswerScoreForTeamAnswer(teamAnswer);
       markMasterAnswerScoreFunction.call(this, masterAnswerScore);
       this.onMasterAnswerScoreChanged();
     },

     logStartSession() {
       this.logEvent('ask_questions', { event_category: 'run', event_label: "action" });
     },
     logAskNextQuestion() {
       this.logEvent('ask_next_question', { event_category: 'run', event_value: this.quiz.currentQuestionNumber });
     },
     logSubmitAnswers() {
       this.logEvent('submit_answers', { event_category: 'run', event_label: "action" });
     },
     logAskMoreQuestions() {
       this.logEvent('ask_more_questions', { event_category: 'run', event_label: "action" });
     },
     logMarkAnswers() {
       this.logEvent('mark_answers', { event_category: 'run', event_label: "action" });
     },
     logRevealScoring() {
       this.logEvent('reveal_scoring', { event_category: 'run', event_label: "action" });
     },
     logRevealLeaderboard() {
       this.logEvent('reveal_leaderboard', { event_category: 'run', event_label: "action" });
     },
     logFinishQuiz() {
       this.logEvent('finish_quiz', { event_category: 'run', event_label: "action" });
     },
   },
 }
</script>

<template>
  <div class="is-footer-flex-container">
    <TopNavBar />
    <b-container fluid class="is-footer-flex-content">
      <div v-if="answerSheet && session">
        <div class="d-lg-flex">

          <div class="d-inline-block">
            <router-link :to="{ path: `/quizmaster/answer-sheet/${answerSheet.id}/invite-team/${session.id}` }">
              <b-button
                size="sm"
                variant="outline-primary"
                class="ml-1 mb-2"
                title="Back to the Lobby, where you can invite or remove teams"
              >
                <span class="text-nowrap">
                  ↩ <font-awesome-icon :icon="['fas', 'user-friends']" class="mr-1" />
                  Back to Lobby
                </span>
              </b-button>
            </router-link>
          </div>
          <div class="d-inline-block status-progress">
            <StatusProgress :sessionStatus="session.sessionStatus" class="ml-1" />
          </div>
        </div>


        <b-row v-if="session.sessionStatus.isNotStarted">
          <b-col class="text-center mt-5 pt-5">
            <b-button @click.prevent="startSession()" size="lg" variant="primary">Ask First Question!</b-button>
          </b-col>
        </b-row>


        <div v-if="quiz" class="mt-2">

          <!-- c Nope -->
          <!-- <KeyPress :key-code="67" event="keydown" :preventDefault="true" @pressed="onSelecCurrentQuestion()" /> -->
          <!-- p -->
          <KeyPress :key-code="80" event="keydown" :preventDefault="true" @pressed="onSelecPreviousQuestion()" />
          <!-- n -->
          <KeyPress :key-code="78" event="keydown" :preventDefault="true" @pressed="onSelectNextQuestion()" />

          <KeyPress :key-code="37" event="keydown" :preventDefault="true" @pressed="onKeyLeft()" />
          <KeyPress :key-code="39" event="keydown" :preventDefault="true" @pressed="onKeyRight()" />

          <KeyPress :key-code="38" event="keydown" :preventDefault="true" @pressed="onKeyUp()" />
          <KeyPress :key-code="40" event="keydown" :preventDefault="true" @pressed="onKeyDown()" />

          <!-- q -->
          <KeyPress :key-code="81" event="keydown" @pressed="onSelectPreviousUnscoredAnswer()" />
          <!-- w -->
          <KeyPress :key-code="87" event="keydown" @pressed="onSelectNextUnscoredAnswer()" />

          <!-- a -->
          <KeyPress :key-code="65" event="keydown" :preventDefault="true" @pressed="onMarkCorrect()" />
          <!-- z -->
          <KeyPress :key-code="90" event="keydown" :preventDefault="true" @pressed="onMarkIncorrect()" />


          <div v-if="session.sessionStatus.isAskingQuestions">
            <StatusActionsAskQuestions
              :quiz="quiz"
              :isCurrentQuestionTheLastOne="isCurrentQuestionTheLastOne"
              @finish-questions="clickFinishQuestions()"
            />

            <ScoringGrid
              :quiz="quiz"
              :masterQuestionsMetadata="masterQuestionsMetadata"
              :selectedQuestion.sync="selectedQuestion"
              :selectedTeamAnswer.sync="selectedTeamAnswer"
              :isCurrentQuestionTheLastOne="isCurrentQuestionTheLastOne"
              :showUnscoredNavButtons="true"
              :showFirstQuestionButton="false"
              :showCurrentQuestion="true"
              :showQuestionDifficulty="false"
              @ask-next-question="clickAskNextQuestion()"
              @finish-questions="clickFinishQuestions()"
              @master-answer-score-changed="onMasterAnswerScoreChanged()"
              @question-status-sheet-changed="onQuestionStatusSheetChanged"
              ref="scoringGrid"
            />
          </div><!-- if isAskingQuestions -->


          <div v-if="session.sessionStatus.isConfirmingAnswers">
            <StatusActionsConfirmAnswers
              :quiz="quiz"
              @mark-answers="clickMarkAnswers()"
              @ask-more-questions="clickAskMoreQuestions()"
            />

            <ScoringGrid
              :quiz="quiz"
              :masterQuestionsMetadata="masterQuestionsMetadata"
              :selectedQuestion.sync="selectedQuestion"
              :selectedTeamAnswer.sync="selectedTeamAnswer"
              :showUnscoredNavButtons="true"
              :showFirstQuestionButton="false"
              :showCurrentQuestion="false"
              :showQuestionDifficulty="false"
              @master-answer-score-changed="onMasterAnswerScoreChanged()"
              @question-status-sheet-changed="onQuestionStatusSheetChanged"
              ref="scoringGrid"
            />
          </div><!-- if isConfirmingAnswers -->


          <div v-if="session.sessionStatus.isMarkingAnswers">
            <StatusActionsMarkAnswers
              :quiz="quiz"
              @reveal-scoring="clickRevealScoring()"
              @ask-more-questions="clickAskMoreQuestions()"
            />

            <ScoringGrid
              :quiz="quiz"
              :masterQuestionsMetadata="masterQuestionsMetadata"
              :selectedQuestion.sync="selectedQuestion"
              :selectedTeamAnswer.sync="selectedTeamAnswer"
              :showUnscoredNavButtons="true"
              :showFirstQuestionButton="true"
              :showCurrentQuestion="false"
              :showQuestionDifficulty="false"
              @master-answer-score-changed="onMasterAnswerScoreChanged()"
              @question-status-sheet-changed="onQuestionStatusSheetChanged"
              ref="scoringGrid"
            />
          </div><!-- if isMarkingAnswers -->


          <div v-if="session.sessionStatus.isRevealingScoring">
            <StatusActionsRevealScoring
              :quiz="quiz"
              @reveal-leaderboard="clickRevealLeaderboard()"
            />

            <ScoringGrid
              :quiz="quiz"
              :masterQuestionsMetadata="masterQuestionsMetadata"
              :showTeamPlacements="true"
              :selectedQuestion.sync="selectedQuestion"
              :selectedTeamAnswer.sync="selectedTeamAnswer"
              :showUnscoredNavButtons="false"
              :showFirstQuestionButton="true"
              :showCurrentQuestion="false"
              :showQuestionDifficulty="true"
              @master-answer-score-changed="onMasterAnswerScoreChanged()"
              ref="scoringGrid"
            />
          </div><!-- if isRevealingScoring -->


          <div v-if="session.sessionStatus.isRevealingLeaderboard">
            <StatusActionsRevealLeaderboard
              :quiz="quiz"
              @finish-quiz="clickFinishQuiz()"
            />

            <QuestionsMetadataCard
              :quiz="quiz"
              :masterQuestionsMetadata="masterQuestionsMetadata"
              class="mt-2"
            />

            <ScoringGrid
              :quiz="quiz"
              :masterQuestionsMetadata="masterQuestionsMetadata"
              :showTeamPlacements="true"
              :selectedQuestion.sync="selectedQuestion"
              :selectedTeamAnswer.sync="selectedTeamAnswer"
              :showUnscoredNavButtons="false"
              :showFirstQuestionButton="true"
              :showCurrentQuestion="false"
              :showQuestionDifficulty="true"
              @master-answer-score-changed="onMasterAnswerScoreChanged()"
              ref="scoringGrid"
            />
          </div><!-- if isRevealingLeaderboard -->


          <div v-if="session.sessionStatus.isFinished">
            <StatusActionsFinished
              :quiz="quiz"
              @done="clickDone()"
            />

            <QuestionsMetadataCard
              :quiz="quiz"
              :masterQuestionsMetadata="masterQuestionsMetadata"
              class="mt-2"
            />

            <ScoringGrid
              :quiz="quiz"
              :masterQuestionsMetadata="masterQuestionsMetadata"
              :showTeamPlacements="true"
              :selectedQuestion.sync="selectedQuestion"
              :showUnscoredNavButtons="false"
              :showCurrentQuestion="false"
              :showQuestionDifficulty="true"
              @master-answer-score-changed="onMasterAnswerScoreChanged()"
              ref="scoringGrid"
            />
          </div><!-- if isFinished -->


          <b-row class="mt-2">
            <b-col>
              <div v-if="hasUnmarkedAnswers && selectedTeamAnswer">
                <NoteBadge><font-awesome-icon :icon="[ 'far', 'lightbulb' ]" /> Keyboard shortcut</NoteBadge>
                <!-- Select <kbd>Q</kbd> <strong>previous</strong>, or <kbd>W</kbd> <strong>next</strong> unmarked answer. -->
                Mark selected answer <kbd>A</kbd> <strong>correct</strong>, or <kbd>Z</kbd> <strong>incorrect</strong> and jump to the next unmarked.
              </div>

              <div v-if="showContinueMarkingTip">
                <NoteBadge><font-awesome-icon :icon="[ 'far', 'lightbulb' ]" /> Pro-tip</NoteBadge>
                You can <strong>still</strong> change the scoring if people moan about their answers. They always moan.
              </div>
            </b-col>
          </b-row>


        </div><!-- if quiz -->


      </div>
      <LoadingSpinner v-else /><!-- if session // Loading screen -->
    </b-container>

    <BottomFooter />
  </div>
</template>

<style>

 div.actions-buttons {
   height: 4.5rem;
 }

 .status-progress {
   margin-left: auto;
 }

</style>
