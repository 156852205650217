
import axios from '@/store/axios';
import LibraryQuestion from '../../../models/library/question.js';
import Contributor from '../../../models/contributor.js';
import LibraryCategory from '../../../models/library/category.js';
import LibraryDifficulty from '../../../models/library/difficulty.js';
import LibraryTag from '../../../models/library/tag.js';

function newLibraryQuestions(libraryQuestions) {
  if ( ! libraryQuestions ) return [];

  const libraryQuestionObjects = libraryQuestions.map(libraryQuestionData => {
    const newLibraryQuestion = new LibraryQuestion(libraryQuestionData);
    return newLibraryQuestion;
  });

  return libraryQuestionObjects;
}

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {

    async fetchList({ dispatch }) {
      const userSession = await dispatch('auth/ensureSession', {}, { root: true });

      const res = await axios.get(`/api/user-session/${userSession.id}/admin/library/question`);
      return {
        libraryQuestions: newLibraryQuestions(res.data.libraryQuestions),
        contributors: res.data.contributors.map(
          contributor => new Contributor(contributor),
        ),
        libraryCategories: res.data.libraryCategories.map(
          libraryCategory => new LibraryCategory(libraryCategory),
        ),
        libraryDifficulties: res.data.libraryDifficulties.map(
          libraryDifficulty => new LibraryDifficulty(libraryDifficulty),
        ),
        libraryTags: res.data.libraryTags.map(
          libraryTag => new LibraryTag(libraryTag),
        ),
      };
      ///JPL: catch
      ///JPL: error if not an account
    },

    async createObject({ dispatch }, libraryQuestion ) {
      const userSession = await dispatch('auth/ensureSession', {}, { root: true });
      const res = await axios.post(
        `/api/user-session/${userSession.id}/admin/library/question`,
        { libraryQuestion },
      );

      return new LibraryQuestion( res.data.libraryQuestion );
    },

    async saveObject({ dispatch }, libraryQuestion ) {
      const userSession = await dispatch('auth/ensureSession', {}, { root: true });
      const res = await axios.post(
        `/api/user-session/${userSession.id}/admin/library/question/${libraryQuestion.id}`,
        { libraryQuestion },
      );

      return new LibraryQuestion( res.data.libraryQuestion );
    },

    async deleteObject({ dispatch }, libraryQuestionId ) {
      const userSession = await dispatch('auth/ensureSession', {}, { root: true });
      const res = await axios.delete(
        `/api/user-session/${userSession.id}/admin/library/question/${libraryQuestionId}`,
      );
      return res.data;
    },


  },

};
