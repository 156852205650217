
export default class SessionStatus {

  static fromString(status) {
    return new SessionStatus({ status });
  }

  constructor({ status = "notStarted" } = {}) {
    ///JPL: validate status?
    return {
      status,


      get isNotStarted() { return this.status === 'notStarted' },

      get isInProgress() {
        if ( this.status === 'askQuestions'      ) return true;
        if ( this.status === 'confirmAnswers'    ) return true;
        if ( this.status === 'markAnswers'       ) return true;
        if ( this.status === 'confirmAnswers'    ) return true;
        if ( this.status === 'revealScoring'     ) return true;
        if ( this.status === 'revealLeaderboard' ) return true;
        return false;
      },

      get hasStarted() { return this.status !== 'notStarted' },

      // Has been played to completion
      get hasFinished() { return this.isFinished },

      get canTeamsSignUp() {
        if ( this.status === 'notStarted'   ) return true;
        if ( this.status === 'askQuestions' ) return true;
        return false;
      },

      get hasRevealedScoring() {
        if ( this.isRevealingScoring ) return true;
        if ( this.isRevealingLeaderboard ) return true;
        if ( this.isFinished ) return true;
        return false;
      },

      get canReadOutAnswers() {
        if ( this.isMarkingAnswers ) return true;
        if ( this.isRevealingScoring ) return true;
        return false;
      },

      get hasRevealedAnswers() {
        return this.hasRevealedScoring;
      },

      get hasRevealedLeaderboard() {
        if ( this.isRevealingLeaderboard ) return true;
        if ( this.isFinished ) return true;
        return false;
      },

      get isAskingQuestions() {
        return this.status === 'askQuestions';
      },

      get isConfirmingAnswers() {
        return this.status === 'confirmAnswers';
      },

      get isMarkingAnswers() {
        return this.status === 'markAnswers';
      },

      get isRevealingScoring() {
        return this.status === 'revealScoring';
      },

      get isRevealingLeaderboard() {
        return this.status === 'revealLeaderboard';
      },

      get isFinished() { return this.status === 'finished' },


      get prettyStatus() {
        return {
          notStarted: "Not Started",
          askQuestions: "Quiz Questions",
          confirmAnswers: "Submit all Answers",
          markAnswers: "Answers Being Marked",
          revealScoring: "See Scores & Answers",
          revealLeaderboard: "Leaderboard",
          finished: "Finished",
        }[ this.status ] || "Unknown";
      },

      get canRevealIndividualAnswers() {
        if ( ! this.hasStarted   ) return false;
        if ( this.hasRevealedAnswers ) return false;
        return true;
      },

      get disableAllTeamGameInput() {
        if ( this.status === 'askQuestions'   ) return false;
        if ( this.status === 'confirmAnswers' ) return false;
        return true;
      },

      get isVotingAvailableToTeams() {
        return this.hasStarted;
      },

      // Serve teamScoreSheets for all teams to the client?
      get isScoreSheetAvailableToTeams() {
        if ( this.status === 'revealScoring'     ) return true;
        if ( this.status === 'revealLeaderboard' ) return true;
        if ( this.status === 'finished'          ) return true;
        return false;
      },

      // Serve questionMetadata to the team client?
      get isQuestionMetadataAvailableToTeams() {
        if ( this.status === 'revealScoring'     ) return true;
        if ( this.status === 'revealLeaderboard' ) return true;
        if ( this.status === 'finished'          ) return true;
        return false;
      },

      get isVotingReminderAvailableToTeams() {
        if ( this.status === 'markAnswers'       ) return true;
        if ( this.status === 'revealScoring'     ) return true;
        if ( this.status === 'revealLeaderboard' ) return true;
        if ( this.status === 'finished'          ) return true;
        return false;
      },

      // Display questionMetadata popular questions to teams?
      get isPopularQuestionsAvailableToTeams() {
        if ( this.status === 'revealLeaderboard' ) return true;
        if ( this.status === 'finished'          ) return true;
        return false;
      },

      // Serve teamPlacements for all teams to the client?
      get isTeamPlacementAvailableToTeams() {
        if ( this.status === 'revealLeaderboard' ) return true;
        if ( this.status === 'finished'          ) return true;
        return false;
      },


      maybeStart() {
        if ( ! this.isNotStarted ) return false;
        this.status = 'askQuestions';
        return true;
      },

      maybeFinishQuestions() {
        if ( ! this.isAskingQuestions ) return false;
        this.status = 'confirmAnswers';
        return true;
      },

      maybeAskMoreQuestions() {
        if ( ! this.isInProgress ) return false;
        this.status = 'askQuestions';
        return true;
      },

      maybeMarkAnswers() {
        if ( ! this.isConfirmingAnswers ) return false;
        this.status = 'markAnswers';
        return true;
      },

      maybeRevealScoring() {
        if ( ! this.isMarkingAnswers ) return false;
        this.status = 'revealScoring';
        return true;
      },

      maybeRevealLeaderboard() {
        if ( ! this.isRevealingScoring ) return false;
        this.status = 'revealLeaderboard';
        return true;
      },

      maybeFinish() {
        if ( ! this.isRevealingLeaderboard ) return false;
        this.status = 'finished';
        return true;
      },

    };
  }
}
