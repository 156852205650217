
import axios from '@/store/axios';
import TeamSession from '../../../models/team/session.js';
import TeamTeam from '../../../models/team/team.js';

// Get team visible session data + register new teams to the session
export default {
  namespaced: true,
  state: {
    teamSessions: [],
  },

  mutations: {
  },

  getters: {
  },

  actions: {
    // Return promise of teamSession with id, after having fetched it
    // from the API. Don't commit it to the Store.
    async fetchObject(context, { id }) {
      if ( ! this.$app.$root.callApi ) {
        ///JPL: fish out of master.answerSession, like on the backend
        return null;
      }

      return await axios.get(`/api/team/session/${id}`)
        .then((res) => {
          const teamSession = res.data.teamSession || {}; // JPL: or throw
          return new TeamSession( teamSession );
        });
      ///JPL: catch
    },

    // Return promise of teamSession with id, after having fetched it
    // from the API. Don't commit it to the Store.
    //
    ///JPL: move to team-team / saveObject ? yes
    async addTeam(context, { sessionId, name }) {
      if ( ! this.$app.$root.callApi ) {
        ///JPL: fish out of master.answerSession, like on the backend
        return null;
      }

      return await axios.post(
        `/api/team/session/${sessionId}/team`,
        { name },
      ).then(res => {
        const teamTeam = res.data.teamTeam || {}; // JPL: or throw
        return new TeamTeam( teamTeam );
      });
      ///JPL: catch
    },
  },
};
