
import ModelBase from '../model-base.js';
import TeamAnswerScore from './answer-score.js';

export default class TeamAnswerScoreSheet extends ModelBase {

  constructor({ sessionId, teamId, questionIdToTeamAnswerScore, placementOrdinal, isPlacementShared }) {
    super();
    return {
      ...this,
      sessionId,
      teamId,
      questionIdToTeamAnswerScore: this.ensureValuesObjects(
        questionIdToTeamAnswerScore,
        TeamAnswerScore,
      ),
      placementOrdinal,
      isPlacementShared,

      get teamAnswerScores() {
        return Object.values( this.questionIdToTeamAnswerScore );
      },

      // Or null
      teamAnswerScoreForQuestion(teamQuestion) {
        return this.questionIdToTeamAnswerScore[ teamQuestion.questionId ] || null;
      },

      get totalPointsAwarded() {
        return this
          .teamAnswerScores
          .map(teamAnswerScore => teamAnswerScore.pointsAwarded)
          .reduce((total, points) => total += points, 0);
      },
    };
  }
}
