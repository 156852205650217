
<script>
 export default {
   name: "CopyableUrl",
   props: [ 'label', 'url' ],

   data() {
     return {
       copyMessage: null,
       copyErrorMessage: null,
     };
   },

   methods: {
     onCopy() {
       this.copyMessage = "URL copied to clipboard";
       this.copyErrorMessage = null;
       setTimeout(() => {
         this.copyMessage = null;
       }, 1500);

     },
     onCopyError() {
       this.copyMessage = null,
       this.copyErrorMessage = "Error: URL not copied";
     },
   }
 }
</script>

<template>
  <b-form-group :label="label">
    <b-form-input class="url-input" readonly size="sm" autocomplete="off" v-model="url" type="text" />
    <b-button class="mt-1" size="sm" variant="outline-dark" v-clipboard:copy="url" v-clipboard:success="onCopy" v-clipboard:error="onCopyError">
      <slot>Copy URL!</slot>
    </b-button>
    <span class="ml-2">
      <transition name="fade">
        <b-badge v-if="copyMessage" variant="success">{{copyMessage}}</b-badge>
        <b-badge v-if="copyErrorMessage" variant="danger">{{copyErrorMessage}}</b-badge>
      </transition>
    </span>
  </b-form-group>
</template>

<style scoped>

 .url-input {
   font-weight: bold;
   font-size: 1.1rem;
 }


 .fade-enter-active {
   transition: opacity 0.75s;
 }
 .fade-leave-active {
   transition: opacity 3s;
 }
 .fade-enter, .fade-leave-to {
   opacity: 0;
 }

</style>
