
export default class MasterClipboardQuestion {

  static newFromString(text) {
    try {
      if ( ! text ) return null;

      const json = JSON.parse(text); // Or throw
      if ( ! json.isClipboardQuestion ) {
        return null;
      }

      return new MasterClipboardQuestion(json);
    }
    catch (e) {
      return null;
    }
  }

  constructor({ points, question, answerEntryType, correctAnswer, correctMultiAnswer, shouldRevealAnswer, autoMarkCorrectStyle = "similar", answerTrivia, revealAnswerTrivia, imageUrl }) {
    return {
      points,
      question,
      answerEntryType,
      correctAnswer,
      correctMultiAnswer,
      shouldRevealAnswer,
      autoMarkCorrectStyle,
      answerTrivia,
      revealAnswerTrivia,
      imageUrl,

      isClipboardQuestion: true, // Marker when reading the clipboard that this is a question

    };
  }
}
