<script>

 import TopNavBar from '@/components/common/TopNavBar.vue';
 import BottomFooter from '@/components/common/BottomFooter.vue';

 import WithUserSession from '@/components/mixins/WithUserSession';

 export default {
   name: 'AdminPage',
   mixins: [ WithUserSession ],
   components: {
     TopNavBar,
     BottomFooter,
   },

 }

</script>

<template>
  <div class="is-footer-flex-container">
    <TopNavBar />

    <b-container class="is-footer-flex-content">

      <h2>Quiz Admin</h2>

      <ul>
        <li v-if="hasPermissionRole('accountEdit')">
          <router-link to="/admin/user-accounts">User Accounts</router-link>
        </li>
        <li v-if="hasPermissionRole('accountEdit')">
          <router-link to="/admin/answer-sheets">Answer Sheets</router-link>
        </li>
      </ul>
      <ul>
        <li v-if="hasPermissionRole('libraryEdit')">
          <router-link to="/admin/library/questions">Library Questions</router-link>
        </li>
      </ul>

    </b-container>

    <BottomFooter />
  </div>
</template>

<style scoped>

</style>
