
<script>

 import WithUserSession from '@/components/mixins/WithUserSession';
 import WithAnalytics from '@/components/mixins/WithAnalytics';

 import SignupOrLoginCard from '@/components/common/SignupOrLoginCard.vue';
 import LibraryQuestionList from '@/components/library/QuestionList.vue';

 export default {
   name: 'EditAnswerSheetTabQuizLibrary',
   mixins: [
     WithUserSession,
     WithAnalytics,
   ],
   components: {
     LibraryQuestionList,
     SignupOrLoginCard,
   },
   props: [
     'quizLibraryTabActivated',
   ],

   watch: {
     'quizLibraryTabActivated': function() {
       if ( ! this.hasUserAccount && this.quizLibraryTabActivated ) {
         this.logViewLibrarySignup();
       }
     },
   },

   computed: {
     hasUserAccount() {
       return this.userSession.hasUserAccount;
     },
   },

   methods: {
     // Log that we've seen the library, but we saw the signup-screen
     logViewLibrarySignup() {
       this.logEvent('library_view_signup', { event_category: 'setup' });
     },
   },
 }
</script>

<template>
  <div>
    <div class="is-friendly-bg is-friendly-panel pt-4 pb-2 mb-2 px-4 mt-0">
      <b-row>
        <b-col cols="0" lg="5" class="d-none d-lg-block text-right pl-4">
          <img
            Xsrc="/unsplash/library/heber-galindo-6fSFv43geOo-unsplash.jpg"
            src="/unsplash/library/jason-yoder-moa4UkLh9S8-unsplash.jpg"
            alt="In the library"
            class="is-horizontal-library-image mb-3 mr-2"
          />
        </b-col>
        <b-col cols="12" lg="7">
          <h5>
            Browse the Quiz Library
          </h5>
          <b-row>
            <b-col>
              <p>
                <strong>You can view</strong> 100 questions per day.
              </p>
              <p>
                This is to avoid <i>your</i>
                players just browsing through the entire library and
                spoiling your quiz.
              </p>
            </b-col>
            <b-col>
              <strong>You can borrow</strong> up to 20 questions from the library per
              day and use them in your own quiz.
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col v-if="hasUserAccount">
              <small>
                <ol>
                  <li>
                    Select library questions below
                  </li>
                  <li>
                    Click
                    <b-badge variant="primary">Borrow questions</b-badge>
                    to add them to <strong>My Questions</strong>
                  </li>
                </ol>
              </small>
            </b-col>
            <b-col v-else class="mb-3">
              You need a <strong>library card</strong> to enter the Quiz Library. <br />
              <strong>
                Please Sign up or Log in below!
              </strong>
            </b-col>
          </b-row>


        </b-col>
      </b-row>
    </div>

    <div v-if="hasUserAccount">
      <LibraryQuestionList
        v-if="quizLibraryTabActivated"
        v-on="$listeners"
      />
    </div>
    <div v-else>
      <b-row class="mt-4">
        <b-col offset="2" cols="8">
          <SignupOrLoginCard
            :redirect="false"
          />
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<style scoped>

 h3 {
   font-size: 1.5em;
 }


 .is-friendly-bg {
   background: #FFF3EC;
 }
 .is-friendly-panel {
   border-bottom-left-radius: 0.7rem;
   border-bottom-right-radius: 0.7rem;
 }

 .is-vertical-library-image {
   border: 1px solid #555;
   border-radius: 5px;
   height: 16rem;
 }
 .is-horizontal-library-image {
   border: 1px solid #555;
   border-radius: 5px;
   width: 18rem;
 }

</style>
