
<script>

 import IconUnscored from '@/components/quizmaster/run/Icon/Unscored.vue';

 export default {
   name: "ScoringBar",
   components: {
     IconUnscored,
   },
   props: [
     'quiz',
     'teamAnswer',
     'selectedTeamAnswer',
     'masterAnswerScore',
     'selectedQuestion',
     'unmarkedAnswerCount',
     'showUnscoredNavButtons',
   ],

   computed: {
     disableUnscoredNavigationButtons() {
       return this.unmarkedAnswerCount == 0;
     },

     selectedQuestionNumber() {
       return this.quiz.questionNumber( this.selectedQuestion );
     },
     selectedTeamName() {
       const teamId = this.selectedTeamAnswer.teamId;
       if (! teamId ) return "";
       return this.quiz.teamTeamForTeamId( this.selectedTeamAnswer.teamId ).name;
     },

     effectiveAnswer() {
       if ( ! this.selectedTeamAnswer ) return "";
       if ( ! this.selectedQuestion ) return "";
       return this.selectedTeamAnswer.effectiveAnswer( this.selectedQuestion );
     },
   },

   methods: {
     selectPreviousUnscoredAnswer() {
       this.$emit("select-previous-unscored-answer");
     },
     selectNextUnscoredAnswer() {
       this.$emit("select-next-unscored-answer");
     },
   },
 };

</script>

<template>
  <b-row>
    <b-col class="maybe-selected-answer mt-2 mt-lg-0" cols="12" order="2" lg="7" xl="8" order-lg="1">
      <div v-if="selectedTeamAnswer">
        <div>
          <small><strong>{{selectedTeamName}}, Answer {{selectedQuestionNumber}}:</strong></small>
          <b-input
            class="team-answer"
            :value="effectiveAnswer"
            :title="effectiveAnswer"
            size="sm"
            readonly />
        </div>
      </div>
    </b-col>
    <b-col cols="12" order="1" lg="5" xl="4" order-lg="2" class="text-right">
      <span v-if="showUnscoredNavButtons">
        <strong>{{unmarkedAnswerCount}}</strong>
        Unmarked Answer{{maybePlural(unmarkedAnswerCount)}}:

        <IconUnscored />

        <b-button v-if="! selectedTeamAnswer"
          class="ml-2"
          @click.prevent="selectNextUnscoredAnswer()"
          :disabled="disableUnscoredNavigationButtons"
          title="Select the next unscored answer (Shortcut key: W)"
          size="sm"
          variant="primary"
        >Mark Some Answers</b-button>
        <b-button-group v-else class="ml-2">
          <b-button
            @click.prevent="selectPreviousUnscoredAnswer()"
            :disabled="disableUnscoredNavigationButtons"
            title="Select the previous unscored answer (Shortcut key: Q)"
            size="sm"
            variant="outline-info"
          >⇠Prev</b-button>
          <b-button
            @click.prevent="selectNextUnscoredAnswer()"
            :disabled="disableUnscoredNavigationButtons"
            title="Select the next unscored answer (Shortcut key: W)"
            size="sm"
            variant="outline-info"
          >Next ⇢</b-button>
        </b-button-group>
      </span>
    </b-col>
  </b-row>
</template>

<style scoped>

 .maybe-selected-answer {
   min-height: 3.6rem;
 }

</style>
