
///JPL: rename TeamQuiz
<script>
 import TopNavBar from '@/components/common/TopNavBar.vue';
 import BottomFooter from '@/components/common/BottomFooter.vue';
 import ShareTweetButton from '@/components/share/Share/TweetButton.vue';
 import TransitionFade from '@/components/transition/TransitionFade.vue';

 import TeamSession from '../../models/team/session';
 import TeamAnswerSheet from '../../models/team/answer-sheet';
 import TeamAnswerScoreSheet from '../../models/team/answer-score-sheet';

 import TeamGameWaiting from '@/components/team/TeamGameWaiting';
 import TeamGameInstructions from '@/components/team/TeamGameInstructions';
 import TeamGameAnswer from '@/components/team/TeamGameAnswer';
 import TeamGameLeaderboard from '@/components/team/TeamGameLeaderboard';
 import TeamGamePopularQuestion from '@/components/team/TeamGamePopularQuestion';

 import WithAnalytics from '@/components/mixins/WithAnalytics';
 import WithTeamSessionInvitation from '@/components/mixins/WithTeamSessionInvitation';
 import WithSocketIo from '@/components/mixins/WithSocketIo';

 export default {
   name: "TeamGame",
   mixins: [
     WithAnalytics,
     WithSocketIo,
     WithTeamSessionInvitation,
   ],
   components: {
     TopNavBar,
     BottomFooter,
     ShareTweetButton,
     TransitionFade,
     TeamGameInstructions,
     TeamGameWaiting,
     TeamGameAnswer,
     TeamGamePopularQuestion,
     TeamGameLeaderboard,
   },

   props: [ 'loadTeamSessionId', 'loadTeamSessionTeamId' ],

   data() {
     return {
       teamSession: null,
       teamTeam: null,
       teamAnswerSheet: null,
       ///JPL: init this with a Null object?
       teamAnswerScoreSheet: null, // Only populated once scores are revealed
     };
   },

   created() {
     this.$store.commit("teamAnswerSheet/ensureBrowserId");

     // Hide top menu bar Account menu items (signup/login)
     ///JPL: display this when the quiz is finished, along with other
     ///CTAs to create new stuff
     this.$store.commit("router/setDisplayAccountMenu", false);

     Promise
       .all([
         this.loadTeamSessionTeamById(
           this.loadTeamSessionId,
           this.loadTeamSessionTeamId,
         ),
         this
           .loadTeamSessionById( this.loadTeamSessionId )
           .then(
             () => document.title = `Pub Quiz: '${this.teamSession.quizName}' | Even Remotely`
           ),
       ])
       .then(() => {
         // Requires both teamTeam for the id and the teamSession
         // for the session id
         this.teamSessionTeamWebCookie.set(this.teamTeam.id);
       });

     this.loadTeamAnswerSheet( this.loadTeamSessionId, this.loadTeamSessionTeamId );

     // If the quiz has started when we join, send answers to trigger
     // a notification to the qm to load the team.
     setTimeout(
       () => {
         const teamSession = this.teamSession;
         if ( ! teamSession ) return;
         if ( ! teamSession.sessionStatus.isInProgress ) return;
         this.onTeamAnswerSheetChanged();
       },
       3000,
     );
   },

   mounted() {
     const io = this.socketIo;

     io.on("teamSessionChanged", message => {
       console.log(`on teamSessionChanged`, message);
       const teamSessionPayload = message.teamSession;
       const didTeamAnswerSheetChange = message.didTeamAnswerSheetChange || false;
       if (! teamSessionPayload) {
         return;
       }

       const currentQuestionNumber = this.teamSession.currentQuestionNumber;
       const currentSessionStatus = this.teamSession.sessionStatus.status;
       const currentRevealedQuestionIds = this.teamSession.revealedQuestionIds;
       const currentFirstUnrevealedQuestion = this.teamSession.firstUnrevealedTeamQuestion;
       this.setTeamSession(new TeamSession(teamSessionPayload));

       if (currentQuestionNumber !== this.teamSession.currentQuestionNumber) {
         this.notifyNextQuestion();
       }

       // Show toast if a new question was revealed
       if ( ! this.teamSession.sessionStatus.hasRevealedScoring ) {
         const revealedTeamQuestion = this.teamSession.justRevealedTeamQuestion(
           currentRevealedQuestionIds,
         );
         if ( revealedTeamQuestion ) {
           this.focusQuestion(revealedTeamQuestion);
         }
       }

       if (currentSessionStatus !== this.teamSession.sessionStatus.status) {
         // Reveal scores: scroll to the first _previosly_ unrevealed Question,
         // where the qm will go through the answers,
         // or don't scroll if there is none
         if ( this.teamSession.sessionStatus.isRevealingScoring ) {
           this.maybeFocusFirstUnrevealedQuestion(currentFirstUnrevealedQuestion);
         }
         // Reveal Leaderboard: scroll it into view
         if ( this.teamSession.sessionStatus.isRevealingLeaderboard ) {
           window.scrollTo(0,0);
         }
       }

       // The server auto-confirmed questions on behalf of the team,
       // so we need to reload the team answer sheet
       if ( didTeamAnswerSheetChange ) {
         this.loadTeamAnswerSheet( this.loadTeamSessionId, this.loadTeamSessionTeamId );
       }
     });

     io.on("teamAnswerScoreSheetChanged", message => {
       console.log(`on teamAnswerScoreSheetChanged`, message);
       const teamAnswerScoreSheetPayload = message.teamAnswerScoreSheet;
       if (! teamAnswerScoreSheetPayload) {
         console.log("Missing ws teamAnswerScoreSheet", message);
         return;
       }

       this.setTeamAnswerScoreSheet(new TeamAnswerScoreSheet(teamAnswerScoreSheetPayload));
     });

     io.on("teamAnswerSheetChanged", message => {
       console.log(`on teamAnswerSheetChanged`, message);
       const teamAnswerSheetPayload = message.teamAnswerSheet;
       if (! teamAnswerSheetPayload) {
         console.log("missing ws teamAnswerSheet", message);
         return;
       }

       const wasBrowswerWriter = this.isBrowserWriter;

       const isBrowserWriter = teamAnswerSheetPayload.writerBrowserId === this.browserId;
       this.$store.commit('teamAnswerSheet/setIsBrowserWriter', isBrowserWriter);

       const didWriterChange = isBrowserWriter !== wasBrowswerWriter;

       if ( this.isBrowserReader || didWriterChange ) {
         this.setTeamAnswerSheet(new TeamAnswerSheet(teamAnswerSheetPayload));
       }
       else {
         console.log("Received teamAnswerSheetChanged, but we're writer. Ignoring...");
       }

       if ( didWriterChange ) {
         const teamInstructions = this.$refs.teamInstructions;
         if ( teamInstructions ) {
           if ( this.isBrowserWriter ) {
             this.$bvToast.toast(`You can now submit answers for your team.`, {
               title: 'You became "secretary"',
               variant: "success",
             });
           }
           else {
             this.$bvToast.toast(`Someone else is now able to submit answers for your team. This page is now readonly.`, {
               title: 'Someone else is the "secretary"',
               variant: "warning",
             });
             teamInstructions.$el.scrollIntoView({
               behaviour: "smooth",
               block: "center",

             });
           }
         }
       }
     });


     io.on('connect', () => {
       // Session data, e.g. sessionStatus, questions asked
       io.emit(
         'teamSubscribeToTeamSession',
         { sessionId: this.loadTeamSessionId },
       );
       // Team's marked answers, points awarded, etc.
       io.emit(
         'teamSubscribeToTeamAnswerScoreSheet',
         { sessionId: this.loadTeamSessionId, teamId: this.loadTeamSessionTeamId },
       );
       // Team's submitted answers, likes, etc. for when there are
       // many people in the same team, send this to browsers who
       // aren't the writer
       io.emit(
         'teamSubscribeToTeamAnswerSheet',
         {
           sessionId: this.loadTeamSessionId,
           teamId: this.loadTeamSessionTeamId,
           browserId: this.browserId,
         },
       );

       // In case of reconnect, the Team may have done edits. This
       // sends the current state to the server
       this.saveTeamAnswerSheet();
     });

   },

   computed: {
     teamQuestions() {
       return [ ...this.teamSession.teamQuestions ].reverse();
     },
     currentQuestionIndex() {
       return this.teamSession.currentQuestionNumber - 1;
     },


     showConfirmQuestionBanner() {
       if ( this.isBrowserReader ) return false;
       return this.unconfirmedAnswerCount > 0
           && this.teamSession.sessionStatus.isConfirmingAnswers;
     },

     haveUnconfirmedAnswers() {
       return this.unconfirmedAnswerCount > 0;
     },

     showRevealScoringBanner() {
       return this.teamSession.sessionStatus.isRevealingScoring
           && this.totalPointsAwarded !== undefined;
     },

     showRevealLeaderboardBanner() {
       return (
         this.teamSession.sessionStatus.isRevealingLeaderboard
         || this.teamSession.sessionStatus.isFinished
       ) && this.totalPointsAwarded !== undefined;
     },


     disableAllInput() {
       if ( this.isBrowserReader ) return true;
       return this.teamSession.sessionStatus.disableAllTeamGameInput;
     },


     disableConfirmAllButton() {
       return this.unconfirmedAnswerCount == 0
           || this.teamSession.sessionStatus.disableAllTeamGameInput;
     },

     teamAnswers() {
       return this.teamQuestions.map(
         question => this.teamAnswerSheet.teamEnsureTeamAnswer(question)
       );
     },

     unconfirmedAnswerCount() {
       return this
         .teamAnswers
         .filter(teamAnswer => ! teamAnswer.isConfirmed )
         .length;
     },

     totalPointsPossible() {
       return this.teamSession.totalPointsPossible;
     },

     totalPointsAwarded() {
       if ( ! this.teamAnswerScoreSheet ) return undefined;
       return this.teamAnswerScoreSheet.totalPointsAwarded;
     },

     placementOrdinal() {
       if ( ! this.teamAnswerScoreSheet ) return "last";
       return this.teamAnswerScoreSheet.placementOrdinal;
     },
     isPlacementShared() {
       if ( ! this.teamAnswerScoreSheet ) return false;
       return this.teamAnswerScoreSheet.isPlacementShared;
     },

     ///JPL: Move up
     showTip() {
       return ! this.teamSession.sessionStatus.disableAllTeamGameInput;
     },
     showTeamAnswerScore() {
       const sessionStatus = this.teamSession.sessionStatus;
       return sessionStatus.hasRevealedScoring;
     },
     showVoteButtons() {
       if ( this.isBrowserReader ) return false;
       const sessionStatus = this.teamSession.sessionStatus;
       return sessionStatus.isVotingAvailableToTeams;
     },
     showConfirmRemainingButton() {
       if ( this.isBrowserReader ) return false;
       return this.unconfirmedAnswerCount > 0;
     },


     showVoteReminder() {
       if ( this.isBrowserReader ) return false;
       const sessionStatus = this.teamSession.sessionStatus;
       return sessionStatus.isVotingReminderAvailableToTeams;
     },

     showTeamPlacements() {
       return this.teamSession.teamPlacements && this.teamSession.teamPlacements.length;
     },

     showPopularQuestions() {
       return this.teamSession.sessionStatus.isPopularQuestionsAvailableToTeams;
     },

     popularQuestionsMetadata() {
       const teamQuestionsMetadata = this.teamSession.teamQuestionsMetadata;
       if ( ! teamQuestionsMetadata ) return [];
       return teamQuestionsMetadata.popularQuestionsMetadata;
     },

     showPopularQuestionsList() {
       if ( ! this.showPopularQuestions ) return false;
       return !! this.popularQuestionsMetadata.length;
     },

     nextQuestionToaster() {
       return this.$root.isMobile ? "b-toaster-bottom-right" : "b-toaster-top-right";
     },

     tweetText() {
       const maybeSharedSpace = this.isPlacementShared ? 'shared ' : ' ';
       return `I just played the Even Remotely Pub Quiz
"${this.teamSession.quizName}".

My team "${this.teamTeam.name}" ended up in ${maybeSharedSpace}${this.ordinalWithSuffix(this.placementOrdinal)} place with a total score of ${this.totalPointsAwarded} point${this.maybePlural(this.totalPointsAwarded)} out of ${this.totalPointsPossible}!

#quiz #pubquiz via @RemotelyPubQuiz

https://even-remotely.com/ Pub Quiz`;
     },
     tweetButtonTitle() {
       if ( this.placementOrdinal === 1 ) {
         return "Tweet about your 1st place!"
       }
       if ( this.placementOrdinal <= 3 ) {
         return "Tweet about your quiz result!"
       }
       return "Tweet about the quiz!"
     },

     isBrowserReader() {
       return ! this.isBrowserWriter;
     },
     isBrowserWriter() {
       const isBrowserWriter = this.$store.state.teamAnswerSheet.isBrowserWriter;
       return isBrowserWriter;
     },
     browserId() {
       return this.$store.state.teamAnswerSheet.browserId;
     },
   },

   methods: {
     loadTeamSessionTeamById(sessionId, id) {
       return this
         .$store
         .dispatch(
           'teamTeam/fetchObject',
           { sessionId, id },
         )
         .then(teamTeam => {
           console.log("Loaded team", teamTeam)
           this.$set(this, 'teamTeam', teamTeam);
         });
     },

     ///JPL: rename, rename param to sessionId
     loadTeamSessionById( id ) {
       return this
         .$store
         .dispatch('teamSession/fetchObject', { id })
         .then(teamSession => {
           this.setTeamSession(teamSession);
         });
     },

     setTeamSession(teamSession) {
       this.$set(this, 'teamSession', teamSession);
       if ( this.teamSession.sessionStatus.hasRevealedLeaderboard ) {
         this.$store.commit("router/setDisplayAccountMenu", true);
       }

     },

     setTeamAnswerScoreSheet(teamAnswerScoreSheet) {
       // console.log("setTeamAnswerScoreSheet", teamAnswerScoreSheet);
       this.$set(this, 'teamAnswerScoreSheet', teamAnswerScoreSheet);
     },

     setTeamAnswerSheet(teamAnswerSheet) {
       this.$set(this, 'teamAnswerSheet', teamAnswerSheet);
     },

     ///JPL: remove / replace with websocket event
     reloadTeamSession() {
       return this.loadTeamSessionById( this.loadTeamSessionId );
     },


     loadTeamAnswerSheet(sessionId, teamId) {
       return this
         .$store
         .dispatch('teamAnswerSheet/fetchObject', { sessionId, teamId })
         .then(teamAnswerSheet => {
           this.$set(this, 'teamAnswerSheet', teamAnswerSheet);
         });
     },

     saveTeamAnswerSheet() {
       if ( ! this.teamAnswerSheet ) return null;

       ///JPL: debounce this 0.3s or something, it's being hit by but
       ///the blur and the confirm button
       ///
       ///JPL: this may be important to first unconfirm then confirm
       ///the answer...
       return this
         .$store
         .dispatch('teamAnswerSheet/saveObject', this.teamAnswerSheet)
     },

     becomeSecretary() {
       return this
         .$store
         .dispatch(
           'teamAnswerSheet/becomeWriter',
           [ this.teamAnswerSheet.sessionId, this.teamAnswerSheet.teamId ],
         )
     },

     teamAnswerForQuestion(question) {
       return this.teamAnswerSheet.teamEnsureTeamAnswer(question);
     },

     teamAnswerScoreForQuestion(question) {
       const teamAnswerScoreSheet = this.teamAnswerScoreSheet;
       if ( ! teamAnswerScoreSheet ) return null;
       return teamAnswerScoreSheet.teamAnswerScoreForQuestion(question);
     },

     isCurrentQuestionIndex(index) {
       // If we're showing the score, there's no longer a current question
       if ( this.showTeamAnswerScore ) return false;
       return index === 0;
     },

     isTeamQuestionHardest(teamQuestion) {
       const teamQuestionsMetadata = this.teamSession.teamQuestionsMetadata;
       if ( ! teamQuestionsMetadata ) return false;

       return teamQuestionsMetadata.isQuestionHardest(teamQuestion);
     },

     isTeamQuestionEasiest(teamQuestion) {
       const teamQuestionsMetadata = this.teamSession.teamQuestionsMetadata;
       if ( ! teamQuestionsMetadata ) return false;

       return teamQuestionsMetadata.isQuestionEasiest(teamQuestion);
     },

     isTeamQuestionPopular(teamQuestion) {
       if ( ! this.showPopularQuestions ) return false;

       const teamQuestionsMetadata = this.teamSession.teamQuestionsMetadata;
       if ( ! teamQuestionsMetadata ) return false;

       return teamQuestionsMetadata.isQuestionPopular(teamQuestion);
     },

     teamQuestionById(questionId) {
       return this.teamSession.teamQuestionById(questionId);
     },

     onTeamAnswerSheetChanged() {
       this.saveTeamAnswerSheet();
     },

     notifyNextQuestion() {
       this.$bvToast.show('next-question-toast');

       // scrollIntoView is probably confused by the transition,
       // possibly by loading the image, so let's do it one more
       // time after giving the transition time to finish
       this.focusCurrentQuestion();
       setTimeout(() => {
         this.focusCurrentQuestion();
       }, 1500);
     },
     focusCurrentQuestion() {
       const el = this.$refs.topOfQuestions;
       if ( ! el ) return;
       el.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
     },

     focusQuestion(teamQuestion) {
       const answerComponents = this.$refs.teamGameAnswer;
       if ( ! answerComponents ) return;
       const answerComponent = answerComponents.find(
         answerComponent => answerComponent.teamQuestion.questionId === teamQuestion.questionId,
       );
       if ( ! answerComponent ) return;
       answerComponent.$el.scrollIntoView({ behavior: 'smooth', block: 'start' });
     },

     maybeFocusFirstUnrevealedQuestion(firstUnrevealedQuestion) {
       if ( ! firstUnrevealedQuestion ) return;
       this.focusQuestion(firstUnrevealedQuestion);
     },

     clickConfirmAll() {
       if ( this.teamAnswerSheet.confirmAllUnconfirmedAnswers() ) {
         this.onTeamAnswerSheetChanged();
       }
       this.logSubmitRemaining();
     },

     logSubmitRemaining() {
       this.logEvent('submit_remaining', { event_category: 'team' });
     },
     logCreateQuiz() {
       this.logEvent('team_create_quiz', { event_category: 'team' });
     },
   },

 }
</script>

<template>
  <div class="is-footer-flex-container">
    <TopNavBar />

    <b-container class="is-footer-flex-content">
      <div v-if="teamSession && teamTeam">
        <h2>{{teamTeam.name}} - {{teamSession.quizName}}</h2>


        <TeamGameWaiting
          v-if="teamSession.sessionStatus.isNotStarted"
          :teamTeam="teamTeam"
          :teamSession="teamSession"
          :isBrowserWriter="isBrowserWriter"
        />

        <div v-if="teamSession.sessionStatus.hasStarted && teamAnswerSheet">

          <TeamGameInstructions
            v-if="showTip"
            ref="teamInstructions"
            header="Tip"
            :isBrowserWriter="isBrowserWriter"
            @become-secretary="becomeSecretary"
          />

          <transition appear name="answer-transition">
            <div v-if="showTeamPlacements" class="mt-5 mb-3">
              <TeamGameLeaderboard :teamSession="teamSession" />
            </div>
          </transition>

          <TransitionFade>
            <div v-if="showTeamPlacements" class="mt-3 mb-5 text-center">
              <ShareTweetButton :tweetText="tweetText" eventLabel="tweetTeam">
                {{tweetButtonTitle}}
                <font-awesome-icon
                  v-if="placementOrdinal === 1"
                  :icon="['fa', 'medal']"
                  class="ml-1"
                />
              </ShareTweetButton>
            </div>
          </TransitionFade>

          <TransitionFade>
            <div v-if="showPopularQuestions">
              <div>
                <b-container class="is-popular-questions w-100 mt-5 mb-5 pb-4 shadow rounded">

                  <b-row class="mx-2">
                    <b-col>
                      <h4 class="pt-4 pb-2 text-center">Popular questions</h4>

                      <div v-if="showPopularQuestionsList">
                        <div
                          v-for="questionMetadata in popularQuestionsMetadata"
                          :key="questionMetadata.questionId"
                          class="overflow-hidden"
                        >
                          <TeamGamePopularQuestion
                            :questionMetadata="questionMetadata"
                            :teamQuestion="teamQuestionById(questionMetadata.questionId)"
                            :teamQuestionsMetadata="teamSession.teamQuestionsMetadata"
                          />
                        </div>
                      </div>
                      <div v-else class="text-center">
                        <p>
                          No votes are in yet.
                        </p>
                        <p>
                          Remember to
                          <font-awesome-icon :icon="['fas', 'heart']" class="mx-1" />
                          your favourite questions below
                          <font-awesome-icon :icon="['far', 'grin-alt']" />
                        </p>
                      </div>
                    </b-col>
                  </b-row>

                </b-container>
              </div>
            </div>
          </TransitionFade>


          <div v-if="showTeamPlacements">
            <b-container class="is-whats-next w-100 mt-5 mb-5 pb-4 shadow rounded">

              <b-row>
                <b-col>
                  <h4 class="pt-4 pb-2 text-center">What's next?</h4>

                  <div class="text-center">
                    <p>
                      You can easily
                      <strong header="Create your own quiz">host your own online quiz</strong>
                      <br />
                      for remote co-workers, or family & friends.
                    </p>
                    <p>
                      Write your own questions, or borrow from the <br />
                      <strong>free Quiz Question Library!</strong>
                    </p>
                  </div>

                  <div class="text-center pb-2">
                    <router-link to="/quizmaster/answer-sheet/new">
                      <b-button @click="logCreateQuiz" pill variant="primary" class="mt-2">
                        Create my own Quiz!
                      </b-button>
                    </router-link>
                  </div>

                </b-col>
              </b-row>

            </b-container>
          </div>


          <div ref="topOfQuestions" class="is-top-of-questions"></div>
          <transition-group appear name="team-transition">
            <TeamGameAnswer
              v-for="(teamQuestion, index) in teamQuestions" :key="teamQuestion.questionId"
              :teamQuestion="teamQuestion"
              :teamAnswer="teamAnswerForQuestion(teamQuestion)"
              :teamScore="teamAnswerScoreForQuestion(teamQuestion)"
              :totalQuestionCount="teamSession.totalQuestionCount"
              :isCurrentQuestion="isCurrentQuestionIndex(index)"
              :showTeamAnswerScore="showTeamAnswerScore"
              :showVoteButtons="showVoteButtons"
              :showVoteReminder="showVoteReminder"
              :isTeamQuestionHardest="isTeamQuestionHardest(teamQuestion)"
              :isTeamQuestionEasiest="isTeamQuestionEasiest(teamQuestion)"
              :isTeamQuestionPopular="isTeamQuestionPopular(teamQuestion)"
              :disableAllInput="disableAllInput"
              @team-answer-sheet-changed="onTeamAnswerSheetChanged()"
              ref="teamGameAnswer"
            />
          </transition-group>

          <b-row
            class="confirm-remaining-row mt-5 mb-4"
          >
            <b-col
              cols="12"
              class="text-right">
              <b-button
                v-show="showConfirmRemainingButton"
                :disabled="disableConfirmAllButton"
                @click.prevent="clickConfirmAll()"
                variant="primary">
                Submit {{this.haveUnconfirmedAnswers ? this.unconfirmedAnswerCount : "" }} Remaining
              </b-button>
            </b-col>

            <b-col
              v-if="showVoteReminder"
              class="text-center"
            >
              Remember to
              <font-awesome-icon :icon="['fas', 'heart']" class="mx-1" />
              your favourite questions
              <font-awesome-icon :icon="['far', 'grin-alt']" />
            </b-col>
          </b-row>

          <b-alert
            v-model="showConfirmQuestionBanner"
            class="position-fixed fixed-bottom m-0 rounded-0"
            style="z-index: 2000"
            variant="warning"
          >
            <strong>
              Please answer and Submit all {{unconfirmedAnswerCount}} remaining
              questions!

              <b-button @click.prevent="clickConfirmAll()" class="float-right" size="sm" variant="secondary">
                Submit Remaining
              </b-button>
            </strong>
          </b-alert>

          <b-alert
            v-model="showRevealScoringBanner"
            class="position-fixed fixed-bottom m-0 rounded-0"
            style="z-index: 2000"
            variant="success"
          >
            <strong>
              You scored a total of {{totalPointsAwarded}}
              point{{maybePlural(totalPointsAwarded)}}
              out of {{totalPointsPossible}}!
            </strong>
          </b-alert>

          <!-- JPL: extract component -->
          <b-alert
            v-model="showRevealLeaderboardBanner"
            class="position-fixed fixed-bottom m-0 rounded-0"
            style="z-index: 2000"
            variant="success"
          >
            <strong>
              You ended up in
              <span v-if="isPlacementShared">shared</span>
              {{ordinalWithSuffix(placementOrdinal)}} place
              with a total score of {{totalPointsAwarded}}
              point{{maybePlural(totalPointsAwarded)}}
              out of {{totalPointsPossible}}
              !
            </strong>
          </b-alert>


          <div class="pb-1 mb-5"></div>

        </div>

        <img
          v-if="teamSession && teamSession.preloadImageUrl"
          class="is-preload-image"
          :src="teamSession.preloadImageUrl"
        />

        <b-toast
          id="next-question-toast"
          variant="info"
          solid
          :toaster="nextQuestionToaster"
        >
          <template v-slot:toast-title>
            {{ teamSession.currentQuestionNumber === 1 ? 'First' : 'Next' }} question!
          </template>
          <h4>
            <a href="#" @click.prevent="focusCurrentQuestion">
              Question {{teamSession.currentQuestionNumber}}
            </a>
          </h4>
        </b-toast>
      </div>
    </b-container>
    <BottomFooter />
  </div>
</template>

<style scoped>

 /* JPL: is this used? */
 .url-input {
   font-weight: bold;
   font-size: 1.1rem;
 }


 .answer-transition-enter-active {
   transition: opacity 1s ease;
 }

 .answer-transition-enter {
   opacity: 0
 }

 .answer-transition-move {
   transition: transform 1s ease;
 }


 .team-transition-enter-active {
   transition: opacity 2s ease;
 }

 .team-transition-enter {
   opacity: 0
 }

 .confirm-remaining-row {
   min-height: 2rem;
 }

 .is-top-of-questions {
   height: 1px;
 }


 .is-whats-next {
   background: #FFF3EC;
 }

 .is-popular-questions {
   background: #FFF3EC;
 }

 img.is-preload-image {
   width: 0px;
   height: 0px;
   visibility: hidden;
   opacity: 0;
 }

</style>
