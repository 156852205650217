
<script>
 export default {
   name: 'TransitionFade',
 }
</script>

<template>
  <transition name="fade" mode="out-in" appear>
    <slot></slot>
  </transition>
</template>

<style scoped>

 .fade-enter-active, .fade-leave-active {
   transition: opacity 1s ease;
 }
 .fade-enter, .fade-leave-to {
   opacity: 0;
 }

</style>
