
import Vue from 'vue';
import Vuex from 'vuex';

import authModule from '@/store/modules/auth';
import routerModule from '@/store/modules/router';
import contentModule from '@/store/modules/content';

import masterAnswerSheetModule from '@/store/modules/master/answer-sheet';
import masterTeamModule from '@/store/modules/master/team';
import masterSessionModule from '@/store/modules/master/session';
import masterQuestionStatusSheetModule from '@/store/modules/master/question-status-sheet';
import masterAnswerScoreSheetModule from '@/store/modules/master/answer-score-sheet';
import masterTeamAnswerSheetModule from '@/store/modules/master/team-answer-sheet';

import teamSessionModule from '@/store/modules/team/session';
import teamTeamModule from '@/store/modules/team/team';
import teamAnswerSheetModule from '@/store/modules/team/answer-sheet';

import libraryQuestionModule from '@/store/modules/library/question';

import adminUserAccountModule from '@/store/modules/admin/user-account';
import adminLibraryQuestionModule from '@/store/modules/admin/library-question';



Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: authModule,
    router: routerModule,
    content: contentModule,

    masterAnswerSheet: masterAnswerSheetModule,
    masterTeam: masterTeamModule,
    masterSession: masterSessionModule,
    masterQuestionStatusSheet: masterQuestionStatusSheetModule,
    masterAnswerScoreSheet: masterAnswerScoreSheetModule,
    masterTeamAnswerSheet: masterTeamAnswerSheetModule,

    teamSession: teamSessionModule,
    teamTeam: teamTeamModule,
    teamAnswerSheet: teamAnswerSheetModule,

    libraryQuestion: libraryQuestionModule,

    adminUserAccount: adminUserAccountModule,
    adminLibraryQuestion: adminLibraryQuestionModule,
  },
});
