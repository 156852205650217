
<script>

 export default {
   name: 'EditQuestionSummaryForTheTeams',
   props: [ "question" ],
 }

</script>

<template>
  <span>
    <span>
      <span v-if="question.revealAnswerTrivia" key="withRevealAnswerTrivia"><strong><font-awesome-icon :icon="['far', 'lightbulb']" /> Reveal Answer Trivia</strong></span>
      <span v-else key="withoutRevealAnswerTrivia"><font-awesome-icon :icon="['far', 'lightbulb']" /> Reveal Answer Trivia</span>
    </span>

    <span>
      <span class="mr-1">, </span>
      <span v-if="question.shouldRevealAnswer" key="withShouldRevealAnswer">
        <strong>
          <font-awesome-icon :icon="['far', 'eye']" />
          Reveal Correct Answer
        </strong>
        <span v-if="question.shortRevealCorrectAnswer">
          (<i>{{question.shortRevealCorrectAnswer}}</i>)
        </span>
      </span>
      <span v-else key="withoutShouldRevealAnswer">
        <font-awesome-icon :icon="['far', 'eye']" />
        Don't Reveal Correct Answer
      </span>
    </span>
  </span>
</template>
