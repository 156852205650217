'use strict';

import ModelBase from '../model-base.js';

export default class LibraryCategory extends ModelBase {
  constructor({ id, name, description, createdTime, updatedTime }) {
    super();
    return {
      id,
      name,
      description,
      createdTime: this.ensureDate(createdTime),
      updatedTime: this.ensureDate(updatedTime),

      // For the vue-tags-input component to pick tags
      getVueTagsInputCategory() {
        // Can't be a getter, since it will stringify and create a
        // circular ref with `this`
        return {
          id: this.id,
          type: "libraryCategory",
          item: this,
          text: this.name,
          classes: "is-library-category is-vue-tags-input",
        };
      },

    };
  }
}
