
<script>

 import RunQuizInstructions from '@/components/quizmaster/RunQuizInstructions.vue';

 export default {
   name: "StatusProgress",
   components: {
     RunQuizInstructions,
   },
   props: [
     'sessionStatus',
   ],

   computed: {
     effectiveStatus() {
       if ( this.sessionStatus.status === 'finished' ) return 'revealLeaderboard';
       return this.sessionStatus.status;
     },
   },

   methods: {
     badgeVariantForStatus(status) {
       return this.effectiveStatus === status ? 'dark' : 'light';
     },
   },

 };

</script>


<template>
  <b-card class="phase-card">
    <div class="phase-container">
      <b-badge
        pill
        :variant="badgeVariantForStatus('askQuestions')"
        v-b-popover.hover.bottom.v-info.html="htmlUl('You ask each question\n ... then click [Ask Next Question]\nThe teams submit answers\nYou should mark answers in between asking questions\nYou may reveal each question and answer as you go.\nOr, you may reveal the questions later.')"
      >
        Quiz Questions
      </b-badge>
      <div class="phase-divider"><small>➞</small></div>

      <b-badge
        pill
        :variant="badgeVariantForStatus('confirmAnswers')"
        v-b-popover.hover.bottom.v-info.html="htmlUl('After asking all questions, immediatley click [Submit all Answers] and tell the teams to submit their final answers!\nGive them 10-20s to confirm their answers')"
      >
        Submit all Answers
      </b-badge>
      <div class="phase-divider"><small>➞</small></div>

      <b-badge
        pill
        :variant="badgeVariantForStatus('markAnswers')"
        v-b-popover.hover.bottom.v-info.html="htmlUl('After waiting 10-20s, click [Mark Answers]\nThe teams can no longer submit answers\nYou should mark any remaining answers.\nYou may reveal each question and go through the answer, one at a time.\nOr, you may reveal all questions at once in the next step.')"
      >
        Mark Answers
      </b-badge>
      <div class="phase-divider"><small>➞</small></div>

      <b-badge
        pill
        :variant="badgeVariantForStatus('revealScoring')"
        v-b-popover.hover.bottom.v-info.html="htmlUl('After marking all answers, click [Reveal Scoring & Answers]\nThe team can see their scores and the correct answers\nYou should go through the correct answers\nYou should go through the winners, and then click [Reveal Leaderboard]')"
      >
        Reveal Scoring &amp; Answers
      </b-badge>
      <div class="phase-divider"><small>➞</small></div>

      <b-badge
        pill
        :variant="badgeVariantForStatus('revealLeaderboard')"
        v-b-popover.hover.bottom.v-info.html="htmlUl('The teams can see the Leaderboard\nThe teams can see popular questions')"
      >
        Leaderboard
      </b-badge>

      <b-button
        v-b-toggle.sidebar-instructions
        size="sm"
        variant="info"
        class="ml-2"
      >
        <font-awesome-icon :icon="[ 'far', 'question-circle' ]" />
      </b-button>
    </div>

    <b-sidebar
      id="sidebar-instructions"
      title="How to run the quiz"
      shadow
      right
      width="35rem"
      backdrop
      backdrop-variant="dark"
    >
      <RunQuizInstructions>
      </RunQuizInstructions>
    </b-sidebar>

  </b-card>
</template>

<style scoped>

 .phase-card {
   display: inline-block;
 }

 .phase-card .card-body {
   padding-top: 0.5rem;
   padding-bottom: 0.7rem;
 }

 .phase-container {
   white-space: nowrap;
   font-size: 1.2rem;
 }

 .phase-divider {
   display: inline-block;
   margin-left: 0.2em;
   margin-right: 0.2em;
 }

</style>
