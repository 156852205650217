
<script>

 export default {
   name: "SessionStatusBadge",
   props: [
     'sessionStatus',
     'teamCount',
   ],

   computed: {
     statusVariant() {
       return {
         notStarted: "info",
         askQuestions: "success",
         markAnswers: "success",
         revealScoring: "success",
         revealLeaderboard: "success",
         finished: "secondary",
       }[ this.sessionStatus.status ] || "light";
     },

     prettyStatus() {
       if ( this.teamCount !== undefined ) {
         if ( this.sessionStatus.isNotStarted ) {
           if ( this.teamCount == 0 ) {
             return "Waiting for teams to register";
           }
         }
       }

       return this.sessionStatus.prettyStatus;
     },
   }
 }

</script>

<template>
  <b-badge :variant="statusVariant">
    {{prettyStatus}}
  </b-badge>
</template>

<style scoped>

</style>
