
<script>
 export default {
   name: "IconHeart",
 };
</script>

<template>
  <b-badge pill variant="danger" class="is-heart" >
    <font-awesome-icon :icon="['fas', 'heart']" />
  </b-badge>
</template>

<style scoped>

 .is-heart {
   padding-left: 0.3rem;
   padding-right: 0.3rem;
 }
</style>
