
import axios from '../axios';

import MasterQuestion from '../../models/master/question';


export default {
  namespaced: true,
  state: {
    termsAndConditionsHtml: "",
    privacyPolicyHtml: "",
  },
  mutations: {
    setTermsAndConditionsHtml(state, html) {
      return state.termsAndConditionsHtml = html;
    },
    setPrivacyPolicyHtml(state, html) {
      return state.privacyPolicyHtml = html;
    },
  },
  actions: {
    async fetchTermsAndConditionsHtml({ commit }) {
      return await axios
        .get(`/api/content/terms-and-conditions`)
        .then((res) => {
          return commit('setTermsAndConditionsHtml', res.data.html);
        });
    },
    async fetchPrivacyPolicyHtml({ commit }) {
      return await axios
        .get(`/api/content/privacy-policy`)
        .then((res) => {
          return commit('setPrivacyPolicyHtml', res.data.html);
        });
    },

    async fetchHtmlFromMarkdown(dummy, markdown) {
      return axios
        .post(`/api/content/markdown`, markdown)
        .then((res) => {
          return res.data.html;
        });
    },

    async fetchQuestionsFromCsv(dummy, csv) {
      const res = await axios .post(`/api/content/csv/questions`, { csv });

      if ( res.data.error ) {
        throw res.data.error;
      }

      return res.data.questions.map(
        masterQuestion => new MasterQuestion(masterQuestion),
      );
    },
  },
};
