
import axios from '../axios';
import UserSession from '../../models/user/session.js';

export default {
  namespaced: true,
  state: {
    userSession: UserSession.newFromCookie(),
    haveMaybeLoadedSession: false,
  },
  mutations: {
    setUserSession(state, userSession) {
      state.userSession = userSession;
    },
    haveTriedToLoadSession(state) {
      state.haveMaybeLoadedSession = true;
    },
  },
  actions: {

    // Ensure there's a session if there's a cookie to identify it
    // Return true if there is a session available
    async maybeLoadSession({ state, dispatch, commit }) {
      const userSession = state.userSession;
      if ( ! userSession.hasCookie ) {
        commit('haveTriedToLoadSession');
        return false;
      }
      if ( userSession.hasSession ) return true;

      await dispatch('loadSession');
      commit('haveTriedToLoadSession');
      return true;
    },

    async loadSession({ commit, state }) {
      const userSession = state.userSession;
      const res = await axios.post(
        `/api/auth/session`,
        { userSessionId: userSession.cookieValue },
      );

      const userSessionId = res.data.userSessionId;
      if ( ! userSessionId ) {
        return null;
      }

      userSession.setUserSessionId(userSessionId);
      userSession.maybeSetUserAccountExtract(res.data.userAccountExtract);

      await commit('setUserSession', userSession);

      return userSession;
    },

    async ensureSession({ dispatch, state }) {
      const userSession = state.userSession;

      if ( userSession.hasSession ) {
        userSession.setCookie();
        return userSession;
      }

      await dispatch('loadSession');

      return userSession;
    },

    async loadAccountObjects({ dispatch }) {
      await dispatch('masterAnswerSheet/fetchList', null, { root: true });
    },

    async clearAccountObjects({ dispatch }) {
      await dispatch('masterAnswerSheet/clearUserSession', null, { root: true });
    },

    ///JPL: DRY these
    async attemptLogin({ commit, state, dispatch }, [ username, password, shouldRememberUsername ]) {
      const userSession = state.userSession;

      return axios.post('/api/auth/session/account/authentication', {
        userSessionId: userSession.cookieValue,
        username,
        password,
      }).then(async res => {
        if ( res.data.error ) {
          return { error: res.data.error };
        }

        if ( res.data.userSessionId ) {
          userSession.setUserSessionId(res.data.userSessionId);
        }
        else {
          return { error: "Invalid response" };
        }

        // Start with clean slate
        await dispatch('clearAccountObjects');

        if ( res.data.userAccountExtract ) {
          userSession.logIn( res.data.userAccountExtract );
          commit('setUserSession', userSession);
        }
        else {
          return { error: "Invalid username/password combination"};
        }

        userSession.setRememberUsernameCookie(username, shouldRememberUsername);

        await dispatch("loadAccountObjects");

        return { message: "Logged in" };
      }).catch(e => {
        if ( e.response && e.response.data && e.response.data.error ) {
          return { error: e.response.data.error };
        }
        console.error("Invalid response", e);
        return { error: "Could not log in" };
      });
    },

    async logOut({ commit, state, dispatch }) {
      const userSession = state.userSession;
      if ( ! userSession.hasUserAccount ) return false;

      await axios.delete(`/api/auth/session/account/authentication/${userSession.id}`);
      userSession.logOut();

      commit('setUserSession', userSession);

      await dispatch('clearAccountObjects');

      return true;
    },



    async signUp({ commit, state, dispatch }, [ username, password, cheese, shouldRememberUsername ]) {
      const userSession = state.userSession;

      return axios.post('/api/auth/session/account', {
        userSessionId: userSession.id,
        username,
        password,
        cheese,
      }).then(async res => {
        if ( res.data.error ) {
          return { error: res.data.error || "Something went wrong" };
        }

        if ( res.data.userSessionId ) {
          userSession.setUserSessionId(res.data.userSessionId);

        }
        else {
          throw new Error("Invalid response");
        }

        // Start with clean slate
        await dispatch('clearAccountObjects');

        if ( res.data.userAccountExtract ) {
          userSession.maybeSetUserAccountExtract(res.data.userAccountExtract);
          commit('setUserSession', userSession);
        }
        else {
          console.error("Could not log in", res.data);
          throw new Error("Invalid response, missing account information");
        }

        userSession.setRememberUsernameCookie(username, shouldRememberUsername);

        await dispatch("loadAccountObjects");

        return { userSession };
      }).catch(e => {
        if ( e.response && e.response.data && e.response.data.error ) {
          return { error: e.response.data.error };
        }
        console.error("Invalid response", e);
        return { error: "Could not sign up" };
      });
    },
  },
};
