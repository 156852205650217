
export default class TeamTeamPlacement {
  constructor({ teamName, totalScore, placementOrdinal } = {}) {
    return {
      teamName,
      totalScore,
      placementOrdinal,
    };
  }
}

