
<script>
 import WithCopyPasteQuestion from '@/components/quizmaster/mixins/WithCopyPasteQuestion';
 import PreviewModalQuestion from '@/components/quizmaster/Preview/ModalQuestion.vue';

 export default {
   name: 'PreviewRunModalQuestion',
   mixins: [
     WithCopyPasteQuestion,
   ],
   components: {
     PreviewModalQuestion,
   },
   props: [
     'quiz',
     'previewSelectedQuestion',
     'modalTitle',
     'questionNumberMax',
     'previewShowTeamAnswerScore',
   ],

   computed: {
     isPreviewQuestionRevealed() {
       return this.quiz.isMasterQuestionRevealed( this.previewSelectedQuestion );
     },

     actualPreviewShowTeamAnswerScore() {
       if ( this.previewShowTeamAnswerScore ) return true;
       return this.isPreviewQuestionRevealed;
     },
   },

   methods: {
     copyPreviewQuestion(clipboardContainerEl) {
       const question = this.previewSelectedQuestion;
       if ( ! question ) return;
       this.copyQuestion(question, clipboardContainerEl);
     },

     onQuestionStatusSheetChanged() {
       this.$emit('question-status-sheet-changed');
     },
   }
 }
</script>

<template>
  <PreviewModalQuestion
    :answerSheet="quiz.masterAnswerSheet"
    :maybeQuiz="quiz"
    :previewSelectedQuestion="previewSelectedQuestion"
    :questionNumberMax="questionNumberMax"
    :previewShowTeamAnswerScore="actualPreviewShowTeamAnswerScore"
    @copy-preview-question="copyPreviewQuestion($event)"
    @question-status-sheet-changed="onQuestionStatusSheetChanged"
    v-on="$listeners"
  >
    <template #modal-title>
      <slot name="modal-title"></slot>
    </template>
    <template #left-footer>
      <div class="flex-grow-1 ml-1">
        <span>
          <span
            v-if="previewSelectedQuestion.answerTrivia"
            key="withAnswerTrivia"
            v-b-popover.hover.focus.topright.v-info="previewSelectedQuestion.answerTrivia"
          >
            <strong>
              <font-awesome-icon :icon="['far', 'lightbulb']" /> Answer Trivia
            </strong>
            <a href="" @click.prevent="">view</a>
          </span>
          <span v-else key="withoutAnswerTrivia">
            <font-awesome-icon :icon="['far', 'lightbulb']" /> Answer Trivia: none
          </span>
        </span>
        <span v-if="isPreviewQuestionRevealed" class="ml-3">
          <font-awesome-icon
            :icon="['fas', 'user-friends']"
            class="mr-1"
            title="The scoring and correct answer for this question have been revealed to the teams. They can no longer submit answers for this question."
          />
        </span>

      </div>
    </template>
  </PreviewModalQuestion>
</template>
