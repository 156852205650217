export default {
   methods: {
     ensureRouteName(name) {
       if ( this.$router.currentRoute.name === name ) return false;
       return this.$router.push({ name: name });
     },
     ensureRoutePath(path) {
       if ( (this.$router.currentRoute.path || "/") === path ) return false;
       return this.$router.push(path);
     },
   },
};
