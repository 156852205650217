
<script>

 export default {
   name: "QuestionRevealButton",
   components: {
   },
   props: {
     quiz: Object,
     selectedQuestion: Object,
     buttonSize: { type: String, default: "sm" },
   },

   data() {
     return {
       shouldShowConfirmationModal: true,
     };
   },

   computed: {
     displayRevealButton() {
       return this.quiz.session.sessionStatus.canRevealIndividualAnswers;
     },

     buttonVariant() {
       if ( this.quiz.session.sessionStatus.isMarkingAnswers ) return 'primary';
       return 'outline-primary';
     },

     buttonText() {
       return this.quiz.isMasterQuestionRevealed( this.selectedQuestion ) ?
              `Q${this.selectedQuestionNumber} is revealed` :
              `Reveal Q${this.selectedQuestionNumber} to teams`;
     },
     isRevealed() {
       return this.quiz.isMasterQuestionRevealed( this.selectedQuestion );
     },
     buttonTitle() {
       const instruction = this.quiz.doesMasterQuestionHaveUnmarkedAnswers(
         this.selectedQuestion,
       ) ? " There are unmarked answers for this question!" : "";
       return this.isRevealed ?
              "The scoring and correct answer for this question have been revealed to the teams. They can no longer submit answers for this question." :
              `Reveal scoring and correct answer for this question to the teams. After this they can no longer submit answers for this question!${instruction}`;
     },

     isDisabled() {
       return ! this.quiz.canRevealMasterQuestion( this.selectedQuestion );
     },
     selectedQuestionNumber() {
       return this.quiz.questionNumber( this.selectedQuestion );
     },

     showSubmitWarning() {
       return ! this.quiz.session.sessionStatus.isMarkingAnswers;
     },
   },

   methods: {
     revealSelectedQuestion() {
       if ( this.shouldShowConfirmationModal ) {
         this.$bvModal.show('bv-modal-reveal-question');
       }
       else {
         this.clickModalRevealQuestion();
       }
     },

     clickModalRevealQuestion() {
       this.$bvModal.hide('bv-modal-reveal-question');

       this.quiz.revealMasterQuestion( this.selectedQuestion );
       this.$emit('question-status-sheet-changed');
       this.$bvToast.toast(
         `The teams can now see the correct answer and their score. They can no longer submit answers for Q${this.selectedQuestionNumber}.`,
         {
           title: `Revealed Q${this.selectedQuestionNumber}`,
           variant: "success",
           autoHideDelay: 2000,
         }
       );
     },

     clickModalCancel() {
       this.$bvModal.hide('bv-modal-reveal-question');
     },

   },
 };

</script>

<template>
  <span
    v-if="displayRevealButton && selectedQuestion" key="display-reveal-button"
    :title="buttonTitle"
  >
    <b-button
      @click.prevent="revealSelectedQuestion"
      class="ml-3"
      :size="buttonSize"
      :disabled="isDisabled"
      :variant="buttonVariant"
    >
      {{buttonText}}
      <span v-if="isRevealed" class="ml-1">
        <font-awesome-icon :icon="['fas', 'user-friends']" class="mr-1" />
      </span>
    </b-button>

    <b-modal id="bv-modal-reveal-question" hide-footer>
      <template v-slot:modal-title>
        Reveal the correct answer to the teams?
      </template>
      <div class="d-block ml-3">
        When you reveal Q{{selectedQuestionNumber}} the teams can
        <ul>
          <li>
            see the correct answer for this question
          </li>
          <li>
            see their own score
          </li>
          <li v-if="showSubmitWarning">
            <strong>no longer submit answers to Q{{selectedQuestionNumber}}</strong>
          </li>
        </ul>

        <div v-if="showSubmitWarning" class="text-center">
          <strong>
            Tell the teams to submit their Q{{selectedQuestionNumber}} answers first!
          </strong>
        </div>

      </div>

      <div class="d-block ml-3 mt-3">
        <details>
          <summary>Other things to think about</summary>
          <ul class="mt-2">
            <li>
              Good idea: <strong>mark submitted answers</strong> before revealing the question
              <ul>
                <li>but you can mark answers at any time</li>
              </ul>
            </li>
            <li>
              <strong>First</strong> reveal the question, <strong>then</strong> read
              out the correct answer so they can see what you're talking
              about
            </li>
          </ul>
        </details>
      </div>
      <b-row class="mt-4">
        <b-col>
          <b-button
            block
            @click="clickModalCancel()">Cancel</b-button>
        </b-col>
        <b-col>
          <b-button
            variant="primary"
            block
            @click="clickModalRevealQuestion()">Reveal Question</b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
        <div class="text-left mt-2 mx-2">
          <b-form-checkbox size="sm" v-model="shouldShowConfirmationModal">
            Keep showing this message for each question
          </b-form-checkbox>
        </div>

        </b-col>
      </b-row>


    </b-modal>
  </span>
</template>

<style scoped>

</style>
