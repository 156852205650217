<script>

 export default {
   name: 'TeamGameAnswerMultiAnswerOption',
   components: {
   },
   props: [
     'teamQuestion',
     'teamAnswer',
     'optionIndex',
     'teamAnswerOption',
     'isDisabled',
   ],

   computed: {

     isSelected() {
       return this.teamAnswer.isMultiChoiceOptionSelected(this.optionIndex);
     },

     buttonVariant() {
       const isDisplayCorrect =
         this.teamAnswerOption.isCorrect
         && this.teamQuestion.shouldRevealAnswer;
       let color = isDisplayCorrect ? "success" : "primary";
       let style = this.isSelected ? "" : "outline-";
       return `${style}${color}`;
     },
   },

   methods: {
     clickButton() {
       this.$refs.button.blur();
       if ( this.isDisabled ) return;
       this.teamAnswer.selectMultiChoiceOption(this.optionIndex);
       this.$emit('confirm-multi-choice-answer');
     },
   },
 };

</script>


<template>
  <b-button
    ref="button"
    @click.prevent="clickButton"
    :variant="buttonVariant"
    class="is-answer-option w-100 h-100"
  >
    {{teamAnswerOption.answer}}
  </b-button>
</template>

<style>

 .is-answer-option.btn-success {
   background: var(--correct-bg);
   font-weight: bold;
 }

 .is-answer-option.btn-success:hover {
   background: var(--correct-bg);
   border: solid 1px #000;
 }

 .is-answer-option.btn-outline-success {
   border: solid 1px var(--correct-bg);
   font-weight: bold;
   border: solid 2px;
 }

</style>

<style scoped>

 .is-answer-option {
   padding: 0.2rem;
   padding-left: 1rem;
   padding-right: 1rem;

   margin-top: 0.4rem;
 }

</style>
