
import _ from 'lodash';

import TeamQuestionsMetadata from '../team/questions-metadata.js';


export default class MasterQuestionsMetadata {

  constructor({ sessionId, masterAnswerScoreSheets, teamAnswerSheets }) {
    return {
      sessionId, ///JPL: not needed?

      // Don't coerce into objects, since that will kill reactivity of
      // masterAnswerScoreSheets
      masterAnswerScoreSheets,
      teamAnswerSheets,

      rawQuestionIdToMetadata: null,



      // Avoid recomputing this for every usage of the data structure
      get computedQuestionIdToMetadata() {

        const questionIdToMetadata = {};
        const getMetadata = (questionId) => {
          return questionIdToMetadata[questionId] =
            questionIdToMetadata[questionId] || {
              questionId: questionId,
              actualPointsAwarded: 0,
              correctCount: 0,
              incorrectCount: 0,
              upvoteCount: 0,
              downvoteCount: 0,
              voteCount: 0,
            };
        };
        this.masterAnswerScoreSheets.forEach(masterAnswerScoreSheet => {
          Object.entries( masterAnswerScoreSheet.questionIdToMasterAnswerScore )
            .forEach(([questionId, masterAnswerScore]) => {
              let questionMetadata = getMetadata(questionId);
              questionMetadata.actualPointsAwarded += masterAnswerScore.actualPointsAwarded;
              if (masterAnswerScore.isCorrect) {
                questionMetadata.correctCount++;
              }
              else {
                questionMetadata.incorrectCount++;
              }
            });
        });


        this.teamAnswerSheets.forEach(teamAnswerSheet => {
          Object.entries( teamAnswerSheet.questionIdToTeamAnswer )
            .forEach(([questionId, teamAnswer]) => {
              let questionMetadata = getMetadata(questionId);
              if (teamAnswer.questionVote === true) {
                questionMetadata.upvoteCount++;
                questionMetadata.voteCount++;
              }
              else if (teamAnswer.questionVote === false) {
                questionMetadata.downvoteCount++;
                questionMetadata.voteCount--;
              }
            });
        });

        return questionIdToMetadata;
      },

      // Note: this is not reactive, so if anything changes the underlying
      // masterAnswerScoreSheets,
      // teamAnswerSheets,
      // you should re-create this entire object and this.$set() it again
      get questionIdToMetadata() {
        if ( this.rawQuestionIdToMetadata ) return this.rawQuestionIdToMetadata;
        return this.rawQuestionIdToMetadata = this.computedQuestionIdToMetadata;
      },

      get questionCount() {
        return Object.keys( this.questionIdToMetadata ).length;
      },

      get questionsMetadataOrderedByCorrect() {
        return _.sortBy(
          Object.values(this.questionIdToMetadata),
          questionMetadata => questionMetadata.correctCount,
          questionMetadata => questionMetadata.actualPointsAwarded,
          questionMetadata => 0 - questionMetadata.incorrectCount,
          questionMetadata => questionMetadata.questionId, // Stable sort order
        );
      },

      get hardestCorrectCount() {
        return this.questionsMetadataOrderedByCorrect[0].correctCount;
      },

      get questionsMetadataOrderedByUpvotes() {
        return _.sortBy(
          Object.values(this.questionIdToMetadata),
          questionMetadata => questionMetadata.upvoteCount,
          questionMetadata => questionMetadata.voteCount,
          questionMetadata => questionMetadata.questionId, // Stable sort order
        );
      },

      get questionsMetadataOrderedByDownvotes() {
        return _.sortBy(
          Object.values(this.questionIdToMetadata),
          questionMetadata => questionMetadata.downvoteCount,
          questionMetadata => 0 - questionMetadata.voteCount,
          questionMetadata => questionMetadata.questionId, // Stable sort order
        );
      },

      get questionsMetadataOrderedByVotes() {
        return _.sortBy(
          Object.values(this.questionIdToMetadata),
          questionMetadata => questionMetadata.voteCount,
          questionMetadata => questionMetadata.upvoteCount,
          questionMetadata => questionMetadata.questionId, // Stable sort order
        );
      },

      // Are there too many to highlight? If so, don't show any
      areThereTooManyHardQuestions(questionCount) {
        return questionCount > 2;
      },

      get hardestQuestionsMetadata() {
        const questionsMetadata = this.questionsMetadataOrderedByCorrect.filter(
          questionMetadata => questionMetadata.correctCount === this.hardestCorrectCount,
        );

        if ( this.areThereTooManyHardQuestions(questionsMetadata.length) ) {
          return [];
        }

        return questionsMetadata;
      },

      // Questions with a positive vote balance
      // but it's still ok if it's got only one downvote
      get popularQuestionsMetadata() {
        const questionsMetadata = this.questionsMetadataOrderedByVotes.filter(
          questionMetadata => questionMetadata.upvoteCount > 0,
        ).filter(
          questionMetadata => questionMetadata.voteCount >= 0,
        ).reverse();
        return this.clampArrayLength(
          questionsMetadata,
          5,
          10, Math.ceil( this.questionCount / 2 ),
        );
      },

      clampArrayLength(array, minCount, ...maxCounts) {
        if ( array.length <= minCount ) return array;
        const actualMaxCount = _.min(maxCounts);
        if ( array.length <= actualMaxCount ) return array;
        return array.slice(0, actualMaxCount);
      },

      // Questions with at least one downvote
      get unpopularQuestionsMetadata() {
        const questionsMetadata = this.questionsMetadataOrderedByDownvotes.filter(
          questionMetadata => questionMetadata.downvoteCount > 0,
        ).reverse();
        return this.clampArrayLength(
          questionsMetadata,
          1, 2,
        );
      },

      isQuestionHardest(question) {
        return this.hardestQuestionsMetadata.find(
          questionMetadata => questionMetadata.questionId === question.id,
        );
      },

      get easiestCorrectCount() {
        return this.questionsMetadataOrderedByCorrect.slice(-1)[0].correctCount;
      },

      get easiestQuestionsMetadata() {
        const questionsMetadata = this.questionsMetadataOrderedByCorrect.filter(
          questionMetadata => questionMetadata.correctCount === this.easiestCorrectCount,
        );

        if ( this.areThereTooManyHardQuestions(questionsMetadata.length) ) {
          return [];
        }

        return questionsMetadata;
      },

      isQuestionEasiest(question) {
        return this.easiestQuestionsMetadata.find(
          questionMetadata => questionMetadata.questionId === question.id,
        );
      },

      isQuestionPopular(question) {
        return this.popularQuestionsMetadata.find(
          questionMetadata => questionMetadata.questionId === question.id,
        );
      },

      get asTeamQuestionsMetadata() {
        return new TeamQuestionsMetadata({
          hardestQuestionsMetadata: this.hardestQuestionsMetadata,
          easiestQuestionsMetadata: this.easiestQuestionsMetadata,
          popularQuestionsMetadata: this.popularQuestionsMetadata,
        });
      },
    };
  }
}
