
import ModelBase from '../model-base.js';

import TeamAnswer from './answer.js';
import TeamAnswerOption from './answer-option.js';

export default class TeamQuestion extends ModelBase {
  constructor({ questionId, question, answerEntryType = "freeText", answerOptions, shouldRevealAnswer, shouldRevealAnswerScore, canTeamSubmitAnswer, revealCorrectAnswer, revealAnswerTriviaHtml, points, ordinal, imageUrl }) {
    super();
    return {
      ...this,
      questionId,
      points: this.ensureNumeric(points),
      ordinal,
      question,
      answerEntryType,
      answerOptions: this.ensureArrayObjects(answerOptions, TeamAnswerOption),
      shouldRevealAnswer,
      shouldRevealAnswerScore,
      canTeamSubmitAnswer,
      revealCorrectAnswer,
      revealAnswerTriviaHtml,
      imageUrl,

      newTeamAnswer(teamId) {
        return new TeamAnswer({
          teamId: teamId,
          questionId: this.questionId,
        });
      },

      get isAnswerTypeFreeText() {
        return this.answerEntryType === "freeText";
      },
      get isAnswerTypeMultiChoice() {
        return this.answerEntryType === "multiChoice";
      },
    };
  }
}
