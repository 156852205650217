'use strict';

export default class UiSelectable {
  constructor({ isSelected = false, isConfirmed = false, item}) {
    return {
      isSelected,
      isConfirmed,
      item,

      toggleSelected() {
       this.isSelected = ! this.isSelected;
      },
    };
  }
}
