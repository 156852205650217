<script>

 import TopNavBar from '@/components/common/TopNavBar.vue';
 import BottomFooter from '@/components/common/BottomFooter.vue';
 import AdminAnswerSheetSessions from '@/components/admin/AdminAnswerSheetSessions.vue';

 export default {
   name: 'AdminUserAccountsPage',
   components: {
     TopNavBar,
     BottomFooter,
     AdminAnswerSheetSessions,
   },

   data() {
     return {
       userAccounts: [],
     };
   },

   async created() {
     const userAccounts = await this.$store.dispatch('adminUserAccount/fetchList');
     this.userAccounts = userAccounts;
   },

 }

</script>

<template>
  <div class="is-footer-flex-container">
    <TopNavBar />

    <b-container class="is-footer-flex-content">

      <h2>User Accounts</h2>

      <h5 class="mt-4">
        {{userAccounts.length}} User Accounts
      </h5>

      <b-table-simple class="mt-4">
        <b-thead>
          <b-tr>
            <b-th>Email</b-th>
            <b-th>Created time</b-th>
            <b-th>Quizzes</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="userAccount in userAccounts" :key="userAccount.id">
            <b-td>
              {{userAccount.username}}
            </b-td>
            <b-td class="text-nowrap" :title="userAccount.createdTime.toISOString()">
              {{userAccount.createdTime.toISOString().split("T")[0]}}
            </b-td>
            <b-td class="py-2">
              <b-table-simple
                v-if="userAccount.masterAnswerSheets.length"
                class="quiz-list mb-0"
                small
              >
                <b-tbody>
                  <b-tr v-for="masterAnswerSheet in userAccount.masterAnswerSheets.slice().reverse()" :key="masterAnswerSheet.id">
                    <b-td class="text-nowrap">
                      {{ masterAnswerSheet.updatedTime.toISOString().split("T")[0] }}
                    </b-td>
                    <b-td class="pl-3 w-100">
                      <AdminAnswerSheetSessions
                        :masterAnswerSheet="masterAnswerSheet"
                      />
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

    </b-container>

    <BottomFooter />
  </div>
</template>

<style scoped>

 .table.quiz-list > tbody > tr:first-child > td {
    border: none;
}

</style>
