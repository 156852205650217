'use strict';

import MasterQuestion from '../master/question.js';
import LibraryTag from './tag.js';

export default class LibraryQuestion extends MasterQuestion {
  constructor(args) {
    super(args);
    let {
      contributorId,
      libraryCategoryId,
      libraryDifficultyId,
      libraryTags,
    } = args;

    return {
      ...this,
      contributorId,
      libraryCategoryId,
      libraryDifficultyId,
      libraryTags: this.ensureArrayObjects(libraryTags, LibraryTag),
      ///JPL: createdTime,

      get asMasterQuestion() {
        return new MasterQuestion(this);
      },

      matchesRegex(filterRegexString) {
        let regex;
        try {
          regex = new RegExp(filterRegexString, 'i');
        }
        catch (e) {
          return false;
        }

        const checkRegex = (string) => {
          string = string || "";
          return !! string.match(regex);
        };

        if ( checkRegex(this.question ) )           return true;
        if ( checkRegex(this.correctAnswer ) )      return true;
        if ( checkRegex(this.correctMultiAnswer ) ) return true;
        if ( checkRegex(this.answerTrivia ) )       return true;
        if ( checkRegex(this.revealAnswerTrivia ) ) return true;
        if ( checkRegex(this.imageUrl ) )           return true;

        return false;
      },

      get libraryTagIds() {
        return this.libraryTags.map(libraryTag => libraryTag.id);
      },

    };
  }

  static newFromMasterQuestion(masterQuestion, libraryQuestionArgs = {}) {
    return new LibraryQuestion({ ...masterQuestion, ...libraryQuestionArgs });
  }

}
