<script>

 export default {
   name: 'TeamAnswerPointsButtons',
   props: [
     'teamAnswer',
     'masterAnswerScore',
   ],

   computed: {
     isCorrectButtonDisabled() {
       if ( ! this.teamAnswer.isConfirmed ) return true;
       if ( this.masterAnswerScore.isCorrect === true ) return true;
       return false;
     },
     isIncorrectButtonDisabled() {
       if ( ! this.teamAnswer.isConfirmed ) return true;
       if ( this.masterAnswerScore.isCorrect === false ) return true;
       return false;
     },
     isButtonGrayedOut() {
       return ! this.teamAnswer.isConfirmed;
     }
   },

   methods: {
     markCorrect() {
       this.masterAnswerScore.markCorrect();
       this.onMasterAnswerScoreChanged();
     },
     markIncorrect() {
       this.masterAnswerScore.markIncorrect();
       this.onMasterAnswerScoreChanged();
     },

     onMasterAnswerScoreChanged() {
       this.$emit('master-answer-score-changed');
     },
   },
 }
</script>

<template>
  <b-input-group class="team-points-group">
    <b-input-group-append>
      <b-button
        @click.prevent="markCorrect()"
        :disabled="isCorrectButtonDisabled"
        :class="{ grayscale: this.isButtonGrayedOut }"
        class="marking-button"
        :title="`Give ${masterAnswerScore.pointsAwarded} point${this.maybePlural(masterAnswerScore.pointsAwarded)} for a correct answer`"
        size="sm" variant="primary">
        <font-awesome-icon :icon="['fas', 'check']" />
      </b-button>
      <b-button
        @click.prevent="markIncorrect()"
        :disabled="isIncorrectButtonDisabled"
        :class="{ grayscale: this.isButtonGrayedOut }"
        class="marking-button"
        title="Mark this answer as incorrect"
        size="sm" variant="primary">
        <font-awesome-icon :icon="['fas', 'times']" />
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<style>
 /* Ignore mouse events on disabled buttons so they can be handled by
 the @click on the question column */
 .team-points-group button.btn[disabled] {
   pointer-events: none;
 }

</style>

<style scoped>

 .team-points-group {
   width: 3.3em;
 }

 .marking-button {
   width: 2rem;
 }

 .grayscale {
   -webkit-filter: grayscale(100%);
   filter: grayscale(100%);
 }

</style>
