
import _ from 'lodash';

import ModelBase from '../model-base.js';
import TeamQuestion from './question.js';
import SessionStatus from '../session-status.js';
import TeamQuestionsMetadata from './questions-metadata.js';

export default class TeamSession extends ModelBase {
  ///JPL: rename sessionId to masterSessionId
  constructor({
    id,
    sessionId,
    quizName,
    teamNames,
    sessionStatus,
    totalQuestionCount,
    teamQuestions = [],
    teamPlacements = [],
    teamQuestionsMetadata = null,
    preloadImageUrl = null,
  }) {
    super();
    return {
      ...this,
      id,         ///JPL: used?
      sessionId,  ///JPL: used? It's undefined?
      quizName,
      teamNames: teamNames || [],
      sessionStatus: this.ensureObject(sessionStatus, SessionStatus),
      totalQuestionCount,
      teamQuestions: this.ensureArrayObjects(teamQuestions, TeamQuestion),
      teamPlacements,
      teamQuestionsMetadata: this.ensureObject(teamQuestionsMetadata, TeamQuestionsMetadata),
      preloadImageUrl,


      get currentQuestionNumber() {
        return this.teamQuestions.length;
      },

      get totalPointsPossible() {
        return this
          .teamQuestions
          .map(teamQuestion => teamQuestion.points)
          .reduce((total, points) => total += points, 0);
      },

      teamQuestionById(questionId) {
        return this.teamQuestions.find(
          teamQuestion => teamQuestion.questionId === questionId,
        );
      },

      get revealedQuestionIds() {
        return this.teamQuestions
          .filter(teamQuestion => teamQuestion.shouldRevealAnswer)
          .map(teamQuestion => teamQuestion.questionId);
      },

      get firstUnrevealedTeamQuestion() {
        return this.teamQuestions
          .find(teamQuestion => ! teamQuestion.shouldRevealAnswer);
      },

      // Return most recently revealed teamQuestion that isn't
      // mentioned in currentRevealedQuestionIds
      justRevealedTeamQuestion(currentRevealedQuestionIds) {
        const newQuestionIds = _.xor(
          this.revealedQuestionIds,
          currentRevealedQuestionIds,
        ).sort();
        if ( ! newQuestionIds.length ) return null;
        return this.teamQuestionById(newQuestionIds[0]);
      },

    };
  }
}

