
<script>

 import WithUserSession from '@/components/mixins/WithUserSession';
 import WithAnalytics from '@/components/mixins/WithAnalytics';

 export default {
   name: 'LoginCard',
   mixins: [
     WithUserSession,
     WithAnalytics,
   ],
   props: [
     'redirect',
     'clickSignupEvent',
   ],

   data() {
     return {
       username: null,
       password: "",
       shouldRememberUsername: false,

       errorMessage: "",
     }
   },

   created() {
     if ( this.rememberedUsername ) {
       this.username = this.rememberedUsername;
       this.shouldRememberUsername = true;
       ///JPL: focus on password, maybe if prop setFocus is true
     }
     else {
       ///JPL: focus on username, maybe if prop setFocus is true
     }
   },

   computed: {
     rememberedUsername() { return this.userSession.rememberedUsername },
   },

   methods: {
     async logIn() {
       this.errorMessage = "";
       const { error } = await this.$store.dispatch(
         'auth/attemptLogin',
         [
           this.username,
           this.password,
           this.shouldRememberUsername,
         ],
       );

       if ( error ) {
         this.errorMessage = error;
       }
       else {
         this.logEvent("login", { event_label: "email" });
         if ( this.redirect ) {
           this.$router.push("/");
         }
       }
     },

     clickSignup() {
       this.$emit('click-signup');
     },
   }

 };

</script>


<template>
  <b-card header="I'm a regular - Log in" class="mb-5">
    <b-form autocomplete="on">
      <b-form-group label="Username:">
        <b-form-input
          v-model="username"
          placeholder="E-mail"
          name="email"
          id="email"
          type="email"
          autocomplete="section-login username"
          required />
      </b-form-group>
      <b-form-group label="Password:">
        <b-form-input
          v-model="password"
          name="password"
          id="password"
          autocomplete="section-login current-password"
          type="password"
          required />
      </b-form-group>
      <b-form-group title="Remember the username on this computer">
        <b-form-checkbox v-model="shouldRememberUsername">
          Remember my username
        </b-form-checkbox>
      </b-form-group>

      <div v-if="errorMessage" class="text-danger">
        {{errorMessage}}
      </div>

      <div class="text-right">
        <b-button type="submit" @click.prevent="logIn()" variant="primary" class="mr-3">Log in!</b-button>
        <span class="text-right">
          Or
          <span v-if="clickSignupEvent">
            <a @click.prevent="clickSignup" href="#">Sign up!</a>
          </span>
          <span v-else>
            <router-link to="/sign-up">Sign up!</router-link>
          </span>
        </span>
      </div>
    </b-form>
  </b-card>

</template>

<style scoped>

</style>
