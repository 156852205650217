'use strict';

import ModelBase from '../model-base.js';

export default class LibraryTag extends ModelBase {
  constructor({ id, name, description, isSpecialInterest = false, createdTime, updatedTime }) {
    super();
    return {
      id,
      name,
      description,
      isSpecialInterest,
      createdTime: this.ensureDate(createdTime),
      updatedTime: this.ensureDate(updatedTime),

      // For the vue-tags-input component to pick tags
      getVueTagsInputTag() {
        // Can't be a getter, since it will stringify and create a
        // circular ref with `this`
        return {
          id: this.id,
          type: "libraryTag",
          item: this,
          text: this.name,
          classes: "is-library-tag is-vue-tags-input",
        };
      },
    };
  }
}
