
<script>
 export default {
   name: "ModalConfirm",
   props: [
     "elementId",
   ],

   methods: {
     clickModalConfirm() {
       this.$bvModal.hide(this.elementId);
       this.$emit('click-ok');
     },
     clickModalCancel() {
       this.$bvModal.hide(this.elementId);
       this.$emit('click-cancel');
     },
   },
 };

</script>

<template>
  <span>
    <b-modal :id="elementId" hide-footer>
      <template v-slot:modal-title>
        <slot name="title"></slot>
      </template>
      <div class="d-block text-center">
        <slot name="message"></slot>
      </div>
      <b-row>
        <b-col>
          <b-button
            variant="primary"
            class="mt-3"
            block
            @click="clickModalConfirm()">OK</b-button>
          <b-button
            class="mt-3"
            block
            @click="clickModalCancel()">Cancel</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

