
<script>

 import QuestionDifficulty from '@/components/elements/QuestionDifficulty.vue';
 import IconHeart from '@/components/quizmaster/run/Icon/Heart.vue';

 export default {
   name: "TeamGame",
   components: {
     QuestionDifficulty,
     IconHeart,
   },
   props: [
     'teamQuestion',
     'questionMetadata',
     'teamQuestionsMetadata',
   ],

   data() {
     return {
     };
   },

   computed: {
     isTeamQuestionHardest() {
       return this.teamQuestionsMetadata.isQuestionHardest(this.teamQuestion);
     },
     isTeamQuestionEasiest() {
       return this.teamQuestionsMetadata.isQuestionEasiest(this.teamQuestion);
     },
   },

 }
</script>

<template>
  <div class="d-flex mb-3">

    <div class="text-nowrap mr-2">
      <small class="mr-1">
        <strong>{{questionMetadata.voteCount}}</strong>
        x</small>
      <IconHeart class="mr-1" />
    </div>

    <div class="flex-grow-1">

      <strong>
        Q{{teamQuestion.ordinal}}:
      </strong>

      {{teamQuestion.question}}

      <span>
        <span
          v-if="isTeamQuestionHardest"
        >
          <QuestionDifficulty :difficulty="3" title="This was a hard one!" />
        </span>
        <span v-else-if="isTeamQuestionEasiest">
          <QuestionDifficulty :difficulty="1" title="Easy question" />
        </span>
      </span>

    </div>

    <div v-if="teamQuestion.imageUrl">
      <img :src="teamQuestion.imageUrl" class="is-image ml-2" />
    </div>

  </div>
</template>

<style scoped>

 .is-image {
   max-width: 5rem;
   max-height: 4rem;
 }

</style>
