
export default class TeamAnswer {
  constructor({ id, teamId, questionId, answer, multiChoiceSelectedOptionIndexes = [], isConfirmed, questionVote = null }) {
    return {
      id, ///JPL: used?
      teamId,
      questionId,
      answer,
      multiChoiceSelectedOptionIndexes,
      isConfirmed: (!! isConfirmed) || false,
      questionVote, // null / true (up), false (down)


      // Return true if the value changed so we can emit a notification
      confirmAnswer()   { return this._setIsConfirmed(true)  },
      unConfirmAnswer() { return this._setIsConfirmed(false) },
      _setIsConfirmed(newValue) {
        const original = this.isConfirmed;
        this.isConfirmed = newValue;
        return original !== this.isConfirmed;
      },

      // Does this contain an answer that can be scored?
      get isEmpty() {
        if ( ! this.isConfirmed          ) return true;
        if (   this.answer === undefined ) return true;
        if (   this.answer === null      ) return true;
        if (   this.answer === ""        ) return true;
        return false;
      },

      isMultiChoiceOptionSelected(optionIndex) {
        return this.multiChoiceSelectedOptionIndexes.indexOf(optionIndex) >= 0;
      },

      selectMultiChoiceOption(optionIndex) {
        if ( this.isMultiChoiceOptionSelected(optionIndex) ) {
          this.multiChoiceSelectedOptionIndexes = [ ];
        } else {
          this.multiChoiceSelectedOptionIndexes = [ optionIndex ];
        }
      },

      multiChoiceAnswer(teamQuestion) {
        return this.multiChoiceSelectedOptionIndexes.map(
          index => teamQuestion.answerOptions[index].answer,
        ).join(", ");
      },

      effectiveAnswer(masterQuestion) {
        if ( masterQuestion.isAnswerEntryTypeFreeText ) {
          return this.answer;
        }
        else if ( masterQuestion.isAnswerEntryTypeMultiChoice ) {
          return this.multiChoiceAnswer(masterQuestion);
        }
        console.log(`Missing answerEntryType`, this, masterQuestion);
        return "";
      }
    };
  }
}

