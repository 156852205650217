
<script>

 import WithAnalytics from '@/components/mixins/WithAnalytics';

 export default {
   name: "AskNextQuestionButton",
   mixins: [
     WithAnalytics,
   ],
   props: {
     'quiz': { required: true },
     'isCurrentQuestionTheLastOne': { required: true },
   },

   data() {
     return {
       hasAllowedMoreQuestions: false,
     };
   },

   computed: {
     askNextQuestionButtonVariant() {
       return this.isCurrentQuestionTheLastOne ? "secondary" : "primary";
     },
     askNextQuestionButtonTitle() {
       return this.isCurrentQuestionTheLastOne
            ? "Ask a New Question"
            : `Ask Next Question (<strong>${this.quiz.currentQuestionNumber + 1} of ${this.quiz.questionCount}</strong>)`;
     },
     nextQuestionText() {
       const nextQuestion = this.quiz.nextQuestion;
       if ( ! nextQuestion ) return null;
       return nextQuestion.question;
     },
   },

   methods: {
     selectPreviousQuestion() {
       this.$emit("select-previous-question");
     },
     selectNextQuestion() {
       this.$emit("select-next-question");
     },
     selectCurrentQuestion() {
       this.$emit("select-current-question");
     },

     clickAskNextQuestion() {
       if ( this.isCurrentQuestionTheLastOne ) {
         if ( ! this.hasAllowedMoreQuestions ) {
           this.logAskNextQuestionConfirmation();
           this.$bvModal.show('bv-modal-allow-more-questions');
           return;
           //
         }
       }
       this.$emit('ask-next-question');
     },

     clickModalAllowMoreQuestions() {
       this.$bvModal.hide('bv-modal-allow-more-questions');
       this.hasAllowedMoreQuestions = true;
       this.logAskNextQuestionConfirmationAllow();
       this.clickAskNextQuestion();
     },
     clickModalFinishQuestions() {
       this.$bvModal.hide('bv-modal-allow-more-questions');
       this.$emit('finish-questions');
       this.logAskNextQuestionConfirmationFinish();
     },


     logAskNextQuestionConfirmation() {
       this.logEvent('ask_next_question_confirmation', { event_category: 'run', event_value: this.quiz.currentQuestionNumber });
     },
     logAskNextQuestionConfirmationFinish() {
       this.logEvent('ask_next_question_confirmation_finish', { event_category: 'run', event_value: this.quiz.currentQuestionNumber });
     },
     logAskNextQuestionConfirmationAllow() {
       this.logEvent('ask_next_question_confirmation_allow', { event_category: 'run', event_value: this.quiz.currentQuestionNumber });
     },
   },
 };

</script>

<template>

  <span>
    <b-button
      @click.prevent="clickAskNextQuestion()"
      :variant="askNextQuestionButtonVariant"
      size="md"
      :title="nextQuestionText"
    ><span v-html=askNextQuestionButtonTitle class="text-nowrap" /></b-button>

    <b-modal id="bv-modal-allow-more-questions" hide-footer>
      <template v-slot:modal-title>
        Finished asking questions?
      </template>
      <div class="d-block text-center">
        <p>
          That was the last question in the answer sheet.
        </p>
        <p>
          You probably want to move on and let the teams<br />
          <strong>Submit all Answers</strong>.
        </p>
        <p>
          But if you want to ask more questions you can do that.
        </p>
      </div>
      <b-row>
        <b-col>
          <b-button
            variant="primary"
            class="mt-3"
            block
            @click="clickModalFinishQuestions()">Submit All Answers</b-button>
          <b-button
            class="mt-3"
            block
            @click="clickModalAllowMoreQuestions()">Ask a New Question</b-button>
        </b-col>
      </b-row>

    </b-modal>
  </span>


</template>

<style scoped>

</style>
