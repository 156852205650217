
import axios from '@/store/axios';
import TeamTeam from '../../../models/team/team.js';

export default {
  namespaced: true,
  state: {
    teamSessionTeams: [],
  },

  mutations: {
  },

  getters: {
  },

  actions: {
    // Return promise of teamTeam with session and id, after
    // having fetched it from the API. Don't commit it to the Store.
    async fetchObject(context, { sessionId, id }) {
      if ( ! this.$app.$root.callApi ) {
        ///JPL: fish out of master.answerSession, like on the backend
        return null;
      }

      return await axios.get(`/api/team/session/${sessionId}/team/${id}`)
        .then((res) => {
          const teamTeam = res.data.teamTeam || {}; // JPL: or throw
          return new TeamTeam( teamTeam );
        });
      ///JPL: catch
    },
  },
};
