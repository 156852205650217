<script>

 import TopNavBar from '@/components/common/TopNavBar.vue';
 import BottomFooter from '@/components/common/BottomFooter.vue';

 export default {
   name: 'LibraryPage',
   components: {
     TopNavBar,
     BottomFooter,
   },
 }

</script>

<template>
  <div class="is-footer-flex-container">
    <TopNavBar />

    <b-container class="is-footer-flex-content">

      <h2>Quiz Library</h2>

      <p>Pre-made questions you can use to create a quiz of your own.</p>

      <p>Please consider <strong>donating your own questions</strong> to the library!</p>

      <ul>
        <li>
          <router-link to="/library/questions">Question Library</router-link>
        </li>
        <li>
          Quiz Library
        </li>
      </ul>

    </b-container>

    <BottomFooter />
  </div>
</template>

<style scoped>

</style>
