
<script>

 import WithCopyPasteQuestion from '@/components/quizmaster/mixins/WithCopyPasteQuestion';
 import WithSpreadsheetImport from '@/components/quizmaster/mixins/WithSpreadsheetImport';

 export default {
   name: 'EditAnswerSheetImportQuestions',
   mixins: [
     WithCopyPasteQuestion,
     WithSpreadsheetImport,
   ],
   props: [
     'answerSheet',
   ],

   computed: {
   },

   methods: {
     async importPasteQuestions() {
       const questionCount = this.answerSheet.questionCount;

       let masterQuestions;
       try {
         masterQuestions = await this.masterQuestionsFromClipboard();
       }
       catch(e) {
         this.$bvToast.toast(
           e,
           {
             title: `Something went wrong`,
             variant: "danger",
           },
         );

         return;
       }
       this.answerSheet.questions.push(...masterQuestions);

       this.$bvToast.toast(
         `${masterQuestions.length} questions were added to the quiz`,
         {
           title: `Questions pasted into the quiz`,
           variant: "success",
         },
       );

       if ( masterQuestions.length > 0 ) {
         this.$nextTick(() => {
           this.$emit('activate-tab-my-questions');
         });
         setTimeout(
           () => this.$emit('focus-question-index', [ questionCount, "start" ]),
           1500,
         );
       }
     },
   },
 }
</script>

<template>
  <div>
    <div class="is-friendly-bg is-friendly-panel pt-4 pb-2 mb-2 px-4 mt-0">
      <b-row>
        <b-col cols="0" lg="5" class="d-none d-lg-block text-right pl-4">
          <img
            src="/unsplash/create/pejmon-hodaee-SeetD-Lu7Nk-unsplash-w25.jpg"
            alt="In the coffee shop"
            class="is-horizontal-import-image mb-3 mr-2"
          />
        </b-col>
        <b-col cols="12" lg="7">
          <h5>
            Import Questions from a spreadsheet
          </h5>
          <b-row>
            <b-col>
              <p>
                <strong>
                  You can import questions from a spreadsheet like MS
                  Excel / Office 365, Google Sheets, Apple Numbers etc.
                </strong>
              </p>
              <p>
                This is useful if you have prepared your quiz in
                advance, or if you need to present the questions
                elsewhere, e.g. in a Twitch stream.
              </p>
            </b-col>
            <b-col>
              <small>
                <p>
                  Each row needs to have a have a certain set of
                  columns, which you can copy here and paste into your
                  spreadsheet.
                </p>

                <p>
                  You can then write the questions in the spreadsheet
                  and copy-paste them into <i>Even Remotely Pub Quiz</i>
                  where they are added to the <strong>My Questions</strong> tab.
                </p>
              </small>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
    </div>

    <b-alert
      v-if="isClipboardDisabled()" show
      variant="danger"
      class="text-center mt-4">
      <small><strong>
        Uh-oh! This browser doesn't support copy-paste.
        <br />
        Please try e.g. Google Chrome.
      </strong></small>
    </b-alert>

    <b-row class="mt-4">
      <b-col offset="1" cols="10">
        <big>
          <ol>
            <li>
              Prepare your spreadsheet
              <small>
                <ul>
                  <li>
                    <div class="float-right">
                      <b-button
                        @click.prevent="importCopyColumns()"
                        :disabled="isClipboardDisabled()"
                        variant="primary" block
                        size="sm"
                        class="mx-2"
                      >
                        <font-awesome-icon :icon="['far', 'copy']" /> Copy Column headings
                      </b-button>
                    </div>
                    <strong>Copy</strong> the required column headings into the clipboard
                  </li>
                  <li>
                    <strong>Paste the column headings</strong> into the top row of a spreadsheet
                  </li>
                  <li>
                    <div class="float-right">
                      <b-button
                        @click.prevent="importCopySampleQuestions()"
                        :disabled="isClipboardDisabled()"
                        variant="outline-primary" block
                        size="sm"
                        class="mx-2"
                      >
                        <font-awesome-icon :icon="['far', 'copy']" /> Copy sample questions
                      </b-button>
                    </div>

                    You can also see what normal column values look
                    like by copying some <strong>sample questions</strong>.
                  </li>
                </ul>
              </small>
            </li>
            <li>
              Write the questions in your spreadsheet

              <small>
                <ul>
                  <li>
                    Empty rows are ignored
                  </li>
                  <li>
                    For multiple-choice options , write each option on its own line
                    <strong>inside the cell</strong>,
                    or use <code>\n</code> to indicate line breaks
                  </li>
                  <li>
                    If you have a double quote <code>"</code> anywhere,
                    depending on your spreadsheet things might get a
                    bit clumsy... If you get an error when
                    importing, try this:

                    <small>
                      <ul>
                        <li>
                          Double up each <code>"</code> in the text, so each <code>"</code> turns into <code>""</code>
                        </li>
                        <li>
                          Begin and end <i>the whole text in the cell</i> with
                          double quotes.
                        </li>
                        <li>
                          Example cell: <code>"Is Alanis Morissette's song ""ironic"" or just inconvenient?"</code>
                        </li>
                      </ul>
                    </small>
                  </li>
                </ul>
              </small>
            </li>
            <li>
              Import the questions from the spreadsheet
              <small>
                <ul>
                  <li>
                    Select the question rows in your spreadsheet
                  </li>
                  <li>
                    <strong>Copy the questions</strong> to the clipboard
                  </li>
                  <li>
                    <div class="float-right">
                      <b-button
                        @click.prevent="importPasteQuestions"
                        :disabled="isClipboardDisabled()"
                        variant="primary" block
                        size="sm"
                        class="mx-2"
                      >
                        <font-awesome-icon :icon="['fas', 'paste']" /> Paste Spreadsheet Questions
                      </b-button>
                    </div>
                    <strong>Paste</strong> in the questions here
                  </li>
                </ul>
              </small>
            </li>
          </ol>
        </big>
      </b-col>
    </b-row>

  </div>
</template>

<style scoped>

 h3 {
   font-size: 1.5em;
 }


 .is-friendly-bg {
   background: #FFF3EC;
 }
 .is-friendly-panel {
   border-bottom-left-radius: 0.7rem;
   border-bottom-right-radius: 0.7rem;
 }

 .is-vertical-import-image {
   border: 1px solid #555;
   border-radius: 5px;
   height: 16rem;
 }
 .is-horizontal-import-image {
   border: 1px solid #555;
   border-radius: 5px;
   width: 18rem;
 }

 ol > li {
   margin-top: 1rem;
 }

</style>
