<script>

 import TopNavBar from '@/components/common/TopNavBar.vue';
 import BottomFooter from '@/components/common/BottomFooter.vue';
 import LoadingSpinner from '@/components/share/LoadingSpinner';

 export default {
   name: 'TermsAndConditionsPage',
   components: {
     TopNavBar,
     BottomFooter,
     LoadingSpinner,
   },

   mounted() {
     this.$store.dispatch('content/fetchTermsAndConditionsHtml');
   },

   computed: {
     termsAndConditionsHtml() {
       return this.$store.state.content.termsAndConditionsHtml;
     },
   },
 }

</script>

<template>
  <div class="is-footer-flex-container">
    <TopNavBar />

    <b-container class="is-footer-flex-content">

      <div id="page-content" class="page-content">
        <h2>Terms &amp; Conditions</h2>

        <div v-if="termsAndConditionsHtml" v-html=termsAndConditionsHtml></div>
        <LoadingSpinner v-else /><!-- if session // Loading screen -->

      </div>

    </b-container>

    <BottomFooter />
  </div>
</template>

<style scoped>

</style>
