
<script>

 import AskNextQuestionButton from '@/components/quizmaster/run/AskNextQuestionButton.vue';
 import PreviewRunModalQuestion from '@/components/quizmaster/Preview/RunModalQuestion.vue';
 import QuestionRevealButton from '@/components/quizmaster/run/QuestionRevealButton.vue';

 export default {
   name: "QuestionBar",
   components: {
     AskNextQuestionButton,
     PreviewRunModalQuestion,
     QuestionRevealButton,
   },
   props: [
     'selectedQuestion',
     'quiz',
     'masterQuestionsMetadata',
     'showFirstQuestionButton',
     'showCurrentQuestion',
     'isCurrentQuestionTheLastOne',
     'showQuestionDifficulty',
   ],

   data() {
     return {
       previewSelectedQuestion: null,
     };
   },

   computed: {
     isCurrentQuestionSelected() {
       if ( ! this.showCurrentQuestion ) return false;
       return this.selectedQuestion === this.quiz.currentQuestion();
     },

     isSelectedQuestionTheFirstQuestion() {
       const index = this.quiz.masterAnswerSheet.getQuestionIndex(
         this.selectedQuestion,
       );
       return index === 0;
     },
     isNextButtonDisabled() {
       const index = this.quiz.masterAnswerSheet.getQuestionIndex(
         this.selectedQuestion,
       );
       if ( index === null || index === this.quiz.currentQuestionNumber - 1) {
         return true;
       }
       return false;
     },

     selectedQuestionNumber() {
       return this.quiz.questionNumber( this.selectedQuestion );
     },

     selectedQuestionPoints() {
       return this.quiz.questionPoints( this.selectedQuestion );
     },

     currentQuestionNumber() {
       return this.quiz.currentQuestionNumber;
     },
     questionCount() {
       return this.quiz.questionCount;
     },

     selectedQuestionBadgeVariant() {
       return this.isCurrentQuestionSelected ?
              "info" :
              "light"
     },

     isViewButtonPrimary() {
       return this.quiz.session.sessionStatus.canReadOutAnswers;
     },

     isMasterQuestionHardest() {
       if ( ! this.showQuestionDifficulty ) return false;
       if ( ! this.masterQuestionsMetadata ) return false;
       return this.masterQuestionsMetadata.isQuestionHardest( this.selectedQuestion );
     },

     isMasterQuestionEasiest() {
       if ( ! this.showQuestionDifficulty ) return false;
       if ( ! this.masterQuestionsMetadata ) return false;
       return this.masterQuestionsMetadata.isQuestionEasiest( this.selectedQuestion );
     },

     previewShowTeamAnswerScore() {
       const sessionStatus = this.quiz.session.sessionStatus;
       return sessionStatus.hasRevealedScoring;
     },
   },

   methods: {
     selectFirstQuestion() {
       this.$emit("select-first-question");
     },
     selectPreviousQuestion() {
       this.$emit("select-previous-question");
     },
     selectNextQuestion() {
       this.$emit("select-next-question");
     },
     selectCurrentQuestion() {
       this.$emit("select-current-question");
     },

     previewQuestion() {
       if ( ! this.selectedQuestion) return;
       this.previewSelectedQuestion = this.selectedQuestion;
     },
     onFocusQuestionIndex(index) {
       this.$emit("select-question-index", index);
     },

     onQuestionStatusSheetChanged() {
       this.$emit('question-status-sheet-changed');
     }
   },
 };

</script>

<template>

  <span>

    <b-row align-v="end">
      <b-col
        cols="12"
        lg="8"
      >
        <span>
          Select question:
          <b-button-group>
            <b-button
              v-if="showFirstQuestionButton"
              :disabled="isSelectedQuestionTheFirstQuestion"
              @click.prevent="selectFirstQuestion()"
              title="Select the first question"
              size="sm"
              variant="outline-primary"
            >⇤ First</b-button>
            <b-button
              :disabled="isSelectedQuestionTheFirstQuestion"
              @click.prevent="selectPreviousQuestion()"
              title="Select the previous question (Shortcut key: ←)"
              size="sm"
              variant="outline-primary"
            >← Prev</b-button>
            <b-button
              :disabled="isNextButtonDisabled"
              @click.prevent="selectNextQuestion()"
              title="Select the next question (Shortcut key: →)"
              size="sm"
              variant="outline-primary"
            >Next →</b-button>

            <b-button
              v-if="showCurrentQuestion"
              :disabled="isCurrentQuestionSelected"
              @click.prevent="selectCurrentQuestion()"
              title="Select the current question (Shortcut key: C)"
              size="sm"
              variant="outline-primary"
            >⇥ Current ({{currentQuestionNumber}} of {{questionCount}})</b-button>
          </b-button-group>

          <QuestionRevealButton
            :quiz="quiz"
            :selectedQuestion="selectedQuestion"
            @question-status-sheet-changed="onQuestionStatusSheetChanged"
          />
        </span>
      </b-col>

      <b-col
        cols="12"
        lg="4"
        class="is-ask-next-question-button text-right"
      >
        <AskNextQuestionButton
          v-if="showCurrentQuestion"
          v-on="$listeners"
          :quiz="quiz"
          :isCurrentQuestionTheLastOne="isCurrentQuestionTheLastOne"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" class="mt-2">
        <div v-if="selectedQuestion">
          <div class="is-selected-question-answer">
            <big class="float-left mr-2">
              <big>
                <b-badge :variant="selectedQuestionBadgeVariant">
                  Q {{selectedQuestionNumber}}
                  <div class="selected-question-points mt-1">
                    {{selectedQuestionPoints}} pt{{maybePlural(selectedQuestionPoints)}}
                  </div>
                </b-badge>
              </big>
            </big>

            <span class="float-left mr-2">
              <b-button
                v-if="isViewButtonPrimary" key="viewButtonIsPrimary"
                @click.prevent="previewQuestion"
                title="Preview how this question is presented to the teams"
                variant="primary"
                class="px-3 align-top"
              >
                <small>View</small>
                <font-awesome-icon :icon="['fas', 'film']" class="ml-1" />
              </b-button>
              <b-button
                v-else key="viewButtonIsNotPrimary"
                @click.prevent="previewQuestion"
                title="Preview how this question is presented to the teams"
                variant="outline-secondary"
                size="sm"
              >
                <small>View</small>
                <font-awesome-icon :icon="['fas', 'film']" class="ml-1" />
              </b-button>
            </span>

            <span v-if="isMasterQuestionHardest" class="mr-1">
              <b-badge pill variant="danger">Hard question</b-badge>
            </span>
            <span v-else>
              <span v-if="isMasterQuestionEasiest" class="mr-1">
                <b-badge pill variant="success">Easy question</b-badge>
              </span>
            </span>

            <span>
              <strong v-if="selectedQuestion.question" :title="selectedQuestion.question">
                {{selectedQuestion.question}}
              </strong>
              <strong v-else><i>-- No question --</i></strong>
            </span>
            <div>
              <strong>A: </strong>

              <span v-if="selectedQuestion.isAnswerEntryTypeFreeText">
                {{selectedQuestion.effectiveCorrectAnswer}}
              </span>

              <span v-if="selectedQuestion.isAnswerEntryTypeMultiChoice">
                (
                <span
                  v-for="(answerOption, index) in selectedQuestion.answerOptions"
                  :key="index"
                >
                  <span v-if="index !== 0"><strong>|</strong></span>
                  <span
                    :class="{ 'is-answer-option-correct': answerOption.isCorrect }"
                  >{{answerOption.answer}}</span>
                </span>
                )
              </span>

              <span v-if="selectedQuestion.answerTrivia" class="ml-2">
                <strong>Trivia:</strong> {{selectedQuestion.answerTrivia}}
              </span>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>


    <PreviewRunModalQuestion
      v-if="previewSelectedQuestion"
      :quiz="quiz"
      :previewSelectedQuestion.sync="previewSelectedQuestion"
      :questionNumberMax="quiz.currentQuestionNumber"
      :previewShowTeamAnswerScore="previewShowTeamAnswerScore"
      @focus-question-index="onFocusQuestionIndex($event)"
      @question-status-sheet-changed="onQuestionStatusSheetChanged"
    >
      <template #modal-title>
        View
        <font-awesome-icon :icon="['fas', 'film']" class="ml-1" />

        <small class="ml-3">Question as seen by the teams</small>
      </template>
    </PreviewRunModalQuestion>

  </span>

</template>

<style scoped>

 .is-selected-question-answer {
   height: 5rem;
   overflow-x: hidden;
   text-overflow: ellipsis;
 }

 .is-ask-next-question-button {
   margin-top: -0.5rem;
 }

 .selected-question-points {
   font-size: 0.7rem;
   font-weight: normal;
 }

 .is-answer-option-correct {
   text-decoration: underline;
 }

</style>
