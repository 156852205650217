<script>

 import SignupCard from "@/components/common/SignupCard.vue";
 import LoginCard from "@/components/common/LoginCard.vue";

 export default {
   name: "SignupOrLoginCard",
   components: {
     SignupCard,
     LoginCard,
   },
   props: [
     "redirect",
   ],

   data() {
     return {
       showSignup: true,
     };
   },

   methods: {
     toggleCard() {
       this.showSignup = ! this.showSignup;
     },
   }
 }

</script>

<template>
  <div>
    <SignupCard
      v-if="showSignup"
      :redirect="redirect"
      :clickLoginEvent="true"
      @click-login="toggleCard"
    />
    <LoginCard
      v-else
      :redirect="redirect"
      :clickSignupEvent="true"
      @click-signup="toggleCard"
    />
  </div>
</template>

<style scoped>

</style>
