
<script>

 import WithUserSession from '@/components/mixins/WithUserSession';

 export default {
   name: 'TopNavBar',
   mixins: [ WithUserSession ],

   computed: {

     showAccountMenuItems() {
       return this.$store.state.router.displayAccountMenu;
     },

     canLogOut() {
       return this.userSession.hasUserAccount;
     },

     username() {
       return this.userSession.userAccountExtract.username;
     },

     hasAnyAdminPermissionRole() {
       if ( ! this.userSession ) return false;
       return this.userSession.hasAnyAdminPermissionRole;
     },
   },

   methods: {
     async logOut() {
       await this.$store.dispatch('auth/logOut');
       this.ensureRouteName("HomePage");
     },

     gotoLogin() { this.$router.push("/log-in") },
     gotoSignup() { this.$router.push("/sign-up") },

     isPath(path) {
       return this.$router.currentRoute.path === path;
     },
   },

 }
</script>

<template>
  <div class="top-nav-bar mb-4 shadow">
    <b-navbar>
      <b-navbar-nav>
        <b-nav-item>
          <router-link :to="{ name: 'HomePage' }" exact>
            <div class="is-logo d-flex">
              <div class="pt-1">
                <img src="/logo/pub-quiz-logo.svg" class="is-logo-image" />
              </div>
              <div class="ml-1">
                <div class="is-logo-even-remotely">
                  <small><strong><i>Even Remotely</i></strong></small>
                </div>
                <div class="is-logo-pub-quiz">
                  <big><strong>Pub Quiz</strong></big>
                </div>
              </div>
            </div>
          </router-link>
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav v-if="showAccountMenuItems" class="ml-auto">

        <b-nav-item
          v-if="hasAnyAdminPermissionRole"
          class="is-role-admin px-1 mr-4"
        >
          <router-link to="/admin">Admin</router-link>
        </b-nav-item>

        <b-nav-item-dropdown v-if="canLogOut" right>
          <template v-slot:button-content>
            <span class="account-dropdown">
              <span class="text-larger"><font-awesome-icon :icon="[ 'fas', 'user-circle' ]" /></span>
              <span class="ml-2">{{username}}</span>
            </span>
          </template>
          <b-dropdown-item href="#" @click.prevent="logOut()">Log Out</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-navbar-nav v-else>
          <b-nav-item>
            <router-link to="/sign-up" :disabled="isPath('/sign-up')"><small><strong>Sign Up!</strong></small></router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/log-in" :disabled="isPath('/log-in')"><small>Log In</small></router-link>
          </b-nav-item>
        </b-navbar-nav>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<style scoped>
 .top-nav-bar {
   background-color: #55423D;
 }

 nav.navbar {
   padding-top: 0;
   padding-bottom: 0;
 }

 .top-nav-bar a {
   color: #fff;
 }

 .account-dropdown {
   color: #fff;
 }

 .is-logo {
   margin-top: -0.2rem;
 }

 .is-logo-image {
   height: 2.2rem;
 }

 .is-logo-even-remotely {
   margin-left: 0.13rem;
 }

 .is-logo-pub-quiz {
   margin-top: -0.5rem;
 }

 .is-role-admin {
   background: #f0f;
   border-radius: 0.5rem;
 }

</style>

