
import ModelBase from '../model-base.js';
import UserAccountExtract from './account-extract.js';
import UserPermissionRole from './permission-role.js';
import AnswerSheet from '../master/answer-sheet.js';

export default class UserAccount extends ModelBase {

  constructor({ id, username, password = "", userPermissionRoles = [], masterAnswerSheets = [], lastUserLoginTime, lastAdminLoginTime, createdTime, updatedTime }) {
    super();
    const now = new Date();
    return {
      ...this,
      id,
      username,
      password,
      userPermissionRoles: this.ensureArrayObjects(
        userPermissionRoles,
        UserPermissionRole,
      ),

      masterAnswerSheets: this.ensureArrayObjects(masterAnswerSheets, AnswerSheet),

      lastUserLoginTime: this.ensureDate(lastUserLoginTime),
      lastAdminLoginTime: this.ensureDate(lastAdminLoginTime),

      createdTime: this.ensureDate(createdTime || now),
      updatedTime: this.ensureDate(updatedTime || now),

      userAuthenticated() {
        this.lastUserLoginTime = new Date();
        this.updatedTime = new Date();
      },

      adminAuthenticated() {
        this.lastAdminLoginTime = new Date();
        this.updatedTime = new Date();
      },

      setUserPermissionRoles(userPermissionRoles) {
        this.userPermissionRoles = this.ensureArrayObjects(
          userPermissionRoles,
          UserPermissionRole,
        );
      },

      get asUserAccountExtract() {
        return new UserAccountExtract(this);
      },
      get asUserAccountExtractTuple() {
        return { userAccountExtract: this.asUserAccountExtract };
      },
    };
  }

}
