
import axios from '@/store/axios';
import TeamAnswerSheet from '../../../models/team/answer-sheet.js';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {

    // Post session to the API.
    async saveObjects({ dispatch }, { sessionId, teamAnswerSheets }) {
      const userSession = await dispatch('auth/ensureSession', {}, { root: true });
      return await axios.post(
        `/api/user-session/${userSession.id}/session/${sessionId}/team-answer-sheet`,
        teamAnswerSheets,
      ).then(res => {
        const data = res.data;
        return data.teamAnswerSheets.map(
          teamAnswerSheet => new TeamAnswerSheet(teamAnswerSheet),
        );
      });
      ///JPL: catch
    },

    async loadObjects({ dispatch }, { sessionId }) {
      const userSession = await dispatch('auth/ensureSession', {}, { root: true });
      return await axios.get(`/api/user-session/${userSession.id}/session/${sessionId}/team-answer-sheet`)
        .then(res => {
          const data = res.data;
          return data.teamAnswerSheets.map(
            teamAnswerSheet => new TeamAnswerSheet(teamAnswerSheet),
          );
        });
      ///JPL: catch
    },

  },

};
