
<script>
 export default {
   name: "IconUnscored",
 };
</script>

<template>
  <span class="unscored-char-icon">
    <font-awesome-icon :icon="[ 'fas', 'highlighter' ]" />
  </span>
</template>

<style scoped>

 .unscored-char-icon {
   padding-top: 0.25rem;
   padding-bottom: 0.25rem;
   padding-left: 0.35rem;
   padding-right: 0.35rem;

   border-radius: 0.2rem;

   background-color: var(--unscored-bg);
 }

</style>
