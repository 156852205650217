
import axios from '@/store/axios';
import AnswerSheet from '../../../models/master/answer-sheet.js';

export default {
  namespaced: true,
  state: {
    answerSheets: [],
  },
  mutations: {

    clearUserSession(state) {
      state.answerSheets.splice(0);
    },

    // When saved on the edit page, add here if no id, or replace if
    // id
    setObject(state, answerSheet) {
      const existingIndex = state.answerSheets.findIndex((q) => q.id === answerSheet.id);
      if (existingIndex >= 0) {
        state.answerSheets.splice(existingIndex, 1, answerSheet);
      }
      else {
        state.answerSheets.push(answerSheet);
      }
    },
    deleteObject(state, id) {
      const existingIndex = state.answerSheets.findIndex((q) => q.id === id);
      if (existingIndex >= 0) {
        state.answerSheets.splice(existingIndex, 1);
      }
      else {
        console.log(`answerSheet(${id}) not found`);
      }
    },

  },
  getters: {

    getList(state) {
      return state.answerSheets;
    },

    getObject: (state) => ({ id }) => {
      return state.answerSheets.find((q) => q.id === id);
    },

    hasAnswerSheets: (state) => {
      return !! state.answerSheets.length;
    },
  },
  actions: {

    async clearUserSession({ commit }) {
      commit('clearUserSession');
    },

    async maybeFetchList({ dispatch }) {
      const didLoad = await dispatch('auth/maybeLoadSession', {}, { root: true });
      if ( ! didLoad ) return null;
      return dispatch('fetchList');
    },


    // Get list of answerSheets with from the API. Add any missing to
    // Store, but don't update existing ones.
    async fetchList({ commit, dispatch }) {
      const userSession = await dispatch('auth/ensureSession', {}, { root: true });
      if ( ! this.$app.$root.callApi ) return;

      return await axios.get(`/api/user-session/${userSession.id}/answer-sheet`)
        .then((res) => {
          const answerSheets = res.data.answerSheets;

          answerSheets.forEach((answerSheetData) => {
            const id = `${answerSheetData.id}`; // Cast to opaque string
            const newAnswerSheet = new AnswerSheet({ ...answerSheetData, id });
            commit('setObject', newAnswerSheet);
          });

        });
      ///JPL: catch
    },

    // Post answerSheet (with or without id) to the API and save a new AnswerSheet
    // with the returned id in the Store.
    async saveObject({ commit, dispatch }, answerSheet) {
      answerSheet.prepareToSave();

      const userSession = await dispatch('auth/ensureSession', {}, { root: true });
      if ( ! this.$app.$root.callApi ) {
        const id = answerSheet.id || `${Math.floor(Math.random() * 10000) + 1}`;
        ///JPL: sessions id
        const newAnswerSheet = new AnswerSheet({ ...answerSheet, id });
        commit('setObject', newAnswerSheet);

        return newAnswerSheet;
      }

      ///JPL: make request with auth
      return await axios.post(`/api/user-session/${userSession.id}/answer-sheet/details`, answerSheet)
        .then((res) => {
          const data = res.data;
          const newAnswerSheet = new AnswerSheet(data.answerSheet);
          commit('setObject', newAnswerSheet);

          return newAnswerSheet;
        });
      ///JPL: catch
    },

    // Return promise of answerSheet with id, after having fetched it from
    // the API. Don't commit it to the Store.
    async fetchObject({ getters, dispatch }, { id }) {
      const userSession = await dispatch('auth/ensureSession', {}, { root: true });
      if ( ! this.$app.$root.callApi ) {
        ///JPL: needs to be a copy, otherwise it'll modify the store
        return getters.getObject({ id });
      }

      ///JPL: clean up / simplify the arg, and the data unpacking?
      ///Then unify with the other modules
      return await axios.get(`/api/user-session/${userSession.id}/answer-sheet/details/${id}`)
        .then((res) => {
          const apiAnswerSheet = res.data.answerSheet;

          const answerSheetData = {
            ...apiAnswerSheet,
          };

          ///JPL: error handling for missing id
          const answerSheet = new AnswerSheet(answerSheetData);

          return answerSheet;
        });
      ///JPL: catch
    },

    async deleteObject({ dispatch, commit }, { id }) {
      const userSession = await dispatch('auth/ensureSession', {}, { root: true });
      await axios.delete(`/api/user-session/${userSession.id}/answer-sheet/details/${id}`);
      commit('deleteObject', id);
    },
  },

};
