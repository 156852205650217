<script>

 import TopNavBar from '@/components/common/TopNavBar.vue';
 import BottomFooter from '@/components/common/BottomFooter.vue';

 import LibraryQuestionCard from '@/components/library/QuestionCard.vue';

 // Note: this one isn't used and is probably broken. Needs at least
 // the UiSelectable thing...
 export default {
   name: 'LibraryQuestionsPage',
   components: {
     TopNavBar,
     BottomFooter,
     LibraryQuestionCard,
   },

   data() {
     return {
       libraryQuestions: [],
       libraryAllowances: null,
     };
   },

   created() {
     this.loadRandomQuestions();
   },

   methods: {

     async loadRandomQuestions() {
       return this
         .$store.dispatch('libraryQuestion/fetchRandomList')
         .then(({ libraryAllowances, libraryQuestions }) => {
           this.libraryQuestions = libraryQuestions;
           this.libraryAllowances = libraryAllowances;
         });
     },

     async refreshRandomQuestions() {
       await this.loadRandomQuestions();
       window.scrollTo(0,0);
     },
   }

 }

</script>

<template>
  <div class="is-footer-flex-container">
    <TopNavBar />

    <b-container class="is-footer-flex-content">

      <h2>Question Library</h2>

      <h3>{{libraryQuestions.length}} Random Questions</h3>


      <b-row>
        <b-col
          v-for="libraryQuestion in libraryQuestions"
          :key="libraryQuestion.id"
          cols="12"
          md="6"
          lg="4"
        >
          <LibraryQuestionCard
            :libraryQuestion="libraryQuestion"
          />
        </b-col>
      </b-row>

      <div class="text-right mt-4">
        <b-button
          @click.prevent="refreshRandomQuestions()"
          variant="primary"
        >Other random questions</b-button>
      </div>

    </b-container>

    <BottomFooter />
  </div>
</template>

<style scoped>

</style>
