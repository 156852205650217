'use strict';

// The allowance for e.g. views or borrows of library questions per
// e.g. day
//
// Given the current usage, there is a remainingCount reported by the
// backend.
export default class LibraryAllowance {
  constructor({ totalCount, remainingCount = 0, period = "day" } = {}) {
    remainingCount = Math.max(0, remainingCount);
    return {
      totalCount,
      remainingCount,
      period,

      currentRemainingCount(currentCount) {
        return Math.max(0, this.remainingCount - currentCount);
      },
    };
  }
}
