
import ModelBase from '../model-base.js';

export default class MasterQuestionStatus extends ModelBase {
  constructor({ questionId, isRevealed = false, canTeamSubmitAnswer = true }) {
    super();
    return {
      ...this,
      questionId,

      // Has the question's answer and score been individually revealed?
      isRevealed,
      // Can a team still submit an answer to this question?
      // Can be false if revealed, or (future: the time has run out)
      canTeamSubmitAnswer,

      get isQuestionRevealed() {
        return this.isRevealed;
      },

      revealQuestion() {
        if ( this.isRevealed ) return false;

        this.isRevealed = true;
        this.canTeamSubmitAnswer = false;

        return true;
      },

    };
  }
}
