
// Someone was invited to a team-session and may have created a team
//
// For use on the TeamInvitation page to maybe redirect to the team's page
//
// For use on the TeamGame page to register that this is now the
// browser's chosen team for this session

import WebCookie from '../../models/web/cookie.js';


export default {

  computed: {
    teamSessionTeamWebCookie() {
      const name = `PQ_SESSION_TEAM_${this.teamSession.id}`;
      return new WebCookie({
        name,
        options: { expires: 3 },
      });
    },
  },

};
