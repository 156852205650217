
import ModelBase from '../model-base.js';
import MasterQuestionStatus from './question-status.js';

export default class MasterQuestionStatusSheet extends ModelBase {

  constructor({ sessionId, questionIdToMasterQuestionStatus = {} } = {}) {
    super();
    return {
      ...this,
      sessionId,
      questionIdToMasterQuestionStatus: this.ensureValuesObjects(
        questionIdToMasterQuestionStatus,
        MasterQuestionStatus,
      ),

      get masterQuestionStatuses() {
        return Object.values(this.questionIdToMasterQuestionStatus);
      },

      get revealedMasterQuestionStatuses() {
        return this.masterQuestionStatuses.filter(
          masterQuestionStatus => masterQuestionStatus.isQuestionRevealed,
        );
      },

      get revealedQuestionIds() {
        return this.revealedMasterQuestionStatuses.map(
          masterQuestionStatus => masterQuestionStatus.questionId,
        );
      },

      get revealedMasterQuestionCount() {
        return this.revealedMasterQuestionStatuses.length;
      },

      addMasterQuestionStatus(questionId, newMasterQuestionStatus) {
        // Use Object.assign to preserve Vue reactivity
        this.questionIdToMasterQuestionStatus = Object.assign(
          { [ questionId ]: newMasterQuestionStatus },
          this.questionIdToMasterQuestionStatus,
        );

        return newMasterQuestionStatus;
      },

      ensureMasterQuestionStatus(answerSheet, question) {
        const masterQuestionStatus = this.questionIdToMasterQuestionStatus[ question.id ];
        if ( masterQuestionStatus ) return masterQuestionStatus;

        return this.addMasterQuestionStatus(
          question.id,
          question.newMasterQuestionStatus(),
        );
      },

      ///JPL: is this used? remove
      ensureMasterQuestionStatuses(masterAnswerSheet) {
        masterAnswerSheet.questions.forEach(masterQuestion => {
          this.ensureMasterQuestionStatus(masterAnswerSheet, masterQuestion);
        });
      },

      isMasterQuestionRevealed(masterAnswerSheet, masterQuestion) {
        const masterQuestionStatus = this.ensureMasterQuestionStatus(
          masterAnswerSheet,
          masterQuestion,
        );
        return masterQuestionStatus.isQuestionRevealed;
      },

      revealMasterQuestion(masterAnswerSheet, masterQuestion) {
        const masterQuestionStatus = this.ensureMasterQuestionStatus(
          masterAnswerSheet,
          masterQuestion,
        );
        return masterQuestionStatus.revealQuestion();
      },

      canTeamSubmitAnswerForQuestion(masterAnswerSheet, masterQuestion) {
        const masterQuestionStatus = this.ensureMasterQuestionStatus(
          masterAnswerSheet,
          masterQuestion,
        );
        return masterQuestionStatus.canTeamSubmitAnswer;
      },

    };
  }
}
