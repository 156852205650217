<script>

 import TopNavBar from '@/components/common/TopNavBar.vue';
 import BottomFooter from '@/components/common/BottomFooter.vue';
 import SessionStatusBadge from '@/components/elements/SessionStatusBadge';

 import WithAnalytics from '@/components/mixins/WithAnalytics';
 import WithTeamSessionInvitation from '@/components/mixins/WithTeamSessionInvitation';


 export default {
   name: "TeamInvitation",
   mixins: [
     WithAnalytics,
     WithTeamSessionInvitation,
   ],
   components: {
     TopNavBar,
     BottomFooter,
     SessionStatusBadge,
   },

   props: [ 'loadSessionId' ],

   data() {
     return {
       teamSession: null,
       teamName: "",
     };
   },

   created() {
     this
       .loadTeamSessionById( this.loadSessionId )
       .then(() => this.focusInitialElement())
       .then(() => {
         const possibleTeamTeamId = this.teamSessionTeamWebCookie.get();
         if ( possibleTeamTeamId ) {
           const url = `/team/game/${this.teamSession.id}/team/${possibleTeamTeamId}`;
           this.$router.push(url);
         }
       })
     ;
   },

   methods: {

     ///JPL: extract to mixin
     focusInitialElement() {
       const el = this.$refs.initialSelectedText;
       if ( el ) {
         this.$refs.initialSelectedText.focus();
         this.$refs.initialSelectedText.select();
       }
     },

     ///JPL: rename token --> id
     loadTeamSessionById( id ) {
       return this
         .$store
         .dispatch('teamSession/fetchObject', { id })
         .then(teamSession => {
           this.$set(this, 'teamSession', teamSession);
         });
     },

     addTeam() {
       this
         .$store
         .dispatch(
           'teamSession/addTeam',
           {
             sessionId: this.loadSessionId,
             name: this.teamName,
           },
         )
         .then(teamTeam => {
           this.$router.push(`/team/game/${teamTeam.sessionId}/team/${teamTeam.id}`);
       });

       this.logCreateTeam();
     },

     logCreateTeam() {
       this.logEvent('create_team', { event_category: 'team' });
     },
   },
 }
</script>

<template>
  <div class="is-footer-flex-container">
    <TopNavBar />

    <b-container class="is-footer-flex-content">
      <div v-if="teamSession">
        <b-row>
          <b-col>
            <h2>{{teamSession.quizName}}</h2>
            <div class="mt-0 mb-2">
              <SessionStatusBadge :sessionStatus="teamSession.sessionStatus" />
            </div>

            <p>
              You are invited to the Quiz '{{teamSession.quizName}}'
            </p>

            <p>
              If you want to participate you can create a team here.
            </p>

            <p class="mt-3">
              If there are many people in your team (in front of
              different screens), you should sign up one team and then
              share that URL amongst the team members.
            </p>

          </b-col>
          <b-col>
            <ValidationObserver v-slot="{ invalid }">
              <b-form>
                <b-form-group
                  label="Team Name:"
                  description="This is your team name, or maybe just your own name."
                >
                  <validation-provider name="Team name" rules="required" v-slot="{ errors, invalid }">
                    <b-form-input :class="`is-${invalid}`" v-model="teamName" ref="initialSelectedText" size="sm"  />
                    <div class="validation-error">{{ errors[0] }}</div>
                  </validation-provider>
                </b-form-group>
                <b-button type="submit" :disabled="invalid" variant="primary" @click.prevent="addTeam">Add Team</b-button>
              </b-form>
            </ValidationObserver>

            <div v-if="teamSession.sessionStatus.hasStarted" class="mt-3 bg-warn">
              <big>
                <NoteBadge /> The quiz has already started, but you can still join!
              </big>
            </div>

          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div v-if="teamSession.teamNames.length">
              <h2>Teams</h2>

              <ul v-for="(teamName, index) in teamSession.teamNames" :key="index">
                <li>{{teamName}}</li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <BottomFooter />
  </div>

</template>

 <style scoped>
</style>




