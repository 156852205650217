<script>

 import WithUserSession from '@/components/mixins/WithUserSession';
 import WithAnalytics from '@/components/mixins/WithAnalytics';
 import TopNavBar from '@/components/common/TopNavBar.vue';
 import BottomFooter from '@/components/common/BottomFooter.vue';
 import TransitionFade from '@/components/transition/TransitionFade.vue';


 export default {
   name: 'HomePageLanding',
   mixins: [
     WithUserSession,
     WithAnalytics,
   ],
   components: {
     TopNavBar,
     BottomFooter,
     TransitionFade,
   },

   data() {
     return {
       showTopMenu: false,
     };
   },

   created () {
     window.addEventListener('scroll', this.handleScroll);
   },
   destroyed () {
     window.removeEventListener('scroll', this.handleScroll);
   },

   computed: {
     isMissingUserAccount() {
       // Avoid flickering the login card when loading the page when logged in
       if ( ! this.$store.state.auth.haveMaybeLoadedSession ) return false;
       if ( ! this.userSession ) return true;
       return ! this.userSession.hasUserAccount;
     },
   },

   methods: {
     handleScroll() {
       this.showTopMenu = window.scrollY !== 0;
     },

     logCreateQuiz() {
       this.logEvent('create_quiz', { event_category: 'setup', event_label: 'landing'});
     },
   }
 }

</script>

<template>
  <div>

    <div class="is-sticky-header">
      <TransitionFade>
        <div
          v-if="showTopMenu"
          class="is-top-menu shadow"
          :style="{ visibility: showTopMenu ? 'visible' : 'hidden' }"
        >
          <TopNavBar />
        </div>
      </TransitionFade>
    </div>


    <div class="is-top-hero is-top-hero-layer shadow">
      <div class="is-top-hero-background is-top-hero-layer w-100">
        <div class="is-background-image-container d-flex">
          <div class="flex-fill overflow-hidden d-none d-md-block">
            <img class="is-background-image img-guy-coffee"
              src="/unsplash/top/william-moreland-gmdy9N0S12A-unsplash-h28.jpg"
              style="filter: brightness(70%) saturate(140%);"
            />
          </div>
          <div class="flex-fill overflow-hidden d-none d-xl-block">
            <img class="is-background-image img-lamp"
              src="/unsplash/top/thiago-garcia-fy9xCWqFbSo-unsplash-h28.jpg"
            />
          </div>
          <div class="flex-fill overflow-hidden d-block d-sm-block">
            <img class="is-wide-background-image img-yellow-bottles"
              src="/unsplash/top/alexander-jawfox-ts2e1Dia5Ec-unsplash-h28.jpg"
              style="filter: brightness(80%) saturate(100%);"
            />
          </div>
          <div class="flex-fill overflow-hidden d-none d-xl-block">
            <img class="is-background-image img-drinks"
              src="/unsplash/top/louis-hansel-shotsoflouis-vm4g1MBZUv4-unsplash-h28.jpg"
              style="filter: brightness(80%) saturate(100%);"
            />
          </div>
          <div class="flex-fill overflow-hidden d-block d-sm-block">
            <img class="is-wide-background-image img-books-in-bed"
              src="/unsplash/top/quyet-nguyen-xLcuNGBsY5k-unsplash-h28.jpg"
              style="filter: brightness(65%) saturate(140%);"
            />
          </div>
          <div class="flex-fill overflow-hidden d-none d-md-block">
            <img class="is-background-image img-guy-bar"
              src="/unsplash/top/steven-erixon-mAhqHDtP_jc-unsplash-h28.jpg"
              style="filter: brightness(80%) saturate(140%);"
            />
          </div>
        </div>
      </div>
      <div class="is-top-hero-foreground is-top-hero-layer w-100">
        <div class="is-main-top-text-block d-flex justify-content-center">
          <div class="ml-4 text-shadow">
            <h2 class="is-even-remotely display-5 text-center">Even Remotely</h2>
            <h1 class="is-pub-quiz font-weight-bolder text-nowrap">Pub Quiz</h1>
            <h4 class="is-without-the-hassle"><i>without the hassle</i></h4>
          </div>
        </div>
        <div class="is-tagline text-right mt-5 pb-2 mr-4 pt-3">
          <i class="text-shadow-harsher">"Just like being in the pub down the road"</i>
        </div>
      </div>
    </div>

    <b-container fluid class="is-intro pb-2">

      <b-row class="mt-4">
        <b-col class="text-center">
          <strong><i>Even Remotely</i> Pub Quiz</strong> makes it easy<br />
          to host your own online quiz<br />
           for remote co-workers, or <br />
          family &amp; friends
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <router-link to="/quizmaster/answer-sheet/new">
            <b-button @click="logCreateQuiz" size="lg" pill variant="primary" class="mt-4">
              Create a Quiz!
            </b-button>
          </router-link>
          <div v-if="isMissingUserAccount" class="mt-2">
            <small>Are you a regular? <router-link to="/log-in"><strong>Log In!</strong></router-link></small>
          </div>
        </b-col>
      </b-row>

    </b-container>


    <div  class="is-setting-the-table w-100 mt-5 pt-5 pb-5 shadow-sm">
      <b-container fluid>

        <b-row class="mt-4">
          <b-col cols="5" offset="1" class="text-right pr-4">
            <img
              src="/unsplash/create/thought-catalog-Nv-vx3kUR2A-unsplash-w25.jpg"
              Xsrc="/unsplash/create/eliott-reyna-iO2d-KYp5JU-unsplash-w25.jpg"
              Ysrc="/unsplash/create/disruptivo-iQ15DTx-63k-unsplash-w25.jpg"
              Csrc="/unsplash/create/bruno-emmanuelle-azsk_6IMT3I-unsplash-w25.jpg"
              Asrc="/unsplash/create/pejmon-hodaee-SeetD-Lu7Nk-unsplash-w25.jpg"
              Dsrc="/unsplash/create/kaleidico-d6rTXEtOclk-unsplash-w25.jpg"

              alt="Curious person"
              class="is-z-image"
            />
          </b-col>
          <b-col cols="5">
            <h3 class="mt-0 mt-md-4">Be creative</h3>

            <p>
              Sit down and <strong>create a quiz</strong> in your own
              time or with your mates.
            </p>
            <p>
              Collect quite interesting trivia, research questions on
              the Internet, or borrow from the <strong>free Quiz Question
              Library</strong>.
            </p>
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col cols="5" offset="1">
            <h3 class="mt-0 mt-lg-4">Invite people you care about</h3>

            <p>
              Use <strong>video conferencing tools</strong> like Zoom
              to hang out with your friends, like you already do in
              <i>These Times</i>(tm).
            </p>
            <p>
              Plan a <strong>regular pub quiz</strong>, or whip out a short <strong>lightning
              round</strong> with just a few questions to mix things up.
            </p>
          </b-col>
          <b-col cols="5" class="text-left pl-4">
            <img
              src="/unsplash/invite/chris-montgomery-smgTvepind4-unsplash-w25.jpg"
              Ysrc="/unsplash/invite/brooke-cagle-tLG2hcpITZE-unsplash-w25.jpg"
              Xsrc="/unsplash/invite/glen-carrie-AzyqGr35vH0-unsplash-w25.jpg"
              alt="People in video conference"
              class="is-z-image"
            />
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col cols="5" offset="1" class="text-right pr-4">
            <img
              src="/unsplash/run/priscilla-du-preez-XkKCui44iM0-unsplash-w25.jpg"
              Xsrc="/unsplash/run/afif-kusuma-CT7IWRM7G5k-unsplash-w25.jpg"
              alt="People doing a pub quiz"
              class="is-z-image"
            />
          </b-col>
          <b-col cols="5">
            <h3 class="mt-0 mt-lg-4">Have a good time</h3>

            <p>
              Run the quiz using <strong><i>Even Remotely</i> Pub Quiz</strong>.
            </p>
            <p>
              Mark the answers as they appear.
              Enjoy the banter as you reveal the correct answers and the ultimate winners.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>


    <b-container fluid class="is-get-started pb-5">

      <b-row class="mt-5">
        <b-col cols="8" offset="2" class="text-center">
          <h1 class="display-4">Get started now!</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <router-link to="/quizmaster/answer-sheet/new">
            <b-button size="lg" pill variant="primary" class="mt-4">
              Create a Quiz!
            </b-button>
          </router-link>

          <div class="mt-3">
            <strong><i>Even Remotely</i> Pub Quiz</strong>
            is in beta at the moment, and <br>
            <strong class="text-center">all features are free</strong>.
          </div>
          <div class="mt-2">
            (The basic quiz will always remain free)
          </div>
        </b-col>
      </b-row>

    </b-container>


    <BottomFooter />
  </div>
</template>

<style scoped>

 /* 28rem */
 /* 448px */

 /* 25rem */
 /* 400px */

 .is-sticky-header {
   position: sticky;
   top: 0;
   left: 0;
   width: 100%;
   z-index: 999999;
   visibility: hidden;
 }

 .is-top-menu {
   background-color: #55423D;
   height: 3rem;
   margin-top: -3rem;
 }

 .is-top-hero-layer {
   height: 25rem;
 }

 .is-top-hero-background {
   background: #222;
 }

 .is-top-hero-foreground {
   position: relative;
   top: -25rem;
   padding-top: 6rem;
 }

 .is-background-image-container {
   height: 25rem;
 }

 .is-background-image {
   width: 19rem;
 }

 .is-wide-background-image {
   width: 23rem;
 }

 .is-top-hero h1, .is-top-hero h2, .is-top-hero h3, .is-top-hero h4 {
   color: #fff;
 }

 .is-top-hero .is-even-remotely {
   margin-top: 1rem;
   margin-bottom: -0.5rem;
 }


 .is-top-hero h1.is-pub-quiz {
   font-size: 6rem;
 }

 .is-top-hero .is-without-the-hassle {
   padding-left: 2.5rem;
   margin-top: -1.3rem;
   margin-bottom: 1.3rem;
 }

 .is-top-hero .is-tagline {
   color: #ddd;
 }

 /* For mobile, reduce the size of "Pub Quiz" and adjust the margin */
 @media screen and (max-width: 480px) {
   .is-top-hero h1.is-pub-quiz {
     font-size: 3.5rem;
     margin-bottom: 1rem;
   }

   .is-main-top-text-block {
     padding-top: 1.5rem;
     padding-bottom: 1.5rem;
   }
 }



 .is-z-image {
   border: 1px solid #555;
   border-radius: 5px;
   width: 25rem;
   max-width: 35vw;
 }

 .is-setting-the-table {
   background: #FFF3EC;
 }

 .text-shadow {
   color: white;
   text-shadow: -1px -1px 20px #222,
                 1px -1px 20px #222,
                -1px  1px 20px #222,
                 1px  1px 20px #222;
 }

 .text-shadow-harsher {
   text-shadow: -1px -1px 10px #000,
                 1px -1px 10px #000,
                -1px  1px 10px #000,
                 1px  1px 10px #000;
 }

</style>
