<script>

 import WithAnalytics from '@/components/mixins/WithAnalytics';
 import ModalConfirm from '@/components/share/Modal/Confirm';
 import AnswerSheetListItemSessionItem from '@/components/quizmaster/AnswerSheetListItemSessionItem';

 export default {
   name: 'AnswerSheetListItem',
   mixins: [
     WithAnalytics,
   ],
   components: {
     ModalConfirm,
     AnswerSheetListItemSessionItem,
   },
   props: [
     'answerSheet',
   ],

   methods: {
     createNewSession(answerSheet, askFirst = false) {
       if ( askFirst ) {
         const doCreate = window.confirm(
           "You already have a quiz session in progress below, do you really want to start a new one?"
         );
         if ( ! doCreate ) {
           return;
         }
       }
       this.$router.push(
         `/quizmaster/answer-sheet/${answerSheet.id}/invite-team/new`,
       );
     },

     async duplicateAnswerSheet() {
       this.$bvModal.show(`bv-modal-duplicate-answer-sheet-${this.answerSheet.id}`);
     },

     async duplicateAnswerSheetOk() {
       const clonedAnswerSheet = this.answerSheet.clone();
       await this.$store.dispatch('masterAnswerSheet/saveObject', clonedAnswerSheet);

       this.logDuplicateQuiz();

       // delay to let the confirmation dialog fade away
       setTimeout(() => {
         window.scrollTo({ top: 0, left: 0, behaviour: "smooth" });
       }, 300);
     },

     async deleteAnswerSheet() {
       this.$bvModal.show(`bv-modal-delete-answer-sheet-${this.answerSheet.id}`);
     },

     async deleteAnswerSheetOk() {
       this
         .$store
         .dispatch('masterAnswerSheet/deleteObject', { id: this.answerSheet.id })
       this.logDeleteQuiz();
     },


     logEditQuiz() {
       this.logEvent('edit_quiz', { event_category: 'setup', event_label: 'answer_sheet_list' });
     },
     logDuplicateQuiz() {
       this.logEvent('duplicate_quiz', { event_category: 'setup', event_label: 'answer_sheet_list' });
     },
     logDeleteQuiz() {
       this.logEvent('delete_quiz', { event_category: 'setup', event_label: 'answer_sheet_list' });
     },
   }
 }
</script>

<template>

  <div>
    <div class="d-md-flex mb-3 mb-md-0">
      <h3 class="flex-grow-1 d-inline-block">{{answerSheet.name}}</h3>
      <div class="text-nowrap ml-2 text-right">
        <span :title="answerSheet.questionsTitleString">
          {{answerSheet.questionCount}} Question{{maybePlural(answerSheet.questionCount)}}
        </span>

        <router-link
          :to="{ path: '/quizmaster/answer-sheet/' + answerSheet.id}"
          class="ml-2"
        >
          <b-button @click="logEditQuiz" size="sm" variant="primary" title="Edit Questions">
            <font-awesome-icon :icon="['fas', 'edit']" />
            Edit
          </b-button>
        </router-link>


        <b-dropdown
          variant="outline-secondary"
          right
          size="sm"
          class="ml-2"
          >
          <template v-slot:button-content>
            <font-awesome-icon :icon="['fa', 'cog']" />
          </template>

          <b-dropdown-item
            @click.prevent="duplicateAnswerSheet()"
            href="#"
            title="Make a copy of the quiz"
          >
            <font-awesome-icon :icon="['far', 'clone']" class="mr-1" />
            Duplicate Quiz
          </b-dropdown-item>

          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item
            @click.prevent="deleteAnswerSheet()"
            href="#"
            variant="danger"
            title="Permanently delete the quiz"
          >
            <font-awesome-icon :icon="['far', 'trash-alt']" class="mr-1" />
            Delete Quiz
          </b-dropdown-item>
        </b-dropdown>

      </div>
    </div>

    <span v-if=" ! answerSheet.unfinishedSessions.length">
      <b-button
        @click.prevent="createNewSession(answerSheet)"
        class="ml-3"
        size="sm"
        variant="primary"
      >
        <font-awesome-icon :icon="['fas', 'user-friends']" class="mr-1" />
        Invite teams to play this Quiz again
      </b-button>
    </span>

    <ul v-for="(session, index) in answerSheet.allSessions" :key="`${index}-${session.id}`">
      <AnswerSheetListItemSessionItem
        :answerSheet="answerSheet"
        :session="session"
      />
    </ul>

    <ModalConfirm :elementId="`bv-modal-duplicate-answer-sheet-${answerSheet.id}`"
      @click-ok="duplicateAnswerSheetOk"
    >
      <template #title>
        Duplicate Quiz?
      </template>
      <template #message>
        <strong>
          Do you want to create a copy of this quiz and all the questions?
        </strong>
      </template>
    </ModalConfirm>

    <ModalConfirm :elementId="`bv-modal-delete-answer-sheet-${answerSheet.id}`"
      @click-ok="deleteAnswerSheetOk"
    >
      <template #title>
        Delete Quiz?
      </template>
      <template #message>
        <b-alert show variant="danger">
          <p>
            Do you want to <strong>permanently</strong> delete the quiz and all
            the questions and all the quiz sessions?
          </p>
          <p>
            <strong>You can not undo this!</strong>
          </p>
        </b-alert>
      </template>
    </ModalConfirm>

  </div>
</template>

<style scoped>

</style>
