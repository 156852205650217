
import ModelBase from '../model-base.js';

export default class MasterAnswerScore extends ModelBase {
  constructor({ id, teamId, questionId, isCorrect, pointsAwarded, isAutoMarkedCorrect = false, wasAutoMarkedCorrect = false }) {
    super();
    return {
      ...this,
      id, ///JPL: not even set in factory
      teamId,
      questionId,
      ///JPL: change this from a boolean to an enum
      // Can be null: qm has not yet scored the answer
      isCorrect: isCorrect === undefined ? null : isCorrect,
      pointsAwarded: this.ensureNumeric(pointsAwarded),

      isAutoMarkedCorrect,
      wasAutoMarkedCorrect,

      // If it's waiting to be marked correct/incorrect, i.e. it's
      // confirmed but not marked
      get isUnmarked() {
        return this.isCorrect === null;
      },

      get isMarked() {
        return ! this.isUnmarked;
      },

      get actualPointsAwarded() {
        if ( this.isCorrect !== true ) return 0;
        const value = Number.parseFloat( this.pointsAwarded );
        if ( Number.isNaN(value) ) return 0;
        return value;
      },

      markCorrect({ isAutoMarkedCorrect = false } = {}) {
        this.isCorrect = true;
        this.isAutoMarkedCorrect = isAutoMarkedCorrect;
        if ( isAutoMarkedCorrect ) {
          this.wasAutoMarkedCorrect = true;
        }
      },

      markIncorrect() {
        this.isCorrect = false;
        this.isAutoMarkCorrect = false;
      },

      markUnmarked() {
        this.isCorrect = null;
        this.isAutoMarkCorrect = false;
        this.wasAutoMarkedCorrect = false;
      },

      // If the team hasn't confirmed their answer yet, there's
      // nothing to have corrected yet, so isCorrect should be
      // "unknown"
      syncWithTeamAnswer(teamAnswer = undefined) {
        // Missing team answer menans they definitely haven't
        // confirmed it yet
        const isConfirmed = teamAnswer ? teamAnswer.isConfirmed : false;
        if ( ! isConfirmed ) {
          this.markUnmarked();
        }
      },


      isTeamAnswerCorrect(teamAnswer) {
       if ( ! teamAnswer.isConfirmed ) return false;
       if ( this.isCorrect === null ) return false;
       return this.isCorrect;
      },

      isTeamAnswerIncorrect(teamAnswer) {
       if ( ! teamAnswer.isConfirmed ) return false;
       if ( this.isCorrect === null ) return false;
       return ! this.isCorrect;
      },

      // Whether the answer needs scoring
      isTeamAnswerUnscored(teamAnswer) {
       if ( ! teamAnswer.isConfirmed ) return false;
       return this.isCorrect === null;
      },

      ///JPL: check if we can _just_ pass in the answer string instead
      ///of the object
      autoMark(masterQuestion, teamAnswer) {
        if ( masterQuestion.id !== this.questionId ) throw("Internal error: questionId mismatch");

        if ( masterQuestion.isAutoMarkCorrect(teamAnswer) ) {
          this.markCorrect({ isAutoMarkedCorrect: true });
        }
        return false;
      },

      autoDetermineIsCorrect(masterQuestion, teamAnswer) {
        if ( masterQuestion.id !== this.questionId ) throw("Internal error: questionId mismatch");

        const autoIsCorrect = masterQuestion.isMultiChoiceAnswerCorrect(teamAnswer);
        if ( autoIsCorrect === true ) {
          this.markCorrect();
        } else {
            this.markIncorrect();
        }
      },
    };
  }
}
