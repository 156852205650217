
export default class TeamAnswerOption {
  constructor({ answer, isCorrect = null }) {
    return {
      answer,
      isCorrect,

      newMaybeRevealed(hasRevealedAnswers) {
        return new TeamAnswerOption({
          answer: this.answer,
          isCorrect: hasRevealedAnswers ? this.isCorrect : null,
        });
      },
    };
  }
}

