<script>
 export default {
   name: "LoadingSpinner",
 }
</script>

<template>
  <b-row class="spinner-container">
    <b-col class="text-center">
      <h1 class="text-muted"><font-awesome-icon spin :icon="[ 'fa', 'spinner' ]" /></h1>
    </b-col>
  </b-row>
</template>

<style scoped>

 .spinner-container {
   margin-top: 8rem;
 }

</style>
