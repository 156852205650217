
export default class TeamQuestionsMetadata {

  constructor({ hardestQuestionsMetadata = [], easiestQuestionsMetadata = [], popularQuestionsMetadata = [] }) {
    return {
      hardestQuestionsMetadata,
      easiestQuestionsMetadata,
      popularQuestionsMetadata,

      isQuestionHardest(question) {
        return this.hardestQuestionsMetadata.find(
          questionMetadata => questionMetadata.questionId === question.questionId,
        );
      },

      isQuestionEasiest(question) {
        return this.easiestQuestionsMetadata.find(
          questionMetadata => questionMetadata.questionId === question.questionId,
        );
      },

      isQuestionPopular(question) {
        return this.popularQuestionsMetadata.find(
          questionMetadata => questionMetadata.questionId === question.questionId,
        );
      },
    };
  }
}
