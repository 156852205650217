
<script>

 import TeamQuestion from '@/models/team/question';
 import TeamAnswerScore from '@/models/team/answer-score';

 import TeamGameAnswer from '@/components/team/TeamGameAnswer';

 export default {
   name: 'PreviewTeamGameAnswer',
   components: {
     TeamGameAnswer,
   },
   props: [
     'masterQuestion',
     'masterAnswerSheet',
     'questionIndex',
     'showTeamAnswerScore',
   ],

   data() {
     return {
       revealAnswerTriviaHtml: "",
     }
   },

   computed: {

     questionPoints() {
       const points = this.masterQuestion.points;
       if ( points ) return points + "";
       return this.masterAnswerSheet.defaultPoints + "";
     },

     teamQuestion() {

       // Trigger the fetch from the backend
       this.getRevealAnswerTriviaHtml();

        const answerOptions = this.masterQuestion.teamAnswerOptions(
          this.showTeamAnswerScore,
        );

       ///JPL: extract as much as possible to master question
       return new TeamQuestion({
         questionId: this.masterQuestion.id,
         points: this.questionPoints,
         ordinal: this.questionIndex + 1,
         question: this.masterQuestion.question,
         answerEntryType: this.masterQuestion.answerEntryType,
         answerOptions,
         shouldRevealAnswer: this.masterQuestion.shouldRevealAnswer,
         revealCorrectAnswer: this.masterQuestion.revealCorrectAnswer,
         revealAnswerTriviaHtml: this.revealAnswerTriviaHtml,
         imageUrl: this.masterQuestion.imageUrl,
       });
     },

     teamAnswer() {
       return this.teamQuestion.newTeamAnswer("dummy-team");
     },

     teamAnswerScore() {
       return new TeamAnswerScore({
         teamId: "dummy-team",
         questionId: this.masterQuestion.id,
         isCorrect: true, ///JPL: or from input
         pointsAwarded: 1, ///JPL: or from input
        });
     },

     isTeamQuestionPopular() {
       return false;
     },

     disableAllInput() {
       return this.showTeamAnswerScore;
     },
   },

   methods: {

     async htmlFromMarkdown(markdown) {
       return this.$store.dispatch(
         'content/fetchHtmlFromMarkdown',
         { markdown: markdown },
       );
     },

     async getRevealAnswerTriviaHtml() {
       this.revealAnswerTriviaHtml = await this.htmlFromMarkdown(
         this.masterQuestion.revealAnswerTrivia,
       );
     },
   },
 };

</script>

<template>
  <div class="preview-question">
    <TeamGameAnswer
      :teamQuestion="teamQuestion"
      :teamAnswer="teamAnswer"
      :teamScore="teamAnswerScore"
      :totalQuestionCount="masterAnswerSheet.questionCount"
      :isCurrentQuestion="false"
      :showTeamAnswerScore="showTeamAnswerScore"
      :showVoteButtons="true"
      :isTeamQuestionHardest="false"
      :isTeamQuestionEasiest="false"
      :isTeamQuestionPopular="isTeamQuestionPopular"
      :disableAllInput="disableAllInput"
    />
  </div>
</template>
