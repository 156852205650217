
<script>

 import WithAnalytics from '@/components/mixins/WithAnalytics';
 import WithCopyPasteQuestion from '@/components/quizmaster/mixins/WithCopyPasteQuestion';

 import BottomButtonBar from '@/components/elements/BottomButtonBar.vue';
 import MasterQuestion from '../../models/master/question.js';
 import EditQuestion from '@/components/quizmaster/EditQuestion';
 import PreviewEditModalQuestion from '@/components/quizmaster/Preview/EditModalQuestion.vue';

 export default {
   name: 'EditAnswerSheetQuestionList',
   mixins: [
     WithAnalytics,
     WithCopyPasteQuestion,
   ],
   components: {
     BottomButtonBar,
     EditQuestion,
     PreviewEditModalQuestion,
   },
   props: [ 'answerSheet' ],

   data() {
     return {
       deletedQuestion: null,
       lastDeletedQuestionNumber: 1,

       previewSelectedQuestion: null,
       previewSessionStatus: "revealScoring",
     }
   },

   computed: {
     questionCount() { return this.answerSheet.questions.length },

     showTipReturnKey() {
       if ( this.$root.isMobile ) return false; // No <Return> key on a phone

       // Show <Ctrl-Return> tip after a few questions, but not too long
       const count = this.answerSheet.questions.length;
       if ( count >= 3 && count <= 5) return true;

       return false;
     },
   },

   methods: {

     focusInitialElement() {
       if ( this.$root.isTouchDevice ) return;
       this.$refs.initialSelectedText.focus();
       this.$refs.initialSelectedText.select();
     },

     addQuestion() {
       this.answerSheet.addQuestion();
       this.logNewQuestion();
       this.$nextTick(() => this.focusLastQuestion());
     },

     focusLastQuestion() {
       const editQuestionComponent = this.$refs.editQuestion.slice(-1)[0];
       if ( ! editQuestionComponent ) return;
       const el = editQuestionComponent.$el;
       if ( ! el ) return;
       el.scrollIntoView({ behaviour: 'smooth', block: 'center' });
       editQuestionComponent.focusQuestionInputField();
     },

     deleteQuestion(question, prepareUndo = false) {
       const index = this.answerSheet.deleteQuestion(question);
       if ( prepareUndo ) {
         this.prepareUndoDeleteQuestion(index, question);
       }
       this.logDeleteQuestion();
       this.$nextTick(() => this.focusQuestionIndex(index));
     },

     addQuestionAbove(question) {
       const index = this.answerSheet.addQuestionAbove(question);
       this.logInsertQuestion();
       this.$nextTick(() => this.focusQuestionIndex(index));
     },

     moveQuestionUp(question) {
       const index = this.answerSheet.moveQuestionUp(question);
       this.$nextTick(() => this.focusQuestionIndex(index));
       this.logMoveQuestionUp();
     },

     moveQuestionDown(question) {
       const index = this.answerSheet.moveQuestionDown(question);
       this.$nextTick(() => this.focusQuestionIndex(index));
       this.logMoveQuestionDown();
     },

     cutQuestion(question) {
       this.setClipboard( question.asClipboardMasterQuestion );
       this.deleteQuestion( question, false );
       this.logCutQuestion();
     },
     async pasteQuestionAbove(question) {
       const masterClipboardQuestion = await this.readQuestionFromClipboard();
       if ( ! masterClipboardQuestion ) {
         return;
       }

       const newQuestion = new MasterQuestion(masterClipboardQuestion);
       const index = this.answerSheet.addQuestionAbove(question, newQuestion);
       this.$nextTick(() => this.focusQuestionIndex(index));

       this.logPasteQuestion();
     },

     copyPreviewQuestion(clipboardContainerEl) {
       const question = this.previewSelectedQuestion;
       if ( ! question ) return;
       this.copyQuestion(question, clipboardContainerEl);
     },

     focusQuestionIndex(index, scrollBlock = "center") {
       if ( index === null || index === undefined ) return;

       // Can't just index into the $refs array, since the
       // animation/transition stuff jumbles the order, so instead
       // find the component for the question itself
       const question = this.answerSheet.questions[index];
       if ( ! question ) return;

       const editQuestionComponent = this.$refs.editQuestion.find(
         component => {
           return component.question === question;
         }
       );
       if ( ! editQuestionComponent ) {
         return this.focusLastQuestion();
       }

       editQuestionComponent.$el.scrollIntoView({
         behaviour: 'smooth',
         block: scrollBlock,
       });
       editQuestionComponent.focusQuestionInputField();
     },

     onFocusQuestionIndex(index, scrollBlock = "center") {
       this.focusQuestionIndex(index, scrollBlock);
     },

     prepareUndoDeleteQuestion(index, question) {
       this.lastDeletedQuestionNumber = index + 1;
       this.deletedQuestion = { index, question };
       this.$bvToast.show('question-deleted');
     },

     undoDeleteQuestion() {
       this.$bvToast.hide('question-deleted');
       if ( ! this.deletedQuestion ) return;

       const { index, question } = this.deletedQuestion;
       this.answerSheet.undoDeleteQuestion(index, question);
       this.$nextTick(() => this.focusQuestionIndex(index));

       this.deletedQuestion = null;

       this.logUndoDeleteQuestion();
     },

     previewQuestion(question) {
       this.previewSelectedQuestion = question;
     },

     cancelAnswerSheet() {
       //JPL: alert Are you sure?
       this.$router.push({ name: "HomePage" });
     },
     async saveAnswerSheet() {
       await this.$store.dispatch('masterAnswerSheet/saveObject', this.answerSheet);
       this.logSaveQuiz();

       this.$router.push({ name: "HomePage" });
     },


     logSaveQuiz() {
       this.logEvent('save_quiz', { event_category: 'setup', event_value: this.questionCount });
     },
     logNewQuestion() {
       this.logEvent('new_question', { event_category: 'setup', event_value: this.questionCount });
     },
     logInsertQuestion() {
       this.logEvent('insert_question', { event_category: 'setup', event_value: this.questionCount });
     },
     logDeleteQuestion() {
       this.logEvent('delete_question', { event_category: 'setup', event_value: this.questionCount });
     },
     logUndoDeleteQuestion() {
       this.logEvent('undo_delete_question', { event_category: 'setup', event_value: this.questionCount });
     },
     logCutQuestion() {
       this.logEvent('cut_question', { event_category: 'setup', event_value: this.questionCount });
     },
     logPasteQuestion() {
       this.logEvent('paste_question', { event_category: 'setup', event_value: this.questionCount });
     },
     logMoveQuestionUp() {
       this.logEvent('move_question_up', { event_category: 'setup', event_value: 'up' });
     },
     logMoveQuestionDown() {
       this.logEvent('move_question_down', { event_category: 'setup', event_value: 'down' });
     },
   },
 }
</script>

<template>
  <div>
    <div class="questions">

      <transition-group
        appear name="question-transition"
      >
        <div
          v-for="(question, index) in answerSheet.questions"
          is="edit-question"
          :key="question.id"

          @delete-question="deleteQuestion(question, true)"
          @add-question-above="addQuestionAbove(question)"
          @move-question-up="moveQuestionUp(question)"
          @move-question-down="moveQuestionDown(question)"
          @cut-question="cutQuestion(question)"
          @copy-question="copyQuestion(question)"
          @paste-question-above="pasteQuestionAbove(question)"
          @preview-question="previewQuestion(question)"
          :question="question"
          :placeholderPoints="answerSheet.defaultPoints"
          :index="index"
          :maxIndex="answerSheet.questionCount"
          ref="editQuestion"
        >
        </div>
      </transition-group>
    </div>


    <b-row class="mt-4">
      <b-col cols="12" md="9">
        <div v-if="showTipReturnKey" class="mt-2">
          <NoteBadge>
            <font-awesome-icon :icon="[ 'far', 'lightbulb' ]" /> Tip</NoteBadge>
            Shortcut:
            <b-badge variant="primary">New Question</b-badge>
            by pressing <NoteBadge>&lt;Ctrl-Return&gt;</NoteBadge>
        </div>
      </b-col>
      <b-col cols="12" md="3" class="text-right">
        <div class="add-button">
          <KeyPress :key-code="13" event="keydown" :modifiers="['ctrlKey']" @pressed="addQuestion()" />
          <button class="btn-primary"
            @click.prevent="addQuestion"
            title="Add a new Question to the end of the list <Ctrl-Return>"
          >New Question</button>
        </div>
      </b-col>
    </b-row>

    <b-toast id="question-deleted" variant="warning" solid class="text-right">
      <template v-slot:toast-title>
        Question {{lastDeletedQuestionNumber}} deleted
      </template>
      <div class="text-right">
        <b-button size="sm" @click.prevent="undoDeleteQuestion" variant="outline-secondary">
          <span class="mr-1 ml-1"><font-awesome-icon :icon="[ 'fas', 'undo-alt' ]" /></span>
          Oops, Undo!
        </b-button>
      </div>
    </b-toast>


    <PreviewEditModalQuestion
      v-if="previewSelectedQuestion"
      :answerSheet="answerSheet"
      :previewSelectedQuestion.sync="previewSelectedQuestion"
      :previewSessionStatus.sync="previewSessionStatus"
      :questionNumberMax="answerSheet.questionCount"
      @focus-question-index="onFocusQuestionIndex($event)"
      @copy-preview-question="copyPreviewQuestion($event)"
    >
      <template #modal-title>
        Preview
        <font-awesome-icon :icon="['fas', 'film']" class="ml-1" />
        <small class="ml-3">Question as seen by the teams</small>
      </template>
    </PreviewEditModalQuestion>


    <BottomButtonBar>
      <b-col cols="4">
        {{questionCount}} Question{{maybePlural(questionCount)}}
      </b-col>
      <b-col cols="8" class="text-right mr-0">
        <!-- Alt-S to save -->
        <KeyPress :key-code="83" event="keydown" :modifiers="['altKey']" @pressed="saveAnswerSheet()" />
        <b-button
          variant="secondary"
          @click.prevent="saveAnswerSheet()"
          title="Save the answer sheet <Alt-S>"
        >Save Quiz</b-button>
        <b-button
          variant="light"
          @click="cancelAnswerSheet()"
          title="Discard any changes made to the answer sheet"
          class="ml-3"
        >Cancel</b-button>
      </b-col>
    </BottomButtonBar>

  </div>
</template>

<style scoped>

 .question-transition-enter-active, .question-transition-leave-active {
   transition: opacity 0.4s ease;
 }

 .question-transition-enter, .question-transition-leave-to {
   opacity: 0
 }

 .question-transition-move {
   transition: transform 0.4s ease;
 }

</style>
