<script>

 import TeamAnswerCorrectnessIcon from '@/components/quizmaster/run/TeamAnswerCorrectnessIcon.vue';
 import TeamAnswerPointsButtons from '@/components/quizmaster/run/TeamAnswerPointsButtons.vue';

 export default {
   name: 'TeamAnswerCell',
   components: {
     TeamAnswerCorrectnessIcon,
     TeamAnswerPointsButtons,
   },

   props: [
     'masterQuestion',
     'teamAnswer',
     'masterAnswerScore',
     'isCurrentQuestion',
     'isSelectedQuestion',
     'isSelectedAnswer',
     'isQuestionAsked',
   ],

   computed: {
     teamPointsType() {
       return this.isSelectedQuestion ? "number" : null;
     },
     teamPointsStepNumber() {
       return this.isSelectedQuestion ? 0.5 : null;
     },
     displayAnswer() {
       return this.teamAnswer.isConfirmed ?
              this.effectiveAnswer :
              "... waiting ...";
     },
     effectiveAnswer() {
       return this.teamAnswer.effectiveAnswer( this.masterQuestion );
     },

     isTeamAnswerIncorrect() {
       return this.masterAnswerScore.isTeamAnswerIncorrect(this.teamAnswer);
     },

     maybeLongEffectiveAnswer() {
       const answer = this.effectiveAnswer || "";
       if ( answer.length <= 20 ) return "";
       return answer;
     }
   },

   methods: {
     selectTeamAnswer() {
       this.$emit('select-team-answer', this.teamAnswer);
     },
     onMasterAnswerScoreChanged() {
       this.$emit('master-answer-score-changed');
     },
     selectQuestion() {
       this.$emit('select-question');
     },
   },
 }
</script>

<template>
  <div
    @click.stop="selectTeamAnswer()"
    class="is-answer-cell grid-cell-height"
    :class="{
      'is-current-question': isCurrentQuestion,
      'is-selected-question': isSelectedQuestion,
      'is-selected-answer': isSelectedAnswer,
    }"
  >
    <div class="d-flex">
      <b-input-group
        size="sm"
        v-b-tooltip.v-dark.hover.left="maybeLongEffectiveAnswer"
      >
        <b-input-group-prepend v-if="teamAnswer.questionVote === true">
          <b-input-group-text title="Upvoted">
            <span class="is-crazy-hack invisible">a</span>
            <small>
              <font-awesome-icon :icon="['fas', 'heart']" />
            </small>
          </b-input-group-text>
        </b-input-group-prepend>
        <b-input-group-prepend v-if="teamAnswer.questionVote === false">
          <b-input-group-text title="Downvoted">
            <span class="is-crazy-hack invisible">a</span>
            <span></span><small><font-awesome-icon :icon="['fas', 'poo']" /></small>
          </b-input-group-text>
        </b-input-group-prepend>

        <b-input
          class="team-answer"
          :value="displayAnswer"
          size="sm"
          readonly
        />
        <TeamAnswerCorrectnessIcon
          :teamAnswer="teamAnswer"
          :masterAnswerScore="masterAnswerScore"
          :isQuestionAsked="isQuestionAsked"
        />
        <b-input-group-append>
          <b-input
            class="team-points"
            :disabled="isTeamAnswerIncorrect"
            title="Number of points to award for a correct answer"
            size="sm"
            :type="teamPointsType"
            :step="teamPointsStepNumber"
            v-model="masterAnswerScore.pointsAwarded"
            @focus="selectQuestion"
            @change="onMasterAnswerScoreChanged"
          />
        </b-input-group-append>

        <TeamAnswerPointsButtons
          v-if="isSelectedQuestion"
          class="ml-1"
          :teamAnswer="teamAnswer"
          :masterAnswerScore="masterAnswerScore"
          v-on="$listeners"
        />
      </b-input-group>
    </div>
  </div>
</template>

<style>
 /* Ignore mouse events on disabled fields so they can be handled by
    the @click on the question column */
 .is-answer-cell input[disabled] {
   pointer-events: none;
 }

</style>

<style scoped>

 .team-answers input {
   display: inline;
 }

 input.team-answer {
   flex-grow: 1;
 }

 .is-selected-question.is-selected-answer {
   margin-left: calc(-0.7rem + 3px);
   padding-left: calc(0.7rem - 3px);

   margin-right: calc(-1rem + 3px);
   padding-right: calc(1rem - 3px);

   outline: dotted 4px black;
 }

 input.team-points {
   width: 2.5rem;
 }

 .is-selected-question input.team-points {
   width: 3.5rem;
 }

 /* Chrome: For some bizarre reason, adding the prepend to the last
    row adds some weird offset in the grid column unless there's a
    regular charater in there. So hide it. */
 .is-crazy-hack {
   width: 0px;
 }

</style>
