
<script>

 import CollapsibleCard from '@/components/share/CollapsibleCard';
 import TeamAnswerPointsButtons from '@/components/quizmaster/run/TeamAnswerPointsButtons';
 import TeamAnswerCell from '@/components/quizmaster/run/TeamAnswerCell';

 import TeamAnswer from '../../models/team/answer.js';
 import MasterQuestion from '../../models/master/question.js';
 import MasterAnswerScore from '../../models/master/answer-score.js';

 export default {
   name: 'MasterRunQuizInstructions',
   components: {
     CollapsibleCard,
     TeamAnswerPointsButtons,
     TeamAnswerCell,
   },

   data() {
     return {
       fakeTeamAnswer: new TeamAnswer({ answer: "Valentina Tereshkova", isConfirmed: true }),
       fakeMasterAnswerScore: new MasterAnswerScore({
         isCorrect: null,
         pointsAwarded: 1,
       }),
       fakeMasterQuestion: new MasterQuestion({}),
     };
   },
 };

</script>

<template>
  <b-card >
    <div role="tablist">
      <CollapsibleCard accordion="instructions" title="Stages of the Quiz" :visible="true">
        <b-card-text>
          <ol>
            <li>Quiz Questions
              <ul>
                <li>You ask each question, one at a time</li>
                <li>The teams answer questions</li>
                <li>You can mark their answers, or do it later</li>
              </ul>
            </li>
            <li>Teams finish and submit their answers</li>
            <li>You mark their final answers</li>
            <li>Teams can see their individual score
              <ul>
                <li>You announce the correct answers</li>
                <li>You announce the winners on the leaderboard</li>
              </ul>
            </li>
            <li>Teams can see the leaderboard</li>
          </ol>
        </b-card-text>
        <b-card-text>
          See what will happen next by expanding the boxes below.
        </b-card-text>
        <b-card-text>
          Yes, it is a lot to read...
        </b-card-text>
      </CollapsibleCard>

      <CollapsibleCard class="mt-2" accordion="instructions" title="Quiz Questions: Asking Questions">

        <h5>Asking questions</h5>
        <b-card-text>
          The questions become visible to the teams one at a time as
          you move along.
        </b-card-text>
        <b-card-text>
          You probably want to read each question out and then give
          them some time to answer.
        </b-card-text>
        <b-card-text>
          When the quiz starts, we're at the first question. After
          that, advance by clicking
          <b-badge size="sm" variant="primary">Ask Next Question</b-badge>.
        </b-card-text>

      </CollapsibleCard>

      <CollapsibleCard class="mt-2" accordion="instructions" title="Quiz Questions: Submitting Answers">
        <h5>Submitting Answers</h5>
        <b-card-text>
          The teams can enter an answer and
          <b-badge size="sm" variant="primary">Submit</b-badge>
          it for you to mark. They can re-submit a different answer if
          they change their minds, until the end of the quiz.
        </b-card-text>

      </CollapsibleCard>

      <CollapsibleCard class="mt-2" accordion="instructions" title="Quiz Questions: Marking Answers">
        <h5>Marking answers</h5>
        <b-card-text>
          You can mark submitted answers. They look like this:
          <b-row class="mt-3">
            <b-col cols="10" offset="1">
              <TeamAnswerCell
                :masterQuestion="fakeMasterQuestion"
                :masterAnswerScore="fakeMasterAnswerScore"
                :teamAnswer="fakeTeamAnswer"
                :isCurrentQuestion="false"
                :isSelectedQuestion="true"
                :isSelectedAnswer="false"
                :isQuestionAsked="true"
              />
            </b-col>
          </b-row>

        </b-card-text>
        <b-card-text>
          You can:
          <ul>
            <li>
               mark answers in your spare time between the questions
            </li>
            <li>
              wait until end of the quiz and mark them all in one go
            </li>
          </ul>
        </b-card-text>
        <b-card-text>
          <div class="float-right">
            <TeamAnswerPointsButtons
              :teamAnswer="fakeTeamAnswer"
              :masterAnswerScore="fakeMasterAnswerScore"
              class="d-inline-block"
            />
          </div>
          Click the
          buttons to mark the answer correct / incorrect.
        </b-card-text>
        <b-card-text>
          For <strong>partially correct answers</strong>, mark the
          answer <i>correct</i> and change the number of points awarded.
        </b-card-text>
      </CollapsibleCard>

      <CollapsibleCard class="mt-2" accordion="instructions" title="Quiz Questions: Finish Questions">
        <h5>Submit all Answers</h5>
        <b-card-text>
          When you've asked all the questions, click
          <b-badge size="sm" variant="primary">Submit all Answers</b-badge>
          to advance to the next stage, where the teams can submit
          their final answers to the all the questions.
        </b-card-text>
        <b-card-text>
          It may be that you have come up with more questions, or need
          to ask a tie-breaker. If that's the case, you can
          <b-badge size="sm" variant="secondary">Ask a New Question</b-badge>
          beyond the ones originally on the answer sheet.
        </b-card-text>
      </CollapsibleCard>

      <CollapsibleCard class="mt-2" accordion="instructions" title="Submit all Answers">
        <b-card-text>
          Now we wait for the teams to catch up.
        </b-card-text>
        <b-card-text>
          After you have asked all your questions, the teams will need
          some time to enter and
          <b-badge size="sm" variant="primary">Submit</b-badge>
          all their remaining answers.
        </b-card-text>
        <b-card-text>
          While you wait, you can mark any submitted answers that trickle in.
        </b-card-text>
        <b-card-text>
          When you have given the teams enough time to answer the
          questions, click
          <b-badge size="sm" variant="primary">Mark Answers</b-badge>
          to go on to the next stage.
        </b-card-text>
      </CollapsibleCard>

      <CollapsibleCard class="mt-2" accordion="instructions" title="Mark Answers">
        <b-card-text>
          At the <strong>Mark Answers</strong> stage, the teams can no longer change
          their submitted answers.
        </b-card-text>
        <b-card-text>
          If needed, you should mark any remaining answers, and then
          continue with
          <b-badge size="sm" variant="primary">Reveal Scoring</b-badge>.
        </b-card-text>
        <b-card-text>
          This stage is your last chance to go back and ask further
          questions.
        </b-card-text>
      </CollapsibleCard>

      <CollapsibleCard class="mt-2" accordion="instructions" title="Reveal Scoring">
        <h5>Teams can now see their <u>own</u> scores</h5>
        <b-card-text>
          The teams can see <i>their own marks</i> and the total
          score.  They can not see the <i>other teams scores</i> or their
          place on the leaderboard.
        </b-card-text>

        <h5>Announce the correct answers</h5>
        <b-card-text>
          You probably want to go through the questions and the
          correct answers.
        </b-card-text>
        <b-card-text>
          Click on each question to read it out, or move on with the
          <b-badge size="sm" variant="outline-secondary">Next →</b-badge> button.
        </b-card-text>
        <b-card-text>
          Note that <strong>you can still change the marking</strong> and
          award more points if (when) the teams complain about things.
        </b-card-text>


        <h5>Announce the leaderboard</h5>
        <b-card-text>
          The teams can't see the lederboard yet, so you may want to
          reveal the winners yourself before the next stage:
          <b-badge size="sm" variant="primary">Leaderboard</b-badge>.
        </b-card-text>

      </CollapsibleCard>

      <CollapsibleCard class="mt-2" accordion="instructions" title="Leaderboard">
        <b-card-text>
          The teams can now see the full leaderboard with each others total
          score and ranking.
        </b-card-text>
        <b-card-text>
          That's it, that's how to run the quiz!
        </b-card-text>

        <slot></slot>

      </CollapsibleCard>


      <CollapsibleCard class="mt-2" accordion="instructions" title="Bonus: Efficiently marking answers">

        <h5>Efficiently marking answers</h5>
        <b-card-text>
          With lots of teams, marking answers gets tedious. You can
          use the keyboard to mark answers.
        </b-card-text>
        <b-card-text>
          Start by clicking
          <b-badge size="sm" variant="primary">Mark Some Answers</b-badge>,
          which will select an unmarked answer.
        </b-card-text>
        <b-card-text>
          Mark the selected answer <kbd>A</kbd> <strong>correct</strong>,
          or <kbd>Z</kbd> <strong>incorrect</strong>. <i>This also jumps
          to the next unmarked answer.</i>
        </b-card-text>
        <b-card-text>
          There will be instructions on the screen to guide you.
        </b-card-text>



        <slot></slot>

      </CollapsibleCard>

    </div>
  </b-card>
</template>

<style scoped>

 h5 {
   color: black;
 }

</style>
