
<script>

 import WithAnalytics from '@/components/mixins/WithAnalytics';

 export default {
   name: 'SignupCard',
   mixins: [ WithAnalytics ],
   props: [
     'redirect',
     'clickLoginEvent',
   ],

   data() {
     return {
       username: null,
       password: null,
       cheese: null,
       shouldRememberUsername: false,

       errorMessage: null,
     };
   },

   methods: {
     async signUp() {
       this.errorMessage = "";
       const { error } = await this.$store.dispatch(
         'auth/signUp',
         [
           this.username,
           this.password,
           this.cheese,
           this.shouldRememberUsername,
         ],
       );

       if ( error ) {
         this.errorMessage = error;
       }
       else {
         this.logEvent("sign_up", { event_label: "email" });
         if ( this.redirect ) {
           this.$router.push("/");
         }
       }
     },

     clickLogin() {
       this.$emit('click-login');
     },
   }
 };

</script>


<template>
  <b-card header="Sign up!" class="mb-5">
    <slot name="header"></slot>
    <b-form>
      <b-form-group label="Username:">
        <b-form-input
          v-model="username"
          placeholder="E-mail"
          name="email"
          id="email"
          type="email"
          autocomplete="section-signup username"
          required
        />
      </b-form-group>
      <b-form-group label="Password:">
        <b-form-input
          v-model="password"
          name="password"
          id="password"
          autocomplete="section-signup new-password"
          type="password"
          required
        />
      </b-form-group>
      <b-form-group title="Remember the username on this computer">
        <b-form-checkbox v-model="shouldRememberUsername">
          Remember my username
        </b-form-checkbox>
      </b-form-group>

      <b-form-group label="What is the last letter in the word 'Quiz'?">
        <b-form-input
          v-model="cheese"
          name="cheese"
          id="password"
          required
        />
      </b-form-group>

      <div class="text-right">
        <b-button type="submit" @click.prevent="signUp()" variant="primary" class="mr-2">Sign up!</b-button>
        <span class="mt-2">
          Or
          <span v-if="clickLoginEvent">
            <a @click.prevent="clickLogin" href="#">Log in!</a>
          </span>
          <span v-else>
            <router-link to="/log-in">Log in!</router-link>
          </span>
        </span>
      </div>

      <div v-if="errorMessage" class="text-danger mt-3">
        {{errorMessage}}
      </div>

      <div class="mt-2 text-center">
        <strong>
          <small>
            By clicking "Sign up!", you agree to the
            <router-link to="terms-and-conditions">Terms and Conditions</router-link>
            and
            <router-link to="privacy-policy">Privacy Policy</router-link>
          </small>
        </strong>
      </div>

    </b-form>
    <slot name="footer"></slot>
  </b-card>
</template>

<style scoped>

</style>
