'use strict';

import ModelBase from './model-base.js';

export default class Contributor extends ModelBase {
  constructor({ id, userAccountId, moniker, createdTime, updatedTime }) {
    super();
    return {
      id,
      userAccountId,
      moniker,
      createdTime: this.ensureDate(createdTime),
      updatedTime: this.ensureDate(updatedTime),
    };
  }
}
