
import ModelBase from '../model-base.js';
import UserPermissionRole from './permission-role.js';

// This is the data in UserAccount, without the sensitive parts,
// e.g. the password
export default class UserAccountExtract extends ModelBase {

  constructor({ id, username, userPermissionRoles }) {
    super();
    return {
      id,
      username,
      userPermissionRoles: this.ensureArrayObjects(
        userPermissionRoles,
        UserPermissionRole,
      ),


      hasPermissionRole(roleName) {
        return !! this.userPermissionRoles.find(role => role.id === roleName);
      },
      get hasAnyAdminPermissionRole() {
        return !! this.userPermissionRoles.length;
      },
    };
  }

}
