<script>

 import _ from 'lodash';

 import TopNavBar from '@/components/common/TopNavBar.vue';
 import BottomFooter from '@/components/common/BottomFooter.vue';
 import AdminAnswerSheetSessions from '@/components/admin/AdminAnswerSheetSessions.vue';

 export default {
   name: 'AdminAnswerSheetsPage',
   components: {
     TopNavBar,
     BottomFooter,
     AdminAnswerSheetSessions,
   },

   data() {
     return {
       userAccounts: [],
     };
   },

   computed: {
     answerSheetsAndAccounts() {
       return this.userAccounts.map(
         userAccount => userAccount.masterAnswerSheets.map(masterAnswerSheet => {
           return {
             id: `${userAccount.id}-${masterAnswerSheet.id}`,
             masterAnswerSheet,
             userAccount,
             masterAnswerSheetCount: userAccount.masterAnswerSheets.length,
           };
         })
       ).flat();
     },
     recentAnswerSheetsAndAccounts() {
       return _.sortBy(
         this.answerSheetsAndAccounts, [
           answerSheetsAndAccount => answerSheetsAndAccount
             .masterAnswerSheet.updatedTime
         ],
       ).slice().reverse();
     },
   },

   async created() {
     const userAccounts = await this.$store.dispatch('adminUserAccount/fetchList');
     this.userAccounts = userAccounts;
   },

 }

</script>

<template>
  <div class="is-footer-flex-container">
    <TopNavBar />

    <b-container class="is-footer-flex-content">

      <h2>Answer Sheets</h2>

      <h5 class="mt-4">
        {{answerSheetsAndAccounts.length}} Answer Sheets
      </h5>

      <b-table-simple class="mt-4">
        <b-thead>
          <b-tr>
            <b-th>Email</b-th>
            <b-th>Date</b-th>
            <b-th>Quiz</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="answerSheetAndAccount in recentAnswerSheetsAndAccounts" :key="answerSheetAndAccount.id">
            <b-td>
              {{answerSheetAndAccount.userAccount.username}}
              ({{answerSheetAndAccount.masterAnswerSheetCount}})
            </b-td>
            <b-td class="text-nowrap" :title="answerSheetAndAccount.masterAnswerSheet.updatedTime.toISOString()">
              {{answerSheetAndAccount.masterAnswerSheet.updatedTime.toISOString().split("T")[0]}}
            </b-td>
            <b-td>
              <AdminAnswerSheetSessions
                :masterAnswerSheet="answerSheetAndAccount.masterAnswerSheet"
              />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

    </b-container>

    <BottomFooter />
  </div>
</template>

<style scoped>

 .table.answersheet-list > tbody > tr:first-child > td {
    border: none;
}

</style>
