
import axios from '@/store/axios';
import UserAccount from '../../../models/user/account.js';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {

    async fetchList({ dispatch }) {
      const userSession = await dispatch('auth/ensureSession', {}, { root: true });

      const res = await axios.get(`/api/user-session/${userSession.id}/admin/user-account/`);
      const userAccounts = res.data.userAccounts || [];
      const modelUserAccounts = userAccounts.map(
        userAccount => new UserAccount(userAccount)
      );
      return modelUserAccounts;
    },
  },

};
