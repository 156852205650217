import { render, staticRenderFns } from "./ScoringGrid.vue?vue&type=template&id=4591804c&"
import script from "./ScoringGrid.vue?vue&type=script&lang=js&"
export * from "./ScoringGrid.vue?vue&type=script&lang=js&"
import style0 from "./ScoringGrid.vue?vue&type=style&index=0&id=4591804c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports