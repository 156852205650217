
export default class TeamTeam {
  constructor({ id, sessionId, name }) {
    return {
      id,
      sessionId,
      name,

      get teamPath() {
        return `/team/game/${sessionId}/team/${this.id}`;
      },
      get teamUrl() {
        return `${process.env.VUE_APP_BASE_URL}${this.teamPath}`;
      },
    };
  }
}

