
<script>
 import PreviewModalQuestion from '@/components/quizmaster/Preview/ModalQuestion.vue';

 export default {
   name: 'PreviewEditModalQuestion',
   components: {
     PreviewModalQuestion,
   },
   props: [
     'answerSheet',
     'previewSelectedQuestion',
     'modalTitle',
     'questionNumberMax',
     'previewSessionStatus',
   ],

   computed: {

     previewShowTeamAnswerScore() {
       return this.previewSessionStatus !== 'askQuestions';
     },

     previewSessionStatusProperty: {
       get ()      { return this.previewSessionStatus },
       set (value) { this.$emit('update:previewSessionStatus', value) },
     },
   },

   methods: {
   },
 }
</script>

<template>
  <PreviewModalQuestion
    :answerSheet="answerSheet"
    :maybeQuiz="null"
    :previewSelectedQuestion="previewSelectedQuestion"
    :questionNumberMax="questionNumberMax"
    :previewShowTeamAnswerScore="previewShowTeamAnswerScore"
    v-on="$listeners"
  >
    <template #modal-title>
      <slot name="modal-title"></slot>
    </template>
    <template #left-footer>
      <div class="flex-grow-1">
        <b-form-radio-group
          v-model="previewSessionStatusProperty"
          :options="[
            { 'text': 'Quiz Questions', 'value': 'askQuestions' },
            { 'text': 'Reveal Scoring', 'value': 'revealScoring' },
          ]"
          buttons
          button-variant="outline-info"
        >
        </b-form-radio-group>
      </div>
    </template>
  </PreviewModalQuestion>
</template>
