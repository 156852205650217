<script>

 import IconHeart from '@/components/quizmaster/run/Icon/Heart.vue';

 export default {
   name: 'QuestionHeadingCell',
   components: { IconHeart },
   props: [
     'quiz',
     'masterQuestionsMetadata',
     'question',
     'questionNumber',
     'isCurrentQuestion',
     'showCurrentQuestion',
     'showQuestionDifficulty',
   ],

   computed: {
     highightCurrentQuestion() {
       if ( ! this.showCurrentQuestion ) return false;
       return this.isCurrentQuestion;
     },

     isMasterQuestionRevealed() {
       if ( this.quiz.session.sessionStatus.hasRevealedScoring ) return true;
       return this.quiz.isMasterQuestionRevealed( this.question );
     },

     isMasterQuestionHardest() {
       if ( ! this.showQuestionDifficulty ) return false;
       if ( ! this.masterQuestionsMetadata ) return false;
       return this.masterQuestionsMetadata.isQuestionHardest( this.question );
     },

     isMasterQuestionEasiest() {
       if ( ! this.showQuestionDifficulty ) return false;
       if ( ! this.masterQuestionsMetadata ) return false;
       return this.masterQuestionsMetadata.isQuestionEasiest( this.question );
     },

     isMasterQuestionPopular() {
       if ( ! this.showQuestionDifficulty ) return false;
       if ( ! this.masterQuestionsMetadata ) return false;
       return this.masterQuestionsMetadata.isQuestionPopular( this.question );
     },

     titleText() {
       if ( this.tooltipText ) return null;
       return this.question.effectiveCorrectAnswer;
     },
     tooltipText() {
       const answer = this.question.effectiveCorrectAnswer;
       if ( answer.length < 33 ) return null;
       return answer;
     },
     tooltipOptions() {
       return {
         title: this.tooltipText,
         disabled: ! this.tooltipText,
       };
     },
   },
 }
</script>

<template>
  <div
    class="grid-cell-width overflow-hidden"
    :class="{
      'is-current-question': highightCurrentQuestion,
    }"
    :title="titleText"
    v-b-tooltip.hover.topright.v-dark="tooltipOptions"
  >
    <span
      v-if="isMasterQuestionRevealed"
      title="This question has been revealed to the teams and they can no longer submit answers. They can see their score and the correct answer."
      class="mr-1 text-primary"
    >
      <font-awesome-icon :icon="['fas', 'user-friends']" class="mr-1" />
    </span>

    <IconHeart
      v-if="isMasterQuestionPopular"
      title="This was a popular question"
      class="mr-1"
    />

    <b-badge
      v-if="isMasterQuestionHardest"
      title="This was a hard question"
      variant="danger"
      class="mr-1"
    >Hard</b-badge>
    <span v-else>
      <b-badge
        v-if="isMasterQuestionEasiest"
        title="This was an easy question"
        variant="success"
        class="mr-1"
      >Easy</b-badge>
    </span>
    <strong>{{questionNumber}}.</strong>
    {{question.effectiveCorrectAnswer}}
  </div>
</template>

<style scoped>

 .is-current-question {
   font-weight: bold;
 }

</style>
