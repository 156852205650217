
<script>

 import IconUnscored from '@/components/quizmaster/run/Icon/Unscored.vue';

 export default {
   name: "StatusActionsMarkAnswers",
   components: {
     IconUnscored,
   },

   props: [
     'quiz',
   ],

   computed: {
     unmarkedAnswerCount() {
       return this.quiz.unmarkedAnswerCount;
     },
     disableRevealCorrectAnswers() {
       return this.quiz.unmarkedAnswerCount > 0;
     },
     areThereUnrevealedQuestions() {
       return this.quiz.areThereUnrevealedQuestions;
     },
     areThereNoRevealedQuestions() {
       return ! this.quiz.areThereRevealedQuestions;
     },
   },

   methods: {
     clickRevealScoring() {
       this.$emit('reveal-scoring');
     },
     clickAskMoreQuestions() {
       this.$emit('ask-more-questions');
     },
   },

 };

</script>


<template>
  <b-row>
    <b-col>
      <b-card>
        <b-row>
          <b-col cols="8">
            <div>
              <span v-if="unmarkedAnswerCount > 0">
                <!-- All answers need marking -->
                <b-card-text>
                  <big class="mr-2"><font-awesome-icon :icon="['fas', 'highlighter']" /></big>
                  <big><strong>
                    Please mark all {{unmarkedAnswerCount}}
                    remaining answer{{maybePlural(unmarkedAnswerCount)}}
                    indicated by
                    <small><IconUnscored /></small>
                    below.
                    </strong></big>
                </b-card-text>
                <b-card-text>
                  You can use <kbd>Q</kbd> and <kbd>W</kbd> to move to the next unmarked answer.
                </b-card-text>
                <b-card-text>
                  Use <kbd>A</kbd> and <kbd>Z</kbd> to mark an answer correct/incorrect and move on to the next.
                </b-card-text>
              </span>
              <span v-else>
                <!-- All answers are marked -->
                <span v-if="areThereUnrevealedQuestions">
                  <b-card-text>
                    Great! All answers are marked.
                  </b-card-text>

                  <b-card-text>
                    <big class="mr-2"><font-awesome-icon :icon="['far', 'eye']" /></big>
                    Select <strong>each question</strong> and click
                    <b-badge size="sm" variant="primary">Reveal Q to teams</b-badge>.
                    <br />
                    <big class="mr-2"><font-awesome-icon :icon="['fas', 'bullhorn']" /></big>
                    Read out the correct answer and any extra trivia.
                  </b-card-text>

                  <span v-if="areThereNoRevealedQuestions">
                    <b-card-text>
                      <strong>or</strong>
                    </b-card-text>

                    <b-card-text>
                      <big class="mr-2"><font-awesome-icon :icon="['far', 'eye']" /></big>
                      Reveal <strong>all questions</strong> by clicking
                      <b-badge size="sm" variant="primary">Reveal Scoring &amp; Answers</b-badge>!
                      <br />
                      This will show each team <strong>their individual score</strong>, and all remaining <strong>correct answers</strong>.
                    </b-card-text>
                  </span>
                </span>
                <span v-else>
                  <b-card-text>
                    Great! All answers are <strong>revealed to the teams</strong>.
                  </b-card-text>
                  <b-card-text>
                    <big class="mr-2"><font-awesome-icon :icon="['far', 'eye']" /></big>
                    Click <b-badge size="sm" variant="primary">Reveal Scoring &amp; Answers</b-badge>
                    to show each team <strong>their individual score</strong>.
                  </b-card-text>
                </span>
              </span>
            </div>
          </b-col>


          <b-col cols="4">
            <div class="actions-buttons text-right">
              <div>
                <div>
                  <b-button @click.prevent="clickAskMoreQuestions()" variant="secondary">
                    <small><font-awesome-icon :icon="[ 'fas', 'undo-alt' ]" /></small> Ask More Questions
                  </b-button>
                </div>
                <div>
                  <b-button
                    @click.prevent="clickRevealScoring()"
                    :disabled="disableRevealCorrectAnswers"
                    variant="primary"
                    class="mt-1"
                  >
                    ➞ Reveal Scoring &amp; Answers
                  </b-button>
                </div>
                <div v-if="disableRevealCorrectAnswers">
                  <small><strong>Mark all answers to continue</strong></small>
                </div>
              </div>
            </div>

          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<style scoped>

</style>
