'use strict';

import ModelBase from '../model-base.js';
import LibraryAllowance from './allowance.js';

export default class LibraryAllowances extends ModelBase {
  constructor({ view, borrow } = {}) {
    super();
    view   = view   || new LibraryAllowance();
    borrow = borrow || new LibraryAllowance();
    return {
      view: this.ensureObject(view, LibraryAllowance),
      borrow: this.ensureObject(borrow, LibraryAllowance),
    };
  }
}
