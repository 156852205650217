
<script>

 import EditQuestion from '@/components/quizmaster/EditQuestion';
 // import PreviewEditModalQuestion from '@/components/quizmaster/Preview/EditModalQuestion.vue';

 export default {
   name: 'AdminLibraryQuestionItem',
   components: {
     EditQuestion,
     // PreviewEditModalQuestion,
   },
   props: [
     'adminLibraryQuestions',
     'libraryQuestion',
     'masterQuestion',
     'index',
     'maxIndex',
     'allVueInputTags',
   ],

   data() {
     return {
       isEditable: false,
       isLibraryQuestionDetailsOpen: null,

       selectedContributorId: null,


       questionTag: '',
       questionTags: [],
     };
   },

   created() {
     this.selectedContributorId = this.libraryQuestion.contributorId;

     let libraryCategoryTag = null;
     if ( this.libraryQuestion.libraryCategoryId ) {
       const libraryCategory = this.adminLibraryQuestions.getLibraryCategoryById(
         this.libraryQuestion.libraryCategoryId,
       );
       libraryCategoryTag = libraryCategory.getVueTagsInputCategory();
     }

     let libraryDifficultyTag = null;
     if ( this.libraryQuestion.libraryDifficultyId ) {
       const libraryDifficulty = this.adminLibraryQuestions.getLibraryDifficultyById(
         this.libraryQuestion.libraryDifficultyId,
       );
       libraryDifficultyTag = libraryDifficulty.getVueTagsInputDifficulty();
     }

     this.questionTags = [
       libraryCategoryTag,
       libraryDifficultyTag,
       ...this.libraryQuestion.libraryTags.map(
         libraryTag => libraryTag.getVueTagsInputTag(),
       )
     ].filter(tag => !! tag);
   },

   computed: {

     selectedContributorOptions() {
       return this.adminLibraryQuestions.orderedContributors;
     },

     questionNumber() {
       return this.index + 1;
     },

     contributor() {
       return this.adminLibraryQuestions.getContributorById(
         this.libraryQuestion.contributorId,
       );
     },

     contributorMoniker() {
       const contributor = this.contributor;
       if ( ! contributor ) return "N/A";
       return contributor.moniker;
     },

     libraryCategory() {
       return this.adminLibraryQuestions.getLibraryCategoryById(
         this.libraryQuestion.libraryCategoryId,
       );
     },

     libraryDifficulty() {
       return this.adminLibraryQuestions.getLibraryDifficultyById(
         this.libraryQuestion.libraryDifficultyId,
       );
     },

     availableQuestionTags() {
       ///JPL: don't look at the text, look at the questionTags
       return this.allVueInputTags.filter(i => {
         return i.text.toLowerCase().indexOf( this.questionTag.toLowerCase() ) !== -1;
       });
     },
   },

   methods: {
     toggleEditable() {
       this.isEditable = ! this.isEditable;
     },

     makeEditable() {
       this.isEditable = true;
     },

     // Master question
     saveQuestion(masterQuestion) {
       this.$emit('save-question', masterQuestion);
     },

     copyQuestion(masterQuestion) {
       this.$emit('copy-question', masterQuestion);
     },

     deleteQuestion(masterQuestion) {
       this.$emit('delete-question', masterQuestion);
     },

     previewQuestion(masterQuestion) {
       this.$emit('preview-question', masterQuestion);
     },

     // Library question metadata, e.g. contributor
     saveLibraryQuestion() {
       this.libraryQuestion.contributorId = this.selectedContributorId;
       this.libraryQuestion.libraryTags = this.questionTags.filter(
         tag => tag.type === "libraryTag",
       ).map(tag => tag.item);

       const [ libraryCategoryId ] = this.questionTags.filter(
         tag => tag.type === "libraryCategory",
       ).map(tag => tag.item.id);
       this.libraryQuestion.libraryCategoryId = libraryCategoryId || null;

       const [ libraryDifficultyId ] = this.questionTags.filter(
         tag => tag.type === "libraryDifficulty",
       ).map(tag => tag.item.id);
       this.libraryQuestion.libraryDifficultyId = libraryDifficultyId || null;

       this.$emit('save-library-question', this.libraryQuestion);
       this.isLibraryQuestionDetailsOpen = null;
     },

     setNewQuestionVueInputTags(newTags) {
       this.questionTags = this.normalizedVueInputTags(newTags);
     },

     normalizedVueInputTags(vueInputTags) {
       const libraryCategoryInputTags = vueInputTags.filter(
         tag => tag.type === "libraryCategory",
       );

       const libraryDifficultyInputTags = vueInputTags.filter(
         tag => tag.type === "libraryDifficulty",
       );

       const libraryTagInputTags = vueInputTags.filter(
         tag => tag.type === "libraryTag",
       );

       return [
         ...libraryDifficultyInputTags.slice(-1).filter(tag => !! tag),
         ...libraryCategoryInputTags.slice(-1).filter(tag => !! tag),
         ...libraryTagInputTags.sort(tag => tag.text),
       ];
     },
   },
 }
</script>

<template>
  <div>
    <b-row
      v-if="!isEditable"
      key="DisplayQuestion"
      @click.prevent="toggleEditable"
      class="border-top pt-1 pb-2"
    >
      <b-col cols="7">
        <div class="d-flex">
          <div class="mr-2"><strong>{{questionNumber}}</strong></div>
          <div>
            {{libraryQuestion.question}}
          </div>
        </div>
      </b-col>
      <b-col cols="3">
        <small>
          <span v-if="libraryDifficulty">
            <b-badge variant="info">{{libraryDifficulty.name}}</b-badge>
          </span>
          <span v-if="libraryCategory" class="ml-1">
            <b-badge variant="warning">{{libraryCategory.name}}</b-badge>
          </span>
          <span
            v-for="libraryTag in libraryQuestion.libraryTags"
            :key="libraryTag.id"
            class="ml-1"
          >
            <b-badge variant="primary">{{libraryTag.name}}</b-badge>
          </span>
        </small>
      </b-col>
      <b-col cols="2">
        <small>
          {{contributorMoniker}}
        </small>
      </b-col>
    </b-row>

    <b-row
      v-if="isEditable" :key="`EditQuestion${libraryQuestion.id}`"
      class="pt-3 mt-2 mb-3 border-top"
    >
      <b-col cols="12">
        <EditQuestion
          :key="masterQuestion.id"
          :hasOrdering="false"
          :hasSaveButton="true"
          :question="masterQuestion"
          :placeholderPoints="1"
          :index="index"
          :maxIndex="maxIndex"
          @save-question="saveQuestion(masterQuestion)"
          @preview-question="previewQuestion(masterQuestion)"
          @copy-question="copyQuestion(masterQuestion)"
          @delete-question="deleteQuestion(masterQuestion)"
        />
        <v-details v-model="isLibraryQuestionDetailsOpen" class="is-below-card ml-2">
          <summary>
            <small>
              <span>
                Contributor:
                <strong>{{contributorMoniker}}</strong>
              </span>

              <span class="ml-2">
                Tags:

                <span v-if="libraryDifficulty">
                  <b-badge variant="info" class="mr-1">
                    {{libraryDifficulty.name}}
                  </b-badge>
                </span>

                <span v-if="libraryCategory">
                  <b-badge variant="warning" class="mr-1">
                    {{libraryCategory.name}}
                  </b-badge>
                </span>

                <span
                  v-for="libraryTag in libraryQuestion.libraryTags"
                  :key="`${libraryQuestion.id}-${libraryTag.id}`"
                >
                  <b-badge variant="primary" class="mr-1">
                    {{libraryTag.name}}
                  </b-badge>
                </span>
              </span>

            </small>
          </summary>

          <b-row class="mt-1">
            <b-col cols="10">
              <div class="w-100">

                <vue-tags-input
                  v-model="questionTag"
                  :tags="questionTags"
                  :autocomplete-items="availableQuestionTags"
                  :add-only-from-autocomplete="true"
                  @tags-changed="setNewQuestionVueInputTags"
                  class="is-tags-input"
                >
                  <div
                    slot="autocomplete-item"
                    slot-scope="props"
                    class="my-item"
                    @click="props.performAdd(props.item)"
                  >
                    <div v-if="props.item.type === 'libraryCategory'" class="d-flex">
                      <div class="flex-grow-1">
                        <b-badge variant="warning">
                          {{props.item.item.name}}
                        </b-badge>
                      </div>
                      <div v-if="props.item.item.description" class="mr-2">
                        <small>{{props.item.item.description}}</small>
                      </div>
                    </div>
                    <div v-if="props.item.type === 'libraryDifficulty'" class="d-flex">
                      <div class="flex-grow-1">
                        <b-badge variant="info">
                          {{props.item.item.name}}
                        </b-badge>
                      </div>
                      <div v-if="props.item.item.description" class="mr-2">
                        <small>{{props.item.item.description}}</small>
                      </div>
                    </div>
                    <div v-if="props.item.type === 'libraryTag'" class="d-flex">
                      <div class="flex-grow-1">
                        <b-badge variant="primary">
                          {{props.item.text}}
                        </b-badge>
                      </div>
                      <div v-if="props.item.item.description" class="mr-2">
                        <small>{{props.item.item.description}}</small>
                      </div>
                    </div>
                  </div>
                </vue-tags-input>
              </div>

              <div class="mt-1">
                <b-form-select
                  v-model="selectedContributorId"
                  :options="selectedContributorOptions"
                  value-field="id" text-field="moniker"
                />
              </div>
            </b-col>
            <b-col cols="2" class="text-right align-self-end">
              <b-button
                @click.prevent="saveLibraryQuestion()"
                variant="primary"
                size="sm"
              >Save</b-button>
            </b-col>
          </b-row>


        </v-details>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>

 .is-below-card {
   margin-top: -0.9rem;
 }

</style>

<style>

 .is-tags-input .ti-input {
   border-radius: 0.25rem;
 }

 .w-100 .is-tags-input.vue-tags-input {
   max-width: 100%;
 }

 .ti-tag.is-vue-tags-input.is-library-tag {
   background-color: #446E9B;
   border-radius: 0.25rem;
 }

 .ti-tag.is-vue-tags-input.is-library-category {
   background-color: #D47500;
   border-radius: 0.25rem;
 }

 .ti-tag.is-vue-tags-input.is-library-difficulty {
   background-color: #3399F3;
   border-radius: 0.25rem;
 }

 /* Autocomplete */
 .vue-tags-input .ti-item.ti-selected-item {
    background: #eee;
    color: #283944;
  }

</style>
