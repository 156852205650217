
<script>

 import WithAnalytics from '@/components/mixins/WithAnalytics';
 import TopNavBar from '@/components/common/TopNavBar.vue';
 import BottomFooter from '@/components/common/BottomFooter.vue';
 import ModalConfirm from '@/components/share/Modal/Confirm';
 import CollapsibleCard from '@/components/share/CollapsibleCard';
 import CopyableUrl from '@/components/share/CopyableUrl';
 import LoadingSpinner from '@/components/share/LoadingSpinner';
 import TransitionFade from '@/components/transition/TransitionFade';

 import SessionStatusBadge from '@/components/elements/SessionStatusBadge';
 import RunQuizInstructions from '@/components/quizmaster/RunQuizInstructions.vue';
 import InviteTeamListItem from '@/components/quizmaster/InviteTeamListItem';

 import WithSessionTeams from '@/components/quizmaster/mixins/WithSessionTeams';
 import WithSocketIo from '@/components/mixins/WithSocketIo';

 export default {
   name: 'InviteTeam',
   mixins: [
     WithSessionTeams,
     WithSocketIo,
     WithAnalytics,
   ],
   components: {
     TopNavBar,
     BottomFooter,
     ModalConfirm,
     CollapsibleCard,
     CopyableUrl,
     LoadingSpinner,
     TransitionFade,

     InviteTeamListItem,
     RunQuizInstructions,
     SessionStatusBadge,
   },

   props: [
     // WithSessionTeams: loadAnswerSheetId, loadSessionId
   ],
   data() {
     return {
       // WithSessionTeams: answerSheet, session, teamTeams
     };
   },

   async created() {
     await this
       .loadAnswerSheetSessionsTeamsByPropsIds()
       .then(() => {
         if ( ! this.loadSessionId ) {
           // This page was the /new to create a new session, so we
           // should now redirect the URL to the session that is
           // actually loaded

           this.logCreateSession();
           this.$router.push({
             path: `/quizmaster/answer-sheet/${this.answerSheet.id}/invite-team/${this.session.id}`,
           });
         }
       })
       .then(() => {
         this.subscribeToWebsockets();
       })
       .catch(() => {
         this.$router.push({ path: `/team/invitation/${this.loadSessionId}` });
       });
   },


   computed: {
     inviteUrl() {
       return this.session.inviteUrl;
     },
   },

   methods: {

     subscribeToWebsockets() {
       const io = this.socketIo;

       io.on("teamSessionChanged", message => {
         console.log('websocket teamSessionChanged', message);
         // Just use the message as a trigger to reload the teamTeams,
         // since the message itself only contains the names of the teams
         this.loadTeamsBySessionId( this.session.id );
       });

       io.on('connect', () => {
         this.emitTeamSubscribeToTeamSession();
       });

       // Subscribe the first time, after waiting to load the
       // session. The 'connect' event already happened.
       this.emitTeamSubscribeToTeamSession();
     },

     emitTeamSubscribeToTeamSession() {
       const io = this.socketIo;
       io.emit(
         'teamSubscribeToTeamSession',
         { sessionId: this.session.id },
       );
     },

     doneWithSession() {
       this.$router.push({ name: "HomePage" });
     },

     deleteSession() {
       this.$bvModal.show('bv-modal-delete-session');
     },
     deleteSessionOk() {
       ///JPL: delete any teams in the session first
       this.answerSheet.deleteSession( this.session );
       this.saveAnswerSheet().then(() => this.$router.push({ name: "HomePage" }));
       this.logDeleteSession();
     },

     onDeleteTeam(team) {
       this
         .$store
         .dispatch(
           'masterTeam/deleteObject',
           { sessionId: this.session.id, id: team.id },
         )
         .then(() => {
           this.teamTeams = this.teamTeams.filter(t => t !== team);
         });
       this.logDeleteTeam();
     },

     logCreateSession() {
       this.logEvent('create_session', { event_category: 'run', event_label: 'invite' });
     },
     logDeleteSession() {
       this.logEvent('delete_session', { event_category: 'run', event_label: 'invite' });
     },
     logDeleteTeam() {
       this.logEvent('delete_team', { event_category: 'run', event_label: 'invite' });
     },
   },
 }
</script>

<template>
  <div class="is-footer-flex-container">
    <TopNavBar />
    <b-container v-if="session" class="is-footer-flex-content edit-session">


      <b-card class="mt-3">
        <template v-slot:header>
          <b-row>
            <b-col>
              <h3>Run Quiz</h3>
            </b-col>
            <b-col class="text-right">
              <div class="mt-0 mb-2">
                <SessionStatusBadge :sessionStatus="session.sessionStatus" />
              </div>
            </b-col>
          </b-row>

        </template>

        <b-row>

          <b-col>

            <TransitionFade>
              <div v-if="teamTeams.length" key="teams-list">
                <div class="mt-4 mb-3 text-center">
                  <router-link
                    :to="{ path: `/quizmaster/answer-sheet/${answerSheet.id}/run-quiz/${session.id}` }"
                  >
                    <b-button
                      v-if="session.sessionStatus.isNotStarted"
                      size="lg"
                      variant="primary"
                    >
                      <font-awesome-icon :icon="['far', 'play-circle']" class="mr-1" />
                      Start Quiz
                    </b-button>
                    <b-button
                      v-else-if="session.sessionStatus.isInProgress"
                      size="lg"
                      variant="primary"
                    >
                      <font-awesome-icon :icon="['far', 'play-circle']" class="mr-1" />
                      Continue Quiz
                    </b-button>
                    <b-button
                      v-else-if="session.sessionStatus.isFinished"
                      size="md"
                      variant="secondary"
                    >
                      <font-awesome-icon :icon="['fas', 'list-ol']" class="mr-1" />
                      View Finished Quiz
                    </b-button>
                  </router-link>

                  <div v-if="session.sessionStatus.canTeamsSignUp" class="text-center mt-2">
                    <small>(Teams can still join while the quiz is in progress)</small>
                  </div>
                </div>

                <h3 class="mb-3">Teams</h3>

                <div v-for="(team, index) in teamTeams" :key="index">
                  <InviteTeamListItem
                    :teamTeam="team"
                    @delete-team="onDeleteTeam(team)"
                  />
                </div>

              </div>
              <div v-else key="teams-waiting">
                <div class="mb-2">
                  <h3 class="mb-3">Teams</h3>

                  <div class="text-center">
                    <p>
                      Waiting for teams to register...
                    </p>
                    <h1>
                      <font-awesome-icon :icon="[ 'fa', 'spinner' ]" class="slow-spin" />
                    </h1>
                    <p class="mt-4">
                      <strong><big>↓</big> Invite people by sending them the URL below! <big>↓</big></strong>
                    </p>
                  </div>
                </div>
              </div>
            </TransitionFade>

          </b-col>

          <b-col cols="12" sm="6" class="mt-3">
            <b-card>
              <p>
                <strong class="mt-3 mb-3 text-center">
                  If this is your first time here, please read this!
                </strong>
              </p>

              <p>See what will happen next, when you start the quiz</p>

              <div class="text-center">
                <b-button v-b-toggle.sidebar-instructions variant="outline-primary">
                  Show instructions
                </b-button>
              </div>
            </b-card>

            <b-sidebar
              id="sidebar-instructions"
              title="How to run the quiz"
              shadow
              right
              width="35rem"
              backdrop
              backdrop-variant="dark"
            >
              <RunQuizInstructions>
                <b-card-text>
                  You are now ready to click
                  <b-badge size="sm" variant="primary">Start Quiz</b-badge>
                  on this page.
                </b-card-text>
              </RunQuizInstructions>
            </b-sidebar>
          </b-col>
        </b-row>

      </b-card>


      <b-card class="mt-3">
        <template v-slot:header>
          <h3>Invite Teams to <strong>{{answerSheet.name}}</strong></h3>
        </template>


        <b-row>
          <b-col cols="12" md="6">

            <CopyableUrl label="Invite URL:" :url="inviteUrl">
              Copy invite URL!
            </CopyableUrl>

            Preview the
            <router-link :to="{ path: session.invitePath }" target="_blank">Quiz invitation page</router-link>
            (this is what the teams see when signing up).


          </b-col>

          <b-col cols="12" md="6">
            <div role="tablist">
              <CollapsibleCard accordion="how-does-invite-work" title="How does this work?" :visible="true">
                <b-card-text>
                  <b>Send the invite URL to people</b> you want to
                  join this Quiz. Anyone with the link can join.
                </b-card-text>
                <b-card-text>
                  If you have a video conference running, you can
                  paste it into the chat.
                </b-card-text>

                <b-card-text>
                  Each team gets a private URL they can use to
                  participate in the Quiz.
                </b-card-text>
              </CollapsibleCard>

            </div>

          </b-col>
        </b-row>
      </b-card>



      <b-row class="mt-3 mb-4">
        <b-col>
          <b-button class="mr-4" variant="outline-danger" @click.prevent="deleteSession()">Delete this Session</b-button>
        </b-col>
        <b-col class="text-right">
          <b-button variant="primary" @click.prevent="doneWithSession()">Back to Quiz list</b-button>
        </b-col>
      </b-row>
    </b-container><!-- if session -->
    <LoadingSpinner v-else /><!-- if session // Loading screen -->

    <ModalConfirm elementId="bv-modal-delete-session"
      @click-ok="deleteSessionOk"
    >
      <template #title>
        Delete Quiz Session?
      </template>
      <template #message>
        <p>
          Are you sure you want to delete this Quiz Session?
        </p>
      </template>
    </ModalConfirm>

    <BottomFooter />
  </div>

</template>


<style scoped>

 .url-input {
   font-weight: bold;
   font-size: 1.1rem;
 }

 .slow-spin {
   -webkit-animation: fa-spin 15s infinite linear;
   animation: fa-spin 15s infinite linear;
 }

</style>
